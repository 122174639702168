import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import ShapeOne from "../../assets/common/svg/shape-1.svg";
import ShapeTwo from "../../assets/common/svg/shape-2.svg";
import ShapeThree from "../../assets/common/svg/shape-3.svg";
import { ReactComponent as VasanaLogo } from "../../assets/header/svg/vasana_logo.svg";
import VSNImage from "../../shared/VSNImage";

const useStyles = makeStyles((theme) => ({
  authWrapper: {
    marginTop: 40,
  },
  authDetail: {
    maxWidth: 530,
    boxShadow: "0px 0px 30px rgba(66, 68, 80, 0.1)",
    borderRadius: 16,
    border: "1px solid #EAEDF0",
    background: theme.palette.primary.light,
    padding: [[30]],
    margin: "0px 16px",
    [theme.breakpoints.down("sm")]: {
      padding: [[22]],
    },
  },
  orDivider: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "23px",
    color: "#86650a",
    position: "relative",
    display: "block",
    textAlign: "center",
    marginBottom: 60,
    "&::after,&::before": {
      position: "absolute",
      content: '""',
      width: "39%",
      top: "50%",
      transform: "translateY(-50%)",
      height: 1,
      backgroundColor: "#fbcebd",
      [theme.breakpoints.down("sm")]: {
        width: "32%",
      },
    },
    "&::after": {
      left: 0,
    },
    "&::before": {
      right: 0,
    },
  },
  shape: {
    position: "absolute",
  },
  shapeOne: {
    top: 80,
    left: -65,
    opacity: 0.4,
    zIndex: -1,
  },
  shapeTwo: {
    right: 0,
    top: "30%",
    transform: "translateY(-30%)",
    opacity: 0.9,
    zIndex: -1,
  },
  shapeThree: {
    left: 0,
    bottom: 40,
    opacity: 0.5,
  },
  redirectText: {
    "&.MuiTypography-root": {
      textAlign: "center",
      color: "#424450",
      fontSize: 16,
      marginTop: 34,
      "& a": {
        fontWeight: 700,
        color: "#E87637",
      },
    },
  },
  socialMediaIcon: {
    position: "static",
    "&.MuiStack-root": {
      gap: "48px",
      marginTop: 52,
      [theme.breakpoints.down("md")]: {
        marginTop: 26,
        gap: "30px",
      },
      "& button": {
        padding: 0,
        "& span": {
          "& img": {
            [theme.breakpoints.down("md")]: {
              width: 30,
            },
          },
        },
      },
    },
  },
  adminLogin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  logo: {
    "& img": {
      cursor: "grab",
      [theme.breakpoints.down("md")]: {
        width: 140,
        marginBottom: 10,
      },
    },
    cursor: "pointer",
  },
}));

const AdminLayout = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.adminLogin}>
      <Box className={classes.authDetail}>
        <Box align="center" className={classes.logo}>
          <VasanaLogo width="176px" height="52" onClick={() => navigate("/")} />
        </Box>
        <Outlet />

        <VSNImage
          src={ShapeOne}
          className={`${classes.shape} ${classes.shapeOne}`}
          alt="ShapeOne"
        />
        <VSNImage
          src={ShapeTwo}
          className={`${classes.shape} ${classes.shapeTwo}`}
          alt="ShapeTwo"
        />
        <VSNImage
          src={ShapeThree}
          className={`${classes.shape} ${classes.shapeThree}`}
          alt="ShapeThree"
        />
      </Box>
    </Box>
  );
};

export default AdminLayout;
