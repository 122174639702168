import React from "react";
import { createDepartmentContainer } from "../../../container/createDepartment.container";
import CreateDepartmentContainer from "./CreateDepartmentContainer";

const CreateDepartmentModal = ({ toggleModelShow, mode }) => {
  const { handleMobileChange, ...rest } = createDepartmentContainer({
    toggleModelShow,
  });
  return (
    <CreateDepartmentContainer
      {...{ handleMobileChange, mode, ...rest, toggleModelShow }}
    />
  );
};

export default CreateDepartmentModal;
