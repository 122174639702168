import styled from "@emotion/styled";
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ReactComponent as Close } from "../assets/admin/svg/close-icon.svg";
import VSNDrawer from "./VSNDrawer";

const useStyles = makeStyles((theme) => ({
  title: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      lineHeight: "31.25px",
      fontSize: "24px",
      padding: "20px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        padding: "15px 0",
        lineHeight: "26px",
      },
    },
  },
  modalHeader: {
    position: "sticky",
    top: 0,
    background: theme.palette.primary.light,
    zIndex: 4,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    background: "rgba(217, 217, 217, 0.79)",
  },
  "& .MuiPaper-root": {
    borderRadius: 5,
    padding: "0 25px",
    maxWidth: 775,
    width: "100%",
    boxShadow: "none",
    margin: 16,
    overflowX: "hidden",
    zIndex: 10,
    maxHeight: "calc(100vh - 34px)",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px",
    },
  },
}));

const CloseIcon = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 20,
  right: 0,
  cursor: "pointer",
  zIndex: 7,
  float: "right",
  display: "block",
  background: "white",
  [theme.breakpoints.down("sm")]: {
    top: 15,
  },
}));

const VSNModel = ({
  open,
  onClose,
  children,
  className,
  mobileDrawer = false,
  title,
}) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  if (!mobileDrawer) {
    return (
      <StyledDialog
        open={open || false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={className}
      >
        <Box position="relative">
          {onClose && (
            <CloseIcon onClick={onClose}>
              <Close />
            </CloseIcon>
          )}
          {children}
        </Box>
      </StyledDialog>
    );
  }
  return (
    <>
      {!matches ? (
        <>
          <StyledDialog
            open={open || false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={className}
          >
            <Box className={classes.modalHeader}>
              {title ? (
                <DialogTitle className={classes.title}>{title}</DialogTitle>
              ) : null}
              <Box>
                {onClose && (
                  <CloseIcon onClick={onClose}>
                    <Close />
                  </CloseIcon>
                )}
              </Box>
              {title ? (
                <Divider
                  sx={{
                    borderColor: "#EAEDF0",
                    marginLeft: { sm: "-25px", xs: "-15px" },
                    marginRight: { sm: "-25px", xs: "-15px" },
                  }}
                />
              ) : null}
            </Box>

            {children}
          </StyledDialog>
        </>
      ) : (
        <VSNDrawer {...{ open, onClose, title }}>{children}</VSNDrawer>
      )}
    </>
  );
};

export default VSNModel;
