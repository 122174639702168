import ReactRouterPrompt from "react-router-prompt";
import React from "react";

const VSNPrompt = ({ when, msg, ConfirmationBox, ...rest }) => {
  return (
    <ReactRouterPrompt when={when} {...rest}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive && (
          <div className="lightbox">
            <div className="container">
              <ConfirmationBox onConfirm={onConfirm} onCancel={onCancel} />
            </div>
          </div>
        )
      }
    </ReactRouterPrompt>
  );
};

export default VSNPrompt;
