import { makeStyles } from "@mui/styles";
import bgFlower from "../../assets/Teams/png/team-bg-banner.png";
import star from "../../assets/assesments/svg/orange-star.svg";

export const IsiResultStyle = makeStyles((theme) => ({
  mainLayout: {
    overflow: "hidden",
    padding: [[30, 30, 45, 30]],
    position: "relative",
    height: "100%",
    zIndex: 2,
    background:
      "linear-gradient(92deg, #F6FCFF 7.06%, rgba(255, 244, 238, 0.50) 86.87%)",
    backgroundSize: "cover",
    minHeight: "calc(100vh - 105px)",
    "&:before": {
      position: "absolute",
      content: "''",
      left: 0,
      right: 0,
      top: 0,
      background: `url(${bgFlower})`,
      zIndex: -1,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      minHeight: 310,
      [theme.breakpoints.down("sm")]: {
        minHeight: 140,
        backgroundSize: "cover",
        backgroundPosition: "0 -10px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[30, 16, 42, 16]],
    },
  },
  assessmentReportCard: {
    padding: "20px 25px",
    borderRadius: 10,
    background: "#FCFCFD",
    border: `2px solid ${theme.palette.primary.light} `,
    boxShadow: "0px 7.7982px 17.32934px 0px rgba(81, 125, 132, 0.07)",
    position: "relative",
    zIndex: 3,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  box1: {
    position: "absolute",
    top: 0,
    left: "36%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  box3: {
    position: "absolute",
    top: 20,
    right: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  box4: {
    position: "absolute",
    top: "20%",
    right: "28%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      lineHeight: "28px",
      fontWeight: 700,
      color: theme.palette.secondary.main,
      position: "relative",
      "&:before": {
        position: "absolute",
        content: "''",
        top: 2,
        height: 22,
        left: -25,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: theme.palette.primary.basic,
        [theme.breakpoints.down("sm")]: {
          left: -15,
        },
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 20,
        lineHeight: "26px",
      },
    },
  },
  mdHeadingMain: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: 700,
      color: "#86650A",
      position: "relative",
      "& span": {
        color: "#555FDD",
        fontSize: 22,
      },
      "&:before": {
        position: "absolute",
        content: "''",
        top: 2,
        height: 22,
        left: -25,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: theme.palette.primary.basic,
        [theme.breakpoints.down("sm")]: {
          left: -15,
        },
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 18,
        lineHeight: "22px",
      },
    },
  },
  desc: {
    fontSize: 16,
    lineHeight: "20px",
    color: theme.palette.secondary.grey,
    fontWeight: 400,
    "& span": {
      color: "#86650A",
    },
  },
  notesList: {
    "&.MuiList-root": {
      display: "flex",
      flexWrap: "wrap",
      padding: "10px 17px 10px 20px",
      background: `linear-gradient(90deg,#FCF0FF 20%,#F2FAFF 60%)`,
      borderRadius: 10,
      marginTop: 13,
      [theme.breakpoints.down("md")]: {
        padding: 10,
        display: "block",
      },
      "& li": {
        display: "inline-block",
        position: "relative",
        paddingLeft: 24,
        paddingRight: 24,
        width: "50%",
        color: theme.palette.secondary.main,
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        "&:before": {
          position: "absolute",
          content: `url(${star})`,
          left: 0,
          top: 10,
        },
        "&:nth-child(even)": {
          "&::after": {
            content: "''",
            position: "absolute",
            left: -10,
            width: 1,
            top: 4,
            bottom: 4,
            background: "rgba(0, 0, 0, 0.12)",
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },
        },
      },
    },
  },
  dailyFunctioning: {
    "&.MuiList-root": {
      borderRadius: 10,
      marginTop: 13,
      background: "#FCFCFD",
      padding: 0,
      "& li": {
        border: `1px solid #EAEDF0`,
        padding: "15px 10px",
        borderRadius: 10,
        alignItems: "start",
        "& img": {
          width: 18,
          height: 18,
        },
        "& p": {
          paddingLeft: 12,
          fontSize: 16,
          fontWeight: 400,
        },
        "& + li": {
          marginTop: 12,
        },
      },
    },
  },
  chartContainer: {
    "& .apexcharts-gridline": {
      strokeDasharray: "4 !important",
    },
  },
  blueFlower: {
    position: "absolute",
    bottom: -15,
    right: "35%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pinkFlower: {
    position: "absolute",
    top: 5,
    left: "15%",
    [theme.breakpoints.down("sm")]: {
      left: "10%",
      top: "35%",
      height: 50,
      width: "auto",
    },
  },
  threeLines: {
    position: "absolute",
    top: 40,
    right: 13,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      height: 26,
      top: "15%",
      width: "auto",
    },
  },
}));
