import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { isBool } from "../utils/javascript";
import VSNTypography from "./VSNTypography";

const useStyles = makeStyles((theme) => ({
  redText: {
    "&.MuiTypography-root": {
      color: "red",
    },
  },
}));

const VSNErrorMessage = ({
  formError,
  errorMessage,
  errorValidationMessage,
  formData,
}) => {
  const classes = useStyles();
  return (
    <>
      {formError ? (
        <Box sx={{ minHeight: 24 }}>
          <VSNTypography className={classes?.redText}>
            {isBool(formError)
              ? !formData
                ? errorMessage
                : errorValidationMessage
              : formError}
          </VSNTypography>
        </Box>
      ) : null}
    </>
  );
};

export default VSNErrorMessage;
