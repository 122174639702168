import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import VSNButton from "../../shared/VSNButton";
import VSNLabel from "../../shared/VSNLabel";
import VSNTypography from "../../shared/VSNTypography";
import ControlForm from "../../shared/form/ControlForm";
import VSNErrorMessage from "../../shared/VSNErrorMessage";

export const useStyles = makeStyles((theme) => ({
  modalHead: {
    "&.MuiStack-root": {
      padding: "20px 0px",
      borderBottom: "1px solid #EAEDF0",
      "& h4": {
        fontWeight: 600,
        fontSize: 24,
        padding: 0,
        lineHeight: "36px",
        color: theme.palette.secondary.main,
        [theme.breakpoints.down("md")]: {
          marginBottom: 12,
          fontSize: 22,
          lineHeight: "30px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 18,
        },
      },
      "& .MuiIconButton-root": {
        backgroundColor: "#E8763740",
        height: 25,
        width: 25,
        "& svg": {
          margin: 0,
          marginBottom: 0,
          color: theme.palette.primary.basic,
        },
        "&:hover": {
          backgroundColor: "#E8763760",
        },
      },
    },
  },
  question: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      lineHeight: "23.44px",
      fontSize: "18px",
      color: "#2D2F39",
      position: "relative",
      display: "inline-block",
      "& .MuiFormLabel-root": {
        position: "absolute",
        left: "100%",
      },
    },
  },
  description: {
    "&.MuiTypography-root": {
      color: "#747787",
      fontWeight: 400,
      lineHeight: "18.23px",
      fontSize: "14px",
      marginBottom: 12,
    },
  },
  button_groups: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#F6F6FD",
    padding: "20px 25px",
    gap: 10,
    marginLeft: "-25px",
    marginRight: "-25px",
    position: "sticky",
    bottom: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "15px",
      gap: 10,
      marginLeft: "-15px",
      marginRight: "-15px",
    },
  },
  button: {
    "&.MuiButtonBase-root": {
      color: theme.palette.primary.light,
      padding: "13px 25px",
      borderRadius: 10,
      minWidth: 176,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& span.icon": {
        marginLeft: 8,
        display: "inline-block",
      },
      "& .content": {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .loader": {
        "& .MuiCircularProgress-root": {
          height: "16px !important",
          width: "16px !important",
        },
      },
      "&.link": {
        background: "white",
        border: "1px solid #555FDD",
        color: "#555FDD",
        "& .linkIcon": {
          height: "16px",
          width: "16px",
          display: "inline-block",
          marginRight: 10,
        },
      },
    },
  },
}));

const AddAdminModal = ({
  buttons,
  addAdminFields,
  makeAdmin,
  handelAdminDataChange,
  addAdminMemberInfo,
  addAdminMemberInfoError,
}) => {
  const classes = useStyles();
  const isAddingMember = useSelector(
    (state) => state.api?.loader?.addMembersLoader
  );

  return (
    <Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          makeAdmin();
        }}
      >
        <Stack position="relative">
          {addAdminFields?.data?.map(
            ({ question, description, value, isRequired }, index) => {
              return (
                <React.Fragment key={index}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.modalHead}
                  >
                    <VSNTypography className={classes.question} variant="h4">
                      {isRequired && <VSNLabel required />} {question}
                    </VSNTypography>
                  </Stack>
                  <Box mt={1.5} key={index} width="100%">
                    <VSNTypography className={classes.description} variant="h6">
                      {description}
                    </VSNTypography>

                    {value?.map((props, index) => {
                      const {
                        isRequired,
                        label,
                        errorMessage,
                        name,
                        value,
                        getValBy,
                        md,
                        doNotShow,
                        control,
                        ...attribute
                      } = props;
                      return (
                        !doNotShow && (
                          <Grid
                            item
                            xs={12}
                            md={md}
                            key={index}
                            mb={{ xs: 2, md: 2.5 }}
                          >
                            <VSNLabel required={isRequired} label={label} />
                            <ControlForm
                              {...{
                                ...attribute,
                                elementId: index,
                                label,
                                name,
                                onChange: handelAdminDataChange,
                                value:
                                  addAdminMemberInfo?.[name] ??
                                  (control === "select" ? 0 : ""),
                                control,
                              }}
                            />
                            <VSNErrorMessage
                              {...{
                                formError: addAdminMemberInfoError?.[name],
                                errorMessage,
                              }}
                            />
                          </Grid>
                        )
                      );
                    })}
                  </Box>
                </React.Fragment>
              );
            }
          )}
        </Stack>
      </form>
      <Box className={classes.button_groups}>
        {buttons &&
          buttons.map(
            (
              {
                label,
                handleClick,
                hasLoader,
                icon,
                className,
                link,
                disabled,
              },
              index
            ) => (
              <VSNButton
                isLoading={hasLoader && isAddingMember}
                className={`${classes.button} ${className}`}
                key={index}
                handleClick={handleClick}
                disabled={disabled}
              >
                {label}
              </VSNButton>
            )
          )}
      </Box>
    </Box>
  );
};

export default AddAdminModal;
