import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const VSNPagination = ({ count, shape, variant, onChange, ...rest }) => {
  return (
    <Stack spacing={2}>
      <Pagination
        count={count}
        variant={variant}
        shape={shape}
        onChange={onChange}
        {...rest}
      />
    </Stack>
  );
};
export default VSNPagination;
