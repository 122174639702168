import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formAttribute } from "../description/signUp.description";
import { ON_FORM_CHANGE, SET_ERROR } from "../redux/constants";
import { checkValid } from "../shared/validation";
import ApiCalls from "../utils/apiCall";
import { equal, length } from "../utils/javascript";
import { setErrors } from "../shared/CustomFunc";
import { endPoints } from "../utils/constant";

export const signUpContainer = () => {
  const { signInCall } = ApiCalls();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const departmentId = queryParams.get("department_id");
  const teamId = queryParams.get("team_id");
  const formPath = { parent: "signUp" };
  const { form } = useSelector((state) => state);

  const signUpData = useSelector(
    (state) => state.form.formData?.[formPath?.parent]
  );
  const signUpError = useSelector(
    (state) => state.form.formErrors?.[formPath?.parent]
  );
  const signupLoader = useSelector(
    (state) => state.api?.loader?.[formPath?.parent]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setErrors({ errors: signUpError, value, name, formPath }));
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: { ...signUpData, [name]: value },
        },
      },
    });
  };

  useEffect(() => {
    dispatch(setErrors({ isClear: true, formPath }));

    // if (Auth === "true" || token) {
    //   localStorage.setItem("currentAssessmentId", assessmentIds?.vasanaType);
    //   localStorage.setItem(
    //     "currentAssessmentType",
    //     assessmentTypes?.vasanaType
    //   );
    //   navigate("/get-started");
    // }
  }, []);

  const handleLoginBtn = () => {
    navigate("/login");
  };

  const apiCall = async () => {
    const data = await signInCall({
      userData: { user_name: signUpData.username },
      showToastMessage: true,
      endPoint: `${endPoints.memberSignUp}?${
        departmentId ? `&department_id=${departmentId}` : ""
      }${teamId ? `&team_id=${teamId}` : ""}`,
      needLoader: true,
      parent: formPath.parent,
    });

    if (data?.status) {
      navigate("/otp");
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let errors = signUpError;
    const passwordValue = signUpData?.password;
    formAttribute?.forEach((obj) => {
      const { validateAs, name } = obj;
      errors = {
        ...errors,
        [name]: validateAs
          ? checkValid({
              validateAs,
              name,
              value: signUpData?.[name],
              passwordValue,
            })
          : "",
      };
      dispatch({
        type: SET_ERROR,
        payload: {
          formErrors: {
            ...form.formErrors,
            [formPath.parent]: { ...errors },
          },
        },
      });
    });
    if (
      errors === undefined ||
      equal(length(Object.values(errors)?.filter(Boolean)))
    ) {
      apiCall();
    }
  };
  return {
    formAttribute,
    handleChange,
    formPath,
    onSubmit,
    handleLoginBtn,
    signInCall,
    signupLoader,
  };
};
