import {
  convertFirstLetterToLowerCase,
  length,
  trimmedVal,
} from "../utils/javascript";

const notEmpty = (val) => {
  const regex = /[^\s]$/;
  return val ? !regex.test(trimmedVal(val)) : true;
};

// this allow only string
//   const regex = /^[a-zA-Z]+( [a-zA-Z]+)*$/;

// this allow string and number
//   const regex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;

// this allow name , string, - , '
export const nameValidation = (val) => {
  const regex = /^[a-zA-Z0-9\-,_,',!,?,.,, \p{L}]+$/u;
  return regex.test(trimmedVal(val));
};

export const emailValidation = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email?.toLowerCase());
};

export const mobileValidation = (val) => {
  const regex = /^\+[1-9]{1}[0-9]{3,14}$/;
  return regex.test(val);
};

const confirmPassword = (confirmPass = "", pass = "") => {
  return confirmPass === pass ? false : "Password not matched";
};
const zipCode = (value) => value?.length !== 5;

export const checkValid = ({ validateAs, value, passwordValue, label }) => {
  let error;

  switch (validateAs) {
    case "name":
      error = notEmpty(value);
      break;
    case "required":
      error = length(value)
        ? nameValidation(value)
          ? false
          : `${convertFirstLetterToLowerCase(
              label
            )} should only consist of alphabets`
        : true;
      break;
    case "email":
      error = !emailValidation(value);
      break;
    case "mobile":
      error = notEmpty(value) || value ? !mobileValidation(value) : false;
      break;
    case "email_mobile":
      error = !(emailValidation(value) || mobileValidation(value));
      break;
    case "confirmPasswordValidation":
      error = confirmPassword(value, passwordValue);
      break;
    case "selectMenu":
      error = !value;
      break;

    case "zipCode":
      error = length(value)
        ? zipCode(value) && "ZipCode should only consist of 5 digit."
        : "ZipCode is Required.";
      break;
    case "date":
      error = !value?.toString();
      break;

    default:
      return false;
  }
  return error;
};
