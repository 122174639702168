import { ERROR_MESSAGE } from "./errorMessage.description";

export const inviteMembersContainer = {
  label: "Invite Members",
  data: [
    {
      isOrSplit: true,
      value: [
        {
          control: "input",
          type: "file",
          name: "csv_file",
          value: "",
          placeholder: "Upload your Team Photo",
          errorMessage: ERROR_MESSAGE?.photo,
          fullWidth: true,
        },
      ],
      subdescription: "Drop CSV files here or click to upload",
    },
    {
      question: "Invite Members",
      description:
        "Add people to your organization by typing their email address.",
      value: [
        {
          control: "input",
          type: "text",
          name: "emails",
          value: "",
          validateAs: "name",
          placeholder:
            "Example anamika@gmail.com, jekson234@taglineinfotech.com",
          errorMessage: "email is required",
          fullWidth: true,
        },
      ],
    },
  ],
};
