import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import VSNTypography from "../../../shared/VSNTypography";

const useStyles = makeStyles((theme) => ({
  selectMain: {
    gap: 24,
    marginTop: 8,
    flexWrap: "wrap",
    "& .MuiStack-root": {
      height: 67,
      maxWidth: 409,
      width: "100%",
      backgroundColor: theme.palette.primary.light,
      border: "1px solid #EAEDF0",
      borderRadius: 5,
      color: theme.palette.secondary.grey,
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      padding: 13,
      boxShadow: "0px 9px 20px 0px #968FB01A",
      "&:hover": {
        color: theme.palette.primary.basic,
        borderColor: theme.palette.primary.basic,
      },
      "&.active": {
        borderColor: theme.palette.primary.basic,
        color: theme.palette.primary.basic,
        boxShadow: "0px 9px 20px 0px #968FB01A",
      },
    },
  },
  information: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxWidth: 1210,
    width: "100%",
    margin: "0 auto",
    padding: "0 15px",
    marginBottom: 40,
    "& h4": {
      fontSize: 22,
      lineHeight: "38.21px",
      fontWeight: 600,
      color: theme.palette.secondary.darkGrey,
      textAlign: "center",
      marginBottom: "8px",
      [theme.breakpoints.down("md")]: {
        lineHeight: "31px",
        marginBottom: 10,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        padding: [[0]],
        marginBottom: 10,
      },
    },
  },
}));

const MultiSelectComponent = ({
  question,
  onChange,
  value,
  onChangeMultiChoice,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.information}>
      <VSNTypography variant="h4" component="h4">
        {`${question?.order}. ${question?.phrase}`}
      </VSNTypography>
      <Stack
        direction="row"
        justifyContent="center"
        className={`${classes.selectMain}`}
      >
        {question?.choices?.map((item, key) => (
          <Stack
            justifyContent="center"
            alignItems="center"
            key={key}
            className={
              value?.value?.find((el) => el?.id === item?.id) ? "active" : ""
            }
            onClick={() => {
              onChangeMultiChoice(item, question);
            }}
          >
            {item?.phrase}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default MultiSelectComponent;
