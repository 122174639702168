import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  LOADING_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
} from "../redux/constants";
import { ApiContainer } from "../utils/api";
import {
  clearFormData,
  setErrors,
  toggleModelShow,
} from "../shared/CustomFunc";
import { LinkIcon } from "../shared/icon";
import { copyToClipboard } from "../utils/javascript";
import { emailValidation } from "../shared/validation";
import { showToast } from "../utils/toastService";
import { endPoints } from "../utils/constant";

export const teamProfileViewContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { api } = ApiContainer();
  const teamProfile = useSelector((state) => state?.api?.teamProfile);
  const topMembers = useSelector((state) => state?.api?.topMembers);
  const loader = useSelector((state) => {
    return {
      teamProfile: state?.api?.loader?.teamProfile,
      currentSelectedTeamMembers:
        state?.api?.loader?.currentSelectedTeamMembers,
      topMembers: state?.api?.loader?.topMembers,
      outsideMembersLoader: state?.api?.loader?.outsideMembersLoader,
      manageTeamMembers: state?.api?.loader?.manageTeamMembers,
      exitTeamLoader: state?.api?.loader?.exitTeamLoader,
      exitTeamAlert: state?.api?.loader?.exitTeamAlert,
    };
  });
  const form = useSelector((state) => state?.form);
  const isParticipantsOpen = useSelector(
    (state) => state.modelStates?.participants
  );
  const isaddMembersOpen = useSelector(
    (state) => state.modelStates?.addMembers
  );
  const isExitTeamOpen = useSelector((state) => state.modelStates?.exitTeam);
  const { currentSelectedTeamMembers, currentSelectedTeam } = useSelector(
    (state) => state.api
  );
  const membersToAdd =
    useSelector((state) => state?.api?.membersToAdd?.data) || [];
  const currentUserId = useSelector(
    (state) => state?.app?.userData?.user_profile?.sub
  );
  const modelState = useSelector((state) => state?.modelStates);
  const defaultProfilePictures =
    useSelector((state) => state.api?.defaultProfilePictures?.data) || [];
  const userProfile = useSelector(
    (state) => state?.app?.userData?.user_profile
  );
  const { formData, formErrors } = form;
  const { participantsModal } = formData;
  const addMemberInfo = formData?.addMemberData;
  const addMemberInfoError = formErrors?.addMemberData;
  const { teamId } = useParams();
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  const [active, setActive] = useState("");
  const [teamType, setTeamType] = useState("");
  const [currentAspect, setCurrentAspect] = useState({});
  const [isExitTeamAlertOpen, setIsExitTeamAlertOpen] = useState(false);
  const priorities = ["sa", "re", "ga", "ma"];
  // for action menu
  const [anchorEl, setAnchorEl] = useState(null);

  const getKeyWithMaxValue = (obj, priorityList) => {
    let maxKey = null;
    let maxValue = -1;

    priorityList.forEach((key) => {
      if (obj[key] > maxValue) {
        maxValue = obj[key];
        maxKey = key;
      }
    });

    return maxKey;
  };

  useEffect(() => {
    if (teamProfile?.vasana_types) {
      const maxKey = getKeyWithMaxValue(teamProfile?.vasana_types, priorities);
      setTeamType(maxKey);
    }
  }, [teamProfile]);

  const handleVideoPlay = (index) => {
    if (currentPlayingIndex === index) {
      setCurrentPlayingIndex(null);
    } else {
      setCurrentPlayingIndex(index);
    }
  };

  const getTeamProfileData = async (needLoader) => {
    const teamProfileData = await api({
      method: "GET",
      endPoint: `teams/team/${teamId}/dashboard`,
      showToastMessage: false,
      urlencoded: false,
      needLoader,
      parent: "teamProfile",
    });
    if (teamProfileData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          teamProfile: teamProfileData?.data || {},
          currentSelectedTeam: teamProfileData?.data || {},
        },
      });
    } else if (teamProfileData?.statusCode === 404) {
      navigate("/teams");
      showToast(teamProfileData?.error, "error");
    }
  };

  const getMembers = async (filters) => {
    const membersData = await api({
      method: "GET",
      endPoint: `teams/team/${teamId}/members?page=1&limit=4${
        filters?.sort_by ? `&sort_by=${filters?.sort_by}` : ``
      }`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "topMembers",
    });
    if (membersData?.status && !!membersData?.data?.success) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          topMembers: membersData?.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          topMembers: [],
        },
      });
    }
  };

  const getMembersForExplore = async () => {
    const membersData = await api({
      method: "GET",
      endPoint: `teams/team/${teamId}/members?page=1&limit=${teamProfile?.no_of_participants}`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "exploreMembers",
    });
    if (membersData?.status && !!membersData?.data?.success) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          allMembers: membersData?.data,
        },
      });
    }
  };

  useEffect(() => {
    if (teamId) {
      getTeamProfileData(true);
      getMembers();
    }
  }, [teamId]);

  useEffect(() => {
    if (teamId && teamProfile?.no_of_participants) {
      getMembersForExplore();
    }
  }, [teamId, teamProfile]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShareLink = (link) => {
    copyToClipboard(link);
    handleClose();
  };

  const handleDeleteTeam = async (id) => {
    const response = await api({
      method: "DELETE",
      endPoint: `teams/team/${id}`,
      showToastMessage: true,
      urlencoded: false,
      needLoader: true,
      parent: "deleteTeam",
    });
    if (response?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          teamProfile: {},
        },
      });
      navigate("/teams");
    } else if (response?.statusCode === 404) {
      showToast(response?.error, "error");
    }
  };

  const getDefaultProfilePictures = async () => {
    const data = await api({
      method: "GET",
      endPoint: endPoints.defaultProfilePictures,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "defaultProfilePicturesLoader",
    });

    if (data?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          defaultProfilePictures: data.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          defaultProfilePictures: [],
        },
      });
    }
  };

  const onEditTeam = async ({ teamId }) => {
    if (teamProfile && teamId) {
      if (!defaultProfilePictures?.length) await getDefaultProfilePictures();
      dispatch(toggleModelShow("editTeam"));
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            teamData: {
              ...formData?.teamData,
              name: teamProfile?.name || "",
              goal: teamProfile?.goal || "",
              id: teamProfile?.id,
              profile_picture: teamProfile?.selected_picture_id
                ? teamProfile?.profile_picture
                : null,
              photo_upload_url: teamProfile?.selected_picture_id
                ? null
                : teamProfile?.profile_picture,
            },
          },
        },
      });
    }
  };

  const onFilterChange = (e) => {
    const { value } = e?.target;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          participantsModal: {
            ...participantsModal,
            current_page: 1,
            sort_by: value || "",
          },
        },
      },
    });
    getMembers({
      sort_by: value,
    });
  };

  const onSeeAllMembers = async (parent, remainOpen) => {
    const membersData = await api({
      method: "GET",
      endPoint: `teams/team/${teamId}/members?page=1&limit=10`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent,
    });
    if (membersData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          currentSelectedTeamMembers: membersData?.data,
        },
      });
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            participantsModal: {
              ...(formData?.participantsModal
                ? { ...formData?.participantsModal }
                : {}),
              ...membersData?.data?.page,
            },
            addMemberData: {},
          },
        },
      });
      if (!remainOpen) {
        dispatch(toggleModelShow("participants"));
      }
    }
  };

  const onCloseParticipantModal = () => {
    dispatch(toggleModelShow("participants"));
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          participantsModal: {},
        },
      },
    });
    getTeamProfileData();
  };

  const addMembers = async () => {
    dispatch({
      type: LOADING_CHANGE,
      payload: { addMembersLoader: true },
    });
    const { added_members, invited_members } =
      form?.formData?.addMemberData || {};
    const data = await api({
      method: "POST",
      endPoint: `teams/team/${teamId}/manage-member`,
      data: {
        members: added_members?.map(({ user_id }) => user_id),
        action: "add",
        invited_members,
        manage_by_admin: false,
      },
      showToastMessage: true,
      urlencoded: false,
      parent: "teamData",
      needLoader: true,
    });
    if (!data?.data?.error) {
      getTeamProfileData(false);
      getMembers();
      if (currentSelectedTeam?.admins?.includes(currentUserId)) {
        onSeeAllMembers("currentSelectedTeamMembers", true);
      }
      dispatch(
        toggleModelShow(
          "addMembers",
          currentSelectedTeam?.admins?.includes(currentUserId)
        )
      );
      dispatch(clearFormData("addMemberData"));
      dispatch({
        type: LOADING_CHANGE,
        payload: { addMembersLoader: false },
      });
    } else {
      dispatch({
        type: LOADING_CHANGE,
        payload: { addMembersLoader: false },
      });
    }
  };

  const getMembersOutside = async (teamId) => {
    const membersData = await api({
      method: "GET",
      endPoint: `teams/${teamId ?? teamProfile?.id}/members/outside`,
      showToastMessage: false,
      urlencoded: false,
      parent: "outsideMembersLoader",
      needLoader: true,
      needFullResponse: true,
    });

    if (membersData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          membersToAdd: membersData.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          membersToAdd: [],
        },
      });
    }
  };

  const handleAddMembers = (flag) => {
    dispatch(toggleModelShow("addMembers", flag));
    getMembersOutside(teamId);
  };

  const setFormData = ({ name, value }) => {
    dispatch(
      setErrors({
        errors: addMemberInfoError,
        value,
        name,
        formPath: { parent: "addMemberData" },
      })
    );
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          addMemberData: {
            ...addMemberInfo,
            [name]: value,
          },
        },
      },
    });
  };

  const handleAddMemberFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ name, value });
  };

  const addMemberButtons = [
    {
      label:
        !form?.formData?.addMemberData?.added_members?.length &&
        !form?.formData?.addMemberData?.invited_members?.length
          ? "Send Invite"
          : form?.formData?.addMemberData?.added_members?.length &&
            !form?.formData?.addMemberData?.invited_members?.length
          ? "Add members"
          : "Send Invite",
      handleClick: addMembers,
      hasLoader: true,
      disabled: !(
        form?.formData?.addMemberData?.added_members?.length ||
        form?.formData?.addMemberData?.invited_members?.length
      ),
    },
    {
      label: "Copy Invitation Link",
      handleClick: () => copyToClipboard(teamProfile?.invitation_link || ""),
      className: "link",
      link: <LinkIcon />,
    },
  ];

  const onInputChange = (event, value) => {
    const isValidMail =
      emailValidation(value) && !addMemberInfo?.emailOptions?.includes(value);
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          addMemberData: {
            ...addMemberInfo,
            invited_members_input_value: value,
            ...(isValidMail ? { emailOptions: [value] } : {}),
          },
        },
      },
    });
  };

  const addMembersFields = {
    data: [
      {
        question: "Add Members",
        description:
          "Start typing a name to add members to your team or select members from the dropdown. ",
        isOrSplit: true,
        value: [
          {
            control: "select",
            type: "select",
            placeholder: "Select members",
            fullWidth: true,
            name: "added_members",
            defaultValue: "",
            options: loader?.outsideMembersLoader
              ? [{ isLoading: true }]
              : membersToAdd || [],
            variant: "standard",
          },
        ],
      },
      {
        question: "Invite a Member",
        description:
          "Add people who aren't on Vasana by typing their email address.",
        value: [
          {
            control: "input",
            placeholder: "Select or enter email",
            fullWidth: true,
            options: addMemberInfo?.emailOptions || [],
            type: "email",
            name: "invited_members",
            validateAs: "email",
            onInputChange,
          },
        ],
      },
    ],
  };

  const onEmailSelect = (name, value) => {
    if (value) {
      const errorEmail = value.find(
        (email) => !emailValidation(email?.email || email)
      );
      if (errorEmail) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              addMemberData: {
                ...addMemberInfo,
                [`${name}_input_value`]: value[value?.length - 1],
              },
            },
          },
        });
      } else {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              addMemberData: {
                ...addMemberInfo,
                [name]: errorEmail ? [...(addMemberInfo?.[name] || [])] : value,
                [`${name}_input_value`]: "",
              },
            },
          },
        });
      }
    }
  };

  const breadcrumbsRoutes = [
    {
      path: "/teams",
      label: "Teams",
    },
    {
      path: `/teams/${teamId}`,
      label: teamProfile?.name || "",
    },
  ];

  const exploreAspects = (aspect) => {
    setCurrentAspect(aspect);
  };

  const onClose = () => {
    setCurrentAspect();
  };

  const exitFromTeam = async () => {
    const data = await api({
      method: "POST",
      endPoint: `teams/${teamId}/exit-team`,
      data: {
        team_id: teamId,
        a: currentUserId,
      },
      showToastMessage: true,
      urlencoded: false,
      parent: "exitTeamLoader",
      needLoader: true,
    });

    if (!data?.data?.error && data?.data?.success) {
      dispatch(toggleModelShow("exitTeam"));
      dispatch({
        type: SET_API_DATA,
        payload: {
          currentSelectedTeamMembers: null,
          currentSelectedTeam: null,
        },
      });
      navigate("/teams");
    }
  };

  const handleExitTeam = () => {
    if (currentSelectedTeam?.admins?.includes(currentUserId)) {
      setIsExitTeamAlertOpen(true);
      return;
    }
    dispatch(toggleModelShow("exitTeam"));
  };

  const onExitTeamConfirm = () => {
    exitFromTeam();
  };

  const onExitTeamDecline = () => {
    dispatch(toggleModelShow("exitTeam"));
  };

  const handleCancel = () => {
    setIsExitTeamAlertOpen(false);
  };

  const handleOk = async () => {
    await onSeeAllMembers("exitTeamAlert");
    setIsExitTeamAlertOpen(false);
  };

  return {
    teamId,
    teamProfile,
    topMembers,
    onFilterChange,
    loader,
    onSeeAllMembers,
    isParticipantsOpen,
    onCloseParticipantModal,
    currentSelectedTeamMembers,
    currentSelectedTeam,
    getMembers,
    isaddMembersOpen,
    handleAddMemberFormChange,
    addMemberInfo,
    addMemberButtons,
    handleAddMembers,
    addMembersFields,
    onInputChange,
    onEmailSelect,
    handleVideoPlay,
    currentPlayingIndex,
    setCurrentPlayingIndex,
    userProfile,
    currentUserId,
    breadcrumbsRoutes,
    active,
    setActive,
    teamType,
    exploreAspects,
    currentAspect,
    isExitTeamOpen,
    isExitTeamAlertOpen,
    onClose,
    handleExitTeam,
    onExitTeamConfirm,
    onExitTeamDecline,
    handleCancel,
    handleOk,
    handleShareLink,
    handleDeleteTeam,
    onEditTeam,
    modelState,
    getTeamProfileData,
  };
};
