import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { ReactComponent as Search } from "../assets/admin/svg/search.svg";

const VSNSearchBox = ({ onChange, ...rest }) => {
  return (
    <TextField
      id="search"
      type="search"
      placeholder="Search"
      onChange={onChange}
      InputLabelProps={{ shrink: false }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default VSNSearchBox;
