import {
  Box,
  CircularProgress,
  Grid,
  Hidden,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import VSNTable from "../../shared/VSNTable";

export default function ExpandTableRow({
  data,
  columns,
  handleAction,
  isLoading,
  hidePagination,
  activeTab,
}) {
  const oddData = data?.filter((_, index) => index % 2 === 0);
  const evenData = data?.filter((_, index) => index % 2 !== 0);
  const matches = useMediaQuery("(min-width:900px)");
  const isMDUP = useMediaQuery("(min-width:900px)");

  return isLoading ? (
    <Box
      sx={{
        textAlign: "center",
        height: 45,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={50} color="inherit" />
    </Box>
  ) : (
    <Grid container spacing={3}>
      <Grid
        item
        xs={activeTab === "organizations" ? 10 : 4}
        xsm={activeTab === "organizations" ? 12 : 6}
        md={6}
      >
        <Box className="expandTableWrapper vcuyegfuvyguygvrtguyb">
          <VSNTable
            handleAction={handleAction}
            columns={columns}
            data={matches ? oddData : data}
            hidePagination={hidePagination}
            isLoading={isLoading}
          />
        </Box>
      </Grid>
      {isMDUP && (
        <Grid item md={6}>
          <Box className="expandTableWrapper">
            <VSNTable
              handleAction={handleAction}
              columns={columns}
              data={evenData}
              hidePagination={hidePagination}
              isLoading={isLoading}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
