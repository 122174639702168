import React from "react";
import changeDepartment from "../../container/org-admin/changeDepartment.container";
import FormInModel from "./FormInModel";
import { toggleModelShow } from "../../shared/CustomFunc";

const ChangeDepartment = () => {
  const {
    formAttribute,
    handleChange,
    formPath,
    handelClick,
    isLoadingChangeDepartment,
    changeDepartmentInfo,
  } = changeDepartment();

  return (
    <>
      <FormInModel
        {...{
          handleChange,
          submitButtonName: "Change Department",
          formPath,
          toggleModelShow,
          titleOfModel: "Change Department",
          formAttribute,
          modelName: "changeDepartment",
          staticValue: {},
          ShowTitle: false,
          submit: handelClick,
          loaderState: isLoadingChangeDepartment,
          disabledBtn: !(
            changeDepartmentInfo?.location && changeDepartmentInfo?.department
          ),
        }}
      />
    </>
  );
};

export default ChangeDepartment;
