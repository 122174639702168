import { Box, Container, Grid, List, ListItem, Stack } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import React from "react";
import { LandingDashboardStyles } from "../landing/LandingContainerStyle";
import { ReactComponent as VasanaLogo } from "../../assets/header/svg/vasana_logo.svg";
import {
  footerDocLinks,
  mainFooterDocLinks,
  mainFooterLinks,
} from "../../description/landing.description";
import VSNTypography from "../../shared/VSNTypography";

const Footer = () => {
  const classes = LandingDashboardStyles();
  const isUser = localStorage?.getItem("token");
  return (
    <footer className={classes.footer}>
      {!isUser && (
        <Stack>
          <Container maxWidth="xl">
            <Box padding="22px 0" borderTop="1px solid #EAEDF0">
              <Stack
                direction="row"
                justifyContent="center"
                marginBottom={2.5}
                gap={2}
              >
                {mainFooterLinks?.map((el, index) => (
                  <Box className={classes.singleIcon} key={index}>
                    <Link style={{ pointerEvents: "none" }} to={el?.path}>
                      {el?.icon || ""}
                    </Link>
                  </Box>
                ))}
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                gap={2.5}
                flexWrap="wrap"
              >
                <List className={classes.mainFooterListItem}>
                  {mainFooterDocLinks?.map(({ path, label, disabled }, i) => (
                    <ListItem
                      key={i}
                      style={disabled ? { cursor: "not-allowed" } : {}}
                    >
                      <NavLink
                        to={path}
                        style={disabled ? { pointerEvents: "none" } : {}}
                      >
                        {label}
                      </NavLink>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Box>
          </Container>
        </Stack>
      )}
      <Stack className={classes.copyrightFooter}>
        <Container maxWidth="xl">
          <Stack
            direction="row"
            justifyContent={{ xs: "center", sm: "space-between" }}
            gap={2.5}
            flexWrap="wrap"
          >
            <List className={classes.footerPartTwoList}>
              {footerDocLinks?.map(({ path, label }, i) => (
                <ListItem key={i}>
                  <NavLink to={path}>{label}</NavLink>
                </ListItem>
              ))}
            </List>
            <VSNTypography variant="body1" component="p">
              © {new Date()?.getFullYear()} Vasana. All right reserved
            </VSNTypography>
          </Stack>
        </Container>
      </Stack>
    </footer>
  );
};

export default Footer;
