import { Box, Container, Grid, Stack } from "@mui/material";
import React from "react";
import section1Bg1 from "../../assets/landing/png/section-1-bg-1.png";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import VSNTypography from "../../shared/VSNTypography";
import { ContactStyle } from "./ContactStyle";
import SEO from "../../shared/SEO";
import FooterSlider from "./FooterSlider";
import { contactUsContainer } from "../../container/contactUs.container";
import VSNLabel from "../../shared/VSNLabel";
import ControlForm from "../../shared/form/ControlForm";
import VSNErrorMessage from "../../shared/VSNErrorMessage";
import { formContainer } from "../../container/form.container";

const Contact = () => {
  const classes = ContactStyle();
  const {
    handleMobileChange,
    handleCreateContact,
    handleChange,
    formPath,
    contactUs,
    createContactLoader,
    contactUsAttribute,
  } = contactUsContainer();
  const { formData, formErrors } = formContainer({ formPath });
  return (
    <>
      <SEO contentKey="contactUs" />
      <Box className={classes.bannerBg}>
        <Box className={classes.heroSection}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sm={12}>
              <Stack textAlign="center">
                <VSNTypography variant="h1" className={classes.lgHeading}>
                  We&apos;re here to{" "}
                  <span className="orangeText"> help you thrive.</span>
                </VSNTypography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box position="relative">
        <Container>
          <form onSubmit={handleCreateContact}>
            <Box className={classes.contactFormBox}>
              {contactUsAttribute?.map(({ label, value }, index) => (
                <Box key={index}>
                  <Grid
                    container
                    rowSpacing={{ xs: 0, md: 2 }}
                    columnSpacing={4}
                    className={classes.formGrid}
                  >
                    {value?.map(
                      (
                        {
                          isRequired,
                          label,
                          errorMessage,
                          name,
                          value,
                          getValBy,
                          md,
                          ...attribute
                        },
                        index
                      ) => (
                        <Grid
                          item
                          xs={12}
                          md={md}
                          key={index}
                          mb={{ xs: 2, md: 0 }}
                        >
                          <VSNLabel required={isRequired} label={label} />
                          <ControlForm
                            {...{
                              ...attribute,
                              elementId: index,
                              label,
                              name,
                              handleMobileChange,
                              onChange: handleChange,
                              // errorMessage,
                              value: contactUs?.[name],
                              // readOnly,
                            }}
                          />
                          <VSNErrorMessage
                            {...{ formError: formErrors?.[name], errorMessage }}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              ))}
              <VSNTypography
                color="secondary.grey"
                my={3}
                sx={{ fontStyle: "italic" }}
              >
                Vasana uses the information you provide to contact you about our
                products and services. You may unsubscribe from these
                communications at any time. For information on our privacy
                practices and commitment to protecting your privacy, check out
                our Privacy Policy.
              </VSNTypography>

              <VSNButton isLoading={createContactLoader} type="submit">
                Submit
              </VSNButton>
            </Box>
          </form>
        </Container>
        <VSNImage
          src={section1Bg1}
          alt="shape"
          className={`${classes.section1Bg1} ${classes.sectionShape}`}
        />
      </Box>
      <FooterSlider mt={{ sm: 10, xs: 5 }} />
    </>
  );
};

export default Contact;
