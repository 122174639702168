import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LOADING_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_ERROR,
} from "../redux/constants";
import {
  clearFormData,
  setErrors,
  toggleModelShow,
} from "../shared/CustomFunc";
import { checkValid, emailValidation } from "../shared/validation";
import { ApiContainer } from "../utils/api";
import { endPoints, organization_location_admin } from "../utils/constant";
import {
  copyToClipboard,
  equal,
  getBase64,
  length,
  values,
} from "../utils/javascript";
import { LinkIcon } from "../shared/icon";
import { ERROR_MESSAGE } from "../description/errorMessage.description";

const teamContainer = ({ isJoinTeam, isTeamsTab, isEditTeam } = {}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { form } = useSelector((state) => state);
  const { formData, formErrors } = form;
  const dispatch = useDispatch();
  const formPath = { parent: "teamData" };
  const addMemberFormPath = { parent: "addMemberData" };
  const createTeamInfo = formData?.[formPath.parent];
  const createTeamInfoError = formErrors?.[formPath.parent];
  const modelState = useSelector((state) => state?.modelStates);
  const myTeams = useSelector((state) => state?.api?.myTeams) || [];
  const teamsToJoin = useSelector((state) => state?.api?.teamsToJoin) || [];
  const userRoleData = useSelector((state) => state.app?.userData);
  const isGuestUser = userRoleData?.user_profile?.organization_name === "Guest";
  const isOrgAdmin = userRoleData?.user_profile?.role;
  const currentUserId = useSelector(
    (state) => state?.app?.userData?.user_profile?.sub
  );
  const { currentSelectedTeam, currentSelectedTeamMembers } = useSelector(
    (state) => state.api
  );
  const { role, organization_type_id, permissions } =
    userRoleData?.user_profile ?? {};
  const defaultProfilePictures =
    useSelector((state) => state.api?.defaultProfilePictures?.data) || [];
  const membersToAdd =
    useSelector((state) => state?.api?.membersToAdd?.data) || [];
  const addMemberInfo = formData?.[addMemberFormPath.parent];
  const addMemberInfoError = formErrors?.[addMemberFormPath.parent];
  const [gridView, setGridView] = useState(true);
  const [toggleTeamView, setToggleTeamView] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [fetched, setFetched] = useState({
    joinTeam: false,
    myTeam: false,
  });
  const [isExitTeamAlertOpen, setIsExitTeamAlertOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const manageTeams = !(
    organization_location_admin?.includes(role) &&
    organization_type_id === 1 &&
    permissions?.teams?.includes("read_only")
  );

  const loaders = useSelector((state) => {
    return {
      createTeamLoader: state.api?.loader?.createTeamLoader,
      saveTeamLoader: state.api?.loader?.saveTeamLoader,
      exitTeamLoader: state.api?.loader?.exitTeamLoader,
      myTeamsLoader: state.api?.loader?.myTeamsLoader,
      joinTeamLoader: state.api?.loader?.joinTeamLoader,
      currentSelectedTeamMembers: state.api?.loader?.currentSelectedTeamMembers,
      deleteTeam: state.api?.loader?.deleteTeam,
      outsideMembersLoader: state.api?.loader?.outsideMembersLoader,
    };
  });

  const handleShareLink = (link) => {
    copyToClipboard(link);
    handleClose();
  };

  const editTeamContainer = {
    label: "Create a new Team",
    data: [
      {
        question: "Team Name",
        description: "Give your team a name that is easy to identify.",
        value: [
          {
            control: "input",
            type: "text",
            name: "name",
            label: "name",
            value: "",
            isRequired: true,
            validateAs: "required",
            placeholder: "Enter your Team Name",
            errorMessage: ERROR_MESSAGE?.team_name,
            fullWidth: true,
          },
        ],
        isRequired: true,
      },
      {
        question: "Team Goal",
        description:
          "Let people know what your team is all about and what you are trying to achieve.",
        isRequired: true,
        value: [
          {
            control: "input",
            type: "text",
            name: "goal",
            label: "goal",
            value: "",
            validateAs: "required",
            isRequired: true,
            placeholder: "Enter your Team Goal",
            errorMessage: ERROR_MESSAGE?.team_goal,
            fullWidth: true,
          },
        ],
      },
      {
        question: "Edit Photo",
        description:
          "Add a photograph to make your Vasana team easily identifiable.",
        value: [
          {
            control: "input",
            type: "file",
            name: "photo_upload",
            value: "",
            placeholder: "Upload your Team Photo",
            errorMessage: ERROR_MESSAGE?.photo,
            fullWidth: true,
          },
        ],
        subdescription:
          "Only JPEG, PNG, JPG and WEBP files with max size 15 MB.",
      },
    ],
  };

  const { api } = ApiContainer();

  const getMyTeams = async ({ needLoader }) => {
    dispatch({
      type: LOADING_CHANGE,
      payload: { myTeamsLoader: needLoader !== "false" },
    });
    const data = await api({
      method: "GET",
      endPoint: manageTeams ? endPoints?.myTeams : `teams/list`,
      showToastMessage: false,
      urlencoded: false,
      parent: "teamData",
      needLoader: needLoader !== "false",
    });

    if (data?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          myTeams: data?.data,
        },
      });
      dispatch({
        type: LOADING_CHANGE,
        payload: { myTeamsLoader: false },
      });
      setFetched((prev) => {
        return { ...prev, myTeam: true };
      });
      setToggleTeamView(data?.data?.length > 0);
    } else {
      setToggleTeamView(false);
      dispatch({
        type: LOADING_CHANGE,
        payload: { myTeamsLoader: false },
      });
      setFetched((prev) => {
        return { ...prev, myTeam: true };
      });
    }
  };

  const getTeamsToJoin = async ({ needLoader }) => {
    dispatch({
      type: LOADING_CHANGE,
      payload: { myTeamsLoader: needLoader !== "false" },
    });
    const data = await api({
      method: "GET",
      endPoint: endPoints?.notMemberTeams,
      showToastMessage: false,
      urlencoded: false,
      parent: "teamData",
      needLoader: needLoader !== "false",
    });

    if (data?.status) {
      setFetched((prev) => {
        return { ...prev, joinTeam: true };
      });
      if (isJoinTeam && !data?.data?.length) {
        navigate("/teams");
      }
      dispatch({
        type: SET_API_DATA,
        payload: {
          teamsToJoin: data?.data,
        },
      });
      dispatch({
        type: LOADING_CHANGE,
        payload: { myTeamsLoader: false },
      });
      setToggleTeamView((prev) => (isJoinTeam ? true : prev));
    } else {
      setFetched((prev) => {
        return { ...prev, joinTeam: true };
      });
      if (
        isJoinTeam &&
        data?.error_code === 404 &&
        pathname === "/teams/join"
      ) {
        navigate("/teams");
      }
      dispatch({
        type: LOADING_CHANGE,
        payload: { myTeamsLoader: false },
      });
    }
  };

  const getTeamsList = ({ needLoader }) => {
    if (!isTeamsTab) return;

    if (isJoinTeam) getTeamsToJoin({ needLoader });
    else getMyTeams({ needLoader });
  };

  const onSuccess = () => {
    if (isJoinTeam) {
      getTeamsToJoin({ needLoader: true });
    } else {
      getMyTeams({ needLoader: true });
    }
  };

  const exitFromTeam = async (teamId) => {
    const data = await api({
      method: "POST",
      endPoint: `teams/${teamId}/exit-team`,
      data: {
        team_id: teamId,
        a: currentUserId,
      },
      showToastMessage: true,
      urlencoded: false,
      parent: "exitTeamLoader",
      needLoader: true,
    });

    if (!data?.data?.error && data?.data?.success) {
      const currentTeam = myTeams?.find((el) => el?.id === teamId);
      const updatedMyTeams = myTeams?.filter((el) => el?.id !== teamId);
      dispatch({
        type: SET_API_DATA,
        payload: {
          teamsToJoin: [...(teamsToJoin || []), currentTeam],
          myTeams: updatedMyTeams,
        },
      });
      setToggleTeamView(updatedMyTeams?.length > 0);
      // getMyTeams({ needLoader: true });
      // getTeamsToJoin({ needLoader: true });
      dispatch(toggleModelShow("exitTeam"));
    }
  };

  const joinTeam = async (teamId) => {
    if (teamId) {
      const data = await api({
        method: "POST",
        endPoint: `teams/${teamId}/join`,
        // data: {
        //   team_id: teamId,
        //   member_id: currentUserId,
        // },
        showToastMessage: true,
        urlencoded: false,
        parent: "joinTeamLoader",
        needLoader: true,
      });
      if (teamsToJoin?.length === 1) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            teamsToJoin: [],
          },
        });
        navigate("/teams");
      }
      if (!data?.data?.error && data?.status) {
        getTeamsToJoin({ needLoader: true });
      }
    }
  };

  const handleExitTeam = (teamId, admins) => {
    handleClose();
    setSelectedTeamId(teamId);
    if (admins?.includes(currentUserId)) {
      setIsExitTeamAlertOpen(true);
      return;
    }
    dispatch(toggleModelShow("exitTeam"));
  };

  const onExitTeamConfirm = () => {
    if (selectedTeamId) exitFromTeam(selectedTeamId);
  };

  const onExitTeamDecline = () => {
    setSelectedTeamId("");
    dispatch(toggleModelShow("exitTeam"));
  };

  useEffect(() => {
    if (!isEditTeam && !_.isEmpty(userRoleData) && isGuestUser && !isOrgAdmin) {
      navigate("/teams");
    }
  }, [userRoleData]);

  useEffect(() => {
    if (
      !isEditTeam &&
      (!isGuestUser || isOrgAdmin) &&
      !_.isEmpty(userRoleData)
    ) {
      getTeamsList({ needLoader: "true" });
    }
  }, [userRoleData, isJoinTeam]);

  useEffect(() => {
    if (
      !isEditTeam &&
      !_.isEmpty(userRoleData) &&
      (!isGuestUser || isOrgAdmin) &&
      !isJoinTeam
    ) {
      getTeamsToJoin({ needLoader: true });
    }
  }, [userRoleData]);

  const updateTeam = async () => {
    dispatch({
      type: LOADING_CHANGE,
      payload: { saveTeamLoader: true },
    });
    const formData = new FormData();
    if (createTeamInfo?.name !== currentSelectedTeam?.name)
      formData.append("name", createTeamInfo?.name);
    if (createTeamInfo?.goal !== currentSelectedTeam?.goal)
      formData.append("goal", createTeamInfo?.goal);
    if (
      createTeamInfo?.photo_upload_url !==
        currentSelectedTeam?.profile_picture &&
      createTeamInfo?.profile_picture !== currentSelectedTeam?.profile_picture
    ) {
      formData.append(
        "is_custom_profile_picture",
        !!createTeamInfo?.profile_picture_file
      );
      formData.append(
        "profile_picture",
        createTeamInfo?.profile_picture_file ?? createTeamInfo?.profile_picture
      );
    }

    const data = await api({
      method: "PUT",
      endPoint: `teams/team/${createTeamInfo?.id}`,
      data: formData,
      showToastMessage: true,
      urlencoded: false,
      parent: "teamData",
      needLoader: true,
      header: { "Content-Type": "multipart/form-data" },
    });
    if (!data?.data?.error) {
      dispatch(toggleModelShow("editTeam"));
      dispatch(clearFormData(formPath.parent));
      dispatch({
        type: LOADING_CHANGE,
        payload: { saveTeamLoader: false },
      });
    } else {
      dispatch({
        type: LOADING_CHANGE,
        payload: { saveTeamLoader: false },
      });
    }
  };

  const onClickParticipants = async (team, remainOpen) => {
    const membersData = await api({
      method: "GET",
      endPoint: `teams/team/${
        team?.id ?? team?.team_id
      }/members?page=1&limit=10`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "currentSelectedTeamMembers",
    });
    if (membersData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          currentSelectedTeamMembers: membersData?.data,
          currentSelectedTeam: team,
        },
      });
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            participantsModal: {
              ...(formData?.participantsModal
                ? { ...formData?.participantsModal }
                : {}),
              ...membersData?.data?.page,
            },
            addMemberData: {},
          },
        },
      });
      if (!remainOpen) {
        dispatch(toggleModelShow("participants"));
      }
    }
  };

  const saveTeam = (cb) => {
    let errors = {};
    editTeamContainer?.data?.forEach((item) => {
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                name,
                value: createTeamInfo?.[name],
                label,
              })
            : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...form.formErrors,
              [formPath.parent]: { ...errors },
            },
          },
        });
      });
    });
    let hasError;
    if (
      equal(errors, undefined) ||
      equal(length(values(errors)?.filter(Boolean)))
    ) {
      hasError = equal(JSON.stringify(createTeamInfo));
      if (!hasError) {
        updateTeam().then(() => cb());
      }
    }
  };

  const setFormData = ({ name, value, files, isRemove, checked }) => {
    const isCheckbox = name === "profile_picture" && !files?.[0];

    const isValidImageFormat = [".png", ".jpg", ".jpeg", ".webp"]?.some(
      (type) => files?.[0]?.name?.toLowerCase()?.endsWith(type)
    );
    const isValidImageSize = files?.[0]?.size / 1024 < 15360;
    if (
      !isRemove &&
      name === "photo_upload" &&
      (!isValidImageFormat || !isValidImageSize)
    ) {
      dispatch({
        type: SET_ERROR,
        payload: {
          formErrors: {
            ...form.formErrors,
            [formPath.parent]: {
              ...createTeamInfoError,
              [name]:
                "Please upload JPEG, PNG, JPG or WEBP files with max size 15 MB.",
            },
          },
        },
      });
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            teamData: {
              ...createTeamInfo,
              photo_upload: null,
              photo_upload_url: null,
              profile_picture_file: null,
              profile_picture:
                createTeamInfo?.profile_picture ||
                defaultProfilePictures?.[0]?.profile_picture,
            },
          },
        },
      });
      return;
    }

    dispatch(setErrors({ errors: createTeamInfoError, value, name, formPath }));
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...createTeamInfo,
            [name]: value,
            ...(isCheckbox && checked
              ? {
                  photo_upload_url: null,
                  profile_picture_file: null,
                }
              : {}),
            ...((name === "photo_upload" || name === "profile_picture") &&
            files?.[0]
              ? {
                  photo_upload_url: URL.createObjectURL(files?.[0]),
                  profile_picture_file: files?.[0],
                  profile_picture: null,
                }
              : {
                  photo_upload_url: isRemove
                    ? ""
                    : form?.formData?.teamData?.photo_upload_url,
                  ...(isCheckbox
                    ? {}
                    : {
                        profile_picture:
                          createTeamInfo?.profile_picture ||
                          defaultProfilePictures?.[0]?.profile_picture,
                      }),
                }),
          },
        },
      },
    });
  };

  const getDefaultProfilePictures = async () => {
    const data = await api({
      method: "GET",
      endPoint: endPoints.defaultProfilePictures,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "defaultProfilePicturesLoader",
    });

    if (data?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          defaultProfilePictures: data.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          defaultProfilePictures: [],
        },
      });
    }
  };

  const onEditTeam = async (team) => {
    const teamDetails = await api({
      method: "GET",
      endPoint: `teams/team/${team?.id}`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "currentSelectedTeam",
    });
    if (teamDetails?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          currentSelectedTeam: teamDetails.data,
        },
      });
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            teamData: {
              ...formData?.teamData,
              name: teamDetails?.data?.name || "",
              goal: teamDetails?.data?.goal || "",
              id: teamDetails?.data?.id,
              profile_picture: teamDetails?.data?.selected_picture_id
                ? teamDetails?.data?.profile_picture
                : null,
              photo_upload_url: teamDetails?.data?.selected_picture_id
                ? null
                : teamDetails?.data?.profile_picture,
            },
          },
        },
      });
      if (!defaultProfilePictures?.length) await getDefaultProfilePictures();
      dispatch(toggleModelShow("editTeam"));
    }
  };

  const handleTeamChange = (e, isRemove) => {
    const { name, value, files, checked } = e.target;
    setFormData({ name, value, files, isRemove, checked });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      setErrors({
        errors: addMemberInfoError,
        value,
        name,
        formPath: addMemberFormPath,
      })
    );
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [addMemberFormPath.parent]: {
            ...addMemberInfo,
            [name]: value,
          },
        },
      },
    });
  };

  const editButtons = [
    {
      label: "Save",
      handleClick: (cb) => saveTeam(cb),
      hasLoader: true,
    },
    {
      label: "Cancel",
      handleClick: () => dispatch(toggleModelShow("editTeam")),
      className: "link",
    },
  ];

  const handleDeleteTeam = async (id) => {
    const response = await api({
      method: "DELETE",
      endPoint: `teams/team/${id}`,
      showToastMessage: true,
      urlencoded: false,
      needLoader: true,
      parent: "deleteTeam",
    });
    if (response?.status) {
      const updatedMyTeams = myTeams?.filter((el) => el?.id !== id);
      dispatch({
        type: SET_API_DATA,
        payload: {
          myTeams: updatedMyTeams,
        },
      });
      setToggleTeamView(updatedMyTeams?.length > 0);
      // getMyTeams({ needLoader: true });
    }
  };

  const handleCancel = () => {
    setIsExitTeamAlertOpen(false);
  };

  const handleOk = async () => {
    const currentTeam = myTeams?.find((el) => el?.id === selectedTeamId);
    if (currentTeam) {
      await onClickParticipants(currentTeam);
      setIsExitTeamAlertOpen(false);
    }
  };

  const getMembersOutside = async (teamId) => {
    const membersData = await api({
      method: "GET",
      endPoint: `teams/${teamId}/members/outside`,
      showToastMessage: false,
      urlencoded: false,
      parent: "outsideMembersLoader",
      needLoader: true,
      needFullResponse: true,
    });

    if (membersData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          membersToAdd: membersData.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          membersToAdd: [],
        },
      });
    }
  };

  const handleAddMembers = (team) => {
    dispatch(toggleModelShow("addMembers", true));
    getMembersOutside(team?.id);
  };

  const onInputChange = (event, value) => {
    const isValidMail =
      emailValidation(value) && !addMemberInfo?.emailOptions?.includes(value);
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [addMemberFormPath.parent]: {
            ...addMemberInfo,
            invited_members_input_value: value,
            ...(isValidMail ? { emailOptions: [value] } : {}),
          },
        },
      },
    });
  };

  const addMembersFields = {
    data: [
      {
        question: "Add Members",
        description:
          "Start typing a name to add members to your team or select members from the dropdown. ",
        isOrSplit: true,
        value: [
          {
            control: "select",
            type: "select",
            placeholder: "Search and add members",
            fullWidth: true,
            name: "added_members",
            defaultValue: "",
            options: loaders?.outsideMembersLoader
              ? [{ isLoading: true }]
              : membersToAdd || [],
            variant: "standard",
          },
        ],
      },
      {
        question: "Invite a Member",
        description:
          "Add people who aren't on Vasana by typing their email address.",
        value: [
          {
            control: "input",
            placeholder: "Select or enter email",
            fullWidth: true,
            options: addMemberInfo?.emailOptions || [],
            type: "email",
            name: "invited_members",
            validateAs: "email",
            onInputChange,
          },
        ],
      },
    ],
  };

  const addMembers = async () => {
    dispatch({
      type: LOADING_CHANGE,
      payload: { addMembersLoader: true },
    });
    const { added_members, invited_members } =
      form?.formData?.addMemberData || {};
    const addedMembers = added_members?.map(({ user_id }) => user_id);
    const data = await api({
      method: "POST",
      endPoint: `teams/team/${currentSelectedTeam?.id}/manage-member`,
      data: {
        members: addedMembers,
        action: "add",
        invited_members,
      },
      showToastMessage: true,
      urlencoded: false,
      parent: "teamData",
      needLoader: true,
    });
    if (!data?.data?.error) {
      dispatch(toggleModelShow("addMembers", true));
      dispatch(clearFormData(addMemberFormPath.parent));
      dispatch({
        type: LOADING_CHANGE,
        payload: { addMembersLoader: false },
      });
      onClickParticipants(currentSelectedTeam, true);
      getMyTeams({ needLoader: "false" });
    } else {
      dispatch({
        type: LOADING_CHANGE,
        payload: { addMembersLoader: false },
      });
    }
  };

  const addMemberButtons = [
    {
      label:
        !form?.formData?.addMemberData?.added_members?.length &&
        !form?.formData?.addMemberData?.invited_members?.length
          ? "Send Invite"
          : form?.formData?.addMemberData?.added_members?.length &&
            !form?.formData?.addMemberData?.invited_members?.length
          ? "Add members"
          : "Send Invite",
      handleClick: addMembers,
      hasLoader: true,
      disabled: !(
        form?.formData?.addMemberData?.added_members?.length ||
        form?.formData?.addMemberData?.invited_members?.length
      ),
    },
    {
      label: "Copy Invitation Link",
      handleClick: () =>
        copyToClipboard(currentSelectedTeam?.invitation_link || ""),
      className: "link",
      link: <LinkIcon />,
    },
  ];

  const handleAddMemberChange = (e) => {
    const { name, value } = e.target;
    setFormData({ name, value });
  };

  const onEmailSelect = (name, value) => {
    if (value) {
      const errorEmail = value.find(
        (email) => !emailValidation(email?.email || email)
      );
      if (errorEmail) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [addMemberFormPath.parent]: {
                ...addMemberInfo,
                [`${name}_input_value`]: value[value?.length - 1],
              },
            },
          },
        });
      } else {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [addMemberFormPath.parent]: {
                ...addMemberInfo,
                [name]: errorEmail ? [...(addMemberInfo?.[name] || [])] : value,
                [`${name}_input_value`]: "",
              },
            },
          },
        });
      }
    }
  };

  return {
    formPath,
    dispatch,
    modelState,
    toggleTeamView,
    setToggleTeamView,
    gridView,
    setGridView,
    loaders,
    handleClick,
    anchorEl,
    handleClose,
    handleShareLink,
    open,
    setAnchorEl,
    teams: isJoinTeam ? teamsToJoin : myTeams,
    myTeams,
    teamsToJoin,
    editButtons,
    handleExitTeam,
    joinTeam,
    selectedTeamMembers: currentSelectedTeamMembers,
    onClickParticipants,
    onSuccess,
    getTeamsList,
    createTeamInfo,
    handleChange,
    onEditTeam,
    onExitTeamConfirm,
    onExitTeamDecline,
    handleDeleteTeam,
    fetched,
    currentSelectedTeam,
    isExitTeamAlertOpen,
    handleOk,
    handleCancel,
    handleAddMembers,
    addMembersFields,
    addMemberButtons,
    addMemberInfo,
    editTeamContainer,
    handleAddMemberChange,
    onInputChange,
    onEmailSelect,
    handleTeamChange,
  };
};

export default teamContainer;
