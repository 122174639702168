import React from "react";
import CreateLinkContainer from "./CreateOrgContainer";
import { createOrgContainer } from "../../../container/createOrg.container";

const CreateLink = ({ toggleModelShow, mode, assessmentData }) => {
  const { handleMobileChange, ...rest } = createOrgContainer({
    toggleModelShow,
  });
  return (
    <CreateLinkContainer
      {...{
        handleMobileChange,
        mode,
        ...rest,
        toggleModelShow,
        assessmentData,
      }}
    />
  );
};

export default CreateLink;
