import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ON_FORM_CHANGE,
  ON_MODEL_STATE_CHANGE,
  SET_API_DATA,
  TAB_CHANGE,
} from "../redux/constants";
import { ApiContainer } from "../utils/api";
import { clearFormData } from "../shared/CustomFunc";
import { tabs } from "../description/dashboard.description";
import { adminPoints } from "../utils/constant";
import { equal, keys } from "../utils/javascript";

export const dashboardContainer = () => {
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const modelState = useSelector((state) => state.modelStates);
  const tabsPath = "summary";

  const form = useSelector((state) => state?.form);
  const activeTab = useSelector((state) => state?.api?.tabs?.[tabsPath]);
  const handleTabChange = (newVal) => {
    dispatch({ type: TAB_CHANGE, payload: { [tabsPath]: newVal } });
  };
  const assessmentSummary = useSelector(
    (state) => state?.api?.assessmentSummary
  );
  const commonFilter = useSelector(
    (state) => state.form.formData?.commonFilter
  );
  const filters = useSelector((state) => state.form.formData?.[activeTab]);
  const loaders = useSelector((state) => ({
    summaryLoader: state.api?.loader?.assessmentSummary,
    editOrgLoader: state.api?.loader?.organizationInfo,
    editLocationLoader: state.api?.loader?.locationInfo,
    editDepartmentLoader: state.api?.loader?.departmentInfo,
  }));
  const orgFilters = useSelector((state) => state.api?.orgFilters);

  const onFilters = ({ tab = "assessmentReports", value, vasanaFilter }) => {
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [tab || "assessmentReports"]: {
            ...form.formData?.[tab || "assessmentReports"],
            page: 1,
            keyword: value || null,
            vasana_filter: vasanaFilter,
          },
        },
      },
    });
    dispatch({
      type: TAB_CHANGE,
      payload: { [tabsPath]: tab || "assessmentReports" },
    });
  };
  const resetFilters = () => {
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [activeTab]: {
            organizationType: "",
            organization: "",
            sortBy: "",
            page: 1,
            rowsPerPage: 10,
            keyword: "",
          },
        },
      },
    });
  };
  const getSummary = async (parent) => {
    const summaryData = await api({
      method: "GET",
      endPoint: adminPoints?.getSummary,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: parent ?? "assessmentSummary",
    });
    if (summaryData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentSummary: summaryData.data,
        },
      });
    }
  };
  const getOrgSummary = async () => {
    const summaryData = await api({
      method: "GET",
      endPoint: `platform-admin/organizations/${commonFilter?.organization}/summary`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessmentSummary1",
    });
    if (summaryData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentSummary: {
            organizations_onboarded: 1,
            ...summaryData.data,
          },
        },
      });
    }
  };
  const getDepartmentType = async () => {
    const departmentType = await api({
      method: "GET",
      endPoint: `organization/department-types`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessmentSummary1",
    });
    if (departmentType?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          departmentType: departmentType?.data?.map(({ id, type }) => ({
            key: type,
            value: id,
          })),
        },
      });
    }
  };

  useEffect(() => {
    dispatch({ type: TAB_CHANGE, payload: { [tabsPath]: tabs[0].value } });
    getDepartmentType();
  }, []);

  useEffect(() => {
    if (commonFilter?.organization) {
      getOrgSummary();
    } else {
      getSummary("assessment");
    }
    resetFilters();
  }, [commonFilter?.organization]);
  const toggleModelShow = (model) => {
    dispatch(clearFormData("organizationInfo"));
    dispatch(clearFormData("locationInfo"));
    dispatch(clearFormData("departmentInfo"));
    dispatch({
      type: SET_API_DATA,
      payload: {
        orgToEdit: "",
        locationToEdit: "",
        departmentToEdit: "",
        // locationFilters: [],
      },
    });
    const newModelState = { ...modelState };
    keys(modelState)?.forEach((key) => {
      if (equal(model, key)) {
        newModelState[key] = !newModelState[key];
      } else {
        newModelState[key] = false;
      }
    });
    dispatch({ type: ON_MODEL_STATE_CHANGE, payload: newModelState });
  };

  const onFilterChange = (e) => {
    const { name, value } = e.target;
    if (JSON.stringify(value) !== JSON.stringify(filters?.[name])) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [activeTab]: {
              ...filters,
              ...(equal(name, "organization") && { page: 1 }),
              ...(equal(name, "organizationType") && { page: 1 }),
              [name]: value,
            },
          },
        },
      });
    }
  };
  const onCommonFilterFilterChange = (e) => {
    const { name, value } = e.target;
    if (JSON.stringify(value) !== JSON.stringify(commonFilter?.[name])) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            commonFilter: {
              ...commonFilter,
              ...(equal(name, "organization") && { page: 1 }),
              [name]: value,
            },
          },
        },
      });
    }
  };

  return {
    modelState,
    toggleModelShow,
    assessmentSummary,
    loaders,
    tabs,
    handleTabChange,
    activeTab,
    onFilters,
    filters,
    onFilterChange,
    orgFilters,
    onCommonFilterFilterChange,
    commonFilter,
  };
};
