import { ON_MODEL_STATE_CHANGE } from "../constants";

const initialState = {
  createOrg: false,
  createLocation: false,
  editLocation: false,
  createDepartment: false,
  editDepartment: false,
  editOrg: false,
  copyLink: false,
  editLink: false,
  sendLink: false,
  createTeam: false,
  participants: false,
  inviteMembers: false,
  addMembers: false,
  editTeam: false,
  exitTeam: false,
  noTeamsToJoin: false,
  teamsOfMember: false,
};

const Model = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ON_MODEL_STATE_CHANGE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default Model;
