import { Box, Collapse, Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { equal, length } from "../utils/javascript";
import GetCustom from "./CustomFunc";
import { DownArrow, UpArrow } from "./icon";

const useStyles = makeStyles((theme) => ({
  tableArrow: {
    marginLeft: 10,
    "& svg": {
      height: 10,
      width: 10,
    },
  },
  arrowWrapper: {
    marginLeft: 10,
    "& .MuiBox-root": {
      cursor: "pointer",
      lineHeight: 0,
      "& +.MuiBox-root": {
        marginTop: 2,
      },
    },
  },
}));

const BasicTable = ({
  columns,
  data,
  isLoading,
  paginationData,
  handleAction,
  apiLoaders,
  sortingFunc,
  minWidth,
  disablePagination,
  hidePagination,
  tableWrapperClass,
  hasExpandableRow,
  expandedRow,
  className,
  ...rest
}) => {
  const { onRowsPerPageChange, onPageChange, rawsPerPageOption } =
    paginationData || {};
  const classes = useStyles();
  const currentUserId = useSelector(
    (state) => state.app?.userData?.user_profile?.sub
  );
  const isDataEmpty = equal(length(data));

  return (
    <Box className={tableWrapperClass}>
      <TableContainer component={Paper} {...rest}>
        <Table
          stickyHeader
          sx={{ minWidth: minWidth || 650 }}
          aria-label="simple table"
          className={className || ""}
        >
          <TableHead>
            <TableRow>
              {columns?.map(
                (
                  { align = "center", label, sortByArrow, name, action, width },
                  index
                ) => (
                  <TableCell
                    key={index}
                    align={align}
                    {...(width && { width })}
                  >
                    <>
                      <Box
                        sx={{
                          display: sortByArrow ? "flex" : "block",
                          alignItems: sortByArrow ? "center" : "",
                        }}
                      >
                        {action?.includes("selectUser")
                          ? label(
                              // Are all rows selected
                              (
                                data?.filter(
                                  (el) => el?.user_id !== currentUserId
                                ) ?? []
                              ).length === 0
                                ? false
                                : _(handleAction.selectedRows)
                                    .xorWith(
                                      data?.filter(
                                        (el) => el?.user_id !== currentUserId
                                      ),
                                      _.isEqual
                                    )
                                    .isEmpty(),
                              // Select/Unselect all rows
                              (allSelected) => {
                                handleAction.setSelectedRows(
                                  allSelected
                                    ? []
                                    : data?.filter(
                                        (el) => el?.user_id !== currentUserId
                                      )
                                );
                              }
                            )
                          : label}
                        {sortByArrow && (
                          <>
                            <Stack
                              justifyContent="center"
                              width="fit-content"
                              className={classes.arrowWrapper}
                            >
                              <Box
                                onClick={() => sortingFunc("ascending", name)}
                              >
                                <UpArrow />
                              </Box>
                              <Box
                                onClick={() => sortingFunc("descending", name)}
                              >
                                <DownArrow />
                              </Box>
                            </Stack>
                          </>
                        )}
                      </Box>
                    </>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={length(columns)}
                  sx={{ textAlign: "center !important" }}
                >
                  <CircularProgress size={24} color="inherit" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {equal(length(data)) ? (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={length(columns)}
                      className="heightAuto"
                    >
                      <Typography variant="body2" textAlign="center" py={2}>
                        No data found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.map((obj, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <TableRow
                          key={ind}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {columns?.map((head, index) => {
                            return (
                              <TableCell align="center" key={index}>
                                <GetCustom
                                  value={obj[head?.name]}
                                  head={head}
                                  handleAction={handleAction}
                                  row={obj}
                                  apiLoaders={apiLoaders}
                                />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        {hasExpandableRow ? (
                          <TableRow>
                            <TableCell
                              colSpan={columns?.length || 12}
                              className="heightAuto"
                            >
                              <Collapse
                                in={obj?.expanded || false}
                                timeout="auto"
                                unmountOnExit
                              >
                                {expandedRow}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </React.Fragment>
                    );
                  })
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!hidePagination && (
        <TablePagination
          SelectProps={{
            disabled: isDataEmpty,
          }}
          backIconButtonProps={
            equal(length(data)) || disablePagination
              ? { disabled: true }
              : undefined
          }
          nextIconButtonProps={
            equal(length(data)) || disablePagination
              ? { disabled: true }
              : undefined
          }
          rowsPerPageOptions={rawsPerPageOption}
          component="div"
          count={paginationData?.total_count || 0}
          rowsPerPage={
            paginationData?.limit !== paginationData?.total_count
              ? paginationData?.limit > paginationData?.total_count
                ? -1
                : Number(paginationData?.limit)
              : -1
          }
          page={paginationData?.current_page - 1 || 0}
          onPageChange={(e, value) => onPageChange(e, value)}
          onRowsPerPageChange={(e) => onRowsPerPageChange(e)}
        />
      )}
    </Box>
  );
};
export default BasicTable;
