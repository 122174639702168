import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ON_FORM_CHANGE, SET_API_DATA } from "../redux/constants";
import { ApiContainer } from "../utils/api";

export const participantsModalContainer = ({ getReports, isOrgAdmin }) => {
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const { api } = ApiContainer();
  const form = useSelector((state) => state?.form);
  const { formData } = form;
  const { participantsModal } = formData;
  const currentSelectedTeam = useSelector(
    (state) => state?.api?.currentSelectedTeam
  );
  const loader = useSelector(
    (state) => state?.api?.loader?.currentSelectedTeamMembers
  );
  const promoteUserLoader = useSelector(
    (state) => state?.api?.loader?.promoteUser
  );
  const [showConfirmPromote, setShowConfirmPromote] = useState();

  useEffect(() => {
    searchRef.current.value = participantsModal?.keyword || "";
  }, [participantsModal?.keyword]);

  const getMembers = async (filters) => {
    if (currentSelectedTeam?.id) {
      const membersData = await api({
        method: "GET",
        endPoint: `teams/team/${currentSelectedTeam?.id}/members?page=${
          filters?.current_page || 1
        }&limit=10${filters?.sort_by ? `&sort_by=${filters?.sort_by}` : ``}${
          filters?.keyword ? `&keyword=${filters?.keyword}` : ``
        }`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        needFullResponse: true,
        parent: "currentSelectedTeamMembers",
      });
      if (membersData?.status && !!membersData?.data?.success) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            currentSelectedTeamMembers: membersData?.data,
          },
        });
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...formData,
              participantsModal: {
                ...filters,
                keyword: searchRef.current.value,
                ...membersData?.data?.page,
              },
            },
          },
        });
      } else {
        dispatch({
          type: SET_API_DATA,
          payload: {
            currentSelectedTeamMembers: {},
          },
        });
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...formData,
              participantsModal: {
                ...filters,
                keyword: searchRef.current.value,
                pages: 0,
                current_page: 0,
              },
            },
          },
        });
      }
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    const { value } = e?.target;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          participantsModal: {
            ...participantsModal,
            current_page: 1,
            keyword: value || "",
          },
        },
      },
    });
    getMembers({
      ...participantsModal,
      current_page: 1,
      keyword: value || "",
    });
  };

  const onFilterChange = (e) => {
    const { value } = e?.target;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          participantsModal: {
            ...participantsModal,
            current_page: 1,
            sort_by: value || "",
          },
        },
      },
    });
    getMembers({
      ...participantsModal,
      current_page: 1,
      sort_by: value || "",
    });
  };

  const onPageChange = (e, page) => {
    if (participantsModal?.current_page === page) return;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          participantsModal: {
            ...participantsModal,
            current_page: page || 1,
          },
        },
      },
    });
    getMembers({
      ...participantsModal,
      current_page: page || 1,
    });
  };

  const onSearchChange = debounce(onSearch, 300);

  const onDeleteParticipant = async (participant) => {
    const data = await api({
      method: "POST",
      endPoint: `/teams/team/${currentSelectedTeam?.id}/manage-member`,
      data: {
        members: [participant?.user_id],
        action: "remove",
        manage_by_admin: !!isOrgAdmin,
      },
      showToastMessage: true,
      urlencoded: false,
      parent: "currentSelectedTeamMembers",
      needLoader: true,
    });
    if (data?.status) {
      getMembers(participantsModal);
      if (getReports) {
        getReports({
          formPath: { parent: "teams" },
          needLoader: "false",
        });
      }
    }
  };

  const onPromoteParticipant = (participant) => {
    setShowConfirmPromote(participant);
  };

  const handlePromote = async () => {
    const data = await api({
      method: "PUT",
      endPoint: `teams/team/${currentSelectedTeam?.id}/admin`,
      data: {
        new_admin_user_id: showConfirmPromote?.user_id || "",
        manage_by_admin: !!isOrgAdmin,
      },
      showToastMessage: true,
      urlencoded: false,
      parent: "promoteUser",
      needLoader: true,
    });
    if (data?.status) {
      const clonnedCurrentSelectedTeam = {
        ...currentSelectedTeam,
        admins: [{ user_id: showConfirmPromote?.user_id }],
      };
      dispatch({
        type: SET_API_DATA,
        payload: {
          currentSelectedTeam: clonnedCurrentSelectedTeam,
        },
      });
      if (getReports) {
        getReports({
          formPath: { parent: "teams" },
          needLoader: "false",
        });
      }
      setShowConfirmPromote();
    }
  };

  const handleCancel = () => {
    setShowConfirmPromote();
  };

  return {
    manageParticipantsActions: {
      onSearchChange,
      onFilterChange,
      onPageChange,
      onDeleteParticipant,
      onPromoteParticipant,
      handlePromote,
      handleCancel,
    },
    filters: participantsModal,
    searchRef,
    loader,
    showConfirmPromote,
    promoteUserLoader,
  };
};
