import { useDispatch, useSelector } from "react-redux";
import {
  LOADING_CHANGE,
  ON_FLAG_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_ERROR,
} from "../../redux/constants";
import { checkValid } from "../../shared/validation";
import { ApiContainer } from "../../utils/api";
import { clearFormData, setErrors } from "../../shared/CustomFunc";
import { equal, length, values } from "../../utils/javascript";
import ApiCalls from "../../utils/apiCall";
import { location_admin_name } from "../../utils/constant";
import { ERROR_MESSAGE } from "../../description/errorMessage.description";

export const createLocationContainer = ({ toggleModelShow }) => {
  const { api } = ApiContainer();
  const { getRoleInfo } = ApiCalls();
  const dispatch = useDispatch();
  const formPath = { parent: "locationInfo" };
  const { form } = useSelector((state) => state);
  const locationInfo = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);
  const locationInfoError = useSelector(
    (state) => state.form.formErrors?.[formPath.parent]
  );
  const locationToEdit = useSelector((state) => state.api?.locationToEdit);
  const createLocationLoader = useSelector(
    (state) => state.api?.loader?.createLocation
  );
  const organization_original_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_original_id
  );
  const editModelState = useSelector(
    (state) => state.modelStates?.editLocation
  );

  const setFormData = (name, value) => {
    dispatch(setErrors({ errors: locationInfoError, value, name, formPath }));
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: { ...locationInfo, [name]: value },
        },
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(name, value);
  };

  const handleMobileChange = (mobile, name) => {
    setFormData(name, `+${mobile}`);
  };

  const apiCall = async () => {
    const { location_name } = locationInfo;
    const dataToSend = {
      name: location_name,
      address_line_1: locationInfo?.address_line_1,
      address_line_2: locationInfo?.address_line_2,
      city: locationInfo?.city,
      zip_code: locationInfo?.zip_code,
    };
    if (locationToEdit?.location_id) {
      const data = await api({
        method: "PUT",
        endPoint: `organization/${locationToEdit?.organization_original_id}/locations/${locationToEdit?.location_original_id}`,
        data: dataToSend,
        showToastMessage: true,
        urlencoded: false,
        needLoader: true,
        parent: "createLocation",
      });
      if (data?.status) {
        dispatch(toggleModelShow("editLocation"));
        dispatch(clearFormData(formPath.parent));
        dispatch({ type: LOADING_CHANGE, payload: { refetchOrgData: true } });
        dispatch({ type: ON_FLAG_CHANGE, payload: { refetchData: true } });
        dispatch({
          type: ON_FLAG_CHANGE,
          payload: { refetchLocationData: true },
        });
      }
    } else {
      const data = await api({
        method: "POST",
        endPoint: `organization/${organization_original_id}/locations`,
        data: locationInfo,
        showToastMessage: true,
        urlencoded: false,
        parent: "createLocation",
        needLoader: true,
      });
      if (data?.status) {
        dispatch(toggleModelShow("createLocation"));
        dispatch(clearFormData(formPath.parent));
        dispatch({
          type: SET_API_DATA,
          payload: { createdOrganization: data.data },
        });
        dispatch({ type: ON_FLAG_CHANGE, payload: { refetchData: true } });
        dispatch({
          type: ON_FLAG_CHANGE,
          payload: { refetchLocationData: true },
        });
      }
    }
    if (location_admin_name?.includes(role)) {
      dispatch(getRoleInfo({ needLoader: true, parent: "getRoleInfo" }));
    }
  };

  const createLocationAttribute = [
    {
      label: "LOCATION DETAILS",
      value: [
        {
          control: "input",
          label: "Location Name",
          type: "text",
          name: "name",
          value: "",
          isRequired: true,
          validateAs: "required",
          placeHolder: "Enter Location name",
          errorMessage: ERROR_MESSAGE?.location_name,
          fullWidth: true,
        },

        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
        },
      ],
    },
    {
      label: "ADMIN DETAILS",
      value: [
        {
          control: "input",
          label: "First Name",
          type: "text",
          name: "first_name",
          value: "",
          validateAs: "required",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Enter first name",
          errorMessage: ERROR_MESSAGE?.first_name,
          md: 6,
        },
        {
          control: "input",
          label: "Last Name",
          type: "text",
          name: "last_name",
          value: "",
          validateAs: "required",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Enter last name",
          errorMessage: ERROR_MESSAGE?.last_name,
          md: 6,
        },
        {
          control: "mobile",
          label: "Mobile Number",
          type: "tel",
          name: "phone_number",
          value: "",
          validateAs: "mobile",
          isRequired: true,
          fullWidth: true,
          placeHolder: "",
          errorMessage: ERROR_MESSAGE?.mobile_number,
          inputStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            borderRadius: "0",
            maxWidth: "100%",
            width: "100%",
          },
          buttonStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            backgroundColor: "transparent",
            borderRadius: "0",
          },
          containerStyle: {
            marginTop: 7,
          },
          dropdownStyle: {
            bottom: "100%",
          },
          md: 6,
        },
        {
          control: "input",
          label: "Email Address",
          type: "email",
          name: "email",
          value: "",
          isRequired: true,
          fullWidth: true,
          validateAs: "email",
          placeHolder: "Enter email address",
          md: 6,
          errorMessage: ERROR_MESSAGE?.email_address,
        },
      ],
    },
  ];
  const editLocationAttribute = [
    {
      label: "LOCATION DETAILS",
      value: [
        {
          control: "input",
          label: "Location Name",
          type: "text",
          name: "location_name",
          value: "",
          isRequired: true,
          validateAs: "required",
          placeHolder: "Enter Location name",
          errorMessage: ERROR_MESSAGE?.location_name,
          fullWidth: true,
        },

        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
        },
      ],
    },
  ];

  const handleCreateLocation = (e) => {
    e.preventDefault();
    let errors = {};

    (editModelState ? editLocationAttribute : createLocationAttribute)?.forEach(
      (item) => {
        Object.values(item.value)?.forEach((control) => {
          const { name, validateAs, label } = control;
          errors = {
            ...errors,
            [name]: validateAs
              ? checkValid({
                  validateAs,
                  name,
                  value: locationInfo?.[name],
                  label,
                })
              : false,
          };

          dispatch({
            type: SET_ERROR,
            payload: {
              formErrors: {
                ...form.formErrors,
                [formPath.parent]: { ...errors },
              },
            },
          });
        });
      }
    );

    if (
      equal(errors, undefined) ||
      equal(length(values(errors)?.filter(Boolean)))
    ) {
      const noChanges = equal(
        JSON.stringify(locationInfo),
        JSON.stringify(locationToEdit)
      );
      if (!noChanges) {
        apiCall();
      }
    }
  };

  return {
    handleMobileChange,
    handleCreateLocation,
    handleChange,
    formPath,
    locationInfo,
    createLocationLoader,
    locationToEdit,
    editLocationAttribute,
    createLocationAttribute,
  };
};
