import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React, { Fragment } from "react";
import VSNPaper from "../../shared/VSNPaper";
import VSNImage from "../../shared/VSNImage";
import VSNTypography from "../../shared/VSNTypography";
import { VSNTypes } from "../../description/common.description";
import VSNButton from "../../shared/VSNButton";
import CreateActions from "./CreateActions";
import { EditIcon } from "../../shared/icon";
import { SET_API_DATA } from "../../redux/constants";
import VSNSelect from "../../shared/VSNSelect";
import {
  defaultOptions,
  organizationsFiler,
} from "../../description/filters.description";
import { department_admin } from "../../utils/constant";
import VSNActions from "../../shared/VSNAction";

const cards = [
  { label: "Location", value: "locations_onboarded", tabValue: "locations" },
  {
    label: "Department",
    value: "departments_onboarded",
    tabValue: "departments",
  },
  { label: "Members", value: "total_members", tabValue: "members" },
];

const useStyles = makeStyles((theme) => ({
  filter: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiFormControl-root": {
      width: 160,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiInputBase-root": {
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #000000",
        },
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#0000003b",
        },
      },
    },
    "& .MuiTypography-root": {
      fontSize: 12,
    },
  },
  saregamaCards: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: [[18]],
    borderRadius: 8,
    border: "1px solid white",
    height: 80,
    "&:hover": {
      border: "1px solid #c9c9c9",
      background:
        "linear-gradient(92deg, rgb(225 225 225 / 50%) 1.56%, rgba(242, 231, 245, 0.00) 77.91%)",
    },
    "&.saPer": {
      "&:hover": {
        border: "1px solid #8304A3",
        background:
          "linear-gradient(92deg, rgba(242, 231, 245, 0.50) 1.56%, rgba(242, 231, 245, 0.00) 77.91%)",
      },
    },
    "&.rePer": {
      "&:hover": {
        border: "1px solid #3498DB",
        background:
          "linear-gradient(92deg, rgba(227, 241, 249, 0.50) 1.56%, rgba(227, 241, 249, 0.00) 77.91%)",
      },
    },
    "&.gaPer": {
      "&:hover": {
        border: "1px solid #FFB142",
        background:
          "linear-gradient(92deg, rgba(255, 224, 180, 0.20) 1.56%, rgba(255, 224, 180, 0.00) 77.91%)",
      },
    },
    "&.maPer": {
      "& img": {
        transform: "scale(1.1)",
      },
      "&:hover": {
        border: "1px solid #56A06F",
        background:
          "linear-gradient(92deg, rgba(200, 233, 211, 0.25) 1.56%, rgba(200, 233, 211, 0.00) 77.91%)",
      },
    },
    boxShadow: "0px 13px 14px #D4D9DE30",
    minWidth: 150,
    [theme.breakpoints.down("lg")]: {
      minWidth: "145px",
      padding: [[15]],
    },
    [theme.breakpoints.down("375")]: {
      minWidth: "120px",
      padding: [[15]],
    },
    "& p": {
      color: theme.palette.secondary.darkGreyrey,
      fontSize: 22,
      fontWeight: 700,
      lineHeight: "28px",
    },
    "& h5": {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      marginRight: 15,
      lineHeight: "21px",
      textAlign: "left",
    },
    "& img": {
      marginRight: 10,
      height: 35,
      [theme.breakpoints.down("375")]: {
        height: 30,
      },
    },
  },
  saregamaCardsActive: {
    "&.saPer": {
      border: "1px solid #8304A3",
      background:
        "linear-gradient(92deg, rgba(242, 231, 245, 0.50) 1.56%, rgba(242, 231, 245, 0.00) 77.91%)",
    },
    "&.rePer": {
      border: "1px solid #3498DB",
      background:
        "linear-gradient(92deg, rgba(227, 241, 249, 0.50) 1.56%, rgba(227, 241, 249, 0.00) 77.91%)",
    },
    "&.gaPer": {
      border: "1px solid #FFB142",
      background:
        "linear-gradient(92deg, rgba(255, 224, 180, 0.20) 1.56%, rgba(255, 224, 180, 0.00) 77.91%)",
    },
    "&.maPer": {
      border: "1px solid #56A06F",
      background:
        "linear-gradient(92deg, rgba(200, 233, 211, 0.25) 1.56%, rgba(200, 233, 211, 0.00) 77.91%)",
    },
  },
  assesmentCard: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: [[18]],
    borderRadius: 8,
    boxShadow: "0px 13px 14px #D4D9DE30",
    border: "1px solid white",
    height: 80,
    "&:hover": {
      border: "1px solid #c9c9c9",
    },
    zIndex: 5,
    [theme.breakpoints.down("md")]: {
      padding: 15,
    },
    "& .MuiStack-root": {
      gap: 25,
      width: "100%",
      justifyContent: "space-between",
    },
    "& p.MuiTypography-root": {
      color: theme.palette.secondary.grey,
      fontSize: 18,
      lineHeight: "23px",
      fontWeight: 500,
      textAlign: "start",
      maxWidth: 120,
    },
    "& h6.MuiTypography-root": {
      color: theme.palette.secondary.darkGrey,
      fontSize: 22,
      lineHeight: "28px",
      fontWeight: 700,
    },
  },
  activeCard: {
    border: "1px solid #c9c9c9 !important",
  },
  createButton: {
    "&.MuiButton-root": {
      "& .content": {
        display: "flex",
        alignItems: "center",
        gap: 10,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "18px",
        padding: "5px 15px",
        "& svg": {
          height: 18,
          width: 18,
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: "8px 12px",
      },
    },
  },
  summary: {
    marginBottom: 17,
    "& h5": {
      fontSize: 30,
      fontWeight: 700,
      color: "#2D2F39",
      lineHeight: "40px",
      [theme.breakpoints.down("md")]: {
        fontSize: 23,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: 5,
      },
    },
    "& .MuiButtonBase-root": {
      padding: "7px 10px",
      lineHeight: "21px",
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.primary.light,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  cardBg: {
    position: "absolute",
    right: 0,
    opacity: 0.15,
    zIndex: -1,
  },
  btnWrapper: {
    "& .MuiButtonBase-root": {
      position: "static",
      height: "100%",
      width: 40,
      margin: 0,
      backgroundColor: "#DFE1F8",
      border: "1px solid #555FDD",
      borderRadius: 8,
      "&:hover": {
        backgroundColor: "#ced2ff",
      },
      "& .MuiSvgIcon-root": {
        color: "#555FDD",
      },
    },
  },
  cardBgShape0: {
    position: "absolute",
    top: 4,
    right: "30%",
    height: 30,
  },
  cardBgShape1: {
    position: "absolute",
    bottom: 0,
    right: "20%",
    height: "30%",
  },
  cardBgShape2: {
    position: "absolute",
    top: 12,
    right: -12,
    transform: "rotate(-90deg)",
  },
  tabs: {
    "&.MuiTabs-root": {
      borderRadius: 8,
      background: "#fff",
      marginBottom: 18,
      "& .MuiTabs-scroller": {
        marginRight: 10,
        marginLeft: 10,
        overflow: "auto !important",
        "& .MuiTabs-flexContainer": {
          "& .MuiButtonBase-root": {
            padding: [[16, 24]],
            marginRight: 8,
            fontSize: 18,
            color: "#747787",
            textTransform: "capitalize",
            fontWeight: 500,
            [theme.breakpoints.down("375")]: {
              fontSize: 15,
            },
            "&.Mui-selected": {
              color: theme.palette.primary.main,
            },
          },
        },
      },
      "& .MuiTabs-indicator": {
        height: 4,
        background: theme.palette.primary.main,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
    },
  },
  FilterOrganizationInput: {
    minWidth: "150px",
    "& .MuiSelect-select": {
      padding: "8px 32px 8px 12px",
      fontSize: 14,
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      right: 7,
      pointerEvents: "none",
    },
  },
  filterDropDown: {
    "& .MuiPaper-root": {
      maxHeight: 250,
      "& ul": {
        "& li": {
          display: "flex",
          justifyContent: "start",
          padding: "6px 16px",
          "& img": {
            height: 28,
            width: "auto",
          },
        },
      },
    },
  },
  inviteButton: {
    "&.MuiButtonBase-root": {
      color: theme.palette.primary.light,
      padding: "11px 11.5px",
      borderRadius: 5,
      border: 0,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& svg": {
        height: 18,
        width: 18,
      },
      "& span.icon": {
        marginLeft: 8,
        display: "inline-block",
      },
      "& .content": {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      },
      "& .loader": {
        "& .MuiCircularProgress-root": {
          height: "16px !important",
          width: "16px !important",
        },
      },
      "&.link": {
        background: "#555FDD0D",
        color: "#555FDD",
        "& .linkIcon": {
          height: "20px",
          width: "20px",
          display: "inline-block",
        },
      },
    },
  },
}));

const summaryCard = ({
  memberSummary,
  findTab,
  platformAdimn,
  isOrgAdmin,
  assessmentSummary,
  onFilters,
  tabs,
  activeTab,
  toggleModelShow,
  title,
  btnAction,
  btnAction1,
  btnAction2,
  editOrganization,
  btnTitle,
  btnTitle1,
  btnTitle2,
  summaryHeaders,
  filters,
  activeSummaryHeader,
  organization_id,
  organizationSummary,
  onEditOrganization,
  handleShareLink,
  permissions,
  btnForEdit,
  functionForEdit,
  handleTabChange,
  orgFilters,
  commonFilter,
  onCommonFilterFilterChange,
  openEventScheduleModel,
}) => {
  const classes = useStyles();
  const VSNTypesTotal = [
    { img: VSNTypes?.sa, type: "sa", total: assessmentSummary?.sa_marker },
    { img: VSNTypes?.re, type: "re", total: assessmentSummary?.re_marker },
    { img: VSNTypes?.ga, type: "ga", total: assessmentSummary?.ga_marker },
    { img: VSNTypes?.ma, type: "ma", total: assessmentSummary?.ma_marker },
  ];

  const VSNMemberCard = [
    {
      img: VSNTypes?.sa,
      value: "Members",
      total: memberSummary?.members,
      type: "as",
      onClick: () => onFilters({ tab: "members" }),
      className: `${classes.assesmentCard} ${
        activeTab === "members" && classes.activeCard
      }`,
    },
    {
      img: VSNTypes?.sa,
      type: "member_status",
      onClick: () => onFilters({ member_status: "assessment_completed" }),
      value: "Assessments Completed",
      total: memberSummary?.completed_assessments,
      className: `${classes.assesmentCard} ${
        filters?.member_status === "assessment_completed" && classes.activeCard
      }`,
    },
    {
      img: VSNTypes?.sa,
      type: "sa",
      total: memberSummary?.sa_score,
    },
    { img: VSNTypes?.re, type: "re", total: memberSummary?.re_score },
    { img: VSNTypes?.ga, type: "ga", total: memberSummary?.ga_score },
    { img: VSNTypes?.ma, type: "ma", total: memberSummary?.ma_score },
  ];

  const dispatch = useDispatch();

  const { role } =
    useSelector((state) => state?.app?.userData?.user_profile) ?? {};

  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        className={classes.summary}
      >
        <VSNTypography variant="h5" component="h5">
          {title}
        </VSNTypography>
        <Stack
          direction="row"
          gap={{ xs: 1, sm: 2 }}
          flexWrap="wrap"
          justifyContent="end"
        >
          {/* {editOrganization && isOrgAdmin && (
            <>
              <VSNButton
                handleClick={() => toggleModelShow(`${btnAction}`)}
                className={classes.createButton}
              >
                {btnTitle}
              </VSNButton>

              <Box className={classes.btnWrapper}>
                <TeamActions
                  id={organization_id}
                  handleShareLink={() =>
                    handleShareLink(organizationSummary?.invitation_link || "")
                  }
                  moduleName="Organization"
                  canEditTeam={organizationSummary?.organization_id}
                  handleEditTeam={() => onEditOrganization(organization_id)}
                />
              </Box>
            </>
          )} */}

          {/* {role === findTab?.name && btnForEdit && (
            <>
              <VSNButton
                handleClick={() => functionForEdit[findTab?.name]()}
                className={classes.createButton}
              >
                {btnForEdit}
              </VSNButton>
            </>
          )} */}
          {role === department_admin &&
            permissions?.event_schedule?.includes("view") &&
            activeTab === "department_admin" && (
              <VSNButton
                handleClick={openEventScheduleModel}
                className={`${classes.inviteButton} link`}
              >
                View Event Schedule
              </VSNButton>
            )}
          {role === department_admin &&
            permissions?.otb?.includes("view") &&
            activeTab === "department_admin" && (
              <VSNButton
                // handleClick={openEventScheduleModel}
                className={`${classes.inviteButton} link`}
              >
                View OTB Reports
              </VSNButton>
            )}

          {findTab?.editButtonName &&
            permissions?.[findTab?.editPermission]?.includes("edit") && (
              <>
                <VSNButton
                  handleClick={() =>
                    functionForEdit[findTab?.name ?? "location_admin"]()
                  }
                  className={classes.createButton}
                >
                  <EditIcon />
                  {findTab?.editButtonName}
                </VSNButton>
              </>
            )}
          {/* <VSNButton
            handleClick={() => dispatch(toggleModelShow(`inviteMembers`))}
            className={classes.createButton}
          >
            {btnTitle}
          </VSNButton> */}
          <>
            {/* <Box className={classes.btnWrapper}>
              <TeamActions
                id={organization_id}
                handleShareLink={() =>
                  handleShareLink(organizationSummary?.invitation_link || "")
                }
                moduleName="Organization"
                canEditTeam
                handleEditTeam={() => onEditOrganization(organization_id)}
              />
            </Box> */}
          </>
          {role === "platform_admin" && (
            <div className={classes.filter}>
              <VSNTypography variant="body1" component="p">
                Organizations
              </VSNTypography>

              <VSNSelect
                fullWidth
                className={classes.FilterOrganizationInput}
                {...{
                  ...organizationsFiler,
                  options: [
                    ...defaultOptions,
                    ...(orgFilters !== undefined ? orgFilters : []),
                  ],
                  onChange: onCommonFilterFilterChange,
                  classes: { popover: classes.filterDropDown },
                  ...{
                    value: commonFilter?.organization ?? 0,
                  },
                }}
              />
            </div>
          )}
          {platformAdimn && btnTitle && btnTitle1 && btnTitle2 && (
            <CreateActions
              isCreateOrganization
              isCreateLocation
              isCreateDepartment
              id={organization_id}
              handleCreateOrganization={() => toggleModelShow(`${btnAction}`)}
              handleCreateLocation={() => toggleModelShow(`${btnAction1}`)}
              handleCreateDepartment={() => toggleModelShow(`${btnAction2}`)}
            />
          )}
        </Stack>
      </Stack>

      <Tabs
        value={activeTab}
        onChange={(e, newVal) => handleTabChange(newVal)}
        textColor="primary"
        indicatorColor="secondary"
        className={classes.tabs}
      >
        {tabs?.map(({ tabLabel, value }) => (
          <Tab value={value} key={value} label={tabLabel} disableRipple />
        ))}
      </Tabs>

      <Box mb={2.5}>
        <Stack direction="row" flexWrap="wrap" gap={{ xs: 2, md: 3 }}>
          {!permissions &&
            Object.entries(summaryHeaders)?.map(
              (
                [key, { title, tab, value, className, member_status, shape }],
                i
              ) => {
                return (
                  <Fragment key={i}>
                    <VSNPaper
                      onClick={() => onFilters({ tab, member_status } || {})}
                      className={`${className} ${
                        activeSummaryHeader({
                          tab,
                          member_status,
                        }) === "active"
                          ? classes.activeCard
                          : ""
                      }`}
                      sx={{ width: { xs: "100%", xsm: "unset" } }}
                    >
                      <VSNImage
                        src={shape}
                        className={classes[`cardBgShape${i}`]}
                      />
                      <VSNTypography variant="h5" component="h5">
                        {title}
                      </VSNTypography>
                      <VSNTypography variant="h4" component="h4">
                        {value}
                      </VSNTypography>
                    </VSNPaper>
                  </Fragment>
                );
              }
            )}
          {findTab?.showCard &&
            role !== "platform_admin" &&
            cards.map(({ label, value, tabValue, onClick }, i) => {
              return (
                <Fragment key={i}>
                  {organizationSummary[value] !== undefined && (
                    <VSNPaper
                      onClick={() =>
                        dispatch({
                          type: SET_API_DATA,
                          payload: {
                            tabs: { ["org-summary"]: tabValue },
                          },
                        })
                      }
                      // className={`${className} ${
                      //   activeSummaryHeader({
                      //     tab,
                      //     member_status,
                      //   }) === "active"
                      //     ? classes.activeCard
                      //     : ""
                      // }`}  `
                      className={classes.assesmentCard}
                      sx={{ width: { xs: "100%", xsm: "unset" } }}
                    >
                      {/* <VSNImage
                      src={shape}
                      className={classes[`cardBgShape${i}`]}
                    /> */}
                      <Stack direction="row" alignItems="center">
                        <VSNTypography variant="h5" component="p">
                          {label}
                        </VSNTypography>
                        <VSNTypography variant="h4" component="h6">
                          {organizationSummary[value]}
                        </VSNTypography>
                      </Stack>
                    </VSNPaper>
                  )}
                </Fragment>
              );
            })}
          {/* {
            Object.entries(summaryHeaders)
              ?.filter(([key, { name }]) =>
                Object.prototype.hasOwnProperty.call(permissions, name)
              )
            Object.entries(summaryHeaders)?.map(
              (
                [key, { title, tab, value, className, member_status, shape }],
                i
              ) => {
                return (
                  <Fragment key={i}>
                    <VSNPaper
                      onClick={() => onFilters({ tab, member_status } || {})}
                      className={`${className} ${
                        activeSummaryHeader({
                          tab,
                          member_status,
                        }) === "active"
                          ? classes.activeCard
                          : ""
                      }`}
                      sx={{ width: { xs: "100%", xsm: "unset" } }}
                    >
                      <VSNImage
                        src={shape}
                        className={classes[`cardBgShape${i}`]}
                      />
                      <VSNTypography variant="h5" component="h5">
                        {title}
                      </VSNTypography>
                      <VSNTypography variant="h4" component="h4">
                        {value}
                      </VSNTypography>
                    </VSNPaper>
                  </Fragment>
                );
              }
            )
          } */}
          {role === "platform_admin" &&
            VSNTypesTotal?.map(({ img, type, total }, index) => (
              <Box key={index} sx={{ width: { xs: "100%", xsm: "unset" } }}>
                <VSNPaper
                  onClick={() => onFilters({ value: type, vasanaFilter: true })}
                  className={`${classes.saregamaCards} ${type}Per ${
                    filters?.activeType === type
                      ? `${classes.saregamaCardsActive} ${type}Per`
                      : ""
                  }`}
                >
                  <VSNImage src={img} alt="no img" width="76px" height="44px" />
                  <VSNTypography variant="body1" component="p">
                    {total || 0}
                  </VSNTypography>
                </VSNPaper>
              </Box>
            ))}
          {activeTab === "members" &&
            role !== "platform_admin" &&
            VSNMemberCard?.map(
              ({ img, type, total, value, className, onClick }, index) => (
                <Box key={index} sx={{ width: { xs: "100%", xsm: "unset" } }}>
                  {value ? (
                    <>
                      <VSNPaper
                        onClick={onClick}
                        className={className}
                        // className={`${classes.saregamaCards} ${type}Per ${
                        //   activeSummaryHeader({
                        //     tab,
                        //     member_status,
                        //   }) === type
                        //     ? `${classes.saregamaCardsActive} ${type}Per`
                        //     : ""
                        // }`}
                      >
                        <Stack direction="row" alignItems="center">
                          <VSNTypography variant="body1" component="p">
                            {value}
                          </VSNTypography>
                          <VSNTypography variant="body1" component="h6">
                            {total || 0}
                          </VSNTypography>
                        </Stack>
                      </VSNPaper>
                    </>
                  ) : (
                    <VSNPaper
                      onClick={() => onFilters({ value: type })}
                      className={`${classes.saregamaCards} ${type}Per ${
                        filters?.activeType === type
                          ? `${classes.saregamaCardsActive} ${type}Per`
                          : ""
                      }`}
                    >
                      <VSNImage
                        src={img}
                        alt="no img"
                        width="76px"
                        height="44px"
                      />
                      <VSNTypography variant="body1" component="p">
                        {total || 0}
                      </VSNTypography>
                    </VSNPaper>
                  )}
                </Box>
              )
            )}
        </Stack>
      </Box>
      <section className={classes.typeAssesment}>
        <VSNTypography variant="h5" component="h5" className={classes.heading}>
          {tabs?.filter((obj) => obj?.value === activeTab)?.[0]?.title}
        </VSNTypography>
      </section>
    </>
  );
};

export default summaryCard;
