import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  LOADING_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_ERROR,
} from "../redux/constants";
import { checkValid } from "../shared/validation";
import { ApiContainer } from "../utils/api";
import { clearFormData, setErrors } from "../shared/CustomFunc";
import { equal, length, values } from "../utils/javascript";
import { adminPoints } from "../utils/constant";
import { ERROR_MESSAGE } from "../description/errorMessage.description";

export const createLocationContainer = ({ toggleModelShow }) => {
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const formPath = { parent: "locationInfo" };
  const { form } = useSelector((state) => state);
  const locationInfo = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const locationInfoError = useSelector(
    (state) => state.form.formErrors?.[formPath.parent]
  );
  const locationToEdit = useSelector((state) => state.api?.locationToEdit);
  const createLocationLoader = useSelector(
    (state) => state.api?.loader?.createLocation
  );
  const orgFilters = useSelector((state) => state.api?.orgFilters);
  const commonFilter = useSelector(
    (state) => state.form.formData?.commonFilter
  );

  const setFormData = (name, value) => {
    dispatch(setErrors({ errors: locationInfoError, value, name, formPath }));
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: { ...locationInfo, [name]: value },
        },
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(name, value);
  };

  const handleMobileChange = (mobile, name) => {
    setFormData(name, `+${mobile}`);
  };

  const apiCall = async () => {
    const { location_name } = locationInfo;
    let dataToSend = {
      name: location_name,
      address_line_1: locationInfo?.address_line_1,
      address_line_2: locationInfo?.address_line_2,
      city: locationInfo?.city,
      zip_code: locationInfo?.zip_code,
    };
    if (!locationToEdit?.location_id) {
      dataToSend = {
        name: locationInfo?.location_name,
        organization_id: locationInfo?.organization_original_id,
        first_name: locationInfo?.first_name,
        last_name: locationInfo?.last_name,
        phone_number: locationInfo?.phone_number,
        email: locationInfo?.email,
        address_line_1: locationInfo?.address_line_1,
        address_line_2: locationInfo?.address_line_2,
        city: locationInfo?.city,
        zip_code: locationInfo?.zip_code,
      };
    }
    if (locationToEdit?.location_id) {
      const data = await api({
        method: "PUT",
        endPoint: `platform-admin/locations/${locationToEdit?.location_original_id}`,
        data: dataToSend,
        showToastMessage: true,
        urlencoded: false,
        needLoader: true,
        parent: "createLocation",
      });
      if (data?.status) {
        toggleModelShow("editLocation");
        dispatch(clearFormData(formPath.parent));
        dispatch({ type: LOADING_CHANGE, payload: { refetchOrgData: true } });
      }
    } else {
      const data = await api({
        method: "POST",
        endPoint: adminPoints?.createLocation,
        data: dataToSend,
        showToastMessage: true,
        urlencoded: false,
        parent: "createLocation",
        needLoader: true,
      });
      if (data?.status) {
        toggleModelShow("createLocation");
        dispatch(clearFormData(formPath.parent));
        dispatch({
          type: SET_API_DATA,
          payload: { createdOrganization: data.data },
        });
        dispatch({ type: LOADING_CHANGE, payload: { refetchOrgData: true } });
      }
    }
  };

  const createLocationAttribute = [
    {
      label: "LOCATION DETAILS",
      value: [
        {
          control: "input",
          label: "Location Name",
          type: "text",
          name: "location_name",
          value: "",
          isRequired: true,
          validateAs: "required",
          placeHolder: "Enter Location name",
          errorMessage: ERROR_MESSAGE?.location_name,
          fullWidth: true,
          md: 6,
        },
        {
          control: "select",
          label: "Organization",
          name: "organization_original_id",
          value: 0,
          validateAs: "selectMenu",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Select Organization",
          errorMessage: ERROR_MESSAGE?.organization,
          options: orgFilters?.map(({ key, value }) => ({
            key,
            value,
          })),
          md: 6,
          variant: "standard",
        },
        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
        },
      ],
    },
    {
      label: "LOCATION ADMIN DETAILS",
      value: [
        {
          control: "input",
          label: "First Name",
          type: "text",
          name: "first_name",
          value: "",
          validateAs: "required",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Enter first name",
          errorMessage: ERROR_MESSAGE?.first_name,
          md: 6,
        },
        {
          control: "input",
          label: "Last Name",
          type: "text",
          name: "last_name",
          value: "",
          validateAs: "required",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Enter last name",
          errorMessage: ERROR_MESSAGE?.last_name,
          md: 6,
        },
        {
          control: "mobile",
          label: "Mobile Number",
          type: "tel",
          name: "phone_number",
          value: "",
          validateAs: "mobile",
          isRequired: true,
          fullWidth: true,
          placeHolder: "",
          errorMessage: ERROR_MESSAGE?.mobile_number,
          inputStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            borderRadius: "0",
            maxWidth: "100%",
            width: "100%",
          },
          buttonStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            backgroundColor: "transparent",
            borderRadius: "0",
          },
          containerStyle: {
            marginTop: 7,
          },
          dropdownStyle: {
            bottom: "100%",
          },
          md: 6,
        },
        {
          control: "input",
          label: "Email Address",
          type: "email",
          name: "email",
          value: "",
          isRequired: true,
          fullWidth: true,
          validateAs: "email",
          placeHolder: "Enter email address",
          md: 6,
          errorMessage: ERROR_MESSAGE?.email_address,
        },
      ],
    },
  ];

  const handleCreateLocation = (e) => {
    e.preventDefault();
    let errors = {};

    createLocationAttribute?.forEach((item) => {
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                name,
                value: locationInfo?.[name],
                label,
              })
            : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...form.formErrors,
              [formPath.parent]: { ...errors },
            },
          },
        });
      });
    });

    if (
      equal(errors, undefined) ||
      equal(length(values(errors)?.filter(Boolean)))
    ) {
      const noChanges = equal(
        JSON.stringify(locationInfo),
        JSON.stringify(locationToEdit)
      );
      if (!noChanges) {
        apiCall();
      }
    }
  };

  useEffect(() => {
    if (!locationToEdit?.location_id) {
      if (commonFilter?.organization) {
        setFormData("organization_original_id", commonFilter?.organization);
      }
    }
  }, []);

  return {
    handleMobileChange,
    handleCreateLocation,
    handleChange,
    formPath,
    locationInfo,
    createLocationLoader,
    locationToEdit,
    createLocationAttribute,
  };
};
