import { Box, Grid, Stack } from "@mui/material";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { teamProfileViewContainer } from "../../../container/teamProfileView.container";
import { clearFormData, toggleModelShow } from "../../../shared/CustomFunc";
import VSNButton from "../../../shared/VSNButton";
import VSNImage from "../../../shared/VSNImage";
import VSNLoader from "../../../shared/VSNLoader";
import VSNModel from "../../../shared/VSNModel";
import VSNTypography from "../../../shared/VSNTypography";
import { UserIcon } from "../../../shared/icon";
import AddMembersModal from "../../orgAdminDashboard/AddMembersModal";
import ParticipantsModal from "../ParticipantsModal";
import VSNBreadcrumbs from "../../../shared/VSNBreadcrumbs";
import Aspects from "./Aspects";
import { TeamDashboardStyle } from "./TeamDashboardStyle";
import Explore from "./Explore";
import TopMembers from "./TopMembers";
import TeamComposition from "./TeamComposition";
import VideoSection from "./VideoSection";
import ExitConfirmation from "../ExitConfirmation";
import VSNConfirmBox from "../../../shared/VSNConfirmBox";
import TeamActions from "../TeamActions";
import EditTeamModal from "../../orgAdminDashboard/EditTeamModal";
import { organization_location_admin } from "../../../utils/constant";

const sortBy = {
  name: "sortBy",
  placeHolder: "Sort by",
  defaultValue: 0,
  options: [
    {
      key: (
        <>
          <VSNImage
            src={`${process.env.REACT_APP_MEDIA_URL}sa/colored-logo.svg`}
            alt="Sa"
          />{" "}
          Type
        </>
      ),
      value: "sa",
    },
    {
      key: (
        <>
          <VSNImage
            src={`${process.env.REACT_APP_MEDIA_URL}re/colored-logo.svg`}
            alt="Re"
          />{" "}
          Type
        </>
      ),
      value: "re",
    },
    {
      key: (
        <>
          <VSNImage
            src={`${process.env.REACT_APP_MEDIA_URL}ga/colored-logo.svg`}
            alt="Ga"
          />{" "}
          Type
        </>
      ),
      value: "ga",
    },
    {
      key: (
        <>
          <VSNImage
            src={`${process.env.REACT_APP_MEDIA_URL}ma/colored-logo.svg`}
            alt="Ma"
          />{" "}
          Type
        </>
      ),
      value: "ma",
    },
  ],
};

const TeamProfileView = () => {
  const classes = TeamDashboardStyle();
  const {
    teamId,
    teamProfile,
    topMembers,
    loader,
    onSeeAllMembers,
    onFilterChange,
    isParticipantsOpen,
    onCloseParticipantModal,
    currentSelectedTeamMembers,
    currentSelectedTeam,
    getMembers,
    isaddMembersOpen,
    handleAddMemberFormChange: handleChange,
    addMemberInfo,
    addMemberButtons: buttons,
    handleAddMembers,
    addMembersFields,
    onInputChange,
    onEmailSelect,
    handleVideoPlay,
    currentPlayingIndex,
    setCurrentPlayingIndex,
    userProfile,
    currentUserId,
    breadcrumbsRoutes,
    setActive,
    active,
    teamType,
    exploreAspects,
    currentAspect,
    onClose,
    isExitTeamOpen,
    isExitTeamAlertOpen,
    handleExitTeam,
    onExitTeamConfirm,
    onExitTeamDecline,
    handleCancel,
    handleOk,
    handleShareLink,
    handleDeleteTeam,
    onEditTeam,
    modelState,
    getTeamProfileData,
  } = teamProfileViewContainer();
  const dispatch = useDispatch();
  const { role, organization_type_id, permissions } =
    useSelector((state) => state?.app?.userData?.user_profile) ?? {};
  const manageTeams = !(
    organization_location_admin?.includes(role) &&
    organization_type_id === 1 &&
    permissions?.teams?.includes("read_only")
  );

  return loader?.teamProfile ? (
    <VSNLoader variant="fullPage" />
  ) : (
    <>
      <Box className={classes.innerWrapper}>
        <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />
        <Stack
          direction="row"
          justifyContent="space-between"
          mb={2.5}
          flexWrap={{ xts: "nowrap", xs: "wrap" }}
          alignItems="center"
          gap={{ sm: 1, xs: 1.4 }}
        >
          <VSNTypography variant="h1" className={classes.heading}>
            {userProfile?.organization_name} - {teamProfile?.name || ""}
          </VSNTypography>
          <Stack direction="row" gap={1}>
            {manageTeams &&
              (currentSelectedTeam?.admins?.find(
                (val) => val?.user_id === currentUserId
              ) ? (
                <VSNButton
                  className={classes.btn}
                  isLoading={loader?.manageTeamMembers}
                  handleClick={() => onSeeAllMembers("manageTeamMembers")}
                >
                  <ManageAccountsOutlinedIcon />
                  Manage Members
                </VSNButton>
              ) : (
                <VSNButton
                  className={classes.btn}
                  handleClick={handleAddMembers}
                >
                  <UserIcon />
                  Add Members
                </VSNButton>
              ))}
            {manageTeams && (
              <Box className={classes.btnWrapper}>
                <TeamActions
                  id={teamId}
                  moduleName="Team"
                  canExitTeam
                  canDeleteTeam={teamProfile?.admins?.find(
                    (val) => val?.user_id === currentUserId
                  )}
                  handleShareLink={() =>
                    handleShareLink(teamProfile?.invitation_link || "")
                  }
                  handleExitTeam={handleExitTeam}
                  canEditTeam={teamProfile?.admins?.find(
                    (val) => val?.user_id === currentUserId
                  )}
                  handleEditTeam={() => onEditTeam({ teamId })}
                  handleDeleteTeam={handleDeleteTeam}
                  // {...rest}
                />
              </Box>
            )}
          </Stack>
        </Stack>
        <Grid container columnSpacing={3}>
          <Grid item md={12} xs={12}>
            <Stack display={{ xl: "none", xs: "block" }}>
              <TeamComposition
                {...{
                  active,
                  setActive,
                  teamProfile,
                  teamType,
                }}
              />
            </Stack>
            <Grid
              container
              columnSpacing={3}
              sx={{ flexDirection: "row-reverse" }}
            >
              <Grid item xl={4.3} xs={12} sx={{ mt: { xl: 0, xs: 3 } }}>
                <Grid container columnSpacing={2}>
                  <Grid item xl={12} lg={4} md={6} xs={12}>
                    <TopMembers
                      {...{
                        teamProfile,
                        onSeeAllMembers,
                        isParticipantsOpen,
                        loader,
                        sortBy,
                        onFilterChange,
                        topMembers,
                        currentSelectedTeam,
                        handleAddMembers,
                        handleExitTeam,
                      }}
                    />
                  </Grid>
                  <Grid item xl={12} lg={8} md={6} xs={12}>
                    <Aspects
                      {...{
                        exploreAspects,
                        currentAspect,
                        onClose,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={7.7} xs={12}>
                <Stack direction="column" height="100%">
                  <Stack display={{ xl: "block", xs: "none" }}>
                    <TeamComposition
                      {...{
                        active,
                        setActive,
                        teamProfile,
                        teamType,
                      }}
                    />
                  </Stack>
                  <Explore />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Stack className={classes.teamViewCard} mt={3}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <VSNTypography variant="h3" className={classes.mdHeading}>
                  Vasana Content To Accelerate Your Team&apos;s Performance
                </VSNTypography>
              </Stack>
              <Grid container spacing={{ sm: 2.6 }}>
                <VideoSection
                  {...{
                    teamProfile,
                    currentPlayingIndex,
                    setCurrentPlayingIndex,
                    handleVideoPlay,
                  }}
                />
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {isParticipantsOpen && (
        <VSNModel
          disableEnforceFocus
          open={isParticipantsOpen}
          onClose={onCloseParticipantModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title={currentSelectedTeam?.name || ""}
        >
          <ParticipantsModal
            {...{
              currentSelectedTeamMembers,
              currentSelectedTeam,
              getReports: getMembers,
              showAdminIcon: true,
              isTeamDashboard: true,
              admins: currentSelectedTeam?.admins || [],
              canDelete: currentSelectedTeam?.admins?.includes(currentUserId),
              handleAddMembers: () => handleAddMembers(true),
            }}
          />
        </VSNModel>
      )}
      {isaddMembersOpen && (
        <VSNModel
          disableEnforceFocus
          open={isaddMembersOpen}
          onClose={() => {
            dispatch(clearFormData("addMemberData"));
            dispatch(toggleModelShow("addMembers", true));
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          title={currentSelectedTeam?.name || ""}
          mobileDrawer
        >
          <AddMembersModal
            {...teamProfile}
            handleChange={handleChange}
            addMemberFormPath={{ parent: "addMemberData" }}
            addMemberInfo={addMemberInfo}
            buttons={buttons}
            onInputChange={onInputChange}
            onEmailSelect={onEmailSelect}
            addMembersFields={addMembersFields}
            loaders={{ addeddMembers: loader?.outsideMembersLoader }}
          />
        </VSNModel>
      )}
      {isExitTeamOpen && (
        <VSNModel
          disableEnforceFocus
          open={isExitTeamOpen}
          onClose={() => {
            dispatch(toggleModelShow("exitTeam"));
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
        >
          <ExitConfirmation
            onLeave={onExitTeamConfirm}
            onStay={onExitTeamDecline}
            exitTeamLoader={loader?.exitTeamLoader}
          />
        </VSNModel>
      )}
      <VSNConfirmBox
        isOpen={isExitTeamAlertOpen}
        title="Are You Sure?"
        msg="You cannot leave the team. Choose an admin before leaving."
        handleOk={handleOk}
        handleCancel={handleCancel}
        okBtnName="Manage members"
        cancelBtnName="Cancel"
        className={classes.conformModel}
        isLoading={loader?.exitTeamAlert}
      />

      {modelState?.editTeam && (
        <VSNModel
          disableEnforceFocus
          open={modelState?.editTeam}
          onClose={() => {
            dispatch(toggleModelShow("editTeam"));
            dispatch(clearFormData("teamData"));
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title="Edit Team Details"
        >
          <EditTeamModal
            {...{
              onSuccess: getTeamProfileData,
            }}
          />
        </VSNModel>
      )}
    </>
  );
};

export default TeamProfileView;
