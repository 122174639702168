import { Box, CircularProgress, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orgAssessmentReportContainer } from "../../container/orgAssessmentResult.container";
import { rawsPerPageOption } from "../../description/dashboard.description";
import { clearFormData, toggleModelShow } from "../../shared/CustomFunc";
import VSNConfirmBox from "../../shared/VSNConfirmBox";
import VSNLoader from "../../shared/VSNLoader";
import VSNModel from "../../shared/VSNModel";
import VSNTable from "../../shared/VSNTable";
import FiltersContainer from "../adminDashboard/FiltersContainer";
import CreateTeamModal from "../teams/CreateTeamModal";
import ParticipantsModal from "../teams/ParticipantsModal";
import AddMembersModal from "./AddMembersModal";
import EditTeamModal from "./EditTeamModal";
import TeamListModal from "./TeamListModal";
import { ON_FORM_CHANGE } from "../../redux/constants";
import ExpandTableRow from "../adminDashboard/ExpandTableRow";
import AssessmentsTab from "./AssessmentsTab";
import AddAdminModal from "./AddAdminModel";
import AssessmentModel from "./AssessmentModel";
import ChangeDepartment from "./ChangeDepartment";
import ViewEventSchedule from "./ViewEventSchedule";

const useStyles = makeStyles((theme) => ({
  inviteAdminModel: {
    "&.MuiModal-root": {
      "& .MuiPaper-root": {
        maxWidth: "385px",
      },
    },
  },
  assesmentTable: {
    borderTop: "1px solid #EAEDF0",
    overflow: "hidden",
    overflowX: "auto",
    "& .MuiPaper-root": {
      boxShadow: "none",
      borderRadius: 0,
      border: "0",
      maxHeight: 500,

      overflow: "auto",
      "& .MuiTable-root": {
        "& thead": {
          position: "sticky",
          zIndex: 4,
          "& tr": {
            "& th": {
              fontSize: 18,
              fontWeight: 500,
              lineHeight: "23px",
              color: theme.palette.secondary.main,
              whiteSpace: "nowrap",
              height: 64,
              padding: [[10, 16]],
              borderColor: "#EAEDF0",
              textAlign: "left",

              "&:nth-child(6),&:nth-child(7),&:nth-child(8),&:nth-child(9) ": {
                minWidth: 76,
              },
              [theme.breakpoints.down("md")]: {
                fontSize: 16,
                height: 60,
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            // "&:nth-child(even)": {
            //   background: "#F8F8FE",
            // },
            "& td": {
              lineHeight: "normal",
              padding: [[7, 16]],
              fontSize: 16,
              height: 64,
              whiteSpace: "nowrap",
              border: 0,
              textAlign: "left",
              color: "#747787",
              "& .MuiCircularProgress-root": {
                display: "block",
                margin: "0 auto",
              },
              "&:nth-child(5)": {
                "& img": {
                  [theme.breakpoints.down("md")]: {
                    maxHeight: 30,
                  },
                },
              },
              [theme.breakpoints.down("md")]: {
                padding: [[10, 16]],
                fontSize: 16,
                height: "auto",
              },
              "&:empty": {
                height: 0,
                padding: 0,
              },
              "& .MuiCollapse-root": {
                margin: "0 4.7%",
                [theme.breakpoints.down("sm")]: {
                  margin: "0 4px",
                },
              },
              "&.heightAuto": {
                padding: 0,
                height: "auto",
              },
            },
          },
        },
      },
    },
    "& .MuiTablePagination-root": {
      "& .MuiToolbar-root": {
        [theme.breakpoints.down("450")]: {
          padding: 0,
        },
        "& .MuiTablePagination-actions": {
          [theme.breakpoints.down("450")]: {
            marginLeft: 5,
          },
          "& .MuiButtonBase-root": {
            [theme.breakpoints.down("450")]: {
              padding: [[8, 4]],
            },
          },
        },
      },
      "& .MuiTablePagination-selectLabel": {
        fontSize: 16,
        fontWeight: "500",
        [theme.breakpoints.down("450")]: {
          fontSize: 13,
        },
      },
      "& .MuiInputBase-root": {
        [theme.breakpoints.down("450")]: {
          marginRight: 5,
          marginLeft: 5,
        },
        "& .MuiSelect-select": {
          fontSize: 16,
          fontWeight: "500",
          minHeight: "auto",
          paddingBottom: 2,
          [theme.breakpoints.down("450")]: {
            fontSize: 13,
            paddingLeft: 0,
          },
        },
        "& .MuiSvgIcon-root": {
          top: 2,
        },
      },
      "& .MuiTablePagination-displayedRows": {
        fontSize: 16,
        fontWeight: "500",
        [theme.breakpoints.down("450")]: {
          fontSize: 13,
        },
      },
    },
    "& .expandTableUser": {
      "& .MuiPaper-root": {
        "& .MuiTable-root": {
          width: "fit-content",
          minWidth: "auto",
          margin: "0 auto",
          "& tbody": {
            "& td:last-child": {
              "& .MuiStack-root": {
                justifyContent: "center",
              },
            },
          },
        },
      },
    },
    "& .expandTableWrapper": {
      "& .MuiPaper-root": {
        backgroundColor: "transparent",
        boxShadow: "none",
        maxHeight: "unset",
        overflow: "unset",
        "& table": {
          minWidth: "auto",
          "& thead": {
            zIndex: 1,
            "& tr": {
              "& th": {
                zIndex: 1,
                backgroundColor: "transparent",
                borderBottom: 0,
                fontWeight: 500,
                height: "fit-content",
              },
            },
          },
          "& tbody": {
            "& tr": {
              "& td": {
                height: "56px !important",
                backgroundColor: "#fff",
                borderBottom: "1px solid #EAEDF0",
                "&:last-child": {
                  width: "20%",
                },
              },
            },
          },
        },
      },
    },
    "&.assessmentFlexTable": {
      display: "flex",
      "& .expandTableUser": {
        flex: "0 0 50%",
        [theme.breakpoints.down("767")]: {
          flex: "0 0 100%",
        },
        "& .MuiPaper-root": {
          "& table": {
            width: "100%",
            "& thead": {
              "& th": {
                [theme.breakpoints.down("xsm")]: {
                  padding: "10px 8px",
                },
              },
            },
            "& tbody": {
              "& tr": {
                "& td": {
                  borderBottom: "1px solid #EAEDF0",
                  [theme.breakpoints.down("xsm")]: {
                    padding: "10px 8px",
                  },
                  "&:last-child": {
                    "& .MuiStack-root": {
                      justifyContent: "start",
                    },
                  },
                },
                "&:nth-child(even)": {
                  backgroundColor: "white",
                },
              },
            },
          },
        },
        "&:last-child": {
          borderLeft: "1px solid #EAEDF0",
          "& .MuiPaper-root": {
            "& table": {
              width: "100%",
              borderLeft: "none",
            },
          },
        },
      },
    },
    "&.dndRow": {
      flexDirection: "column",
      padding: "16px 10px",
    },
  },
  tablePagination: {
    marginTop: 20,
    "& .MuiStack-root": {
      "& nav": {
        "& ul": {
          justifyContent: "center",
        },
      },
    },
  },
  deleteDialog: {
    "& .MuiDialog-paper": {
      maxWidth: 570,
      width: "100%",
    },
    "& .MuiDialogTitle-root": {
      borderBottom: "1px solid #e46a30",
      padding: "10px 24px",
      fontSize: 22,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiDialogContent-root": {
      padding: "20px 24px !important",
      fontSize: 17,
    },
    "& .MuiButton-root": {
      maxWidth: "15%",
      width: "100%",
    },
  },
  tableCard: {
    backgroundColor: theme.palette.primary.light,
    padding: "13px 0",
    borderRadius: "10px 10px 0 0",
    marginTop: 15,
    "&.assessmentTableCardWrapper": {
      padding: 0,
      borderRadius: 8,
      overflow: "hidden",
      "& .assessmentFlexTable": {
        borderTop: "0",
      },
    },
  },
  leavingModel: {
    "&.MuiModal-root": {
      "& .MuiPaper-root": {
        maxWidth: 450,
        padding: "0 25px",
      },
    },
  },
}));

const AssessmentResult = ({
  columns,
  sortBy,
  getFrom,
  isSortFilter,
  showResetFilter,
  showDateFilter,
  showDeleteButton,
  showAssessmentSort,
  minWidth,
  showSearchInput,
  hidePagination,
  tableWrapperClass,
  hasExpandableRow,
  expandedColumns,
  showCreateButton,
  createLocation,
  createDepartment,
  showOrgLocationFilter,
  showDepartmentTypeFilter,
  inviteAdminName,
  inviteMembersDepartment,
  locationFilter,
  departmentFilter,
}) => {
  const {
    activeTab,
    data,
    onFilterChange,
    paginationData,
    handlePageChange,
    resetFilters,
    filters,
    onSearchChange,
    keyword,
    loaders,
    handleChangeRowsPerPage,
    searchRef,
    showLocationFilter,
    handleAction,
    apiLoaders,
    onDelete,
    onDeleteClick,
    showDialog,
    isParticipantsOpen,
    currentSelectedTeamMembers,
    selectedRows,
    isDeleting,
    sortingFunc,
    addMemberModalTeam,
    handleAddMemberFormChange: handleChange,
    addMemberFormPath,
    addMemberInfo,
    addMemberButtons: buttons,
    onInputChange,
    onEmailSelect,
    addMembersFields,
    getReports,
    onCloseParticipantModal,
    handleButtonClick,
    onSuccess,
    currentSelectedTeam,
    teamsOfMember,
    currentSelectedMember,
    accessOfAssessmentsForMember,
    userData,
    pathname,
    makeAdmin,
    handleInviteAdmin,
    ButtonsForAdmin,
    addAdminFields,
    handelAdminDataChange,
    addAdminMemberInfo,
    addAdminMemberInfoError,
    assessMentUserTableValue,
    filterColumn,
  } = orgAssessmentReportContainer({ getFrom, columns });
  const classes = useStyles();
  const dispatch = useDispatch();
  const modelState = useSelector((state) => state.modelStates);
  const { formData } = useSelector((state) => state?.form);
  const locationFiltersValue = useSelector(
    (state) => state.api?.locationFilters
  );
  const departmentFiltersValue = useSelector(
    (state) => state.api?.departmentFilters
  );
  return (
    <Box
      className={`${classes.tableCard} ${
        activeTab === "assessments" ? "assessmentTableCardWrapper" : ""
      }`}
    >
      {(loaders?.currentSelectedTeam ||
        loaders?.currentSelectedTeamMembers ||
        (!modelState?.teamsOfMember && loaders?.teamsOfMember)) && (
        <VSNLoader variant="fullPage" />
      )}
      <FiltersContainer
        {...{
          sortBy,
          onFilterChange,
          resetFilters,
          filters,
          onSearchChange,
          keyword,
          searchRef,
          isSortFilter,
          activeTab,
          showResetFilter,
          showDateFilter,
          onDeleteClick,
          selectedRows,
          showDeleteButton,
          showAssessmentSort,
          handleButtonClick,
          showSearchInput,
          userData,
          pathname,
          showCreateButton,
          createLocation,
          createDepartment,
          showOrgLocationFilter,
          showDepartmentTypeFilter,
          makeAdmin,
          inviteAdminName,
          handleInviteAdmin,
          inviteMembersDepartment,
          showLocationFilter,
          locationFilter,
          locationFiltersValue,
          departmentFilter,
          departmentFiltersValue,
        }}
      />

      {loaders?.assessments ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          width="100%"
          sx={{ minHeight: 56 }}
        >
          <CircularProgress size={24} color="inherit" />
        </Stack>
      ) : (
        <Box
          className={`${
            activeTab === "assessments" ? "assessmentFlexTable dndRow" : ""
          } ${classes.assesmentTable}`}
        >
          {activeTab === "assessments" ? (
            <AssessmentsTab data={data} />
          ) : (
            <VSNTable
              handleAction={handleAction}
              columns={filterColumn}
              data={data}
              isLoading={loaders?.[activeTab] || apiLoaders?.sortByValue}
              paginationData={{
                ...paginationData,
                onPageChange: handlePageChange,
                onRowsPerPageChange: handleChangeRowsPerPage,
                rawsPerPageOption,
              }}
              apiLoaders={apiLoaders}
              sortingFunc={sortingFunc}
              minWidth={minWidth}
              disablePagination={loaders?.members || loaders?.teams}
              hidePagination={hidePagination}
              tableWrapperClass={tableWrapperClass}
              hasExpandableRow={hasExpandableRow}
              expandedRow={
                <ExpandTableRow
                  {...{
                    data: accessOfAssessmentsForMember?.assessments || [],
                    handleAction,
                    isLoading: loaders?.expandRowOfMember,
                    columns: expandedColumns,
                    hidePagination: true,
                    activeTab,
                  }}
                />
              }
            />
          )}
        </Box>
      )}
      {isParticipantsOpen && (
        <VSNModel
          disableEnforceFocus
          open={isParticipantsOpen}
          onClose={onCloseParticipantModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title={currentSelectedTeam?.name || ""}
        >
          <ParticipantsModal
            {...{
              currentSelectedTeamMembers,
              getReports,
              handleAddMembers: handleAction?.handleAddMembers,
              currentSelectedTeam,
              isOrgAdmin: true,
              showAdminIcon: true,
              admins: currentSelectedTeam?.admins || [],
              canDelete: true,
            }}
          />
        </VSNModel>
      )}
      {modelState?.addMembers && (
        <VSNModel
          disableEnforceFocus
          open={modelState?.addMembers}
          onClose={() => {
            dispatch(clearFormData("addMemberData"));
            dispatch(toggleModelShow("addMembers", true));
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title={addMemberModalTeam?.name || ""}
        >
          <AddMembersModal
            {...addMemberModalTeam}
            handleChange={handleChange}
            addMemberFormPath={addMemberFormPath}
            addMemberInfo={addMemberInfo}
            buttons={activeTab === "teams" ? buttons : ButtonsForAdmin}
            onInputChange={onInputChange}
            onEmailSelect={onEmailSelect}
            addMembersFields={addMembersFields}
            showTeamName
            loaders={{ addeddMembers: loaders?.outsideMembersLoader }}
          />
        </VSNModel>
      )}
      {modelState?.editTeam && (
        <VSNModel
          disableEnforceFocus
          open={modelState?.editTeam}
          onClose={() => {
            dispatch(toggleModelShow("editTeam"));
            dispatch(clearFormData("teamData"));
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title="Edit Team Details"
        >
          <EditTeamModal onSuccess={getReports} />
        </VSNModel>
      )}
      {modelState?.InviteAdminModel && (
        <VSNModel
          open={modelState?.InviteAdminModel}
          onClose={() => {
            dispatch(toggleModelShow("InviteAdminModel"));
          }}
          disableEnforceFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          className={classes.inviteAdminModel}
        >
          <AddAdminModal
            buttons={ButtonsForAdmin}
            addAdminFields={addAdminFields}
            makeAdmin={makeAdmin}
            {...{
              handelAdminDataChange,
              addAdminMemberInfo,
              addAdminMemberInfoError,
            }}
          />
        </VSNModel>
      )}
      {modelState?.createTeam && (
        <VSNModel
          open={modelState?.createTeam}
          onClose={() => {
            if (formData?.teamData?.step === 2) {
              onSuccess();
            }
            dispatch(
              toggleModelShow("createTeam"),
              dispatch(clearFormData("teamData"))
            );
          }}
          disableEnforceFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
        >
          <CreateTeamModal
            onSuccess={onSuccess}
            open={modelState?.createTeam}
            inOrgAdmin
          />
        </VSNModel>
      )}

      {modelState?.assessmentList && (
        <VSNModel
          disableEnforceFocus
          open
          onClose={() => dispatch(toggleModelShow("assessmentList"))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title={`${
            assessMentUserTableValue?.userDetail?.first_name ??
            "Assessment List"
          } ${assessMentUserTableValue?.userDetail?.last_name ?? ""}`}
        >
          <AssessmentModel userDetail={assessMentUserTableValue?.userDetail} />
        </VSNModel>
      )}
      {modelState?.changeDepartment && (
        <VSNModel
          disableEnforceFocus
          open
          onClose={() => dispatch(toggleModelShow("changeDepartment"))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title="Change Department"
          className={classes.leavingModel}
        >
          <ChangeDepartment />
        </VSNModel>
      )}
      {modelState?.viewEventSchedule && (
        <VSNModel
          disableEnforceFocus
          open={modelState}
          onClose={() => dispatch(toggleModelShow("viewEventSchedule"))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
        >
          <ViewEventSchedule />
        </VSNModel>
      )}

      {modelState?.teamsOfMember && (
        <VSNModel
          open={modelState?.teamsOfMember}
          onClose={() => {
            dispatch({
              type: ON_FORM_CHANGE,
              payload: {
                formData: {
                  ...formData,
                  teamsOfMember: {},
                },
              },
            });
            dispatch(toggleModelShow("teamsOfMember"));
          }}
          disableEnforceFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title={
            currentSelectedMember?.first_name ||
            currentSelectedMember?.last_name
              ? `${currentSelectedMember?.first_name || ""} ${
                  currentSelectedMember?.last_name || ""
                }`
              : currentSelectedMember?.email || ""
          }
        >
          <TeamListModal {...{ teamsOfMember }} />
        </VSNModel>
      )}
      <VSNConfirmBox
        isOpen={showDialog}
        title="Are You Sure?"
        msg="Are you sure you want to delete ?"
        handleOk={onDelete}
        handleCancel={() => onDeleteClick(null)}
        okBtnName="Delete"
        cancelBtnName="Cancel"
        className={classes.conformModel}
        isLoading={isDeleting}
      />
    </Box>
  );
};

export default AssessmentResult;
