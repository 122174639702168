import React from "react";
import { Helmet } from "react-helmet";
import { allMetaContent } from "../description/common.description";

const SEO = ({ contentKey }) => {
  const metaContent = allMetaContent?.[contentKey];
  return (
    <Helmet
      title={metaContent?.title}
      htmlAttributes={{ lang: "en" }}
      meta={metaContent?.meta}
    />
  );
};

export default SEO;
