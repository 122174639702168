import peter from "../assets/assesments/png/coach-peter.png";
import James from "../assets/assesments/png/coach-james.png";
import Jennifer from "../assets/assesments/png/coach-jennifer.png";
import workEnviroment from "../assets/quickView/jpg/content-image-4.jpg";
import teamBuilding from "../assets/quickView/jpg/content-image-5.jpg";
import AgileWorkflow from "../assets/quickView/jpg/content-image-6.jpg";
import { ReactComponent as Type1 } from "../assets/quickView/svg/box-1-img-1.svg";
import { ReactComponent as Type2 } from "../assets/quickView/svg/box-1-img-2.svg";
import { ReactComponent as Type3 } from "../assets/quickView/svg/box-1-img-3.svg";
import { ReactComponent as Type4 } from "../assets/quickView/svg/box-1-img-4.svg";
import { ReactComponent as Type5 } from "../assets/quickView/svg/box-1-img-7.svg";
import { ReactComponent as Re } from "../assets/quickView/svg/combination-3.svg";
import { ReactComponent as Bg1 } from "../assets/quickView/svg/bg-1.svg";
import { ReactComponent as Bg2 } from "../assets/quickView/svg/bg-2.svg";
import { ReactComponent as Bg3 } from "../assets/quickView/svg/bg-3.svg";
import { ReactComponent as Bg4 } from "../assets/quickView/svg/bg-4.svg";

export const videoDetail = [
  {
    title: "Work Environment",
    image: workEnviroment,
    time: "02:00 Mins",
    views: "24k views",
    description:
      "Building a team takes time and effort. Do not expect a group of people to work together smoothly for the first time.",
    coachName: "Peter",
    uploaded: "Uploaded 6 days ago",
    coachImage: peter,
  },
  {
    title: "Team Building Techniques",
    image: teamBuilding,
    time: "02:00 Mins",
    views: "24k views",
    description:
      "Building a team takes time and effort. Do not expect a group of people to work together smoothly for the first time.",
    coachName: "Jennifer",
    uploaded: "Uploaded 6 days ago",
    coachImage: Jennifer,
  },
  {
    title: "Agile workflow",
    image: AgileWorkflow,
    time: "02:00 Mins",
    views: "24k views",
    description:
      "Building a team takes time and effort. Do not expect a group of people to work together smoothly for the first time.",
    coachName: "James",
    uploaded: "Uploaded 6 days ago",
    coachImage: James,
  },
];

export const imagesToLoad = [
  "ma/colored-logo.svg",
  "sa/colored-logo.svg",
  "re/colored-logo.svg",
  "ga/colored-logo.svg",
  "sa/black-logo.svg",
  "re/black-logo.svg",
  "ga/black-logo.svg",
  "ma/black-logo.svg",
  "strength.svg",
  "gaps.svg",
  "triggers.svg",
  "natural-viewpoints/time-frame.svg",
  "natural-viewpoints/virtue.svg",
  "natural-viewpoints/progress-path.svg",
  "natural-viewpoints/motivated-by.svg",
  "aspects-of-personality/positioning.svg",
  "aspects-of-personality/communication-style.svg",
  "aspects-of-personality/conversational-pace.svg",
  "aspects-of-personality/body-language.svg",
  "aspects-of-personality/hand-gesturing-style.svg",
  "aspects-of-personality/area-of-focus.svg",
  "aspects-of-personality/point-of-focus.svg",
  "aspects-of-personality/seeking.svg",
  "aspects-of-personality/decision-making-style.svg",
];

export const bgImages = [
  {
    image: <Type1 />,
    className: "shapeOne",
  },
  {
    image: <Type2 />,
    className: "shapeTwo",
  },
  {
    image: <Type3 />,
    className: "shapeThree",
  },
  {
    image: <Type4 />,
    className: "shapeFour",
  },
  {
    image: <Type5 />,
    className: "shapeFive",
  },
];

export const bgImagesMain = [
  {
    image: <Bg1 />,
    className: "bg1",
  },
  {
    image: <Bg2 />,
    className: "bg2",
  },
  {
    image: <Bg3 />,
    className: "bg3",
  },
  {
    image: <Bg4 />,
    className: "bg4",
  },
  {
    image: <Re />,
    className: "bg5",
  },
];
