import React from "react";
import { signUpContainer } from "../../container/signUp.container";
import SignUpContainer from "./SignUpContainer";

const Signup = () => {
  const {
    formAttribute,
    handleChange,
    formPath,
    onSubmit,
    handleLoginBtn,
    ...rest
  } = signUpContainer();

  return (
    <SignUpContainer
      {...{
        formAttribute,
        handleChange,
        formPath,
        onSubmit,
        handleLoginBtn,
        ...rest,
      }}
    />
  );
};

export default Signup;
