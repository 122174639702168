import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ApiContainer } from "../utils/api";
import { SET_API_DATA } from "../redux/constants";

const ProQOLResultContainer = ({ endPointToCall }) => {
  const { assessmentId } = useParams();
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const userRoleData = useSelector((state) => state.app.userData);
  const {
    assessmentList,
    assessmentResult: allAssessmentResults,
    loader,
  } = useSelector((state) => state.api);
  const result = useSelector(
    (state) => state.api.assessmentResult?.[assessmentId]
  );
  const [value, setValue] = useState(0);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [currentData, setCurrentData] = useState(
    result?.medium_range_for_compassion_satisfaction
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        setCurrentData(result?.medium_range_for_compassion_satisfaction || {});
        break;
      case 1:
        setCurrentData(result?.medium_range_for_burnout || {});
        break;
      case 2:
        setCurrentData(
          result?.medium_range_for_secondary_traumatic_stress || {}
        );
        break;
      default:
        setCurrentData({});
        break;
    }
  };

  const getReport = async () => {
    try {
      const response = await api({
        method: "GET",
        endPoint: endPointToCall ?? `assessment/proqol/one-pager`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "assessmentResult",
      });

      if (response?.status) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            assessmentResult: {
              ...allAssessmentResults,
              [assessmentId]: response.data,
            },
          },
        });
      }
    } catch (error) {
      console.error("Failed to fetch report:", error);
    }
  };

  useEffect(() => {
    const assessment = [
      ...(userRoleData?.assessments || []),
      ...(assessmentList || []),
    ]?.find(
      (data) => data?.assessment_id?.toString() === assessmentId && data?.result
    );
    setCurrentAssessment(assessment);
  }, [userRoleData, assessmentList, assessmentId]);

  useEffect(() => {
    if (currentAssessment?.result && !endPointToCall) {
      getReport();
    }
  }, [currentAssessment, assessmentId]);
  useEffect(() => {
    if (endPointToCall) {
      getReport();
    }
  }, []);

  useEffect(() => {
    if (result) {
      setCurrentData(result?.medium_range_for_compassion_satisfaction || {});
    }
  }, [result]);

  return {
    proqolAssessmentResult: currentData,
    loader,
    currentAssessment,
    value,
    handleChange,
    result,
  };
};

export default ProQOLResultContainer;
