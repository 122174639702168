import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect } from "react";
import VSNTextField from "./VSNTextField";

const VSNDateInput = (props) => {
  const { value, handleDateChange, readOnly, ...rest } = props;
  useEffect(() => {
    if (
      props?.minDate &&
      value &&
      dayjs(value)?.isBefore(props?.minDate, "day")
    ) {
      handleDateChange(props?.minDate, props?.name);
    }
  }, [props?.minDate, value]);
  return (
    <>
      {readOnly ? (
        <VSNTextField {...props} />
      ) : (
        <DesktopDatePicker
          onChange={(newValue) => handleDateChange(newValue, props?.name)}
          value={value ? dayjs(value) : null}
          {...rest}
          disabled={readOnly}
          renderInput={(params) => <VSNTextField {...params} />}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "0",
              position: "relative",
              "&::after": {
                borderBottom: `2px solid #555FDD`,
                left: 0,
                bottom: 0,
                content: "''",
                position: "absolute",
                right: 0,
                transform: "scaleX(0)",
                transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                pointerEvents: "none",
              },
              "&:focus-visible": {
                outline: 0,
              },
              "&:hover": {
                "& fieldset": {
                  borderBottom: "2px solid #0000003b",
                },
              },
              "&.Mui-focused": {
                "& fieldset": {
                  border: 0,
                  borderBottom: "2px solid #0000003b",
                },
                "&::after": {
                  transform: "scaleX(1) translateX(0)",
                },
              },
              "&.Mui-disabled": {
                "& fieldset": {
                  borderBottom: 0,
                },
              },
            },
            "& .MuiInputBase-input": {
              padding: 1,
            },
            "& fieldset": {
              border: 0,
              borderBottom: "1px solid #0000003b",
            },
          }}
        />
      )}
    </>
  );
};

export default VSNDateInput;
