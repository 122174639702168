import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ON_FORM_CHANGE, SET_ERROR } from "../redux/constants";
import { setErrors } from "../shared/CustomFunc";
import { ApiContainer } from "../utils/api";
import { endPoints } from "../utils/constant";
import ApiCalls from "../utils/apiCall";
import { trimmedVal } from "../utils/javascript";
import { nameValidation } from "../shared/validation";

const formPath = { parent: "joinOrganization" };

export const joinOrganizationContainer = () => {
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state);
  const formErrors = form?.formErrors?.[formPath.parent];
  const { api } = ApiContainer();
  const { joinOrganizationLoader } =
    useSelector((state) => state?.api?.loader) || {};
  const { getRoleInfo } = ApiCalls();

  const handleSetErrors = (errors) => {
    dispatch({
      type: SET_ERROR,
      payload: {
        formErrors: {
          ...form.formErrors,
          [formPath.parent]: errors,
        },
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "organization_id" && value && formErrors?.organization_id) {
      handleSetErrors({});
    }

    dispatch(
      setErrors({
        errors: formErrors,
        value,
        name,
        formPath,
      })
    );
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...form?.formData?.[formPath.parent],
            [name]: value,
          },
        },
      },
    });
  };

  const joinOrganization = async () => {
    const { organization_id } = form.formData?.[formPath.parent] || {};
    const errors = !trimmedVal(organization_id)
      ? { organization_id: "This field is required." }
      : !nameValidation(organization_id)
      ? {
          organization_id:
            "The organization id should only consist of alphabets",
        }
      : {};
    handleSetErrors(errors);

    if (!_.isEmpty(errors)) return;

    const data = await api({
      method: "POST",
      endPoint: `organization/join`,
      data: form?.formData?.[formPath.parent],
      showToastMessage: true,
      urlencoded: false,
      parent: "joinOrganizationLoader",
      needLoader: true,
    });

    if (data?.status)
      dispatch(getRoleInfo({ needLoader: true, parent: "getRoleInfo" }));
  };

  return {
    joinOrganization,
    formErrors,
    handleChange,
    joinOrganizationLoader,
  };
};
