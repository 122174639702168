import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import IntroExtroResult from "../../assessment/IntroExtroResult";
import OnePagerResult from "../../assessment/OnePagerResult";
import ProQOLResult from "../../assessment/ProQOLResult";
import VasanaTypeResult from "../../assessment/VasanaTypeResult";
import VSNBreadcrumbs from "../../../shared/VSNBreadcrumbs";
import { getAssessmentState } from "../../../shared/CustomFunc";
import { ApiContainer } from "../../../utils/api";
import VSNButton from "../../../shared/VSNButton";
import { SET_API_DATA } from "../../../redux/constants";
import { formatName } from "../../../utils/javascript";
import VSNTypography from "../../../shared/VSNTypography";

export const QuickViewStyle = makeStyles((theme) => ({
  releaseButton: {
    "&.MuiButton-root": {
      padding: "8px 12px",
      fontWeight: 400,
      minWidth: 150,
      "& .content": {
        fontSize: 16,
      },
      [theme.breakpoints.down("450")]: {
        width: "100%",
      },
    },
  },
}));

const QuickViewForAdmin = () => {
  const { locationId, userId, assessmentId } = useParams();
  const { api } = ApiContainer();
  const loaders = useSelector((state) => state?.api?.loader);
  const dispatch = useDispatch();
  const { organization_original_id, permissions } =
    useSelector((state) => state?.app?.userData?.user_profile) ?? {};
  const userData = useSelector((state) => state?.app?.userData) ?? {};
  const endPointToCall = `assessment/users/${userId}/assessments/${assessmentId}/one-pager`;
  const { assessmentUserInfo } = useSelector((state) => state.api);
  const currentAssessment = getAssessmentState({
    assessments: userData?.assessments,
    id: assessmentId,
  });
  const classes = QuickViewStyle();
  const breadcrumbsRoutes = [
    {
      path: "/org-admin",
      label: `Org Admin`,
    },
    {
      path: `/org-admin/${locationId}/${userId}`,
      label: assessmentUserInfo?.first_name
        ? `${formatName(assessmentUserInfo?.first_name ?? "")} ${formatName(
            assessmentUserInfo?.last_name ?? ""
          )}`
        : assessmentUserInfo?.email ?? "Username",
    },
    {
      label: `${currentAssessment?.assessment_name}`,
    },
  ];
  const releaseAssessment = async () => {
    const data = await api({
      method: "POST",
      endPoint: `organization/${organization_original_id}/locations/${locationId}/members/${userId}/assessments/${assessmentId}/release`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessmentResult",
    });
    if (data?.status) {
      const response = await api({
        method: "GET",
        endPoint: endPointToCall,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "assessmentResult",
      });
      if (response?.status) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            assessmentResult: {
              [assessmentId]: response?.data,
            },
          },
        });
      }
    }
  };
  const getUserDetail = async () => {
    const response = await api({
      method: "GET",
      endPoint: `user/${userId}/info`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "releaseAllLoader",
    });
    if (response?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentUserInfo: response.data,
        },
      });
    }
  };
  useEffect(() => {
    getUserDetail();
  }, []);

  const releaseButton = (
    <>
      {permissions?.user_assessments?.includes("release") && (
        <VSNButton
          handleClick={releaseAssessment}
          className={classes.releaseButton}
        >
          Release
        </VSNButton>
      )}
    </>
  );

  const breadCrumbAndButton = (
    <Box>
      <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />
      <VSNTypography
        variant="h2"
        fontSize={{ lg: 34, md: 28, xs: 24 }}
        fontWeight={700}
        lineHeight="30px"
        color="#2D2F39"
        mr="10px"
        mt="10px"
      >
        {assessmentUserInfo?.first_name
          ? `${formatName(assessmentUserInfo?.first_name ?? "")} 
            ${formatName(assessmentUserInfo?.last_name ?? "")}`
          : assessmentUserInfo?.email ?? "Username"}{" "}
        : {currentAssessment?.assessment_name}
      </VSNTypography>
    </Box>
  );

  const getAssessmentResultComponent = (assessmentId) => {
    switch (assessmentId) {
      case "2":
        return (
          <IntroExtroResult
            endPointToCall={endPointToCall}
            releaseButton={releaseButton}
            breadCrumbAndButton={breadCrumbAndButton}
            isLogedUser={false}
          />
        );
      case "3":
      case "4":
      case "5":
      case "7":
      case "8":
      case "9":
        return (
          <OnePagerResult
            endPointToCall={endPointToCall}
            releaseButton={releaseButton}
            showBreadCrumbs={false}
            breadCrumbAndButton={breadCrumbAndButton}
          />
        );

      case "6":
        return (
          <ProQOLResult
            endPointToCall={endPointToCall}
            showBreadCrumbs={false}
            releaseButton={releaseButton}
            breadCrumbAndButton={breadCrumbAndButton}
          />
        );
      default:
        return <VasanaTypeResult />;
    }
  };

  return <>{getAssessmentResultComponent(assessmentId)}</>;
};

export default QuickViewForAdmin;
