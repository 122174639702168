import { Box, Container, Grid, Stack } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import VSNTypography from "../../shared/VSNTypography";
import { LandingDashboardStyles } from "./LandingContainerStyle";
import logo1 from "../../assets/landing/png/logo1.png";
import logo2 from "../../assets/landing/png/logo2.png";
import logo3 from "../../assets/landing/png/logo3.png";
import logo4 from "../../assets/landing/png/logo4.png";
import logo5 from "../../assets/landing/png/logo5.png";
import logo6 from "../../assets/landing/png/logo6.png";
import logo7 from "../../assets/landing/png/logo7.png";
import logo8 from "../../assets/landing/png/logo8.png";
import logo9 from "../../assets/landing/png/logo9.png";
import VSNImage from "../../shared/VSNImage";

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    position: "relative",
    padding: [[300, 0]],
    [theme.breakpoints.down("lg")]: {
      padding: [[200, 0]],
    },
    [theme.breakpoints.down("md")]: {
      padding: [[150, 0]],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[110, 0]],
    },
    [theme.breakpoints.down("xsm")]: {
      padding: [[80, 0]],
    },
  },
  achieveLogo: {
    position: "absolute",
  },
  logo1: {
    top: 0,
    left: 0,
    right: 0,
    margin: "0 auto",
    [theme.breakpoints.down("xlg")]: {
      width: "30%",
    },
  },
  logo2: {
    top: "15%",
    left: 0,
    [theme.breakpoints.down("xlg")]: {
      width: "32%",
    },
  },
  logo3: {
    top: "17%",
    left: 0,
    right: 0,
    margin: "0 auto",
    [theme.breakpoints.down("xlg")]: {
      width: "10%",
    },
  },
  logo4: {
    top: "17%",
    right: "3%",
    [theme.breakpoints.down("xlg")]: {
      width: "25%",
    },
  },
  logo5: {
    top: "45%",
    right: 0,
    [theme.breakpoints.down("xlg")]: {
      width: "7%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "10%",
    },
  },
  logo6: {
    bottom: "20%",
    right: "4%",
    [theme.breakpoints.down("xlg")]: {
      width: "16%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "20%",
    },
  },
  logo7: {
    bottom: 0,
    right: "24%",
    [theme.breakpoints.down("xlg")]: {
      width: "20%",
    },
  },
  logo8: {
    bottom: "5%",
    left: "15%",
    [theme.breakpoints.down("xlg")]: {
      width: "30%",
      bottom: "8%",
    },
  },
  logo9: {
    bottom: "30%",
    left: 0,
    [theme.breakpoints.down("xlg")]: {
      width: "18%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "20%",
    },
  },
}));

const AchieveLogo = ({ title }) => {
  const landingClasses = LandingDashboardStyles();
  const classes = useStyles();
  return (
    <Stack className={landingClasses.spacing} position="relative">
      <Container maxWidth="xl">
        <Box className={classes.logoWrapper}>
          <Grid container justifyContent="center">
            <Grid item xlg={7} lg={6} md={6} sm={6} xsm={7} xs={8}>
              <VSNTypography
                variant="h2"
                component="h2"
                fontWeight="700"
                textAlign="center"
                fontSize={{ xlg: 64, lg: 54, md: 42, sm: 30, xs: 26 }}
              >
                {title}
              </VSNTypography>
            </Grid>
          </Grid>
          {[logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9].map(
            (image, i) => {
              return (
                <VSNImage
                  key={i}
                  src={image}
                  className={classNames(
                    classes[`logo${i + 1}`],
                    classes.achieveLogo
                  )}
                  alt={`logo${i + 1}`}
                />
              );
            }
          )}
        </Box>
      </Container>
    </Stack>
  );
};

export default AchieveLogo;
