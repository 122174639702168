import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import anchorContainer from "../../container/anchor.container";
import {
  DepartmentIcon,
  LocationIcon,
  OrganizationIcon,
  PlusIcon,
} from "../../shared/icon";

const useStyles = makeStyles((theme) => ({
  MenuWrapper: {
    "&.MuiButtonBase-root": {
      background: "linear-gradient(132.61deg, #E87637 32.09%, #FFBE78 163.78%)",
      borderRadius: 8,
      backdropFilter: "blur(8px)",
      fontWeight: 700,
      maxHeight: 41,
      marginTop: "auto",
      height: "100%",
      marginLeft: 0,
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
  },
  popupIcon: {
    height: 36,
    width: 36,
    borderRadius: "100%",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CreateActions = ({
  isCreateOrganization,
  isCreateLocation,
  isCreateDepartment,
  handleCreateOrganization,
  handleCreateLocation,
  handleCreateDepartment,
}) => {
  const { open, anchorEl, setAnchorEl, handleClose } = anchorContainer();
  const classes = useStyles();

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        size="small"
        sx={{ ml: 2, gap: 1 }}
        aria-controls={open ? `menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={classes.MenuWrapper}
      >
        <PlusIcon /> Create
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="menu"
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          handleClose(e);
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleClose(e);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.10)",
            background: "#FFFFFF",
            backdropFilter: "blur(3px)",
            borderRadius: "8px",
            padding: "4px",
            "& .MuiList-root ": {
              padding: 0,
              "& .MuiMenuItem-root": {
                padding: "4px 12px",
                color: "#424450",
                fontWeight: 700,
                "& span": {
                  marginLeft: "14px",
                  fontSize: "14px",
                },
              },
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {isCreateOrganization && (
          <MenuItem onClick={handleCreateOrganization}>
            <Box className={classes.popupIcon}>
              <OrganizationIcon />
            </Box>
            <span>Organization</span>
          </MenuItem>
        )}
        {isCreateLocation && (
          <MenuItem onClick={handleCreateLocation}>
            <Box className={classes.popupIcon}>
              <LocationIcon />
            </Box>
            <span>Location</span>
          </MenuItem>
        )}
        {isCreateDepartment && (
          <MenuItem onClick={handleCreateDepartment}>
            <Box className={classes.popupIcon}>
              <DepartmentIcon />
            </Box>
            <span>Department</span>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default CreateActions;
