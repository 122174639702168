import { ERROR_MESSAGE } from "./errorMessage.description";

export const knowYouBetter = "We'd Love to Learn More About You";

export const userFormAttribute = [
  {
    label: "Personal Details",
    value: [
      {
        control: "input",
        label: "Preferred Email",
        type: "email",
        name: "email",
        value: "",
        fullWidth: true,
        placeHolder: "Your Email Address",
        getValBy: "email",
        md: 12,
        readOnly: true,
      },
      {
        control: "input",
        label: "First Name",
        type: "text",
        name: "given_name",
        value: "",
        validateAs: "required",
        isRequired: true,
        fullWidth: true,
        placeHolder: "Your First Name",
        errorMessage: ERROR_MESSAGE?.first_name,
        getValBy: "given_name",
        md: 6,
      },
      {
        control: "input",
        label: "Last Name",
        type: "text",
        name: "family_name",
        value: "",
        validateAs: "required",
        isRequired: true,
        fullWidth: true,
        placeHolder: "Your Last Name",
        errorMessage: ERROR_MESSAGE?.last_name,
        getValBy: "family_name",
        md: 6,
      },

      {
        control: "select",
        label: "Gender",
        name: "gender",
        value: 0,
        validateAs: "required",
        isRequired: true,
        fullWidth: true,
        placeHolder: "Select Gender",
        errorMessage: ERROR_MESSAGE?.gender,
        options: [
          { key: "Male", value: "Male" },
          { key: "Female", value: "Female" },
          { key: "Other", value: "Other" },
          { key: "Prefer not to say", value: "PREFER_NOT_TO_SAY" },
        ],
        md: 6,
        variant: "standard",
      },
      {
        control: "mobile",
        label: "Mobile Number",
        type: "tel",
        name: "phone_number",
        value: "",
        validateAs: "mobile",
        isRequired: true,
        fullWidth: true,
        placeHolder: "Ex. +911234567890",
        errorMessage: ERROR_MESSAGE?.mobile_number,
        errorValidationMessage: "Enter valid mobile number",
        md: 6,
        inputStyle: {
          border: "none",
          borderBottom: "1px solid #cbcbcf",
          borderRadius: "0",
          maxWidth: "100%",
          width: "100%",
        },
        buttonStyle: {
          border: "none",
          borderBottom: "1px solid #cbcbcf",
          backgroundColor: "transparent",
          borderRadius: "0",
        },
        containerStyle: {
          marginTop: 7,
        },
      },
    ],
  },
  {
    label: "Organization Details",
    value: [
      {
        control: "input",
        label: "Organization Name",
        name: "organization_name",
        value: "",
        isRequired: false,
        fullWidth: true,
        placeHolder: "Enter your organization name",
        errorMessage: ERROR_MESSAGE?.organization_name,
        md: 12,
        readOnly: true,
      },
      {
        control: "input",
        label: "Organization ID",
        name: "organization_original_id",
        value: "",
        isRequired: false,
        fullWidth: true,
        placeHolder: "Enter your organization ID",
        errorMessage: "Organization ID is required",
        md: 12,
        readOnly: true,
      },
    ],
  },
];

export const OtherGenderField = {
  control: "input",
  label: "Other (Gender)",
  type: "text",
  name: "other_gender",
  value: "",
  fullWidth: true,
  placeHolder: "Your Gender",
  getValBy: "other_gender",
  validateAs: "required",
  isRequired: true,
  errorMessage: ERROR_MESSAGE?.other_gender,
  md: 6,
};
