import styled from "@emotion/styled";
import { Box, Divider, SwipeableDrawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ReactComponent as Close } from "../assets/admin/svg/close-icon.svg";
import VSNTypography from "./VSNTypography";

const useStyles = makeStyles((theme) => ({
  title: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      lineHeight: "31.25px",
      fontSize: "24px",
      padding: "20px 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        padding: "15px 0",
        lineHeight: "26px",
      },
    },
  },
  modalHeader: {
    position: "sticky",
    top: 0,
    background: theme.palette.primary.light,
    zIndex: 4,
  },
}));

const CloseIcon = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 20,
  right: 0,
  cursor: "pointer",
  zIndex: 7,
  float: "right",
  display: "block",
  background: "white",
  [theme.breakpoints.down("sm")]: {
    top: 15,
  },
  "& svg": {
    height: 25,
    width: 25,
  },
}));

const VSNDrawer = ({ open, onClose, children, className, title, ...rest }) => {
  const classes = useStyles();
  return (
    <SwipeableDrawer
      anchor="bottom"
      className={className || ""}
      open={open}
      onClose={onClose}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiPaper-root": {
          padding: "0 15px 0 15px",
          maxHeight: "calc(100vh - 105px)",
          borderRadius: "20px 20px 0 0 ",
        },
      }}
      {...rest}
    >
      <Box position="relative">
        <Box className={classes.modalHeader}>
          {title ? (
            <VSNTypography variant="h2" className={classes.title}>
              {title}
            </VSNTypography>
          ) : null}
          {onClose && (
            <CloseIcon onClick={onClose}>
              <Close />
            </CloseIcon>
          )}
          {title ? (
            <Divider
              sx={{
                borderColor: "#EAEDF0",
                marginLeft: { sm: "-25px", xs: "-15px" },
                marginRight: { sm: "-25px", xs: "-15px" },
              }}
            />
          ) : null}
        </Box>
        {children}
      </Box>
    </SwipeableDrawer>
  );
};

export default VSNDrawer;
