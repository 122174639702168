import { useState } from "react";

const anchorContainer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  return {
    open,
    anchorEl,
    setAnchorEl,
    handleClose,
  };
};

export default anchorContainer;
