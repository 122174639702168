import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import VSNPagination from "../../shared/VSNPagination";
import VSNSearchBox from "../../shared/VSNSearchBox";
import VSNSelect from "../../shared/VSNSelect";
import VSNTypography from "../../shared/VSNTypography";
import { ParticipantsModalStyle } from "../teams/participantsModalStyle";
import AssessmentModelContainer from "../../container/assessmentModel.container";

const AssessmentModel = ({ userDetail }) => {
  const classes = ParticipantsModalStyle();
  const sortBy = {
    name: "assessment_type",
    defaultValue: 0,
    placeHolder: "Assessment Type",
    options: [
      {
        key: "All",
        value: "",
      },
      {
        key: "Wellness Assessment",
        value: "wellness",
      },
      {
        key: "Behavioral Assessment",
        value: "behavioral",
      },
    ],
  };
  const {
    setFilter,
    getMemberAssessmentDetail,
    assessMentList,
    getAssessmentLoader,
    filter,
  } = AssessmentModelContainer({
    userDetail,
  });
  const { assessMent, page } = assessMentList;
  return (
    <>
      <Box position="relative">
        <Stack direction="row" justifyContent="space-between" py={1.25} gap={2}>
          <div style={{ maxWidth: "500px" }} className={`${classes.search}`}>
            <VSNSearchBox
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, keyWord: e.target.value }))
              }
              name="keyword"
              value={filter?.keyword}
              sx={{ width: "100%" }}
            />
          </div>
          <div className={classes.sortFilter}>
            <Stack direction="row">
              <VSNSelect
                {...{
                  ...sortBy,
                  value: filter?.type || 0,
                  onChange: (e) =>
                    setFilter((prev) => {
                      const newValue = {
                        ...prev,
                        type: e.target.value,
                        page: 1,
                      };
                      getMemberAssessmentDetail(newValue);
                      return newValue;
                    }),
                  classes: { popover: classes.filterDropDown },
                  // value: filters?.sort_by,
                }}
              />
            </Stack>
          </div>
        </Stack>
        <TableContainer>
          <Table sx={{ minWidth: 600 }}>
            <TableBody className={classes.memberList}>
              <TableRow className={classes.tableHeadRow}>
                <TableCell width="35%">Assessment Name</TableCell>
                <TableCell width="20%">
                  <Box>Assessment Type</Box>
                </TableCell>

                <TableCell width="13%">
                  <Box>Required</Box>
                </TableCell>
                <TableCell width="20%">
                  <Box>Last Completed Date</Box>
                </TableCell>
              </TableRow>
              {getAssessmentLoader || assessMent === undefined ? (
                <TableRow>
                  <TableCell colSpan={4} align="center" sx={{ height: 100 }}>
                    <CircularProgress size={24} />
                  </TableCell>
                </TableRow>
              ) : assessMent?.length ? (
                assessMent.map((val, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <VSNTypography>{val?.name}</VSNTypography>
                      </TableCell>
                      <TableCell>
                        <VSNTypography>{val?.type}</VSNTypography>
                      </TableCell>

                      <TableCell>
                        <VSNTypography>
                          {val?.required ? "Yes" : "No"}
                        </VSNTypography>
                      </TableCell>
                      <TableCell>
                        <VSNTypography>
                          {" "}
                          {val?.completed_at
                            ? new Date(val?.completed_at).toLocaleDateString(
                                "en-US"
                              )
                            : val?.required
                            ? "Pending"
                            : "-"}
                        </VSNTypography>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={4} sx={{ height: 100 }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* {filters?.pages ? ( */}
        <Stack spacing={2} className={classes.paginationFooter}>
          <Stack spacing={2}>
            <VSNPagination
              count={page?.pages}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) =>
                setFilter((prev) => {
                  const newValue = {
                    ...prev,
                    page: value,
                  };
                  getMemberAssessmentDetail(newValue);
                  return newValue;
                })
              }
              page={page?.current_page || 1}
            />
          </Stack>
        </Stack>
        {/* ) : null} */}
      </Box>
    </>
  );
};

export default AssessmentModel;
