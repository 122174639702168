import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ON_FORM_CHANGE, SET_API_DATA } from "../redux/constants";
import { ApiContainer } from "../utils/api";

export const teamListModalContainer = () => {
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const { api } = ApiContainer();
  const form = useSelector((state) => state?.form);
  const { formData } = form;
  const { teamsOfMember } = formData;
  const currentSelectedMember = useSelector(
    (state) => state?.api?.currentSelectedMember
  );
  const organization_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_id
  );
  const loader = useSelector((state) => state?.api?.loader?.teamsOfMember);

  useEffect(() => {
    searchRef.current.value = teamsOfMember?.keyword || "";
  }, [teamsOfMember?.keyword]);

  const getMembers = async (filters) => {
    if (currentSelectedMember?.user_id) {
      const response = await api({
        method: "GET",
        endPoint: `organization/${organization_id}/members/${
          currentSelectedMember?.user_id
        }/teams?page=${filters?.current_page || 1}&limit=10${
          filters?.keyword ? `&keyword=${filters?.keyword}` : ``
        }`,
        showToastMessage: false,
        urlencoded: false,
        parent: "teamsOfMember",
        needLoader: true,
        needFullResponse: true,
      });
      if (response?.status && !!response?.data?.success) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            teamsOfMember: response?.data,
          },
        });
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...formData,
              teamsOfMember: {
                ...filters,
                keyword: searchRef.current.value,
                ...response?.data?.page,
              },
            },
          },
        });
      } else {
        dispatch({
          type: SET_API_DATA,
          payload: {
            teamsOfMember: {},
          },
        });
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...formData,
              teamsOfMember: {
                ...filters,
                keyword: searchRef.current.value,
                pages: 0,
                current_page: 0,
              },
            },
          },
        });
      }
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    const { value } = e?.target;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          teamsOfMember: {
            ...teamsOfMember,
            current_page: 1,
            keyword: value || "",
          },
        },
      },
    });
    getMembers({
      ...teamsOfMember,
      current_page: 1,
      keyword: value || "",
    });
  };

  const onPageChange = (e, page) => {
    if (teamsOfMember?.current_page === page) return;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          teamsOfMember: {
            ...teamsOfMember,
            current_page: page || 1,
          },
        },
      },
    });
    getMembers({
      ...teamsOfMember,
      current_page: page || 1,
    });
  };

  const onSearchChange = debounce(onSearch, 300);

  return {
    filters: teamsOfMember,
    loader,
    searchRef,
    onSearchChange,
    onPageChange,
  };
};
