import { React, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip } from "@mui/material";

export default function EmailAutoComplete({
  placeholder,
  multiple,
  options,
  label,
  name,
  onChange,
  onInputChange,
  className,
  ...rest
}) {
  return (
    <Autocomplete
      multiple
      fullWidth
      freeSolo
      className={className || ""}
      options={options}
      filterSelectedOptions
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((email, index) => (
          <Chip key={index} label={email} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => {
        return (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        );
      }}
      onChange={onChange}
      onInputChange={onInputChange}
      {...rest}
    />
  );
}
