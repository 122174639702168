import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { equal, keys, length } from "../../utils/javascript";
import VSNLoader from "../../shared/VSNLoader";

function ProtectedRouteAdmin() {
  const history = useNavigate();
  const { pathname } = useLocation();
  const userRoleData = useSelector((state) => state.app?.userData);
  const userInfo = userRoleData.user_profile;
  const roleInfoLoader = useSelector((state) => state.api.loader?.getRoleInfo);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      window.onpopstate = () => history("/admin/login");
      history("/admin/login");
    }
    if (token && length(keys(userRoleData))) {
      if (!equal(userInfo?.role, "platform_admin")) {
        window.onpopstate = () => history("/admin/login");
        history("/admin/login");
      }
    }
  }, [pathname, userRoleData]);

  return (
    <div>
      {roleInfoLoader === undefined || roleInfoLoader ? (
        <VSNLoader variant="fullPage" />
      ) : (
        <Outlet />
      )}
    </div>
  );
}

export default ProtectedRouteAdmin;
