import { lazy, Suspense } from "react";
import UserInfo from "../presentation/userInfo";
import ThemeSelection from "../presentation/theme";
import LogIn from "../presentation/logIn";
import Landing from "../presentation/landing";
import SignUp from "../presentation/signUp";
import SignUpLayout from "../presentation/layout/SignUpLayout";
import OTPSection from "../presentation/otpSection";
import ProtectedRoute from "../presentation/auth/ProtectedRoute";
import NotFound from "../presentation/NotFound";
import Dashboard from "../presentation/orgAdminDashboard/index";
import TestLayout from "../presentation/layout/TestLayout";
import QuickNote from "../presentation/assessment/QuickNote";
import QuestionSet from "../presentation/assessment/QuestionSet";
import User from "../presentation/userInfo/user";
import PublicRoute from "../presentation/auth/PublicRoute";
import VSNLoader from "../shared/VSNLoader";
import Admin from "../presentation/adminDashboard";
import ProtectedRouteAdmin from "../presentation/auth/ProtectedRouteAdmin";
import AdminLayout from "../presentation/layout/AdminAuthLayout";
import AdminLogIn from "../presentation/adminDashboard/AdminLogin";
import AdminOTP from "../presentation/adminDashboard/AdminOTP";
import TermsCondition from "../presentation/terms-condition/Terms-Condition";
import PrivacyPolicy from "../presentation/terms-condition/PrivacyPolicy";
import InternalError from "../presentation/InternalError";
import DemoError from "../DemoError";
import TypeShow from "../presentation/Types";
import Teams from "../presentation/teams/index";
import TeamView from "../presentation/teams/team-view/index";
import TeamLayout from "../presentation/layout/TeamLayout";
import AssessmentLayout from "../presentation/layout/AssessmentLayout";
import AssessmentsView from "../presentation/assessmentsView/index";
import QuickView from "../presentation/assessmentsView/QuickView";
import VasanaForTeams from "../presentation/landing/VasanaForTeams";
import WhoWeAre from "../presentation/landing/WhoWeAre";
import VasanaHealthCare from "../presentation/landing/VasanaHealthCare";
import Contact from "../presentation/landing/Contact";
import AssessmentForAdmin from "../presentation/orgAdminDashboard/AssessmentForAdmin/AssessmentForAdmin";
import QuickViewForAdmin from "../presentation/orgAdminDashboard/AssessmentForAdmin/QuickViewForAdmin";
// import Learning from "../presentation/learning/Learning";
import Huma from "../presentation/huma/Huma";

const Assessment = lazy(() => import("../presentation/assessment"));

const publicRoutes = [
  {
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/sa",
        element: <TypeShow />,
      },
      {
        path: "/re",
        element: <TypeShow />,
      },
      {
        path: "/ga",
        element: <TypeShow />,
      },
      {
        path: "/ma",
        element: <TypeShow />,
      },
      {
        path: "/terms-conditions",
        element: <TermsCondition />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/demo-error",
        element: <DemoError />,
      },
      {
        path: "/error",
        element: <InternalError />,
      },
      {
        element: <PublicRoute />,
        children: [
          {
            path: "/signup",
            element: <SignUpLayout />,
            children: [
              {
                index: true,
                element: <SignUp />,
              },
            ],
          },
          {
            path: "/demo-login",
            element: <AdminLayout />,
            children: [{ index: true, element: <AdminLogIn /> }],
          },
          {
            path: "/otp",
            element: <SignUpLayout />,
            children: [{ index: true, element: <OTPSection codeLength={4} /> }],
          },
          {
            path: "/login",
            element: <SignUpLayout />,
            children: [{ index: true, element: <LogIn /> }],
          },
        ],
      },
      {
        path: "/for-teams",
        element: <VasanaForTeams />,
      },
      {
        path: "/public-safety",
        element: <VasanaHealthCare />,
      },
      {
        path: "/who-we-are",
        element: <WhoWeAre />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

const protectedRoute = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/edit-profile",
        element: <SignUpLayout />,
        children: [
          {
            index: true,
            element: <UserInfo />,
          },
        ],
      },
      {
        path: "/teams",
        element: <TeamLayout />,
        children: [
          {
            index: true,
            element: <Teams />,
          },
          {
            path: "join",
            element: <Teams isJoinTeam />,
          },
          {
            path: ":teamId",
            element: <TeamView />,
          },
        ],
      },
      {
        path: "/assessments",
        element: <AssessmentLayout />,
        children: [
          {
            index: true,
            element: <AssessmentsView />,
          },
          {
            path: ":assessmentId",
            element: <QuickView />,
          },
        ],
      },
      // {
      //   path: "/assessments/:assessmentId",
      //   element: <QuickView />,
      // },
      // {
      //   path: "/learning",
      //   element: <Learning />,
      // },
      {
        path: "/huma",
        element: <Huma />,
      },
      {
        path: "/theme",
        element: <SignUpLayout />,
        children: [{ index: true, element: <ThemeSelection /> }],
      },
      {
        path: "/org-admin",
        element: <TeamLayout />,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          { path: ":locationId/:userId", element: <AssessmentForAdmin /> },
          {
            path: ":locationId/:userId/:assessmentId",
            element: <QuickViewForAdmin />,
          },
        ],
      },

      {
        element: <TestLayout />,
        children: [
          {
            index: true,
            path: "/get-started/:assessmentName",
            element: (
              <Suspense fallback={<VSNLoader variant="fullPage" />}>
                <Assessment />
              </Suspense>
            ),
          },
          { path: "/quick-note/:assessmentName", element: <QuickNote /> },
          {
            path: "/assessment/:assessmentName",
            element: <QuestionSet />,
          },
        ],
      },

      {
        path: "/user",
        element: <User />,
      },
    ],
  },
];

const adminRoutes = [
  {
    element: <AdminLayout />,
    children: [
      {
        path: "/admin/login",
        element: <AdminLogIn />,
      },
      {
        path: "/admin/otp",
        element: <AdminOTP codeLength={4} />,
      },
    ],
  },
  {
    element: <ProtectedRouteAdmin />,
    children: [
      {
        path: "/admin",
        element: <Admin />,
      },
    ],
  },
];

const routes = [...publicRoutes, ...protectedRoute, ...adminRoutes];
export default routes;
