import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import VSNTypography from "../../shared/VSNTypography";
import { ReactComponent as SlideIcon } from "../../assets/common/svg/chex.svg";
import { ReactComponent as WhiteFlower } from "../../assets/landing/svg/white-flower.svg";
import VSNButton from "../../shared/VSNButton";

const useStyles = makeStyles((theme) => ({
  marquee: {
    whiteSpace: "nowrap",
    display: "flex",
    gap: "0 28px",
    marginBottom: 20,
    animation: "$marquee 25s linear infinite",
    [theme.breakpoints.down("md")]: {
      gap: "0 16px",
    },
    "& svg": {
      width: 40,
      height: 40,
      [theme.breakpoints.down("md")]: {
        width: 24,
        height: 24,
      },
      "& path": {
        fillOpacity: "0.2",
      },
    },
    "& h2": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 32,
      },
    },
  },
  "@keyframes marquee": {
    "0%": {
      transform: "translateX(0%)",
    },
    "100%": {
      transform: "translateX(-100%)",
    },
  },
  marqueeOrange: {
    padding: "10px 0",
    marginBottom: 0,
    animation: "$marqueeOrange 160s linear infinite",
    "& h2": {
      fontWeight: 300,
      fontSize: 56,
      lineHeight: "72px",
      color: "#ffffff",
      [theme.breakpoints.down("lg")]: {
        fontSize: 46,
        lineHeight: "62px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        lineHeight: "52px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
        lineHeight: "40px",
      },
      "& span": {
        fontWeight: 700,
      },
    },
    "& svg": {
      "& path": {
        fillOpacity: 1,
      },
    },
  },
  "@keyframes marqueeOrange": {
    "0%": {
      transform: "translateX(0%)",
    },
    "100%": {
      transform: "translateX(-14000px)",
    },
  },
}));

const FooterSlider = ({ isBlackSlider = true, ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isAuth = localStorage.getItem("isAuth");

  const handleNavigate = () => {
    if (isAuth) {
      navigate("/");
    } else {
      navigate("/signup");
    }
  };

  return (
    <>
      {isBlackSlider ? (
        <>
          <Box overflow="hidden">
            <Box className={classes.marquee} {...props}>
              {Array.from({ length: 12 }, () => "Thrive with Vasana")?.map(
                (str, i) => (
                  <Stack
                    key={i}
                    direction="row"
                    alignItems="center"
                    gap={{ xs: 2, md: 3.5 }}
                  >
                    <VSNTypography variant="h2" fontWeight={500}>
                      {str}
                    </VSNTypography>
                    <SlideIcon />
                  </Stack>
                )
              )}
            </Box>
          </Box>

          <VSNTypography
            color="#2D2F39"
            fontSize={{ sm: 20, xs: 16 }}
            fontWeight={500}
            textAlign="center"
            mb={{ sm: 6, xs: 3 }}
          >
            Change the way you live . Build better relationships. Shape your
            future.
          </VSNTypography>
          <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <VSNButton
              sx={{
                width: "fit-content",
                padding: "12px 32px",
              }}
              handleClick={handleNavigate}
              // endIcon={<RightArrow />}
            >
              Get Started
            </VSNButton>
          </Box>
        </>
      ) : (
        <>
          <Box overflow="hidden" backgroundColor="#E87637">
            <Box
              className={`${classes.marquee} ${classes.marqueeOrange}`}
              {...props}
            >
              {/* <Stack
                    direction="row"
                    alignItems="center"
                    gap={{ xs: 2, md: 3.5 }}
                  >
                    <VSNTypography variant="h2"></VSNTypography>
                    <WhiteFlower />
                  </Stack> */}
              {Array.from(
                { length: 10 },
                () =>
                  `Advanced wellness evaluations. <span>Behavioral</span> and <span>physical</span> diagnostics. Whole health focus. Personalized therapeutics.  Secure patient-centric framework.`
              )?.map((str, i) => (
                <Stack
                  key={i}
                  direction="row"
                  alignItems="center"
                  gap={{ xs: 2, md: 3.5 }}
                >
                  <WhiteFlower />
                  <VSNTypography
                    variant="h2"
                    dangerouslySetInnerHTML={{ __html: str }}
                  />
                </Stack>
              ))}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default FooterSlider;
