import React from "react";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const StyledInput = styled(TextField)(({ disabled }) => ({
  "& .MuiInputBase-root": {
    "& .Mui-disabled": {
      WebkitTextFillColor: "#202024 !important",
    },
    "& .MuiInputBase-input": {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: "23px",
      color: "#202024",
      padding: "8px 0",
      "&::-webkit-input-placeholder": {
        fontWeight: 400,
        color: "#898994",
      },
      "&:-webkit-autofill": {
        animation: "none !important",
        WebkitTextFillColor: "#000",
        WebkitBoxShadow: "0 0 0px 1000px rgba(0,0,0,0) inset",
        transition: "background-color 5000s ease-in-out 0s",
      },
      "&:-webkit-autofill:hover": {
        WebkitTextFillColor: "#000",
        WebkitBoxShadow: "0 0 0px 1000px rgba(0,0,0,0) inset",
        transition: "background-color 5000s ease-in-out 0s",
      },
      "&-webkit-autofill:focus": {
        WebkitTextFillColor: "#000",
        WebkitBoxShadow: "0 0 0px 1000px rgba(0,0,0,0) inset",
        transition: "background-color 5000s ease-in-out 0s",
      },
    },
    "&::before": {
      borderBottom: !disabled ? "1px solid #CBCBCF" : "0px",
    },
    "&:hover": {
      "&::before": {
        borderBottomWidth: "1px solid !important",
        borderBottomColor: "#CBCBCF !important",
      },
    },
  },
}));

const VSNTextField = ({
  type,
  id,
  name,
  onChange,
  InputLabelProps,
  fullWidth,
  placeHolder,
  endAdornment,
  maxDate,
  pattern,
  readOnly,
  ...rest
}) => (
  <StyledInput
    id={`${name}-${id}`}
    name={name}
    type={type}
    InputLabelProps={InputLabelProps}
    onChange={onChange}
    fullWidth={fullWidth}
    placeholder={placeHolder}
    variant="standard"
    InputProps={{
      endAdornment,
    }}
    disabled={readOnly}
    autoCapitalize={type === "email" ? "none" : undefined}
    {...rest}
    label=""
  />
);

export default VSNTextField;
