import communication from "../../../assets/Teams/svg/communication-style.svg";
import conversationPace from "../../../assets/Teams/svg/conversation-pace.svg";
import bodyLanguage from "../../../assets/Teams/svg/body-language.svg";
import handGesturingStyle from "../../../assets/Teams/svg/hand-gesturing-style.svg";
import decisionMakingStyle from "../../../assets/Teams/svg/decision-making-style.svg";
import strivesFor from "../../../assets/Teams/svg/strives-for.svg";

import communicationStyleSa from "../../../assets/Teams/svg/aspects/communication-style-sa.svg";
import communicationStyleRe from "../../../assets/Teams/svg/aspects/communication-style-re.svg";
import communicationStyleGa from "../../../assets/Teams/svg/aspects/communication-style-ga.svg";
import communicationStyleMa from "../../../assets/Teams/svg/aspects/communication-style-ma.svg";

import conversationalSpaceSa from "../../../assets/Teams/svg/aspects/conversational-space-sa.svg";
import conversationalSpaceRe from "../../../assets/Teams/svg/aspects/conversational-space-re.svg";
import conversationalSpaceGa from "../../../assets/Teams/svg/aspects/conversational-space-ga.svg";
import conversationalSpaceMa from "../../../assets/Teams/svg/aspects/conversational-space-ma.svg";

import bodyLanguageSa from "../../../assets/Teams/svg/aspects/body-language-sa.svg";
import bodyLanguageRe from "../../../assets/Teams/svg/aspects/body-language-re.svg";
import bodyLanguageGa from "../../../assets/Teams/svg/aspects/body-language-ga.svg";
import bodyLanguageMa from "../../../assets/Teams/svg/aspects/body-language-ma.svg";

import handGesturingStyleSa from "../../../assets/Teams/svg/aspects/hand-gesture-sa.svg";
import handGesturingStyleRe from "../../../assets/Teams/svg/aspects/hand-gesture-re.svg";
import handGesturingStyleGa from "../../../assets/Teams/svg/aspects/hand-gesture-ga.svg";
import handGesturingStyleMa from "../../../assets/Teams/svg/aspects/hand-gesture-ma.svg";

import decisionMakingStyleSa from "../../../assets/Teams/svg/aspects/decision-making-sa.svg";
import decisionMakingStyleRe from "../../../assets/Teams/svg/aspects/decision-making-re.svg";
import decisionMakingStyleGa from "../../../assets/Teams/svg/aspects/decision-making-ga.svg";
import decisionMakingStyleMa from "../../../assets/Teams/svg/aspects/decision-making-ma.svg";

import strivesForSa from "../../../assets/Teams/svg/aspects/strives-for-sa.svg";
import strivesForRe from "../../../assets/Teams/svg/aspects/strives-for-re.svg";
import strivesForGa from "../../../assets/Teams/svg/aspects/strives-for-ga.svg";
import strivesForMa from "../../../assets/Teams/svg/aspects/strives-for-ma.svg";

export const aspectsOfVasanaType = [
  {
    img: communication,
    key: "communication",
    title: "Communication Style",
    desc: "Discover the different style in which four types express themselves.",
    aspects: [
      {
        key: "sa",
        title: "Measured",
        img: communicationStyleSa,
      },
      {
        key: "re",
        title: "Animated",
        img: communicationStyleRe,
      },
      {
        key: "ga",
        title: "Gregarious",
        img: communicationStyleGa,
      },
      {
        key: "ma",
        title: "Precise",
        img: communicationStyleMa,
      },
    ],
  },
  {
    img: conversationPace,
    key: "conversationPace",
    title: "Conversational Pace",
    desc: "Learn about the speed and rhythm at which different types converse.",
    aspects: [
      {
        key: "sa",
        title: "Thoughtful & Direct",
        img: conversationalSpaceSa,
      },
      {
        key: "re",
        title: "Fast & Scattered",
        img: conversationalSpaceRe,
      },
      {
        key: "ga",
        title: "Busy & Harried",
        img: conversationalSpaceGa,
      },
      {
        key: "ma",
        title: "Methodical & Deliberate",
        img: conversationalSpaceMa,
      },
    ],
  },
  {
    img: bodyLanguage,
    key: "bodyLanguage",
    title: "Body Language",
    desc: "Learn how different styles use non-verbal gestures to communicate.",
    aspects: [
      {
        key: "sa",
        title: "Pensive",
        img: bodyLanguageSa,
      },
      {
        key: "re",
        title: "Restless",
        img: bodyLanguageRe,
      },
      {
        key: "ga",
        title: "Affable",
        img: bodyLanguageGa,
      },
      {
        key: "ma",
        title: "Restrained",
        img: bodyLanguageMa,
      },
    ],
  },
  {
    img: handGesturingStyle,
    key: "handGesturingStyle",
    title: "Hand Gesturing Style",
    desc: "Discover how different types use hand movements to communicate non-verbally.",
    aspects: [
      {
        key: "sa",
        title: "Measured",
        img: handGesturingStyleSa,
      },
      {
        key: "re",
        title: "Open",
        img: handGesturingStyleRe,
      },
      {
        key: "ga",
        title: "Embracing",
        img: handGesturingStyleGa,
      },
      {
        key: "ma",
        title: "Closed",
        img: handGesturingStyleMa,
      },
    ],
  },
  {
    img: decisionMakingStyle,
    key: "decisionMakingStyle",
    title: "Decision Making Style",
    desc: "Learn how Vasana types evaluate information and options for decision making.",
    aspects: [
      {
        key: "sa",
        title: "Decisive",
        img: decisionMakingStyleSa,
      },
      {
        key: "re",
        title: "Impulsive",
        img: decisionMakingStyleRe,
      },
      {
        key: "ga",
        title: "Collaborative",
        img: decisionMakingStyleGa,
      },
      {
        key: "ma",
        title: "Methodical",
        img: decisionMakingStyleMa,
      },
    ],
  },
  {
    img: strivesFor,
    key: "strivesFor",
    title: "Strives for",
    desc: "Discover what drives the different Vasana types and what they work towards",
    aspects: [
      {
        key: "sa",
        title: "Understanding",
        img: strivesForSa,
      },
      {
        key: "re",
        title: "Challenges",
        img: strivesForRe,
      },
      {
        key: "ga",
        title: "Structure",
        img: strivesForGa,
      },
      {
        key: "ma",
        title: "Standards",
        img: strivesForMa,
      },
    ],
  },
];
