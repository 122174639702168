import {
  Box,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { PulseLoader } from "react-spinners";
import Bg from "../../assets/introExtro/png/into-extro-bg.png";
import { ReactComponent as BtnArrow } from "../../assets/introExtro/svg/btn-arrow.svg";
import { ReactComponent as ListArrow } from "../../assets/introExtro/svg/list-arrow.svg";
import { ReactComponent as ListHead1 } from "../../assets/introExtro/svg/list-head1.svg";
import { ReactComponent as ListHead2 } from "../../assets/introExtro/svg/list-head2.svg";
import { ReactComponent as ListHead3 } from "../../assets/introExtro/svg/list-head3.svg";
import { ReactComponent as People1 } from "../../assets/introExtro/svg/people1.svg";
import { ReactComponent as People2 } from "../../assets/introExtro/svg/people2.svg";
import { ReactComponent as People3 } from "../../assets/introExtro/svg/people3.svg";
import { ReactComponent as Shape1 } from "../../assets/introExtro/svg/shapes-1.svg";
import { ReactComponent as Shape2 } from "../../assets/introExtro/svg/shapes-2.svg";
import { ReactComponent as Shape3 } from "../../assets/introExtro/svg/shapes-3.svg";
import { ReactComponent as Shape4 } from "../../assets/introExtro/svg/shapes-4.svg";
import flower from "../../assets/introExtro/png/flower-shapes.png";
import rainbowPurple from "../../assets/introExtro/png/rainbow-purple.png";
import snake from "../../assets/introExtro/png/snakes-line.png";
import zigzag from "../../assets/introExtro/png/zig-zag.png";
import greenRainbow from "../../assets/introExtro/png/light-green-rainbow.png";
import blueFlower from "../../assets/introExtro/png/blue-flower.png";
import bgBottomRight from "../../assets/introExtro/png/bg-extrovert-right.png";
import bgResponsiveExtrovertBottom from "../../assets/introExtro/png/bg-responsive-extrovert.png";
import bgResponsiveExtrovert from "../../assets/introExtro/png/bg-responsive-extrovert-top.png";
import bgRight from "../../assets/common/png/bgRightShapes.png";
import bgBottom from "../../assets/common/png/bg-responsive-bottom.png";
import greenFlower from "../../assets/quickView/svg/green-half.svg";
import halfRound from "../../assets/quickView/svg/half-round-card.svg";
import { bgImagesMain } from "../../description/assessment.description";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import VSNPaper from "../../shared/VSNPaper";
import VSNTypography from "../../shared/VSNTypography";
import { RightArrow } from "../../shared/icon";
import { IntroExtroResultStyle } from "./IntroExtroResultStyle";
import introExtroResultContainer from "../../container/introExtroResult.container";
import { assessmentIds, assessmentTypes } from "../../utils/constant";
import VSNLoader from "../../shared/VSNLoader";
import {
  colorFromResults,
  renderTypesWithImage,
} from "../../shared/CustomFunc";
import AssessmentsSection from "./AssessmentsSection";

const IntroExtroResult = ({
  endPointToCall,
  showBreadCrumbs,
  breadCrumbAndButton,
  releaseButton,
  isLogedUser,
}) => {
  const isExLargeScreen = useMediaQuery("(min-width:1440px)");
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isSmallScreen = useMediaQuery("(max-width:1199px)");
  const isMdLgScreen = useMediaQuery("(min-width:900px)");
  const isMdScreen = useMediaQuery("(max-width:899px)");
  const {
    userRoleData,
    introExtroAssessment,
    result,
    loaders,
    generatingIntroExtroReport,
    downloadIntroExtroReportData,
    assessmentsSection,
    reportLoaders,
    introExtroAssessmentData,
    navigate,
    changeTextForIntroExtro,
    downloadIntroExtroReport,
    downloadAllReports,
    handleAssessmentClick,
    toggleReport,
  } = introExtroResultContainer({ endPointToCall });

  const classes = IntroExtroResultStyle({ result });
  const getClass = {
    1: classes.stickOne,
    2: classes.stickTwo,
    3: classes.stickThree,
    4: classes.stickFour,
  };

  const mythAndMisconception = (
    <Box
      className={`${isMdScreen ? classes.introExtroBox : ""} ${
        isMdScreen ? classes.communicationBox : ""
      }`}
    >
      <VSNTypography
        variant="h5"
        className={`${classes.lgHeading} ${classes.respLgHead}`}
      >
        {introExtroAssessment?.myths_and_misconceptions?.title}
      </VSNTypography>
      <Box
        className={`${isMdLgScreen ? classes.introExtroBox : ""} ${
          isLargeScreen ? classes.mythsBox : ""
        }`}
      >
        <Stack
          direction={{
            xs: "column",
            md: isLogedUser === false && "row",
            xl: "row",
          }}
          spacing={{ xs: 2.75, xl: 0 }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <VSNImage
            src={introExtroAssessment?.myths_and_misconceptions?.image}
            alt="myths_and_misconceptions"
          />
          <Box padding={{ xl: "9px 0" }}>
            <VSNTypography color="secondary.grey" className={classes.mythsDesc}>
              {introExtroAssessment?.myths_and_misconceptions?.description}
            </VSNTypography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );

  const communicationBox = (
    <Box
      className={`${isSmallScreen ? classes.introExtroBox : ""} ${
        isSmallScreen ? classes.communicationBox : ""
      }`}
      mt={{ md: 3 }}
    >
      <VSNTypography
        variant="h5"
        className={`${classes.lgHeading} ${classes.respLgHead}`}
      >
        {introExtroAssessment?.communication_style?.title}
      </VSNTypography>{" "}
      <Box
        className={`${isLargeScreen ? classes.introExtroBox : ""} ${
          isLargeScreen ? classes.communicationBox : ""
        }`}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: "22px", md: "30px", lg: "40px" }}
          alignItems="center"
        >
          <VSNImage
            src={introExtroAssessment?.communication_style?.image}
            alt="communication_style_image"
          />
          <Box padding={{ xs: 0, lg: "10px 0" }}>
            <VSNTypography color="secondary.grey" className={classes.mythsDesc}>
              {`${introExtroAssessment?.communication_style?.description} ${introExtroAssessment?.communication_style?.characteristics}`}
            </VSNTypography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );

  const downloadReportBtn = (
    <VSNButton
      className={classes.downloadButton}
      isLoading={
        generatingIntroExtroReport?.download &&
        introExtroAssessmentData?.pdf_exists
      }
      handleClick={() =>
        generatingIntroExtroReport?.isGenerating &&
        !downloadIntroExtroReportData
          ? changeTextForIntroExtro()
          : downloadIntroExtroReport()
      }
      disabled={!generatingIntroExtroReport?.reportSuccess}
    >
      {!generatingIntroExtroReport?.reportSuccess ? (
        <PulseLoader size={10} color="#ffffff" />
      ) : (
        "Download Full Report"
      )}
    </VSNButton>
  );
  const retakeAssessmentBtn = (
    <VSNButton
      className={classes.downloadButton}
      variant="outlined"
      handleClick={() => navigate("/assessments")}
    >
      Retake Assessment
    </VSNButton>
  );

  if (loaders?.assessmentResult || !introExtroAssessment) {
    return <VSNLoader variant="fullPage" />;
  }
  return (
    <div className={classes.mainLayout}>
      {bgImagesMain?.map((data, i) => (
        <span
          key={i}
          className={`${classes.shape}  ${classes?.[data?.className]}`}
        >
          {data.image}
        </span>
      ))}

      {!showBreadCrumbs ? (
        <Box className={classes.headingButton}>
          <Box>{breadCrumbAndButton}</Box>
          <Box>{introExtroAssessment?.is_locked ? releaseButton : ""}</Box>
        </Box>
      ) : (
        ""
      )}

      <Grid container spacing={3} mb={{ xs: 0, md: 3.75 }}>
        <Grid item lg={8} xs={12}>
          <Box mb={{ xs: 3.25, md: 2 }}>
            <VSNPaper className={classes.firtBox}>
              <img src={Bg} alt="Bg" className={classes.firstBoxBg} />
              <Shape1 className={classes.shape1} />
              <Shape2 className={classes.shape2} />
              <Shape3 className={classes.shape3} />
              <Shape4 className={classes.shape4} />
              <Stack
                justifyContent="space-between"
                position="relative"
                zIndex="1"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  className={classes.vasanatype}
                >
                  <VSNTypography variant="h4">
                    <span style={{ color: "#86650A" }}>
                      Introvert or Extrovert:
                    </span>{" "}
                    Your Results in Brief
                  </VSNTypography>
                  <Hidden lgDown>
                    <Stack
                      direction="row"
                      className={classes.DownBtnWrapper}
                      spacing={2}
                    >
                      {isLogedUser !== false ? downloadReportBtn : ""}
                      {userRoleData?.is_demo_user && retakeAssessmentBtn}
                    </Stack>
                  </Hidden>
                </Stack>
                <Stack
                  direction={{ xs: "column-reverse", sm: "row" }}
                  justifyContent="space-between"
                  alignItems="end"
                >
                  <Stack spacing={1}>
                    {isLogedUser !== false ? (
                      <Stack direction="row" spacing="5px" alignItems="center">
                        <VSNTypography>You are an </VSNTypography>
                        <VSNTypography
                          variant="body"
                          color="primary.main"
                          fontWeight="700"
                          fontSize="20px"
                        >
                          {result}
                        </VSNTypography>
                      </Stack>
                    ) : (
                      ""
                    )}
                    <VSNTypography color="secondary.grey" maxWidth="912px">
                      {introExtroAssessment?.result?.description}
                    </VSNTypography>
                  </Stack>
                  <Box className={classes.people1}>
                    <VSNImage
                      src={introExtroAssessment?.result?.icon}
                      alt="result_icon"
                    />
                  </Box>
                </Stack>
                <Hidden lgUp>
                  <Stack
                    className={classes.DownBtnWrapper}
                    spacing={{ xsm: 2, xs: 2 }}
                    mt={1.5}
                    direction={{ xsm: "row" }}
                  >
                    {isLogedUser !== false ? downloadReportBtn : ""}
                    {userRoleData?.is_demo_user && retakeAssessmentBtn}
                  </Stack>
                </Hidden>
              </Stack>
            </VSNPaper>
          </Box>
          <Box>
            <VSNTypography variant="h5" className={classes.lgHeading} mb={1}>
              Aspects of Your Personality
            </VSNTypography>{" "}
            <Box className={`${isMdLgScreen ? classes.introExtroBox : ""}`}>
              <Grid container spacing={{ xs: 1.75, md: 0, lg: 1.75 }}>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={4}>
                  <List
                    className={`${classes.introList} ${
                      isMdScreen ? classes.respIntroListBox : ""
                    }`}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      marginBottom={1}
                      className={classes.listHeadMain}
                    >
                      <VSNImage
                        src={
                          introExtroAssessment?.aspect_of_personality
                            ?.strengths_at_work?.icon
                        }
                        alt="strengths_at_work_icon"
                      />
                      <VSNTypography variant="h6" className={classes.listHead}>
                        {introExtroAssessment?.aspect_of_personality
                          ?.strengths_at_work?.title || ""}
                      </VSNTypography>
                    </Stack>
                    {introExtroAssessment?.aspect_of_personality?.strengths_at_work?.objectives?.map(
                      (listItems, listItemIndex) => {
                        return (
                          <ListItem key={listItemIndex}>
                            <ListItemIcon>
                              <ListArrow />
                            </ListItemIcon>
                            <ListItemText primary={listItems?.description} />
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={4}>
                  <List
                    className={`${classes.introList} ${
                      isMdScreen ? classes.respIntroListBox : ""
                    }`}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      marginBottom={1}
                      className={classes.listHeadMain}
                    >
                      <VSNImage
                        src={
                          introExtroAssessment?.aspect_of_personality
                            ?.weaknesses_at_work?.icon
                        }
                        alt="weaknesses_at_work_icon"
                      />
                      <VSNTypography variant="h6" className={classes.listHead}>
                        {introExtroAssessment?.aspect_of_personality
                          ?.weaknesses_at_work?.title || ""}
                      </VSNTypography>
                    </Stack>
                    {introExtroAssessment?.aspect_of_personality?.weaknesses_at_work?.objectives?.map(
                      (listItems, listItemIndex) => {
                        return (
                          <ListItem key={listItemIndex}>
                            <ListItemIcon>
                              <ListArrow />
                            </ListItemIcon>
                            <ListItemText primary={listItems?.description} />
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={4}>
                  <List
                    className={`${classes.introList} ${
                      isMdScreen ? classes.respIntroListBox : ""
                    }`}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      marginBottom={1}
                      className={classes.listHeadMain}
                    >
                      <VSNImage
                        src={
                          introExtroAssessment?.aspect_of_personality
                            ?.annoyed_by?.icon
                        }
                        alt="annoyed_by_icon"
                      />
                      <VSNTypography variant="h6" className={classes.listHead}>
                        {introExtroAssessment?.aspect_of_personality?.annoyed_by
                          ?.title || ""}
                      </VSNTypography>
                    </Stack>
                    {introExtroAssessment?.aspect_of_personality?.annoyed_by?.objectives?.map(
                      (listItems, listItemIndex) => {
                        return (
                          <ListItem key={listItemIndex}>
                            <ListItemIcon>
                              <ListArrow />
                            </ListItemIcon>
                            <ListItemText primary={listItems?.description} />
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Stack sx={{ display: { md: "block", xs: "none" } }}>
            {communicationBox}
          </Stack>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Grid container spacing={{ md: 3, lg: 0 }}>
            {isLogedUser !== false ? (
              <Grid item md={6} lg={12}>
                <AssessmentsSection
                  assessmentsSection={(
                    [
                      ...(assessmentsSection || [])?.filter(
                        (el) =>
                          el?.assessment_id?.toString() !==
                          assessmentIds?.introExtro
                      ),
                    ] || []
                  )?.slice(0, 2)}
                  downloadAllReports={downloadAllReports}
                  reportLoaders={reportLoaders}
                  handleAssessmentClick={handleAssessmentClick}
                  toggleReport={toggleReport}
                />
              </Grid>
            ) : (
              ""
            )}
            <Stack sx={{ display: { md: "none", xs: "block" } }}>
              <Grid item md={6} lg={12}>
                {communicationBox}
              </Grid>
            </Stack>
            <Grid item md={isLogedUser !== false ? 6 : 12} lg={12}>
              <Stack sx={{ display: { md: "block", xs: "none" } }}>
                {mythAndMisconception}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Stack sx={{ display: { md: "none", xs: "block" } }}>
        <Grid container spacing={{ xs: 3 }} mt={{ xs: 0 }}>
          <Grid item md={6} lg={4} xs={12}>
            {mythAndMisconception}
          </Grid>
        </Grid>
      </Stack>

      <Box className={classes.diffrentAspectPersonalityMain}>
        <VSNTypography
          variant="h5"
          component="h5"
          className={classes.lgHeading}
          mb={1}
          mt={3.7}
        >
          Adjectives that Describe You
        </VSNTypography>
        <VSNPaper className={classes.diffrentPersonalityBox}>
          <VSNImage
            className={classes.flower}
            src={result === "Introvert" ? flower : blueFlower}
            alt="flower"
          />
          <VSNImage className={classes.snake} src={snake} alt="snake" />
          <VSNImage className={classes.zigzag} src={zigzag} alt="zigzag" />

          <VSNImage
            className={classes.rainbowPurple}
            src={result === "Introvert" ? rainbowPurple : greenRainbow}
            alt="rainbowPurple"
          />
          <VSNImage
            className={classes.bgRight}
            src={result === "Introvert" ? bgRight : bgBottomRight}
            alt="bgRight"
          />
          <VSNImage
            className={classes.bgBottom}
            src={
              result === "Introvert" ? bgBottom : bgResponsiveExtrovertBottom
            }
            alt="bgBottom"
          />
          <VSNImage
            className={classes.bgResponsiveExtrovert}
            src={result === "Introvert" ? {} : bgResponsiveExtrovert}
            alt="bgResponsiveExtrovert"
          />
          <Stack
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: 4, md: 1 }}
            justifyContent="space-around"
          >
            {introExtroAssessment?.adjectives_describing_you?.characteristics
              ?.filter((el) => el?.icon)
              ?.map((data, i) => (
                <Box className={classes.positionPersonality} key={i}>
                  <Box
                    className={`${classes.stick} ${
                      (i + 1) % 4 === 0 ? getClass[4] : getClass[(i + 1) % 4]
                    }`}
                  >
                    {/* {data.stick} */}
                  </Box>
                  <Box className={classes.roundShape}>{data.round}</Box>
                  <Box className="imgOut">
                    <VSNImage src={data.icon} alt="imgOut_icon" />
                    <VSNTypography variant="h6" component="h6">
                      {" "}
                      {data.title}
                    </VSNTypography>
                  </Box>
                </Box>
              ))}
          </Stack>
        </VSNPaper>
      </Box>
    </div>
  );
};

export default IntroExtroResult;
