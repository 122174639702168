import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const StyleButton = styled(Button)(({ theme, isloading }) => ({
  background: "linear-gradient(132.61deg, #e87637 32.09%, #ffbe78 163.78%)",
  border: 0,
  borderRadius: "8px !important",
  padding: "12px 66px",
  minWidth: "146px",
  color: "#ffffff",
  textTransform: "none",
  fontWeight: 700,
  lineHeight: "23px",
  fontSize: 18,
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
  "& > span": {
    display: "flex",
    alignItems: "center",
  },
  "& .content": {
    opacity: isloading ? 0 : 1,
    visibility: isloading ? "hidden" : "visible",
    margin: 0,
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  "& .loader": {
    position: "absolute",
    display: isloading ? "block" : "none",
    left: "50%",
    transform: "translate(-50%)",
    "& > span": {
      fontSize: "14px",
      lineHeight: "23px",
      whiteSpace: "nowrap",
      color: "#E87637",
      "& .MuiCircularProgress-root": {
        width: "20px",
        height: "20px",
        verticalAlign: "middle",
        marginLeft: "10px",
      },
    },
  },
  "& .text": {
    transform: "",
  },
  "&:disabled": {
    background: "#F1D4C4",
    color: "#F5F5F5",
  },
  "&.MuiButton-outlined": {
    background: "transparent",
    border: " 1px solid #E87637",
    color: "#E87637",
  },
  "& a": {
    color: "#fff",
  },
}));

const VSNButton = ({
  handleClick = () => {},
  children,
  isLoading,
  disabled,
  loadingText,
  ...rest
}) => (
  <StyleButton
    {...rest}
    disableElevation
    onClick={(e) => (handleClick ? handleClick(e) : null)}
    disabled={isLoading || disabled}
    isloading={isLoading ? isLoading?.toString() : undefined}
  >
    <span>
      <span className="loader">
        {loadingText ? (
          <span>
            {loadingText}
            <CircularProgress size={16} color="inherit" />
          </span>
        ) : (
          <CircularProgress size={24} color="inherit" />
        )}
      </span>
      <span className="content">{children}</span>
    </span>
  </StyleButton>
);

export default VSNButton;
