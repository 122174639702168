import { ReactComponent as Bullet1 } from "../assets/termsCondition/svg/Bullet1.svg";
import { ReactComponent as Bullet2 } from "../assets/termsCondition/svg/Bullet2.svg";
import { ReactComponent as Bullet3 } from "../assets/termsCondition/svg/Bullet3.svg";
import { ReactComponent as Bullet4 } from "../assets/termsCondition/svg/Bullet4.svg";
import { ReactComponent as Bullet5 } from "../assets/termsCondition/svg/Bullet5.svg";
import { ReactComponent as Bullet6 } from "../assets/termsCondition/svg/Bullet6.svg";
import { ReactComponent as Bullet7 } from "../assets/termsCondition/svg/Bullet7.svg";
import { ReactComponent as Bullet8 } from "../assets/termsCondition/svg/Bullet8.svg";
import { ReactComponent as Bullet9 } from "../assets/termsCondition/svg/Bullet9.svg";
import { ReactComponent as Bullet10 } from "../assets/termsCondition/svg/Bullet10.svg";

import { ReactComponent as HeaderImage } from "../assets/termsCondition/svg/headerImage.svg";

export const headerTermsConditionDescription = [
  {
    heading: "Terms and Conditions",
    description: (
      <>
        Please read these terms and conditions (&quot;terms and
        conditions&quot;, &quot;terms&quot;) carefully before using the{" "}
        <a href="/">www.vasana.ai</a> website (&quot;website&quot;,
        &quot;service&quot;) operated by RedAzure Corporation LLC
        (&quot;us&quot;, &quot;we&quot;, &quot;our&quot;).
      </>
    ),
    image: <HeaderImage />,
  },
];

export const termsConditionDescription = [
  {
    color: "#27AE60",
    title: "Conditions of Use",
    description: [
      "By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not agree to be bound by the terms of this Agreement, you are advised to stop using the website accordingly. RedAzure only grants use and access to this website, its products, and its services to those who have accepted its terms.",
    ],
    hasPoint: false,
  },
  {
    color: "#E87637",
    title: "Privacy Policy",
    description: [
      <>
        Before you continue using our website, we advise you to read our{" "}
        <a href="/privacy-policy" style={{ textDecoration: "underline" }}>
          privacy policy
        </a>{" "}
        regarding our user data collection and practices. Understanding our
        privacy policy will help you make informed decisions about sharing your
        data with us.
      </>,
    ],
    hasPoint: false,
  },
  {
    color: "#8B3F9E",
    title: "Age Restriction",
    description: [
      "You must be at least 18 (eighteen) years of age to use this website. By using this website, you warrant that you are at least 18 years of age and can legally adhere to this Agreement. RedAzure assumes no responsibility for liabilities related to age misrepresentation.",
    ],
    hasPoint: false,
  },
  {
    color: "#97C8E9",
    title: "Intellectual Property",
    description: [
      "You agree that all materials, products, and services provided on this website are the property of RedAzure, its affiliates, directors, officers, employees, agents, suppliers, or licensors, including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree not to reproduce or redistribute RedAzure’s intellectual property in any form without explicit permission. For issues regarding intellectual property claims, please contact us directly.",
    ],
    hasPoint: false,
  },
  {
    color: "#FFB142",
    title: "User Accounts",
    description: [
      "As a user of this website, you may be asked to register and provide personal information. You are responsible for ensuring the accuracy of this information and maintaining the safety and security of your account credentials. You are responsible for all activities under your account. If you suspect any security breaches, inform us immediately. We reserve the right to terminate accounts and edit or remove content at our sole discretion.",
    ],
    hasPoint: false,
  },
  {
    color: "#97C8E9",
    title: "Medical Disclaimer",
    description: [
      {
        innerPoints: [
          <>
            <span>No Medical Advice:</span> The content provided on this
            website, including assessments and therapeutic resources, is for
            informational purposes only and does not constitute medical advice.
            Users should consult a qualified healthcare provider for personal
            medical advice.
          </>,
          <>
            <span>No Doctor-Patient Relationship:</span> Use of this website
            does not establish a doctor-patient relationship. RedAzure is not a
            substitute for professional medical diagnosis, treatment, or
            emergency care.
          </>,
        ],
      },
    ],
    hasPoint: true,
  },
  {
    color: "#27AE60",
    title: "Use of Services",
    description: [
      {
        innerPoints: [
          <>
            <span>Permitted Use:</span> You may use the services provided
            through this website for personal, non-commercial purposes only. You
            agree not to use the services for any unlawful or prohibited
            activities.
          </>,
          <>
            <span>Modification of Services:</span> We reserve the right to
            modify, suspend, or discontinue any part of our services at any time
            without notice.
          </>,
        ],
      },
    ],
    hasPoint: true,
  },
  {
    color: "#E87637",
    title: "Applicable Law",
    description: [
      "By using this website, you agree that the laws of the state of New Jersey, without regard to principles of conflict of laws, will govern these terms and conditions and any dispute that might arise between RedAzure and you or its business partners and associates.",
    ],
    hasPoint: false,
  },
  {
    color: "#8B3F9E",
    title: "Disputes",
    description: [
      "Any dispute related to your use of this website or to products you purchase from us shall be resolved through arbitration in New Jersey, and you consent to the exclusive jurisdiction and venue of such courts.",
    ],
    hasPoint: false,
  },
  {
    color: "#97C8E9",
    title: "Indemnification",
    description: [
      "You agree to indemnify RedAzure and its affiliates and hold RedAzure harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.",
    ],
    hasPoint: false,
  },
  {
    color: "#E87637",
    title: "Exclusion of Third-Party Rights",
    description: [
      "This contract is between you and us. No other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person to end the contract or make any changes to these terms.",
    ],
    hasPoint: false,
  },
  {
    color: "#FFB142",
    title: "Limitation of Liability",
    description: [
      "RedAzure is not liable for any damages that may occur to you as a result of your misuse of our website. This includes direct, indirect, incidental, or consequential damages. RedAzure reserves the right to edit, modify, and change this Agreement at any time. Revised terms will apply from the date of publication on the website.",
    ],
    hasPoint: false,
  },
  {
    color: "#8B3F9E",
    title: "Termination",
    description: [
      "We reserve the right to terminate or suspend your account and access to the website and its services at our discretion, without notice, for conduct that we believe violates these terms or is harmful to other users of the website, us, or third parties, or for any other reason.",
    ],
    hasPoint: false,
  },
  {
    color: "#27AE60",
    title: "Entire Agreement",
    description: [
      "This Agreement constitutes the entire agreement between RedAzure and you concerning the use of this website. This Agreement supersedes all prior agreements regarding your use of the website.",
    ],
    hasPoint: false,
  },
  {
    color: "#8B3F9E",
    title: "Changes to Terms",
    description: [
      "We may update these terms from time to time. Continued use of the website following any changes indicates your acceptance of the new terms.",
    ],
    hasPoint: false,
  },
  {
    color: "#97C8E9",
    title: "Contact Information",
    description: [
      <>
        If you have any questions or concerns about these terms and conditions,
        please contact us at{"  "}
        <a href="mailto:huma@vasana.ai">huma@vasana.ai</a>.
      </>,
    ],
    hasPoint: false,
  },
];
