import React from "react";
import VSNFormControl from "../VSNFormControl";
import VSNSelect from "../VSNSelect";
import VSNTextField from "../VSNTextField";
import VSNDatePicker from "../VSNDatePicker";
import { ReactComponent as DatePicker } from "../../assets/common/svg/datepicker.svg";
import { ReactComponent as ArrowDown } from "../../assets/common/svg/arrowDown.svg";
import VSNMobileInput from "../VSNMobileInput";
import EmailAutoComplete from "../VSNEmailAutoComplete";
import VSNDateInput from "../VSNDateInput";

const ControlForm = (props) => {
  const {
    control,
    onChange,
    handleSelectChange,
    handleDateChange,
    handleMobileChange,
    label,
    readOnly,
    onInputChange,
    onEmailSelect,
    elementId,
    validateAs,
    ...rest
  } = props;
  switch (control) {
    case "input":
      return (
        <VSNTextField
          {...{
            onChange,
            InputLabelProps: { shrink: true },
            label,
            readOnly,
            ...rest,
          }}
        />
      );
    case "formControl":
      return <VSNFormControl {...{ label, ...rest }} />;
    case "select":
      return (
        <VSNSelect
          IconComponent={ArrowDown}
          {...{ onChange, label, elementId, readOnly, ...rest }}
        />
      );
    case "mobile":
      return (
        <VSNMobileInput
          {...{
            handleMobileChange,
            label,
            readOnly,
            ...rest,
          }}
        />
      );

    case "date-time-picker":
      return (
        <VSNDatePicker
          components={{ OpenPickerIcon: DatePicker }}
          {...{ handleDateChange, ...rest }}
        />
      );
    case "date":
      return (
        <VSNDateInput
          handleDateChange={handleDateChange}
          readOnly={readOnly}
          {...rest}
        />
      );
    default:
      return null;
  }
};

export default ControlForm;
