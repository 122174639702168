import * as React from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Stack } from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VSNButton from "./VSNButton";
import VSNModel from "./VSNModel";
import { CloseIcon } from "./icon";

const useStyles = makeStyles((theme) => ({
  leavingModel: {
    "&.MuiModal-root": {
      "& .MuiPaper-root": {
        maxWidth: 458,
        width: "fit-content",
        padding: 0,
      },
    },
  },
  modalHead: {
    "&.MuiStack-root": {
      padding: "20px 26px",
      borderBottom: "1px solid #EAEDF0",
      "& h4": {
        fontWeight: 700,
        fontSize: 24,
        padding: 0,
        lineHeight: "31px",
        color: theme.palette.secondary.main,
        [theme.breakpoints.down("md")]: {
          marginBottom: 12,
          fontSize: 22,
          lineHeight: "25px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 18,
        },
      },
      "& .MuiIconButton-root": {
        backgroundColor: "#E8763740",
        height: 25,
        width: 25,
        "& svg": {
          margin: 0,
          marginBottom: 0,
          color: theme.palette.primary.basic,
        },
        "&:hover": {
          backgroundColor: "#E8763760",
        },
      },
    },
  },
  container: {
    position: "static",
    textAlign: "center",
    "& .MuiDialogActions-root": {
      justifyContent: "center",
      backgroundColor: "#F6F6FD",
      padding: "24px 40px 18px",
      borderRadius: 5,
      [theme.breakpoints.down("sm")]: {
        padding: "18px 20px",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "0 60px",
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        padding: "0 16px",
      },
      "& .info": {
        marginBottom: 10,
        marginTop: 24,
        [theme.breakpoints.down("md")]: {
          marginBottom: 12,
          width: 250,
        },
      },
    },

    "& p": {
      fontSize: 14,
      lineHeight: "21px",
      color: theme.palette.secondary.grey,
      "& span": {
        fontWeight: 700,
        marginRight: 4,
      },
    },
  },
  cancelButton: {
    "&.MuiButtonBase-root": {
      background: "transparent",
      color: theme.palette.primary.basic,
    },
  },
  attentionButton: {
    "& .MuiButtonBase-root": {
      padding: [[8, 37]],
      borderRadius: 10,
      width: "auto",
      "& .content": {
        fontSize: 16,
      },
      [theme.breakpoints.down("md")]: {
        padding: [[8, 28]],
      },
    },
  },
  logoutButton: {
    "&.MuiButtonBase-root": {
      color: theme.palette.primary.light,
    },
  },
}));

export default function VSNConfirmBox({
  isOpen,
  msg,
  handleCancel,
  handleOk,
  title,
  okBtnName = "Ok",
  cancelBtnName = "Cancel",
  isLoading,
}) {
  const classes = useStyles();
  return (
    <VSNModel open={isOpen} className={classes.leavingModel}>
      <div className={classes.container}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.modalHead}
        >
          <DialogTitle id="draggable-dialog-title" component="h4">
            {title}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        </Stack>

        {msg && (
          <DialogContent>
            <InfoRoundedIcon
              sx={{ color: "#F7A23B", height: "94px", width: "94px" }}
              className="info"
              fontSize="55px"
            />
            <DialogContentText
              className={classes.note}
              component="p"
              sx={{ mb: { xs: "30px", md: 3 } }}
            >
              {msg}
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Stack
            width="100%"
            direction={{ xs: "column", xts: "row" }}
            gap={{ md: 3, xs: 2 }}
            className={classes.attentionButton}
            justifyContent="center"
          >
            <VSNButton
              handleClick={handleCancel}
              className={classes.cancelButton}
              variant="outlined"
            >
              {cancelBtnName}
            </VSNButton>
            <VSNButton
              handleClick={handleOk}
              className={classes.logoutButton}
              isLoading={isLoading}
            >
              {okBtnName}
            </VSNButton>
          </Stack>
        </DialogActions>
      </div>
    </VSNModel>
  );
}
