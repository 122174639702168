import { questionSetContainer } from "../../container/questionSet.container";
import NewQuestionSet from "./NewQuestionSet";
import AssessmentCompletion from "./AssessmentCompletion";
import VSNPrompt from "../../shared/VSNPrompt";
import LeaveAssessment from "./LeaveAssessment";

const QuestionSet = () => {
  const {
    currentQuestion,
    handelNextQuestion,
    currentQuestionIndex,
    onChange,
    assessmentFormData,
    allow_resubmission,
    totalQuestion,
    getTransformValue,
    giftBox,
    progressBar,
    onChangeMultiChoice,
    isAssessmentCompleted,
    logOut,
  } = questionSetContainer();

  if (isAssessmentCompleted) {
    return <AssessmentCompletion />;
  }

  return (
    <>
      <VSNPrompt when={!logOut} ConfirmationBox={LeaveAssessment} />
      <NewQuestionSet
        {...{
          currentQuestion,
          handelNextQuestion,
          currentQuestionIndex,
          onChange,
          assessmentFormData,
          allow_resubmission,
          totalQuestion,
          getTransformValue,
          giftBox,
          progressBar,
          onChangeMultiChoice,
        }}
      />
    </>
  );
};

export default QuestionSet;
