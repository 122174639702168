import userBanner from "../assets/common/svg/edit-profile.webp";
import greetingImg from "../assets/common/jpg/greeting-2.webp";
import {
  bannerdesc,
  becomeMember,
  userInfoTitle,
  userInfoBannerDesc,
} from "./banner.description";

export const allMetaContent = {
  landing: {
    title: "Vasana : AI-Powered Personalized Mental Healthcare",
    meta: [
      {
        name: "description",
        content:
          "Vasana's revolutionary approach to mental healthcare combines advanced diagnostics, AI, and personalized therapeutics to enhance well-being, foster happiness, and improve lives.  Tailored for frontline workers, teams, and individuals.",
      },
      {
        ...(process.env.REACT_APP_ENVIRONMENT === "production"
          ? { property: "robots", content: "index, follow" }
          : { property: "robots", content: "noindex, nofollow" }),
      },
      { property: "og:title", content: "Landing" },
      {
        name: "keywords",
        content:
          "Vasana, mental health innovation, behavioral health solutions, happiness and wellbeing, performance acceleration, diagnostics technology",
      },
      {
        property: "og:image",
        content: "https://vasana-images.s3.amazonaws.com/vasana-logo.png",
      },
      {
        property: "og:description",
        content:
          "Vasana's revolutionary approach to mental healthcare combines advanced diagnostics, AI, and personalized therapeutics to enhance well-being, foster happiness, and improve lives.  Tailored for frontline workers, teams, and individuals.",
      },
      { property: "og:url", content: "https://vasana.ai" },
      { property: "og:type", content: "website" },
    ],
  },
  publicSafety: {
    title: "Holistic Healthcare Solutions for Frontline Workers with Vasana",
    meta: [
      {
        name: "description",
        content:
          "Vasana for Frontline revolutionizes holistic care for frontline workers by integrating advanced physical, behavioral, and mental health diagnostics, empowering first responders with personalized therapeutics.",
      },
      {
        ...(process.env.REACT_APP_ENVIRONMENT === "production"
          ? { property: "robots", content: "index, follow" }
          : { property: "robots", content: "noindex, nofollow" }),
      },
      { property: "og:title", content: "Public Safety" },
      {
        name: "keywords",
        content:
          "Helping Deliver, Meaningful Holistic Care, Physical and Mental and Behavioral Attributes, Better Care, Healthcare,  Public Safety ,Vasana teams, neuroscience in the workplace, behavioral science applications, team communication, trust building, workplace inclusivity, collaborative solutions",
      },
      {
        property: "og:image",
        content: "https://vasana-images.s3.amazonaws.com/vasana-logo.png",
      },
      {
        property: "og:description",
        content:
          "Vasana for Frontline revolutionizes holistic care for frontline workers by integrating advanced physical, behavioral, and mental health diagnostics, empowering first responders with personalized therapeutics.",
      },
      { property: "og:url", content: "https://vasana.ai/public-safety" },
      { property: "og:type", content: "website" },
    ],
  },
  ForTeams: {
    title: "Transform Team Dynamics and Performance | Vasana for Teams",
    meta: [
      {
        name: "description",
        content:
          "With evidence-based mental health programs, enhance workplace well-being, reduce stress, and boost performance. Leverage neuroscience, AI, and behavioral psychology to create a culture of trust, empathy, and resilience.",
      },
      {
        ...(process.env.REACT_APP_ENVIRONMENT === "production"
          ? { property: "robots", content: "index, follow" }
          : { property: "robots", content: "noindex, nofollow" }),
      },
      { property: "og:title", content: "For Teams" },
      {
        name: "keywords",
        content:
          "Thriving Relationships, Stronger Teams, Exceptional Performance, Anytime, anywhere , Vasana teams, neuroscience in the workplace, behavioral science applications, team communication, trust building, workplace inclusivity, collaborative solutions",
      },
      {
        property: "og:image",
        content: "https://vasana-images.s3.amazonaws.com/vasana-logo.png",
      },
      {
        property: "og:description",
        content:
          "With evidence-based mental health programs, enhance workplace well-being, reduce stress, and boost performance. Leverage neuroscience, AI, and behavioral psychology to create a culture of trust, empathy, and resilience.",
      },
      { property: "og:url", content: "https://vasana.ai/for-teams" },
      { property: "og:type", content: "website" },
    ],
  },
  whoWeAre: {
    title: "Who We Are | Vasana",
    meta: [
      {
        name: "description",
        content:
          "Vasana's mission is to revolutionize mental healthcare through AI-driven diagnostics and personalized therapeutics. Discover how we are working towards enhancing the well-being of frontline workers, teams, and individuals.",
      },
      {
        ...(process.env.REACT_APP_ENVIRONMENT === "production"
          ? { property: "robots", content: "index, follow" }
          : { property: "robots", content: "noindex, nofollow" }),
      },
      { property: "og:title", content: "Who We Are" },
      {
        name: "keywords",
        content:
          "Vasana mission, organizational transformation, improving lives, Vasana vision, fostering happiness, Vasana values",
      },
      {
        property: "og:image",
        content: "https://vasana-images.s3.amazonaws.com/vasana-logo.png",
      },
      {
        property: "og:description",
        content:
          "Vasana's mission is to revolutionize mental healthcare through AI-driven diagnostics and personalized therapeutics. Discover how we are working towards enhancing the well-being of frontline workers, teams, and individuals.",
      },
      { property: "og:url", content: "https://vasana.ai/who-we-are" },
      { property: "og:type", content: "website" },
    ],
  },
  contactUs: {
    title: "Contact Us | Vasana",
    meta: [
      {
        name: "description",
        content:
          "Get in touch with Vasana to learn more about our mental healthcare solutions for frontline workers, teams, and individuals. We're here to help you thrive.",
      },
      {
        ...(process.env.REACT_APP_ENVIRONMENT === "production"
          ? { property: "robots", content: "index, follow" }
          : { property: "robots", content: "noindex, nofollow" }),
      },
      { property: "og:title", content: "Contact Us" },
      {
        name: "keywords",
        content:
          "help you thrive, intrested in, personalized health access, mental health platform, Thrive with Vasana, Build better relationships, Shape your future, health insights, Vasana account access",
      },
      {
        property: "og:image",
        content: "https://vasana-images.s3.amazonaws.com/vasana-logo.png",
      },
      {
        property: "og:description",
        content:
          "Get in touch with Vasana to learn more about our mental healthcare solutions for frontline workers, teams, and individuals. We're here to help you thrive.",
      },
      { property: "og:url", content: "https://vasana.ai/contact-us" },
      { property: "og:type", content: "website" },
    ],
  },
  signup: {
    title: "Log In to Vasana: Transform Your Mental Health",
    meta: [
      {
        name: "description",
        content:
          "Enter your Vasana account to continue your journey towards enhanced mental and behavioral health. Discover personalized diagnostics, adaptive solutions, and transformative insights.",
      },
      {
        ...(process.env.REACT_APP_ENVIRONMENT === "production"
          ? { property: "robots", content: "index, follow" }
          : { property: "robots", content: "noindex, nofollow" }),
      },
      {
        property: "og:title",
        content: "Log In to Vasana: Transform Your Mental Health",
      },
      {
        name: "keywords",
        content:
          "Vasana login, personalized health access, mental health platform, behavioral diagnostics login, health insights, Vasana account access",
      },
      {
        property: "og:image",
        content: "https://vasana-images.s3.amazonaws.com/vasana-logo.png",
      },
      {
        property: "og:description",
        content:
          "Enter your Vasana account to continue your journey towards enhanced mental and behavioral health. Discover personalized diagnostics, adaptive solutions, and transformative insights.",
      },
      { property: "og:url", content: "https://vasana.ai/signup" },
      { property: "og:type", content: "website" },
    ],
  },
  login: {
    title: "Log In to Vasana: Transform Your Mental Health",
    meta: [
      {
        name: "description",
        content:
          "Enter your Vasana account to continue your journey towards enhanced mental and behavioral health. Discover personalized diagnostics, adaptive solutions, and transformative insights.",
      },
      {
        ...(process.env.REACT_APP_ENVIRONMENT === "production"
          ? { property: "robots", content: "index, follow" }
          : { property: "robots", content: "noindex, nofollow" }),
      },
      {
        property: "og:title",
        content: "Log In to Vasana: Transform Your Mental Health",
      },
      {
        name: "keywords",
        content:
          "Vasana login, personalized health access, mental health platform, behavioral diagnostics login, health insights, Vasana account access",
      },
      {
        property: "og:image",
        content: "https://vasana-images.s3.amazonaws.com/vasana-logo.png",
      },
      {
        property: "og:description",
        content:
          "Enter your Vasana account to continue your journey towards enhanced mental and behavioral health. Discover personalized diagnostics, adaptive solutions, and transformative insights.",
      },
      { property: "og:url", content: "https://vasana.ai/login" },
      { property: "og:type", content: "website" },
    ],
  },
  OTP: {
    title: "OTP | Vasana : AI-Powered Personalized Mental Healthcare",
    meta: [{ property: "og:title", content: "OTP" }],
  },
  profile: {
    title: "Profile | Vasana : AI-Powered Personalized Mental Healthcare",
    meta: [{ property: "og:title", content: "Profile" }],
  },
  getStarted: {
    title: "Get Started | Vasana : AI-Powered Personalized Mental Healthcare",
    meta: [{ property: "og:title", content: "Get Started" }],
  },
  quickNote: {
    title: "Quick Note |  Vasana : AI-Powered Personalized Mental Healthcare",
    meta: [{ property: "og:title", content: "Quick Note" }],
  },
  assessment: {
    title: "Assessment |  Vasana : AI-Powered Personalized Mental Healthcare",
    meta: [{ property: "og:title", content: "Assessment" }],
  },
  quickView: {
    title: "Quick View | Vasana : AI-Powered Personalized Mental Healthcare",
    meta: [{ property: "og:title", content: "Quick View" }],
  },
  termsConditions: {
    title:
      "Terms & Conditions | Vasana : AI-Powered Personalized Mental Healthcare",
    meta: [{ property: "og:title", content: "Terms & Conditions" }],
  },
  privacyPolicy: {
    title:
      "Privacy Policy | Vasana : AI-Powered Personalized Mental Healthcare",
    meta: [{ property: "og:title", content: "Privacy Policy" }],
  },
  admin: {
    title: "",
    meta: [{ property: "og:title", content: "Admin" }],
  },
};

export const getBannerInfo = {
  "/otp": {
    imgSrc: greetingImg,
    heading: becomeMember,
    description: bannerdesc,
    social: true,
    alternativeText: "or Sign Up with",
  },
  "/": {
    imgSrc: greetingImg,
    redirectText: "Already a member?",
    navLink: "/login",
    linkName: "Log In",
  },
  "/login": {
    imgSrc: greetingImg,
    heading: becomeMember,
    description: bannerdesc,
    social: true,
    redirectText: "Don’t have an account?",
    linkName: "Register Now",
    navLink: "/signup",
    alternativeText: "or Sign In with",
  },
  "/signup": {
    imgSrc: greetingImg,
    heading: becomeMember,
    description: bannerdesc,
    social: true,
    redirectText: "Already a member?",
    linkName: "Log In",
    navLink: "/login",
    alternativeText: "or Sign Up with",
  },
  "/edit-profile": {
    imgSrc: userBanner,
    opacity: true,
    heading: userInfoBannerDesc,
    bannerClassName: "editProfileBgWrap",
  },
  "/theme": {
    imgSrc: userBanner,
    heading: userInfoTitle,
    description: bannerdesc,
  },
};

export const VSNTypes = {
  sa: `${process.env.REACT_APP_MEDIA_URL}sa/colored-logo.svg`,
  re: `${process.env.REACT_APP_MEDIA_URL}re/colored-logo.svg`,
  ga: `${process.env.REACT_APP_MEDIA_URL}ga/colored-logo.svg`,
  ma: `${process.env.REACT_APP_MEDIA_URL}ma/colored-logo.svg`,
};

export const changeLocationAdminOption = [
  { key: "Location Admin", value: 2 },
  { key: "Head Clinician", value: 3 },
  { key: "Clinician", value: 4 },
];
export const activeStatusOption = [
  { key: "Active", value: true },
  { key: "Inactive ", value: false },
];
