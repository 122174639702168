import React from "react";

import ThemeContainer from "./ThemeContainer";

const ThemeSelection = () => {
  return (
    <ThemeContainer
    // {...{
    //   themeDetail,
    //   onGetStarted,
    //   handleCheck,
    // }}
    />
  );
};

export default ThemeSelection;
