import { Box, Stack } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import small2 from "../../../assets/assesments/svg/small-2.svg";
import small6 from "../../../assets/assesments/svg/small-6.svg";
import VSNImage from "../../../shared/VSNImage";
import VSNTypography from "../../../shared/VSNTypography";

const useStyles = makeStyles((theme) => ({
  selectMain: {
    gap: 5,
    marginBottom: "48px",
    "& .MuiStack-root": {
      height: 67,
      minWidth: 160,
      maxWidth: 160,
      padding: "13px",
      backgroundColor: theme.palette.primary.light,
      border: "1px solid #EAEDF0",
      borderRadius: 5,
      color: theme.palette.secondary.grey,
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      textAlign: "center",
      boxShadow: "0px 9px 20px 0px #968FB01A",
      "&:hover": {
        color: theme.palette.primary.basic,
        borderColor: theme.palette.primary.basic,
      },
      "&.active": {
        borderColor: theme.palette.primary.basic,
        color: theme.palette.primary.basic,
        boxShadow: "0px 9px 20px 0px #968FB01A",
      },
    },
  },
  information: {
    position: "relative",
    minHeight: 160,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: 1210,
    width: "100%",
    margin: "0 auto",
    padding: "0 15px",
    "& h4": {
      fontSize: 22,
      lineHeight: "26px",
      fontWeight: 600,
      color: theme.palette.secondary.darkGrey,
      textAlign: "center",
      maxWidth: 800,
      margin: "0 auto 30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        padding: [[0]],
        marginBottom: 45,
      },
    },
  },
  ImgWrapper: {
    position: "relative",
    padding: [[0, 45]],
    maxWidth: 710,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  small: {
    position: "absolute",
  },
  small2: {
    top: "-6%",
    left: "10%",
    zIndex: -1,
    [theme.breakpoints.down("1200")]: {
      left: "9%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  small6: {
    top: "-14%",
    right: "17%",
    zIndex: -1,
    [theme.breakpoints.down("1200")]: {
      right: "9%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  progressBtn: {
    "&.MuiButton-root": {
      background: theme.palette.primary.light,
      minHeight: 67,
      color: theme.palette.secondary.grey,
      border: "1px solid #EAEDF0",
      borderRadius: 5,
      maxWidth: 169,
      width: "100%",
      height: "100%",
      padding: 12,
      display: "flex",
      alignItems: "center",
      transition: "all 0.3s ease-in-out",
      [theme.breakpoints.down("sm")]: {
        width: "48%",
      },
      "& .content": {
        fontSize: 16,
        fontWeight: 500,
      },
      "&:hover": {
        background: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.basic}`,
        color: theme.palette.primary.basic,
      },
    },
  },
}));

const DoNotAllowReSubmissionChoice = ({
  question,
  onChange,
  handelNextQuestion,
  currentQuestionIndex,
  allow_resubmission,
}) => {
  const classes = useStyles();
  return (
    <Box mb={5} className={classes.information}>
      <VSNImage
        src={small2}
        alt="small2"
        className={`${classes.small2} ${classes.small}`}
      />
      <VSNImage
        src={small6}
        alt="small6"
        className={`${classes.small6} ${classes.small}`}
      />
      <VSNTypography variant="h4" component="h4">
        {question?.phrase}
      </VSNTypography>
      <Stack
        direction="row"
        justifyContent="center"
        flexWrap="wrap"
        className={`${classes.selectMain}`}
      >
        {question?.choices?.map((item, key) => (
          <Stack
            key={key}
            className={classes.progressBtn}
            justifyContent="center"
            onClick={() => {
              onChange(item, question);
              return (
                !allow_resubmission &&
                handelNextQuestion(currentQuestionIndex + 1, item)
              );
            }}
          >
            {item?.phrase}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default DoNotAllowReSubmissionChoice;
