import { Grid, Stack, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { inviteMembersContainer } from "../../description/inviteMembersModal.description";
import { summaryCardHeaders } from "../../description/orgDashboard.description";
import SEO from "../../shared/SEO";
import VSNLoader from "../../shared/VSNLoader";
import VSNModel from "../../shared/VSNModel";
import VSNTabs from "../../shared/VSNTabs";
import { InviteIcon } from "../../shared/icon";
import SummaryCard from "../adminDashboard/SummaryCard";
import InviteMembersModal from "./InviteMembersModal";
import VSNTypography from "../../shared/VSNTypography";
import AllModel from "./AllModel";
import { editOrgContainer } from "../../container/EditOrg.container";
import { createLocationContainer } from "../../container/org-admin/createLocation.conatainer";
import { createDepartmentContainer } from "../../container/org-admin/createDepartment.container";
import ShowDetail from "./ShowDetail";
import ShowAdminModel from "./ShowAdminModel";
import AssessmentModel from "./AssessmentModel";
import { department_admin, nameOfRole } from "../../utils/constant";
import CreateEventScheduleContainer from "../../container/org-admin/createeventSchedule.container";

const useStyles = makeStyles((theme) => ({
  dashboard: {
    padding: [[0, 23]],
    zIndex: 5,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: [[0, 16]],
    },
  },
  saregamaCards: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: [[20, 16]],
    borderRadius: 8,
    border: "1px solid white",
    "&:hover": {
      border: "1px solid #c9c9c9",
      background:
        "linear-gradient(92deg, rgb(225 225 225 / 50%) 1.56%, rgba(242, 231, 245, 0.00) 77.91%)",
    },
    "&.saPer": {
      "&:hover": {
        border: "1px solid #8304A3",
        background:
          "linear-gradient(92deg, rgba(242, 231, 245, 0.50) 1.56%, rgba(242, 231, 245, 0.00) 77.91%)",
      },
    },
    "&.rePer": {
      "&:hover": {
        border: "1px solid #3498DB",
        background:
          "linear-gradient(92deg, rgba(227, 241, 249, 0.50) 1.56%, rgba(227, 241, 249, 0.00) 77.91%)",
      },
    },
    "&.gaPer": {
      "&:hover": {
        border: "1px solid #FFB142",
        background:
          "linear-gradient(92deg, rgba(255, 224, 180, 0.20) 1.56%, rgba(255, 224, 180, 0.00) 77.91%)",
      },
    },
    "&.maPer": {
      "& img": {
        transform: "scale(1.1)",
      },
      "&:hover": {
        border: "1px solid #56A06F",
        background:
          "linear-gradient(92deg, rgba(200, 233, 211, 0.25) 1.56%, rgba(200, 233, 211, 0.00) 77.91%)",
      },
    },
    boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.1)",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      minWidth: "145px",
      padding: [[15]],
    },
    [theme.breakpoints.down("375")]: {
      minWidth: "120px",
      padding: [[15]],
    },
    "& p": {
      fontSize: 20,
      fontWeight: 700,
      flexGrow: 1,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
      },
    },
    "& h5": {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      marginRight: 15,
      lineHeight: "21px",
      textAlign: "left",
    },
    "& img": {
      marginRight: 10,
      [theme.breakpoints.down("sm")]: {
        height: 35,
      },
      [theme.breakpoints.down("375")]: {
        height: 30,
      },
    },
  },
  assesmentCard: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: 16,
    borderRadius: 8,
    boxShadow: "0px 3.05px 7.63px 0px #D4D9DE1A",
    backgroundColor: theme.palette.primary.light,
    border: "1px solid white",
    "&:hover": {
      border: "1px solid #c9c9c9",
    },
    zIndex: 5,
    [theme.breakpoints.down("md")]: {
      padding: 15,
    },
    "& h5": {
      fontSize: 18,
      fontWeight: 500,
      color: "#747787",
      marginRight: 15,
      lineHeight: "21px",
      textAlign: "left",
    },
    "& h4": {
      fontSize: 22,
      lineHeight: "27px",
      fontWeight: 700,
      flexGrow: 1,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("xsm")]: {
        textAlign: "right",
      },
    },
  },
  shape: {
    position: "absolute",
    zIndex: -2,
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      lineHeight: "31px",
      marginBottom: 32,
      [theme.breakpoints.down("md")]: {
        marginBottom: 15,
        fontSize: 20,
      },
    },
  },
  createButton: {
    "&.MuiButton-root": {
      "& .content": {
        fontSize: 16,
        [theme.breakpoints.down("md")]: {
          fontSize: 14,
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: "8px 12px",
      },
    },
  },
  tabs: {
    "&.MuiTabs-root": {
      borderRadius: 8,
      background: "#fff",
      marginBottom: 18,
      "& .MuiTabs-scroller": {
        marginRight: 10,
        marginLeft: 10,
        overflow: "auto !important",
        "& .MuiTabs-flexContainer": {
          "& .MuiButtonBase-root": {
            padding: [[16, 24]],
            marginRight: 8,
            fontSize: 18,
            color: "#747787",
            textTransform: "capitalize",
            fontWeight: 500,
            [theme.breakpoints.down("375")]: {
              fontSize: 15,
            },
            "&.Mui-selected": {
              color: theme.palette.primary.main,
            },
          },
        },
      },
      "& .MuiTabs-indicator": {
        height: 4,
        background: theme.palette.primary.main,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
    },
  },
  label: {
    "&.MuiTypography-root": {
      fontSize: 14,
      color: "#747787",
      fontWeight: 700,
      lineHeight: "18px",
      minHeight: 22,
    },
  },
  data: {
    "&.MuiTypography-root": {
      fontSize: 18,
      color: theme.palette.black.main,
      fontWeight: 500,
      lineHeight: "20px",
      padding: "8px 0",
    },
  },
}));

const Dashboard = ({
  isOrgAdmin,
  modelState,
  toggleModelShow,
  organization_summary,
  loaders,
  handleTabChange,
  activeTab,
  tabs,
  onFilters,
  organizationSummary,
  filters,
  organization_id,
  onEditOrganization,
  handleShareLink,
  permissions,
  onEditLocation,
  onDepartmentEdit,
  findTab,
  openEventScheduleModel,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const memberSummary = useSelector((state) => state?.api?.summaryMemberData);
  const allModelState = useSelector((state) => ({
    editOrgModel: state?.modelStates?.editOrg,
    editLocationModel: state?.modelStates?.editLocation,
    createLocationModel: state?.modelStates?.createLocation,
    createDepartmentModel: state?.modelStates?.createDepartment,
    editDepartmentModel: state?.modelStates?.editDepartment,
    inviteMember: state?.modelStates?.inviteMembers,
    getRoleInfo: state?.modelStates?.getRoleInfo,
    organizationSummary: state?.modelStates?.organizationSummary,
    showAdmin: state?.modelStates?.showAdmin,
    assessmentList: state?.modelStates?.assessmentList,
    createEventSchedule: state?.modelStates?.createEventSchedule,
  }));
  const userInfo = useSelector((state) => state?.app?.userData?.user_profile);

  const loaderState = useSelector((state) => {
    const { organizationInfo, locationInfo, departmentInfo, changingRole } =
      state?.api?.loader;
    return organizationInfo || locationInfo || departmentInfo || changingRole;
  });

  const summaryHeaders = summaryCardHeaders({ organizationSummary, classes });
  const assessmentSummary = {
    sa_marker: organizationSummary?.markers?.sa,
    re_marker: organizationSummary?.markers?.re,
    ga_marker: organizationSummary?.markers?.ga,
    ma_marker: organizationSummary?.markers?.ma,
  };
  const functionForEdit = {
    organization_admin: () => onEditOrganization(organization_id),
    location_admin: () => onEditLocation(userInfo?.location_id),
    department_admin: () => onDepartmentEdit(userInfo?.department_id),
  };
  const { editOrganization, orgToEdit, EditOrgAttribute } = editOrgContainer({
    toggleModelShow,
  });
  const {
    handleCreateLocation,
    createLocationAttribute,
    locationToEdit,
    editLocationAttribute,
  } = createLocationContainer({
    toggleModelShow,
  });
  const {
    handleCreateDepartment,
    editDepartmentAttribute,
    departmentToEdit,
    createDepartmentAttribute,
  } = createDepartmentContainer({
    toggleModelShow,
  });
  const {
    createEventScheduleAttribute,
    handleCreateEventSchedule,
    eventScheduleToEdit,
  } = CreateEventScheduleContainer();

  const allModelArray = [
    {
      modelState: allModelState?.createDepartmentModel,
      formAttribute: createDepartmentAttribute,
      formPath: { parent: "departmentInfo" },
      toggleModelShow,
      modelName: "createDepartment",
      loaderState: "createDepartment",
      titleOfModel: "Create Department",
      submitButtonName: "Create Department",
      staticValue: {},
      SubmitFunc: handleCreateDepartment,
    },
    {
      modelState: allModelState?.editDepartmentModel,
      formAttribute: editDepartmentAttribute,
      formPath: { parent: "departmentInfo" },
      clearApiState: "departmentToEdit",
      toggleModelShow,
      modelName: "editDepartmentModel",
      loaderState: "createDepartment",
      titleOfModel: "Edit Department",
      submitButtonName: "Save",
      staticValue: departmentToEdit,
      SubmitFunc: handleCreateDepartment,
    },
    {
      modelState: allModelState?.createLocationModel,
      formAttribute: createLocationAttribute,
      formPath: { parent: "locationInfo" },
      toggleModelShow,
      modelName: "createLocation",
      loaderState: "createLocation",
      titleOfModel: " Create Location",
      submitButtonName: "Create Location",
      staticValue: locationToEdit,
      SubmitFunc: handleCreateLocation,
    },
    {
      modelState: allModelState?.editLocationModel,
      formAttribute: editLocationAttribute,
      formPath: { parent: "locationInfo" },
      toggleModelShow,
      clearApiState: "locationToEdit",
      modelName: "editLocation",
      loaderState: "createLocation",
      titleOfModel: "Edit Location",
      submitButtonName: "Save",
      staticValue: locationToEdit,
      SubmitFunc: handleCreateLocation,
    },
    {
      modelState: allModelState?.editOrgModel,
      formAttribute: EditOrgAttribute,
      formPath: { parent: "organizationInfo" },
      clearApiState: "orgToEdit",
      toggleModelShow,
      modelName: "editOrg",
      loaderState: "organizationInfo",
      titleOfModel: "Edit Organization",
      submitButtonName: "Save",
      staticValue: orgToEdit,
      SubmitFunc: editOrganization,
    },
    {
      modelState: allModelState?.createEventSchedule,
      formAttribute: createEventScheduleAttribute,
      formPath: { parent: "eventScheduleInfo" },
      toggleModelShow,
      clearApiState: "eventScheduleToEdit",
      modelName: "createEventSchedule",
      loaderState: "createEventSchedule",
      titleOfModel: "Event Schedule",
      submitButtonName: isOrgAdmin !== department_admin && "Save",
      staticValue: eventScheduleToEdit,
      SubmitFunc: handleCreateEventSchedule,
    },
  ];

  if (
    loaders?.orgSummaryLoader ||
    organizationSummary === undefined ||
    allModelState?.organizationSummary
  ) {
    return <VSNLoader variant="fullPage" />;
  }
  return (
    <div className={`${classes.dashboard}`}>
      <SEO contentKey="admin" />
      <SummaryCard
        {...{
          functionForEdit,
          findTab,
          organization_summary,
          onFilters,
          tabs,
          activeTab,
          toggleModelShow,
          handleTabChange,
          title: `${nameOfRole?.[isOrgAdmin]} : ${organizationSummary?.name}`,
          // btnAction: "inviteMembers",
          editOrganization: "true",
          organization_id,
          organizationSummary,
          onEditOrganization,
          handleShareLink,
          isOrgAdmin,
          memberSummary,
          permissions,
          openEventScheduleModel,
          // btnTitle: (
          //   <Stack direction="row" alignItems="center">
          //     <InviteIcon /> &nbsp;Invite Members
          //   </Stack>
          // ),
          btnEditFunc: onEditOrganization,
          summaryHeaders,
          assessmentSummary,
          filters: {
            ...filters,
            activeType: filters?.sortByVasanaType?.toLowerCase(),
          },
          activeSummaryHeader: ({ tab, member_status }) =>
            tab === "members"
              ? activeTab === tab &&
                !member_status &&
                !filters?.member_status &&
                !filters?.sortByVasanaType?.toLowerCase()
                ? "active"
                : ""
              : member_status
              ? filters?.member_status
                ? "active"
                : ""
              : activeTab === tab
              ? "active"
              : "",
        }}
      />
      <ShowDetail tabs={tabs} activeTab={activeTab} findTab={findTab} />

      <section className={classes.tabsMain}>
        <VSNTabs
          permissions={permissions}
          tabs={tabs}
          handleTabChange={handleTabChange}
          activeTab={activeTab}
        />
      </section>
      {allModelState?.inviteMember && (
        <VSNModel
          disableEnforceFocus
          open={modelState?.inviteMembers}
          onClose={() => dispatch(toggleModelShow("inviteMembers"))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title={inviteMembersContainer?.label}
        >
          <InviteMembersModal />
        </VSNModel>
      )}
      {allModelState?.showAdmin && (
        <VSNModel
          disableEnforceFocus
          open={modelState?.showAdmin}
          onClose={() => dispatch(toggleModelShow("showAdmin"))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title={`${findTab?.tabLabel} Admins`}
        >
          <ShowAdminModel />
        </VSNModel>
      )}

      <AllModel allModelArray={allModelArray} />
      {loaderState && <VSNLoader variant="transParent" />}

      {/* {modelState?.editOrg && (
        <VSNModel
          disableEnforceFocus
          open={modelState?.editOrg}
          onClose={() => toggleModelShow("editOrg")}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
        >
          <EditOrganizationLink toggleModelShow={toggleModelShow} />
        </VSNModel>
      )} */}
    </div>
  );
};

export default Dashboard;
