import { useEffect, useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ON_FORM_CHANGE, SET_ERROR } from "../redux/constants";
import { checkValid } from "../shared/validation";

import {
  memberLogInAttribute,
  demoUserLoginAttribute,
  adminLoginAttribute,
} from "../description/logIn.description";
import ApiCalls from "../utils/apiCall";
import { equal, keys, length } from "../utils/javascript";
import {
  adminPoints,
  assessmentIds,
  assessmentTypes,
  endPoints,
} from "../utils/constant";
import { handleNavigation, setErrors } from "../shared/CustomFunc";

export const logInContainer = () => {
  const { pathname } = useLocation();
  const getEndPoint = (pathname) => {
    switch (pathname) {
      case "/login":
        return endPoints.memberLogin;
      case "/admin/login":
        return adminPoints.adminLogin;
      case "/demo-login":
        return endPoints.demoUserLogin;
      default:
    }
    return null;
  };
  const endPoint = getEndPoint(pathname);
  const userRoleData = useSelector((state) => state.app.userData);
  const { signInCall, getRoleInfo } = ApiCalls();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formPath = { parent: "logIn" };
  const { form } = useSelector((state) => state);
  const [isDisable, setIsDisable] = useState(false);

  const logInData = useSelector(
    (state) => state.form.formData?.[formPath?.parent]
  );
  const logInError = useSelector(
    (state) => state.form.formErrors?.[formPath?.parent]
  );
  const loginLoader = useSelector(
    (state) => state.api?.loader?.[formPath?.parent]
  );
  const [showPassword, setShowPassword] = useState({
    password: false,
  });
  const getLoginAtt = (pathname) => {
    switch (pathname) {
      case "/login":
        return memberLogInAttribute;
      case "/admin/login":
        return adminLoginAttribute;
      case "/demo-login":
        return demoUserLoginAttribute;
      default:
    }
    return null;
  };

  const [logInAttribute, setLoginAttribute] = useState(getLoginAtt(pathname));

  useEffect(() => {
    dispatch(setErrors({ isClear: true, formPath }));

    // const Auth = localStorage.getItem("isAuth");
    // const token = localStorage.getItem("token");
    // if (Auth === "true" || token) {
    //   localStorage.setItem("currentAssessmentId", assessmentIds?.vasanaType);
    //   localStorage.setItem(
    //     "currentAssessmentType",
    //     assessmentTypes?.vasanaType
    //   );
    //   navigate("/get-started");
    // }
  }, []);

  const handleClickShowPassword = (name) => {
    setShowPassword((pre) => ({
      ...pre,
      ...showPassword[name],
      [name]: !showPassword[name],
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    const newLogInAttribute = logInAttribute.map(
      ({ name, type, ...rest }, index) => {
        if (name === "password") {
          return {
            name,
            ...rest,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword(name)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword[name] ? (
                    <div>
                      <VisibilityOff />
                    </div>
                  ) : (
                    <div>
                      <Visibility />
                    </div>
                  )}
                </IconButton>
              </InputAdornment>
            ),
            htmlid: `${name}-${index}`,
            type: showPassword[name] ? "text" : "password",
          };
        }
        return { name, type, ...rest };
      }
    );

    setLoginAttribute([...newLogInAttribute]);
  }, [showPassword]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    dispatch(setErrors({ errors: logInError, value, name, formPath }));

    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: { ...logInData, [name]: value },
        },
      },
    });
  };

  const apiCall = async (second) => {
    const data = await signInCall({
      userData: {
        user_name: logInData.username,
        ...(pathname === "/demo-login" && { password: logInData.password }),
      },
      showToastMessage: true,
      ...(pathname === "/demo-login" && { notShowMsgOnCode: [200] }),
      needLoader: true,
      parent: formPath.parent,
      endPoint,
    });
    if (data?.status) {
      if (pathname === "/login") {
        localStorage.setItem("currentAssessmentId", assessmentIds?.vasanaType);
        navigate("/otp");
      } else if (pathname === "/admin/login") {
        navigate("/admin/otp");
      } else if (pathname === "/demo-login") {
        const token = data?.data?.access_token;

        localStorage?.setItem("token", token);
        setIsDisable(true);
        dispatch(
          getRoleInfo({
            needLoader: true,
            parent: "getRoleInfo",
            showToastMessage: true,
            toastMessage: "Login was successful.",
          })
        );
        localStorage.setItem("currentAssessmentId", assessmentIds?.vasanaType);
      }
      localStorage.setItem("otp-second", 60);
    }
  };
  useEffect(() => {
    handleNavigation(navigate, userRoleData);
  }, [userRoleData]);

  const onSubmit = async (e) => {
    e.preventDefault();
    let errors = logInError;
    logInAttribute?.forEach((obj) => {
      const { validateAs, name } = obj;
      errors = {
        ...errors,
        [name]: validateAs
          ? checkValid({
              validateAs,
              name,
              value: logInData?.[name],
            })
          : "",
      };
      dispatch({
        type: SET_ERROR,
        payload: {
          formErrors: {
            ...form.formErrors,
            [formPath.parent]: { ...errors },
          },
        },
      });
    });
    if (
      errors === undefined ||
      equal(length(Object.values(errors)?.filter(Boolean)))
    ) {
      apiCall();
    }
  };
  return {
    logInAttribute,
    handleChange,
    formPath,
    onSubmit,
    loginLoader,
    isDisable,
  };
};
