import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    "&.MuiButtonBase-root": {
      padding: 3,
    },
    "&.MuiCheckbox-root.Mui-checked": {
      color: "#e87637",
    },
  },
}));

const VSNCheckbox = ({ onChange, children, className, ...props }) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onChange();
    }
  };
  const classes = useStyles();
  return (
    <Checkbox
      variant="contained"
      {...props}
      className={`${classes.checkbox} ${className}`}
      onChange={onChange}
      onKeyDown={handleKeyDown}
    >
      {children}
    </Checkbox>
  );
};

export default VSNCheckbox;
