import saType from "../assets/landing/sa-type/svg/sa1.webp";
import reType from "../assets/landing/re-type/svg/re1.webp";
import gaType from "../assets/landing/ga-type/svg/ga1.webp";
import maType from "../assets/landing/ma-type/svg/ma2.webp";
import saWorkPlace1 from "../assets/landing/sa-type/svg/sa-work-1.svg";
import saWorkPlace2 from "../assets/landing/sa-type/svg/sa-work-2.svg";
import saWorkPlace3 from "../assets/landing/sa-type/svg/sa-work-3.svg";
import saWorkPlace4 from "../assets/landing/sa-type/svg/sa-work-4.svg";
import reWorkPlace1 from "../assets/landing/re-type/svg/re-work-1.svg";
import reWorkPlace2 from "../assets/landing/re-type/svg/re-work-2.svg";
import reWorkPlace3 from "../assets/landing/re-type/svg/re-work-3.svg";
import reWorkPlace4 from "../assets/landing/re-type/svg/re-work-4.svg";
import gaWorkPlace1 from "../assets/landing/ga-type/svg/ga-work-1.svg";
import gaWorkPlace2 from "../assets/landing/ga-type/svg/ga-work-2.svg";
import gaWorkPlace3 from "../assets/landing/ga-type/svg/ga-work-3.svg";
import gaWorkPlace4 from "../assets/landing/ga-type/svg/ga-work-4.svg";
import maWorkPlace1 from "../assets/landing/ma-type/svg/ma-work-1.svg";
import maWorkPlace2 from "../assets/landing/ma-type/svg/ma-work-2.svg";
import maWorkPlace3 from "../assets/landing/ma-type/svg/ma-work-3.svg";
import maWorkPlace4 from "../assets/landing/ma-type/svg/ma-work-4.svg";
import saWhyVasana from "../assets/landing/sa-type/svg/sa2.webp";
import reWhyVasana from "../assets/landing/re-type/svg/re2.webp";
import gaWhyVasana from "../assets/landing/ga-type/svg/ga2.webp";
import maWhyVasana from "../assets/landing/ma-type/svg/ma1.webp";
import { changeSaReGaMaStyle } from "../shared/CustomFunc";

export const colorPalatte = {
  sa: {
    light: "#F2E7F580",
    dark: "#8B3F9E",
    icon: "#DAB9E2",
    gradient1: "#F4F6FF",
    gradient2: "#FDF9FF",
  },
  re: {
    light: "#E3F1F9",
    dark: "#1780C6",
    icon: "#97C8E9",
    gradient1: "#EDF8FF",
    gradient2: "#F2FCFF",
  },

  ga: {
    light: "#FFF7EC",
    dark: "#FFB142",
    icon: "#FFE0B4",
    gradient1: "#F8F7F5",
    gradient2: "#F1FCFF",
  },
  ma: {
    light: "#F0F9F2",
    dark: "#2D8F4E",
    icon: "#C8E9D3",
    gradient1: "#F5FDF7",
    gradient2: "#F1FCFF",
  },
};

export const types = {
  sa: {
    type: "Sa",
    tends_to: "Independent, Strong-willed, Focused, and Purposeful.",
    who_is_type_title: "Who is a Sa-Type Individual?",
    who_is_type: (
      <>
        People with a{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: changeSaReGaMaStyle("Vasana-type Sa"),
          }}
        />{" "}
        are also called Control. Determined and resolute, individuals with the{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: changeSaReGaMaStyle("Vasana-type Sa"),
          }}
        />{" "}
        tend to be decisive, quick-to-comprehend, and intuitive in providing
        direction with minimal but essential information. They hold firm beliefs
        and can be perceived as inflexible. What is more factual is that they
        are individuals who ‘can be wrong, but never uncertain’.{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: changeSaReGaMaStyle("Sa-Type"),
          }}
        />{" "}
        individuals expect straightforward responses from those around them and
        have little patience for what they perceive as incompetence.
      </>
    ),
    image: saType,
    type_image: `${process.env.REACT_APP_MEDIA_URL}sa/colored-logo.svg`,
    type_small: `${process.env.REACT_APP_MEDIA_URL}sa/black-logo.svg`,
    workplace_desc:
      " individuals possess a remarkable ability to identify and communicate a project's strategic direction, inspiring their team with a visionary and entrepreneurial spirit. They embrace challenges as opportunities and their generous nature encourages others to exceed their limits and achieve remarkable results.",
    quality: [
      {
        header: "Strengths",
        value: [
          "Ideation",
          "Ability to concentrate on strategy",
          "Future-focused insight",
        ],
      },
      {
        header: "Weaknesses",
        value: [
          "Undervaluing relationships",
          "Stubbornness",
          "Inclined to micromanagement",
        ],
      },
      {
        header: "Annoyances",
        value: [
          "Lack of big-picture context",
          "Lack of thought-out ideas",
          "Overthinking and impulsiveness",
        ],
      },
    ],
    skills: [
      {
        image: saWorkPlace1,
        desc: "Preferred Communication Style",
        subDesc: "Clear, precise, and straightforward",
      },
      {
        image: saWorkPlace2,
        desc: "Optimal Meetings",
        subDesc:
          "Solution-focused discussions with organized agendas and factual summaries",
      },
      {
        image: saWorkPlace3,
        desc: "Optimal Work Environment",
        subDesc: "Fast-paced and decisive",
      },
      {
        image: saWorkPlace4,
        desc: "Source of Motivation",
        subDesc: "Acceptance and implementation of their ideas by team members",
      },
    ],

    why_vasana_image: saWhyVasana,
  },
  re: {
    type: "Re",
    tends_to: "Energetic, Spontaneous, Friendly, and Adaptable",
    who_is_type_title: "Who is a Sa-Type Individual?",
    who_is_type: (
      <>
        Individuals with the{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: changeSaReGaMaStyle("Vasana-type Re"),
          }}
        />{" "}
        are also known as Influence. They are typically highly energetic and
        action-oriented ‘free-spirits’. They prioritize relationships and tend
        to establish friendships and connections easily.{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: changeSaReGaMaStyle("Re-Type"),
          }}
        />{" "}
        individuals seek unique and unconventional approaches and are open to
        out-of-the-box possibilities. Their attitude can be highly infectious
        and, when harnessed effectively, can generate significant momentum for a
        team. They are often early adopters of change.
      </>
    ),
    image: reType,
    type_image: `${process.env.REACT_APP_MEDIA_URL}re/colored-logo.svg`,
    type_small: `${process.env.REACT_APP_MEDIA_URL}re/black-logo.svg`,
    workplace_desc:
      " individuals excel in creative relationship building, favoring unconventional methods over cold-calling. They prioritize genuine connections, showcasing sincerity, and embrace competition and risk. They inspire others to embrace novelty and take calculated risks.",
    quality: [
      {
        header: "Strengths",
        value: [
          "Starting new projects",
          "Creating new relationships",
          "Trying new things",
        ],
      },
      {
        header: "Weaknesses",
        value: [
          "Underdelivering on promises",
          "Becoming restless too quickly ",
          "Need for constant acknowledgment",
        ],
      },
      {
        header: "Annoyances",
        value: ["Overthinkers", "Boasting without action", "Slow drivers"],
      },
    ],
    skills: [
      {
        image: reWorkPlace1,
        desc: "Preferred Communication Style",
        subDesc: "Animated, spontaneous, and fast-paced",
      },
      {
        image: reWorkPlace2,
        desc: "Optimal Meetings",
        subDesc: "Relaxed meetings with flexible agendas and open questions",
      },
      {
        image: reWorkPlace3,
        desc: "Optimal Work Environment",
        subDesc: "Fast-paced and stimulating",
      },
      {
        image: reWorkPlace4,
        desc: "Source of Motivation",
        subDesc: "Acknowledgement and developing professional friendships",
      },
    ],

    why_vasana_image: reWhyVasana,
  },
  ga: {
    type: "Ga",
    tends_to: "Responsible, Amenable, Supportive, and Inclusive",
    who_is_type_title: "Who is a Ga-Type Individual?",
    who_is_type: (
      <>
        People with a{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: changeSaReGaMaStyle("Vasana-type Ga"),
          }}
        />{" "}
        are also referred to as Power. Consistency is the key trait that defines
        these individuals. They commit to tasks and demonstrate relentless drive
        and persistence to see these tasks through to completion. They are
        doers, and their endurance is a significant asset to a team, inspiring
        others to join and work together to accomplish things. Often{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: changeSaReGaMaStyle("Ga-Type"),
          }}
        />{" "}
        individuals are the glue in a relationship as they stay connected, keep
        track of progress, and ensure everyone is on the same page.
      </>
    ),
    image: gaType,
    type_image: `${process.env.REACT_APP_MEDIA_URL}ga/colored-logo.svg`,
    type_small: `${process.env.REACT_APP_MEDIA_URL}ga/black-logo.svg`,
    workplace_desc:
      " individuals excel in production, organization, and task completion. They use a diplomatic approach in business, involving others in decisions, making them great to work with. Their collaboration skills are enjoyed by colleagues, and they're skilled at building long-term client relationships.",
    quality: [
      {
        header: "Strengths",
        value: [
          "Focus on tangible results ",
          "Sustaining relationships over time",
          "Fairness toward others",
        ],
      },
      {
        header: "Weaknesses",
        value: [
          "Taking on too much",
          "Intolerance of underperformance",
          "Losing sight of priorities",
        ],
      },
      {
        header: "Annoyances",
        value: [
          "Rudeness",
          "Being excluded from the process ",
          "Underestimation of the value you bring to the table",
        ],
      },
    ],
    skills: [
      {
        image: gaWorkPlace1,
        desc: "Preferred Communication Style",
        subDesc: "Less direct, focused on listening, and thought out.",
      },
      {
        image: gaWorkPlace2,
        desc: "Optimal Meetings",
        subDesc: "Peaceful meetings with a focus on practical details",
      },
      {
        image: gaWorkPlace3,
        desc: "Optimal Work Environment",
        subDesc: "Collaborative and inclusive",
      },
      {
        image: gaWorkPlace4,
        desc: "Source of Motivation",
        subDesc:
          "Inclusion, heightened productivity and developing professional friendships",
      },
    ],

    why_vasana_image: gaWhyVasana,
  },
  ma: {
    type: "Ma",
    tends_to: "Methodical, Perfectionist, Systematic, and Exacting",
    who_is_type_title: "Who is a Ma-Type Individual?",
    who_is_type: (
      <>
        Individuals with{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: changeSaReGaMaStyle("Vasana Type Ma,"),
          }}
        />{" "}
        also known as Authority, are rational, orderly, and focused on ensuring
        high-quality standards. They possess a keen eye for identifying areas
        for improvement, and their attention to detail is unparalleled compared
        to the other Vasana types. When they adopt a helpful mindset instead of
        an argumentative one in situations where things aren’t working
        optimally,{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: changeSaReGaMaStyle("Ma-Type"),
          }}
        />{" "}
        individuals become highly valuable members of the team.
      </>
    ),
    image: maType,
    type_image: `${process.env.REACT_APP_MEDIA_URL}ma/colored-logo.svg`,
    type_small: `${process.env.REACT_APP_MEDIA_URL}ma/black-logo.svg`,
    workplace_desc:
      " individuals excel in creative relationship building, favoring unconventional methods over cold-calling. They prioritize genuine connections, showcasing sincerity, and embrace competition and risk. They inspire others to embrace novelty and take calculated risks.",
    quality: [
      {
        header: "Strengths",
        value: [
          "Ability to analyze",
          "Quality control",
          "Prudency with resources",
        ],
      },
      {
        header: "Weaknesses",
        value: [
          `Prone to "analysis paralysis"`,
          "Moving too slowly for others",
          "Being too critical of others' work",
        ],
      },
      {
        header: "Annoyances",
        value: ["Inefficiencies", "Inaccuracies", "Tardiness"],
      },
    ],
    skills: [
      {
        image: maWorkPlace1,
        desc: "Preferred Communication Style",
        subDesc:
          "Precise and detail-oriented, with a preference for written communication",
      },
      {
        image: maWorkPlace2,
        desc: "Optimal Meetings",
        subDesc:
          "Formal meetings run systematically with punctual attendees and adherence to agendas",
      },
      {
        image: maWorkPlace3,
        desc: "Optimal Work Environment",
        subDesc: "Well organized without surprises and inconsistencies",
      },
      {
        image: maWorkPlace4,
        desc: "Source of Motivation",
        subDesc:
          "Getting everything exactly correct and exceeding expectations",
      },
    ],

    why_vasana_image: maWhyVasana,
  },
};

export const otherTypes = {
  title: "Other Vasana Types",
  desc: "The Vasana assessment suggests your Vasana Type, the dominant type most pronounced in high-pressure situations, however individuals are combinations of various attributes from all the Types. Learning about the different Types can help you accelerate your performance and better connect with those around you.",
  types: [
    {
      type: "sa",
      img: `${process.env.REACT_APP_MEDIA_URL}sa/colored-logo.svg`,
      firstPoint: (
        <>
          Individuals with the <span />
          <span
            dangerouslySetInnerHTML={{
              __html: changeSaReGaMaStyle("Vasana-type Sa"),
            }}
            style={{ color: colorPalatte?.sa?.dark }}
          />{" "}
          are single minded about achieving their goals.
        </>
      ),
      secondPoint: (
        <>
          They comprehend things quickly and can intuitively provide direction
          with minimal but essential information.
        </>
      ),
    },
    {
      type: "re",
      img: `${process.env.REACT_APP_MEDIA_URL}re/colored-logo.svg`,
      firstPoint: (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: changeSaReGaMaStyle("Re-type"),
            }}
            style={{ color: colorPalatte?.re?.dark }}
          />{" "}
          individuals are typically highly action-oriented with a ‘go-go-go’
          personality.
        </>
      ),
      secondPoint: (
        <>
          They prioritize relationships and tend to establish friendships and
          connections easily.
        </>
      ),
    },
    {
      type: "ga",
      img: `${process.env.REACT_APP_MEDIA_URL}ga/colored-logo.svg`,
      firstPoint: (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: changeSaReGaMaStyle("Ga-type"),
            }}
            style={{ color: colorPalatte?.ga?.dark }}
          />{" "}
          individuals take pleasure in uniting a team to achieve tangible
          outcomes.
        </>
      ),
      secondPoint: (
        <>
          They are committed doers who demonstrate a relentless drive to see
          tasks through to completion.
        </>
      ),
    },
    {
      type: "ma",
      img: `${process.env.REACT_APP_MEDIA_URL}ma/colored-logo.svg`,
      firstPoint: (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: changeSaReGaMaStyle("Ma-type"),
            }}
            style={{ color: colorPalatte?.ma?.dark }}
          />{" "}
          individuals possess a keen eye for detail and identifying areas of
          improvement.
        </>
      ),
      secondPoint: (
        <>
          They are rational, orderly, and focused on upholding high standards
          and quality.
        </>
      ),
    },
  ],
};

export const whyVasanaObj = {
  title: "Why Learn Your Vasana Type",
  desc1:
    "Vasana delves into the unspoken concerns of individuals. These concerns often arise from primary fears experienced under pressure, distorting the perception of oneself and others. People often assume that others view things the same way, leading to a lack of appreciation for different perspectives and, therefore, failed attempts at coordination.",
  desc2: (
    <>
      By helping individuals understand both their own and others&apos; unspoken
      concerns, Vasana helps <span>build more powerful relationships.</span> The
      Vasana assessment gives you the insights you need to leverage the
      strengths of each Vasana Type for business success.
    </>
  ),
};
