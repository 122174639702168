import { ERROR_MESSAGE } from "./errorMessage.description";

export const memberLogInAttribute = [
  {
    control: "input",
    label: "Enter your email address",
    placeholder: "name@domain.com",
    type: "email",
    name: "username",
    value: "",
    isRequired: true,
    fullWidth: true,
    validateAs: "email",
    errorMessage: ERROR_MESSAGE?.email_address,
  },
];
export const memberSignInAttribute = [
  {
    control: "input",
    label: "Enter your preferred email address",
    placeholder: "name@domain.com",
    type: "email",
    name: "username",
    value: "",
    isRequired: true,
    fullWidth: true,
    validateAs: "email",
    errorMessage: ERROR_MESSAGE?.email_address,
  },
];
export const demoUserLoginAttribute = [
  memberLogInAttribute[0],
  {
    control: "input",
    label: "Password",
    type: "password",
    name: "password",
    value: "",
    isRequired: true,
    fullWidth: true,
    validateAs: "name",
    errorMessage: ERROR_MESSAGE?.password,
  },
];
export const adminLoginAttribute = [memberLogInAttribute[0]];
export const loginHeadingText =
  "Log in to your account to pick up where you left off.";

export const adminLoginHeadingText = "Log in to your account";
