import { makeStyles } from "@mui/styles";
import bgFlower from "../../assets/Teams/png/team-bg-banner.png";

export const HumaStyle = makeStyles((theme) => ({
  mainWrapper: {
    overflow: "hidden",
    padding: [[30, 30, 45, 30]],
    position: "relative",
    // height: "100%",
    zIndex: 2,
    background: `linear-gradient(180deg, rgba(249, 250, 252, 0) 0%, #F9FAFC 90.38%)`,
    backgroundSize: "cover",
    height: "calc(100vh - 105px)",
    "&:before": {
      position: "absolute",
      content: "''",
      left: 0,
      right: 0,
      top: 0,
      background: `url(${bgFlower})`,
      zIndex: -1,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      minHeight: 310,
      [theme.breakpoints.down("sm")]: {
        minHeight: 140,
        backgroundSize: "cover",
        backgroundPosition: "0 -10px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[30, 16, 42, 16]],
    },
  },
  iconWrapper: {
    borderRadius: 6,
    margin: "0 5px",
    height: 40,
    width: 40,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
    "& svg": {
      fontSize: "1.4rem",
    },
  },
  teamViewCard: {
    boxShadow: "none",
    borderRadius: 10,
    background: "#FCFCFD",
    height: "auto",
    position: "relative",
    zIndex: 3,
    width: "100%",
    maxWidth: 1125,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  chatInputMain: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 -18px -16px -18px",
    },
  },
  chatInput: {
    "& .MuiOutlinedInput-root": {
      padding: [[6, 20, 6, 10]],
      background: "#F2F2F2",
      gap: 9,
      borderRadius: 10,
      "& svg": {
        height: 30,
        width: 30,
        padding: 4,
      },
    },
    "& fieldset": {
      border: 0,
    },
    "& .MuiInputBase-input::placeholder": {
      color: theme.palette.secondary.grey,
      fontFamily: "DM Sans",
      fontSize: 14,
      fontWeight: 500,
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      padding: "8px 10px !important",
    },
  },
  sendBtn: {
    "&.MuiButtonBase-root": {
      color: "#000000",
      padding: 0,
      minWidth: 0,
      maxWidth: 0,
    },
  },
  chatSidear: {
    position: "fixed",
    top: 66,
    left: 0,
    height: "calc(100vh - 107px)",
    overflowY: "auto",
    width: 320,
    borderRight: "1px solid #E0E0E0",
    padding: "16px 16px 16px 12px",
    backgroundColor: "#f9f9f9",
    transition: "all 0.4s ease-in-out",
    zIndex: 99,
    "&.hide": {
      transform: "translateX(-100%)",
    },
    "&::-webkit-scrollbar": {
      width: 0,
      padding: 0,
    },
  },
  chatContent: {
    marginLeft: 320,
    width: "calc(100% - 320px)",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    height: "calc(100vh - 137px)",
    transition: "all 0.4s ease-in-out",
    [theme.breakpoints.down(500)]: {
      height: "100%",
    },
    "&.hide": {
      marginLeft: 0,
      width: "100%",
    },
    [theme.breakpoints.down("slg")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  scrollebleContent: {
    maxWidth: 990,
    margin: "0 auto",
    width: "100%",
    justifyContent: "center",
    "&::-webkit-scrollbar": {
      width: 8,
      padding: 4,
    },
    "&::-webkit-scrollbar-track": {
      background: "#F6F6FD",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      background: "#E87637",
      border: "1px solid #ffffff",
    },
  },
  searchIcon: {
    backgroundColor: "#FFF3EC",
    borderRadius: 50,
    minWidth: 30,
  },
  sliderHeading: {
    "&.MuiTypography-root": {
      fontSize: 14,
      lineHeight: "15px",
      fontWeight: 600,
      color: "#2D3652",
      paddingLeft: 16,
      marginTop: 20,
      marginBottom: 0,
    },
  },
  sliderSearch: {
    "&.MuiList-root": {
      zIndex: 1,
      display: "flex",
      flexWrap: "wrap",
      borderRadius: 10,
      fontSize: 16,
      fontWeight: 400,
    },
  },
  chatLogoMain: {
    height: 42,
    maxWidth: 42,
    width: "100%",
    marginRight: "6px",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    "& svg": {
      verticalAlign: "middle",
      marginTop: 5,
    },
  },
  message: {
    maxWidth: "60%",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
    // 80%
    "& .MuiTypography-body1": {
      fontWeight: 500,
      lineHeight: "20.83px",
      padding: 16,
      borderRadius: 10,
    },
    "& .MuiTypography-body2": {
      fontSize: 12,
      color: "#888",
      marginLeft: 8,
    },
  },
  receivMessage: {
    "& .MuiTypography-body1": {
      backgroundColor: "#F0F1F1",
      color: "#424450",
    },
  },
  sendMessage: {
    marginLeft: "auto",
    "& .MuiTypography-body1": {
      backgroundColor: "#FBE8E2",
      color: "#FF743C",
    },
  },
  iconMainWrapper: {
    "& .MuiButtonBase-root": {
      height: 24,
      width: 24,
      padding: 0,
      borderRadius: 5,
      "&:hover": {
        backgroundColor: "#F5F5F5",
      },
    },
  },
  chatBodyMain: {
    width: "100%",
    maxWidth: "1125px",
    margin: "auto auto 10px",
    maxHeight: "calc(100vh - 175px)",
    // maxHeight: "calc(100vh - 275px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 6,
      padding: 4,
    },
    "&::-webkit-scrollbar-track": {
      background: "#F6F6FD",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      background: "#E87637",
      border: "1px solid #ffffff",
    },
  },
  paperStyle: {
    minWidth: "210px",
    height: "max-content",
    minHeight: 128,
    maxWidth: 210,
    [theme.breakpoints.down(500)]: {
      minHeight: "unset",
      "& svg": {
        display: "none",
      },
    },
    "&.MuiPaper-root": {
      padding: "16px",
      borderRadius: 10,
      boxShadow: "none",
    },
    "& p": {
      fontSize: 14,
      fontWeight: 400,
    },
  },
}));
