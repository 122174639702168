import React from "react";
import { logInContainer } from "../../container/logIn.container";
import LogInContainer from "./LogInContainer";

const LogIn = () => {
  const {
    logInAttribute,
    handleVerifyDetail,
    handleChange,
    formPath,
    onSubmit,
    handleLoginBtn,
    ...rest
  } = logInContainer();

  return (
    <LogInContainer
      {...{
        logInAttribute,
        handleVerifyDetail,
        handleChange,
        formPath,
        onSubmit,
        handleLoginBtn,
        ...rest,
      }}
    />
  );
};

export default LogIn;
