import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import React from "react";
import { createTeamModalContainer } from "../../container/createTeamModal.container";
import { formContainer } from "../../container/form.container";
import VSNButton from "../../shared/VSNButton";
import EmailAutoComplete from "../../shared/VSNEmailAutoComplete";
import VSNErrorMessage from "../../shared/VSNErrorMessage";
import VSNImage from "../../shared/VSNImage";
import VSNLabel from "../../shared/VSNLabel";
import MembersAutoComplete from "../../shared/VSNMembersAutoComplete";
import VSNTypography from "../../shared/VSNTypography";
import ControlForm from "../../shared/form/ControlForm";
import {
  CheckedIcon,
  CloseIcon,
  EditIcon,
  ImageIcon,
  WithoutCheckIcon,
} from "../../shared/icon";

export const useStyles = makeStyles((theme) => ({
  title: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      lineHeight: "31.25px",
      fontSize: "24px",
      padding: "20px 0",
      whiteSpace: "nowrap",
      marginRight: 32,
      textOverflow: "ellipsis",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        lineHeight: "26px",
        whiteSpace: "unset",
        "-webkit-line-clamp": "2",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        padding: 0,
        margin: "20px 32px 20px 0",
      },
    },
  },

  modalHeader: {
    position: "sticky",
    top: 0,
    background: theme.palette.primary.light,
    zIndex: 3,
  },

  stepColor: {
    "&.MuiTypography-root": {
      background: "#E876371A",
      width: "98px",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18.23px",
      padding: "5px 10px",
      borderRadius: "5px",
      margin: "15px 0",
      color: "#747787",
    },
  },

  question: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      lineHeight: "23.44px",
      fontSize: "18px",
      color: "#2D2F39",
      position: "relative",
      display: "inline-block",
      "& .MuiFormLabel-root": {
        position: "absolute",
        left: "100%",
      },
    },
  },

  description: {
    "&.MuiTypography-root": {
      color: "#747787",
      fontWeight: 400,
      lineHeight: "18.23px",
      fontSize: "14px",
      marginTop: 4,
    },
  },

  input: {
    "&.css-qv3mbf-MuiFormControl-root-MuiTextField-root .MuiInputBase-root .MuiInputBase-input":
      {
        position: "relative",
        fontSize: 14,
      },
    "& .MuiInputBase-root": {
      "& .Mui-disabled": {
        WebkitTextFillColor: "#202024 !important",
      },
      "& .MuiInputBase-input": {
        padding: "8px 0px",
        width: "700px",
        gap: "8px",
      },
    },
  },

  button_groups: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#F6F6FD",
    padding: "20px 25px",
    gap: 10,
    marginLeft: "-25px",
    marginRight: "-25px",
    position: "sticky",
    bottom: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "15px",
      gap: 10,
      marginLeft: "-15px",
      marginRight: "-15px",
    },
  },

  button: {
    "&.MuiButtonBase-root": {
      color: theme.palette.primary.light,
      padding: "13px 25px",
      minWidth: "150px",
      borderRadius: 10,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& span.icon": {
        marginLeft: 8,
        display: "inline-block",
      },
      "& .content": {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .loader": {
        "& .MuiCircularProgress-root": {
          height: "16px !important",
          width: "16px !important",
        },
      },
      "&.link": {
        background: "white",
        border: "1px solid #555FDD",
        color: "#555FDD",
        "& .linkIcon": {
          height: "16px",
          width: "16px",
          display: "inline-block",
          marginRight: 10,
        },
      },
    },
  },

  uploadOut: {
    "&.MuiStack-root": {
      height: "100%",
      width: "100%",
      maxHeight: 134,
      borderRadius: 3,
      background: "#F6F6FD80",
      cursor: "pointer",
      color: "#555FDD",
      position: "relative",
      flex: "0 0 auto",
      [theme.breakpoints.down("sm")]: {
        height: 134,
      },
      "& .MuiBox-root": {
        minHeight: "0",
      },
      "& img": {
        maxHeight: "100%",
      },
    },
  },

  closeIcon: {
    position: "absolute",
    top: -10,
    right: -10,
    height: 28,
    width: 28,
    boxShadow: "5px 5px 15px rgba(69, 97, 101, 0.10)",
    background: "white",
    borderRadius: "100%",
    zIndex: 99,
  },

  editIcon: {
    position: "absolute",
    bottom: -10,
    right: -10,
    height: 28,
    width: 28,
    boxShadow: "5px 5px 15px rgba(69, 97, 101, 0.10)",
    background: "white",
    borderRadius: "100%",
    "& path": {
      fill: theme.palette.primary.basic,
    },
  },

  uploadText: {
    "&.MuiTypography-root": {
      fontSize: 14,
      lineHeight: "18px",
      color: "#747787",
      fontWeight: 500,
      maxWidth: 245,
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        lineHeight: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
  },
  inputUpload: {
    position: "absolute",
    inset: 0,
    opacity: 0,
    cursor: "pointer",
    zIndex: 9,
    "& .MuiInputBase-root": {
      height: "100%",
      "& .MuiInputBase-input": {
        padding: 0,
        height: "100%",
        display: "none",
      },
    },
  },

  uplodLabel: {
    "&.MuiFormLabel-root": {
      position: "absolute",
      inset: 0,
      cursor: "pointer",
    },
  },

  mainBox: {
    background: "#fff",
    borderRadius: 5,
    maxWidth: 1040,
    width: "100%",
    margin: "0 auto",
    position: "relative",
    textAlign: "center",
    zIndex: 2,
  },

  innerBox: {
    flex: 1,
  },

  borderBox: {
    borderRadius: 5,
    border: "1px dashed rgba(116, 119, 135, 0.30)",
    padding: 10,
    marginBottom: 10,
    flex: "1",
  },

  TeamImageBox: {
    position: "relative",
    height: 72,
    width: "100%",
    borderRadius: 5,
    overflow: "hidden",
    "&::after": {
      content: "''",
      position: "absolute",
      inset: 0,
      background:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 46.61%, rgba(66, 68, 80, 0.80) 124.31%)",
    },
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
    "& .MuiButtonBase-root": {
      position: "absolute",
      right: 4,
      top: 4,
      padding: 0,
    },
  },

  uploadClick: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 5,
  },

  UnderlineDropdown: {
    "& div": {
      "&.MuiInputBase-root": {
        paddingLeft: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
        borderBottomWidth: 1,
        borderRadius: 0,
        borderColor: "#EAEDF0",
      },
    },
  },

  steptoWrapper: {
    "&.MuiStack-root": {
      "& .MuiGrid-container": {
        justifyContent: "space-between",
        "& .MuiGrid-item": {
          display: "flex",
          flexDirection: "column",
          width: "calc(50% - 40px)",
          flexBasis: "calc(50% - 40px)",
          maxWidth: "calc(50% - 40px)",
          marginBottom: 30.4,
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        },
      },
    },
  },

  divider: {
    position: "relative",
    margin: "0 18px",
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0",
    },
    "&:before": {
      position: "absolute",
      content: "''",
      height: "100%",
      width: 2,
      background:
        "linear-gradient(180deg, rgba(85, 95, 221, 0.00) 0%, rgba(85, 95, 221, 0.10) 48.44%, rgba(85, 95, 221, 0.00) 100%)",
      zIndex: 0,
      [theme.breakpoints.down("sm")]: {
        height: 2,
        width: "100%",
        background:
          "linear-gradient(90deg, rgba(85, 95, 221, 0.00) 0%, rgba(85, 95, 221, 0.10) 48.44%, rgba(85, 95, 221, 0.00) 100%)",
      },
    },
    "& span": {
      color: "#86650A",
      height: 33,
      width: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      backgroundColor: "#F6F6FD",
      position: "relative",
      zIndex: 2,
      [theme.breakpoints.down("sm")]: {
        height: 41,
        width: 41,
      },
    },
  },

  positionDivider: {
    position: "absolute",
    left: "50%",
    top: 0,
    bottom: "50%",
    transform: "translateX(-50%)",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      left: "unset",
      right: "unset",
      top: "unset",
      transform: "unset",
    },
  },
  avtarOut: {
    "& .MuiAvatar-root.MuiAvatar-circular": { border: "2px solid #fff" },
  },
  smHeading: {
    "&.MuiTypography-root": {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: 700,
      color: "#424450",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  desc: {
    "&.MuiTypography-root": {
      fontSize: 14,
      lineHeight: "18px",
      color: "#747787",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  imageType: {
    height: 25,
    minWidth: 60,
    "& img": {
      height: "100%",
      width: "auto",
    },
  },
}));

const totalSteps = 2;

const renderForm = ({
  createTeamContainer,
  classes,
  handleChange,
  createTeamInfo,
  formData,
  formErrors,
  onEmailSelect,
  onInputChange,
  onInviteEmailSelect,
  defaultProfilePictures = [],
}) => {
  return (
    <>
      <Grid container spacing={{ md: 3.8, xs: 2 }}>
        {createTeamContainer[createTeamInfo?.step]?.data
          ?.filter((el) => !el?.hide)
          ?.map(
            (
              {
                question,
                description,
                value,
                subtitle,
                subdescription,
                isRequired,
                isOrSplit,
                md,
                xs,
                lg,
              },
              index
            ) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={xs || 12} md={md} lg={lg}>
                    <Box key={index} className="innnerBox">
                      <VSNTypography className={classes.question} variant="h4">
                        {isRequired && <VSNLabel required />} {question}
                      </VSNTypography>
                      <VSNTypography
                        className={classes.description}
                        variant="h6"
                        mb="auto"
                      >
                        {description}
                      </VSNTypography>
                      {value?.map(
                        (
                          {
                            isRequired,
                            label,
                            errorMessage,
                            name,
                            ...attribute
                          },

                          index
                        ) => {
                          if (question === "Upload Photo") {
                            return (
                              <Stack
                                direction={{ sm: "row", xs: "column" }}
                                alignItems={{ sm: "center", xs: "start" }}
                                my={2.5}
                                key={index}
                              >
                                <Box className={classes.mainBox}>
                                  <Stack
                                    direction={{ sm: "row", xs: "column" }}
                                  >
                                    <Stack className={classes.innerBox}>
                                      <Box className={classes.borderBox}>
                                        <Grid container spacing={1}>
                                          {defaultProfilePictures.map(
                                            ({ id, profile_picture }) => (
                                              <Grid item xs={4} key={id}>
                                                <Box
                                                  className={
                                                    classes.TeamImageBox
                                                  }
                                                >
                                                  <Checkbox
                                                    sx={{
                                                      "&:hover": {
                                                        bgcolor: "transparent",
                                                      },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    name="profile_picture"
                                                    value={profile_picture}
                                                    checkedIcon={
                                                      <CheckedIcon />
                                                    }
                                                    icon={<WithoutCheckIcon />}
                                                    inputProps={{
                                                      "aria-label":
                                                        "Checkbox demo",
                                                    }}
                                                    onChange={handleChange}
                                                    checked={
                                                      createTeamInfo?.profile_picture ===
                                                      profile_picture
                                                    }
                                                  />
                                                  <VSNImage
                                                    src={profile_picture}
                                                    alt="team-photo-1"
                                                  />
                                                </Box>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Box>
                                      <VSNTypography
                                        className={classes.uploadText}
                                      >
                                        Select your team photo
                                      </VSNTypography>
                                    </Stack>
                                    <Stack
                                      alignItems="center"
                                      justifyContent="center"
                                      className={classes.divider}
                                    >
                                      <span>OR</span>
                                    </Stack>
                                    <Stack className={classes.innerBox}>
                                      <Box className={classes.borderBox}>
                                        <Stack
                                          alignItems="center"
                                          justifyContent="center"
                                          color="#E87637"
                                          className={classes.uploadOut}
                                        >
                                          <input
                                            type="file"
                                            accept="image/*"
                                            id="photo_upload-undefined"
                                            onChange={handleChange}
                                            value=""
                                            className={classes.inputUpload}
                                            name={name}
                                          />
                                          <VSNLabel
                                            htmlFor="photo_upload-undefined"
                                            className={classes.uplodLabel}
                                            label={
                                              formData?.photo_upload_url ? (
                                                <Stack
                                                  alignItems="center"
                                                  justifyContent="center"
                                                  className={classes.editIcon}
                                                >
                                                  <EditIcon />
                                                </Stack>
                                              ) : (
                                                ""
                                              )
                                            }
                                          />
                                          <VSNTypography
                                            className={classes.questionTitle}
                                            variant="h6"
                                          >
                                            {subtitle}
                                          </VSNTypography>
                                          {formData?.photo_upload_url ? (
                                            <>
                                              <img
                                                src={formData?.photo_upload_url}
                                                alt="img"
                                              />
                                              <Stack
                                                alignItems="center"
                                                justifyContent="center"
                                                className={classes.closeIcon}
                                                onClick={() =>
                                                  handleChange(
                                                    {
                                                      target: {
                                                        name,
                                                        value: "",
                                                      },
                                                    },
                                                    true
                                                  )
                                                }
                                              >
                                                <CloseIcon />
                                              </Stack>
                                            </>
                                          ) : (
                                            <>
                                              <ImageIcon />
                                              <Typography
                                                variant="span"
                                                className={classes.uploadClick}
                                              >
                                                {" "}
                                                Click here to upload
                                              </Typography>
                                            </>
                                          )}
                                        </Stack>
                                      </Box>
                                      <VSNTypography
                                        className={classes.uploadText}
                                      >
                                        {subdescription}
                                      </VSNTypography>
                                      <VSNErrorMessage
                                        {...{
                                          formError: formErrors?.[name],
                                          errorMessage,
                                        }}
                                      />
                                    </Stack>
                                  </Stack>
                                </Box>
                              </Stack>
                            );
                          }
                          if (name === "added_members") {
                            return (
                              <MembersAutoComplete
                                className={classes.UnderlineDropdown}
                                key={index}
                                autoHighlight
                                options={attribute?.options || []}
                                onChange={onEmailSelect}
                                placeholder="Select or enter email"
                                value={createTeamInfo?.added_members || []}
                                sx={{
                                  marginTop: 2,
                                  "& .MuiAutocomplete-inputRoot": {
                                    padding: 0.5,
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "black",
                                  },
                                  "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#EAEDF0",
                                    },
                                  "&:focus-within .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#EAEDF0 !important",
                                      borderRadius: "8px 8px 0 0",
                                    },
                                  "&:focus-within .MuiOutlinedInput-notchedOutline:hover":
                                    {
                                      borderColor: "#EAEDF0",
                                    },
                                }}
                              />
                            );
                          }
                          if (name === "invited_members") {
                            return (
                              <EmailAutoComplete
                                className={classes.UnderlineDropdown}
                                key={index}
                                autoHighlight
                                options={createTeamInfo?.emailOptions || []}
                                onChange={onInviteEmailSelect}
                                onInputChange={onInputChange}
                                placeholder="Select or enter email"
                                value={createTeamInfo?.invited_members || []}
                                inputValue={
                                  createTeamInfo?.invited_members_input_value ||
                                  ""
                                }
                                filterSelectedOptions
                                slotProps={{
                                  popper: {
                                    sx: {
                                      overflow: "auto",
                                      "& ul": {
                                        width: "max-content",
                                        minWidth: "100%",
                                        "& .MuiAutocomplete-option": {
                                          width: "100%",
                                          overflow: "visible",
                                        },
                                      },
                                    },
                                  },
                                }}
                                sx={{
                                  marginTop: 2,
                                  "& .MuiChip-label": {
                                    maxWidth: 200,
                                  },
                                  "& .MuiAutocomplete-inputRoot": {
                                    "& .MuiAutocomplete-input": {
                                      minWidth: "max-content",
                                    },
                                    padding: 0.5,
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "black",
                                  },
                                  "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#EAEDF0",
                                    },
                                  "&:focus-within .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#EAEDF0 !important",
                                      borderRadius: "8px 8px 0 0",
                                    },
                                  "&:focus-within .MuiOutlinedInput-notchedOutline:hover":
                                    {
                                      borderColor: "#EAEDF0",
                                    },
                                }}
                              />
                            );
                          }
                          return (
                            <Box key={index} width="100%">
                              <ControlForm
                                className={classes.input}
                                {...{
                                  ...attribute,
                                  onChange: handleChange,
                                  name,
                                  value: createTeamInfo?.[name] || "",
                                  onEmailSelect,
                                }}
                              />
                              <VSNErrorMessage
                                {...{
                                  formError: formErrors?.[name],
                                  errorMessage,
                                }}
                              />
                              <VSNTypography
                                className={classes.questionTitle}
                                variant="h6"
                              >
                                {subtitle}
                              </VSNTypography>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                    {isOrSplit && (
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        className={`${classes.divider} ${classes.positionDivider}`}
                      >
                        <span>OR</span>
                      </Stack>
                    )}
                  </Grid>
                </React.Fragment>
              );
            }
          )}
      </Grid>
    </>
  );
};

const CreateTeamModal = ({ onSuccess, handleCreateTeam, inOrgAdmin }) => {
  const {
    formPath,
    createTeamInfo,
    handleChange,
    loaders,
    buttonsContainer,
    createTeamContainer,
    onEmailSelect,
    onInputChange,
    onInviteEmailSelect,
    defaultProfilePictures,
  } = createTeamModalContainer({ onSuccess, handleCreateTeam, inOrgAdmin });
  const classes = useStyles();
  const { formData, formErrors } = formContainer({ formPath });
  const buttons = buttonsContainer[createTeamInfo?.step];
  const defaultProfilePicturesLoader = useSelector(
    (state) => state?.api?.loaders?.defaultProfilePicturesLoader
  );

  if (defaultProfilePicturesLoader) {
    return <CircularProgress />;
  }
  return (
    <Box style={{ position: "relative" }}>
      <Box className={classes.modalHeader}>
        <VSNTypography
          id="modal-modal-title"
          variant="h4"
          component="h2"
          className={classes.title}
        >
          {createTeamContainer[createTeamInfo?.step]?.label}
        </VSNTypography>
        <Divider
          sx={{
            borderColor: "#EAEDF0",
            marginLeft: { sm: "-25px", xs: "-15px" },
            marginRight: { sm: "-25px", xs: "-15px" },
          }}
        />
      </Box>
      <VSNTypography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        className={classes.stepColor}
      >
        Step <span style={{ color: "#E87637" }}> {createTeamInfo?.step}</span>{" "}
        of {totalSteps}
      </VSNTypography>
      {createTeamContainer[createTeamInfo?.step]?.optionalSplit ? (
        <Stack
          direction={{ sm: "row" }}
          flexWrap="wrap"
          position="relative"
          columnGap={{ sm: 10, xs: 1 }}
          rowGap={{ sm: 2, xs: 1 }}
          className={classes.steptoWrapper}
        >
          {renderForm({
            createTeamContainer,
            classes,
            handleChange,
            createTeamInfo,
            formData,
            formErrors,
            onEmailSelect,
            onInputChange,
            onInviteEmailSelect,
            defaultProfilePictures,
          })}
          <Hidden smDown>
            <Stack
              alignItems="center"
              justifyContent="center"
              className={`${classes.divider} ${classes.positionDivider}`}
            >
              <span>OR</span>
            </Stack>
          </Hidden>
        </Stack>
      ) : (
        renderForm({
          createTeamContainer,
          classes,
          handleChange,
          createTeamInfo,
          formData,
          formErrors,
          onEmailSelect,
          onInputChange,
          onInviteEmailSelect,
          defaultProfilePictures,
        })
      )}
      <Box className={classes.button_groups}>
        {buttons &&
          buttons.map(
            (
              {
                label,
                handleClick,
                hasLoader,
                icon,
                className,
                link,
                disabled,
              },
              index
            ) => (
              <VSNButton
                isLoading={hasLoader && loaders?.createTeamLoader}
                className={`${classes.button} ${className}`}
                key={index}
                handleClick={handleClick}
                disabled={disabled}
              >
                <span className="linkIcon">{link}</span>
                {label}
                <span className="icon">{icon}</span>
              </VSNButton>
            )
          )}
      </Box>
    </Box>
  );
};

export default CreateTeamModal;
