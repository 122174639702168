import { makeStyles } from "@mui/styles";
import bgFlower from "../../assets/Teams/png/team-bg-banner.png";
import star from "../../assets/assesments/svg/orange-star.svg";

export const OnePagerResultStyle = makeStyles((theme) => ({
  mainLayout: {
    overflow: "hidden",
    padding: [[0, 30, 35, 30]],
    position: "relative",
    height: "100%",
    zIndex: 2,
    backgroundSize: "cover",
    minHeight: "calc(100vh - 105px)",
    [theme.breakpoints.down("sm")]: {
      padding: [[0, 16, 42, 16]],
    },
  },
  assessmentReportCard: {
    overflow: "hidden",
    padding: "20px 25px",
    borderRadius: 10,
    background: "#FCFCFD",
    border: `2px solid ${theme.palette.primary.light} `,
    boxShadow: "0px 7.7982px 17.32934px 0px rgba(81, 125, 132, 0.07)",
    position: "relative",
    zIndex: 3,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  box1: {
    position: "absolute",
    top: 6,
    right: "40%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  box2: {
    transform: "rotate(90deg)",
    position: "absolute",
    top: 140,
    right: 140,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  box3: {
    position: "absolute",
    top: 50,
    right: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  box4: {
    position: "absolute",
    bottom: "-2%",
    right: "15%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      lineHeight: "26px",
      fontWeight: 700,
      color: theme.palette.secondary.main,
      position: "relative",
      "&:before": {
        position: "absolute",
        content: "''",
        top: 2,
        height: 22,
        left: -25,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: theme.palette.primary.basic,
        [theme.breakpoints.down("sm")]: {
          left: -15,
        },
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 20,
        lineHeight: "24px",
      },
    },
  },
  mdHeadingMain: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: 700,
      color: "#86650A",
      position: "relative",
      display: "inline",
      "& p": {
        display: "inline",
        fontSize: 22,
        [theme.breakpoints.down("sm")]: {
          fontSize: 20,
          lineHeight: "26px",
        },
      },
      "& span": {
        color: "#555FDD",
        fontSize: 22,
        [theme.breakpoints.down("1200")]: {
          fontSize: 20,
          lineHeight: "26px",
        },
      },
      "&:before": {
        position: "absolute",
        content: "''",
        top: 2,
        height: 22,
        left: -25,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: theme.palette.primary.basic,
        [theme.breakpoints.down("sm")]: {
          left: -15,
        },
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 20,
        lineHeight: "22px",
      },
    },
  },
  desc: {
    fontSize: 16,
    lineHeight: "20px",
    color: theme.palette.secondary.grey,
    fontWeight: 400,
    "& span": {
      color: "#86650A",
    },
  },
  headDesc: {
    fontSize: 16,
    lineHeight: "20px",
    color: theme.palette.secondary.grey,
    fontWeight: 500,
    "& span": {
      color: "#86650A",
      fontWeight: 700,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "22px",
    },
  },
  notesList: {
    "&.MuiList-root": {
      zIndex: 1,
      display: "flex",
      flexWrap: "wrap",
      padding: "10px 17px 10px 20px",
      background: `linear-gradient(90deg,#FCF0FF 12%,#F2FAFF 72%)`,
      borderRadius: 10,
      marginTop: 13,
      [theme.breakpoints.down("md")]: {
        padding: 10,
        display: "block",
      },
      "& li": {
        display: "inline-block",
        position: "relative",
        paddingLeft: 24,
        paddingRight: 24,
        width: "50%",
        color: theme.palette.secondary.main,
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        "&:before": {
          position: "absolute",
          content: `url(${star})`,
          left: 0,
          top: 10,
        },
        "&:nth-child(even)": {
          "&::after": {
            content: "''",
            position: "absolute",
            left: -10,
            width: 1,
            top: 4,
            bottom: 4,
            background: "rgba(0, 0, 0, 0.12)",
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          },
        },
      },
    },
  },
  notesListOne: {
    "&.MuiList-root": {
      zIndex: 1,
      display: "flex",
      flexWrap: "wrap",
      padding: "10px 17px 10px 20px",
      background: `linear-gradient(90deg,#FCF0FF 12%,#F2FAFF 72%)`,
      borderRadius: 10,
      marginTop: 13,
      [theme.breakpoints.down("md")]: {
        padding: 10,
        display: "block",
      },
      "& li": {
        display: "inline-block",
        position: "relative",
        paddingLeft: 24,
        paddingRight: 24,
        width: "100%",
        color: theme.palette.secondary.main,
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        "&:before": {
          position: "absolute",
          content: `url(${star})`,
          left: 0,
          top: 10,
        },
      },
    },
  },
  importantNote: {
    padding: "15px 20px",
    marginTop: 15,
    backgroundColor: "#FFF",
    border: "1px solid #EAEDF0",
    borderRadius: "10px",
    zIndex: 999,
  },
  dailyFunctioning: {
    "&.MuiList-root": {
      borderRadius: 10,
      marginTop: 13,
      background: "#FCFCFD",
      paddingRight: 10,
      padding: 0,
      overflowY: "auto",
      maxHeight: 270,
      "& li": {
        border: "1px solid #EAEDF0",
        padding: "15px 10px",
        borderRadius: 10,
        alignItems: "start",
        "& img": {
          minWidth: 18,
          maxWidth: 18,
          height: 18,
        },
        "& p": {
          paddingLeft: 12,
          fontSize: 16,
          fontWeight: 400,
        },
        "& + li": {
          marginTop: 12,
        },
      },
      "&::-webkit-scrollbar": {
        width: 8,
        padding: 4,
      },
      "&::-webkit-scrollbar-track": {
        background: "#F6F6FD",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 6,
        background: "#E87637",
        border: "1px solid #ffffff",
      },
    },
  },
  chartContainer: {
    "& .apexcharts-gridline": {
      strokeDasharray: "4 !important",
    },
  },
  blueFlower: {
    position: "absolute",
    bottom: -15,
    right: "35%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pinkFlower: {
    position: "absolute",
    top: 5,
    left: "15%",
    [theme.breakpoints.down("sm")]: {
      left: "10%",
      top: "35%",
      height: 50,
      width: "auto",
    },
  },
  threeLines: {
    position: "absolute",
    top: 40,
    right: 13,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  threeLinesgray: {
    position: "absolute",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      height: 38,
      display: "block",
      top: "20%",
      right: 20,
      width: "auto",
    },
  },
  optionInfo: {
    zIndex: 99,
    background: `linear-gradient(90deg, #FCF0FF 11.61%, #F2FAFF 72.11%)`,
    borderRadius: 10,
    margin: [[0]],
    lineHeight: "24px",
    padding: [[15, 5, 15, 15]],
    position: "relative",
    width: "100%",
    maxWidth: "100%",
    minHeight: 210,
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      minHeight: 210,
    },
    [theme.breakpoints.down("1400")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      minHeight: 155,
      flexDirection: "row",
    },
    [theme.breakpoints.down("450")]: {
      flexDirection: "column",
    },
  },
  ImgOne: {
    [theme.breakpoints.down("1400")]: {
      height: 147,
      minHeight: 147,
      marginLeft: "auto",
    },
  },
  CustomTabs: {
    "& .MuiTabs-flexContainer": {
      gap: "10px",
      padding: "2px 0 0 2px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
      },
      "& .MuiButtonBase-root": {
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
        },
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-root": {
      textTransform: "none",
      color: "#747787",
      background: "linear-gradient(180deg, #FEFEFE 2.13%, #E6F1FA 96.23%)",
      borderRadius: "10px 10px 0px 0px",
      fontSize: 16,
      fontWeight: 500,
      minWidth: 120,
      "&.Mui-selected": {
        fontWeight: 700,
        color: "#3F51B5",
        backgroundColor: "white",
        background: "#FCFCFD",
        // borderWidth: "1px 1px 0px 1px",
        // borderStyle: "solid",
        // borderImage:
        //   "linear-gradient(180deg, #555FDD -18.09%, rgba(85, 95, 221, 0) 85.11%)",
        // borderImageSlice: 1,
        position: "relative",
        overflow: "visible",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "calc(100% + 2px)",
          width: "calc(100% + 3px)",
          zIndex: -1,
          background:
            "linear-gradient(to bottom, #555FDD -10%, transparent 80%)",
          borderRadius: "10px 10px 0 0",
        },
      },
    },
  },
  headingButton: {
    display: "flex",
    justifyContent: "space-between",
    gap: 14,
    alignItems: "center",
    marginBottom: 10,
    [theme.breakpoints.down("600")]: {
      alignItems: "end",
      "& .MuiButtonBase-root": {
        marginBottom: 10,
      },
    },
    [theme.breakpoints.down("450")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      "& .MuiBox-root": {
        width: "100%",
      },
    },
  },
}));
