import React from "react";
import internalErrorImg from "../assets/errorPage/svg/internal-page.svg";
import ErrorPage from "../shared/ErrorPage";

const InternalError = () => {
  return (
    <ErrorPage
      title="Oops! This is unexpected"
      image={internalErrorImg}
      location="error"
    />
  );
};

export default React.memo(InternalError);
