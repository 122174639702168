import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { assessmentIds } from "../../utils/constant";
import VSNLoader from "../../shared/VSNLoader";
import {
  getAssessmentState,
  getSlug,
  navigateAfterLogin,
} from "../../shared/CustomFunc";
import { ApiContainer } from "../../utils/api";
import ApiCalls from "../../utils/apiCall";

function ProtectedRoute() {
  const history = useNavigate();
  const { pathname } = useLocation();
  const userRoleData = useSelector((state) => state.app?.userData);
  const roleInfoLoader = useSelector((state) => state.api.loader?.getRoleInfo);
  const { assessmentList } = useSelector((state) => state.api);
  const token = localStorage.getItem("token");
  const signup_flow_completed =
    userRoleData?.user_profile?.signup_flow_completed;
  const { api } = ApiContainer();
  const { getRoleInfo } = ApiCalls();
  const dispatch = useDispatch();
  const userName = localStorage.getItem("user-name");
  const vasanaTypeAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.vasanaType,
  });

  const remainingAssessment = assessmentList?.length
    ? assessmentList.find(
        (assessment) =>
          assessment.is_required_for_signup && !assessment.assessment_taken
      )
    : null;

  useEffect(() => {
    if (!token) {
      history("/login");
    } else if (
      pathname === "/org-admin" &&
      userRoleData?.user_profile?.role === null
    ) {
      history("/");
    } else if (pathname === "/login") {
      navigateAfterLogin(history, userRoleData);
    } else if (pathname?.includes("otp")) {
      if (!userName) {
        history("/login");
      } else {
        navigateAfterLogin();
      }
    } else if (
      !signup_flow_completed &&
      userRoleData?.assessments &&
      remainingAssessment &&
      !["/get-started", "/quick-note", "/edit-profile"]?.some((path) =>
        pathname?.includes(path)
      ) &&
      !(pathname?.includes("assessment") && !pathname?.includes("/assessments"))
    ) {
      localStorage.setItem(
        "currentAssessmentId",
        remainingAssessment?.assessment_id
      );
      const slug = getSlug(assessmentList, remainingAssessment?.assessment_id);
      history(`/get-started/${slug}`);
    } else if (
      !signup_flow_completed &&
      !remainingAssessment &&
      assessmentList?.length
    ) {
      api({
        method: "PATCH",
        endPoint: `complete-signup`,
        urlencoded: false,
      });
      getRoleInfo({ needLoader: false });
      dispatch(getRoleInfo({ needLoader: false, parent: "getRoleInfo" }));
    }
  }, [pathname, userRoleData]);

  return (
    <>
      {roleInfoLoader === undefined || roleInfoLoader ? (
        <VSNLoader variant="fullPage" />
      ) : (
        <Outlet />
      )}
    </>
  );
}

export default ProtectedRoute;
