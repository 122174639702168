import { makeStyles } from "@mui/styles";
import leaves from "../../assets/Teams/png/leaves-img.png";

export const MyTeamsStyle = makeStyles((theme) => ({
  heading: {
    "&.MuiTypography-root": {
      fontSize: 30,
      fontWeight: "bold",
      color: "#424450",
      textAlign: "center",
      position: "relative",
      "& span": {
        color: "#E87637",
      },
      [theme.breakpoints.down("650")]: {
        fontSize: 22,
        lineHeight: "28px",
      },
    },
  },
  text: {
    "&.MuiTypography-root": {
      textAlign: "center",
      fontSize: 15,
      color: "#747787",
      maxWidth: "892px",
      margin: "15px auto 0",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "18px",
      },
    },
  },
  btn: {
    "&.MuiButtonBase-root": {
      maxWidth: 250,
      width: "100%",
      padding: 12,
      marginTop: 24,
      [theme.breakpoints.down("sm")]: {
        padding: 8,
        marginTop: 12,
        maxWidth: "100%",
        width: "100%",
      },
      "& .content": {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        fontWeight: "400",
        gap: 5,
        "& img": {
          height: "23px",
          width: "auto",
        },
        "& svg": {
          [theme.breakpoints.down("sm")]: {
            height: 17,
          },
        },
      },
      "& span": {
        lineHeight: "16px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
          lineHeight: "18px",
        },
      },
    },
  },
  btnBlue: {
    "&.MuiButton-root": {
      background: "linear-gradient(273deg, #3842C7 9.98%, #555FDD 95.46%)",
      borderColor: "#3842C7",
    },
  },
  card: {
    background: "#fff",
    borderRadius: 10,
    padding: 28,
    textAlign: "center",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "0px 7.7982px 17.32934px 0px rgba(81, 125, 132, 0.07)",
    position: "relative",
    zIndex: 2,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
    "&:after": {
      position: "absolute",
      content: "''",
      bottom: 0,
      right: 0,
      background: `url(${leaves})`,
      backgroundRepeat: "no-repeat",
      height: 237,
      width: "100%",
      maxWidth: 220,
      zIndex: -1,
    },
    "& img.icon": {
      [theme.breakpoints.down("sm")]: {
        height: 66,
      },
    },
  },
  shape1: {
    position: "absolute",
    top: 20,
    left: "5%",
  },
  shape2: {
    position: "absolute",
    bottom: "34%",
    left: "9%",
  },
  shape3: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "5%",
  },
  shape4: {
    position: "absolute",
    top: "5%",
    right: "5%",
    [theme.breakpoints.down("sm")]: {
      top: "-6%",
    },
  },
  shape: {
    height: 18,
    width: 18,
    position: "absolute",
    top: "58%",
    left: "40%",
    transform: "translateY(-50%)",
  },
  greenRound: {
    position: "absolute",
    right: "18%",
    bottom: "20%",
  },
  orangeFlower: {
    position: "absolute",
    left: "12%",
    top: "65%",
    transform: "translateY(-50%)",
  },
  orangeRainbow: {
    position: "absolute",
    left: "50%",
    bottom: "12%",
    transform: "translateX(-50%)",
  },
  purpleRainbow: {
    position: "absolute",
    right: 0,
    top: "25%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainBox: {
    background: "#fff",
    borderRadius: 5,
    maxWidth: 775,
    width: "100%",
    margin: "30px auto 0",
    position: "relative",
    padding: 25,
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  subHeading: {
    "&.MuiTypography-root": {
      fontSize: 14,
      color: "#86650A",
      fontWeight: 700,
    },
  },
  contactBox: {
    padding: "24px 157px 24px 20px",
    background: "#F7F3FB",
    borderRadius: 5,
    position: "relative",
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "15px 15px 5px",
    },
    "& img": {
      width: 169,
      height: "auto",
      position: "absolute",
      right: 0,
      bottom: 0,
      [theme.breakpoints.down("sm")]: {
        position: "unset",
        marginTop: 5,
        width: 200,
      },
    },
  },
}));
