import { useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import { assessmentIds } from "../../utils/constant";
import { getAssessmentState } from "../../shared/CustomFunc";
import { keys, length } from "../../utils/javascript";
import { showToast } from "../../utils/toastService";
import { SET_API_DATA } from "../../redux/constants";
import { ApiContainer } from "../../utils/api";

const notShow = ["/admin/login", "/admin/otp", "/demo-login"];
const AppLayout = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const interval = useRef(null);
  const { api } = ApiContainer();
  const userRoleData = useSelector((state) => state.app.userData);
  const downloadReportData = useSelector(
    (state) => state.api?.downloadVasanaTypeReport
  );
  const generatingReport = useSelector(
    (state) => state.api?.generatingReport
  ) || { isGenerating: false, reportSuccess: true, download: false };
  const vasanaTypeAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.vasanaType,
  });
  // const [generatingReport, setGeneratingReport] = useState({
  //   isGenerating: false,
  //   reportSuccess: true,
  //   download: false,
  // });
  const [count, setCount] = useState(0);
  const token = localStorage.getItem("token");
  const isLandingPage =
    [
      "/",
      "/for-teams",
      "/public-safety",
      "/who-we-are",
      "/contact-us",
    ].includes(pathname) && !token;

  const generateReport = async () => {
    // setGeneratingReport((prev) => ({
    //   ...prev,
    //   isGenerating: true,
    // }));
    dispatch({
      type: SET_API_DATA,
      payload: {
        generatingReport: {
          ...generatingReport,
          isGenerating: true,
        },
      },
    });
    if (localStorage?.getItem("token") && !downloadReportData) {
      const reportData = await api({
        method: "GET",
        endPoint: `assessment/${assessmentIds?.vasanaType}/download-report?submission_id=${vasanaTypeAssessment?.submission_id}`,
      });
      // setGeneratingReport((prev) => ({
      //   ...prev,
      //   isGenerating: true,
      //   reportSuccess: reportData?.data?.status,
      // }));
      dispatch({
        type: SET_API_DATA,
        payload: {
          generatingReport: {
            ...generatingReport,
            isGenerating: true,
            reportSuccess: reportData?.data?.status,
          },
        },
      });
      setCount((prevcount) => prevcount + 1);
      if (reportData?.data?.status === "Generating Full Report.") {
        // setGeneratingReport((prev) => ({
        //   ...prev,
        //   isGenerating: true,
        //   reportSuccess: false,
        //   download: false,
        // }));
        dispatch({
          type: SET_API_DATA,
          payload: {
            generatingReport: {
              ...generatingReport,
              isGenerating: true,
              reportSuccess: false,
              download: false,
            },
          },
        });
      } else if (reportData?.data[0]?.url) {
        const url = reportData?.data[0]?.url;
        const filename = reportData?.data[0]?.file_name;
        clearInterval(interval.current);
        // setGeneratingReport((prev) => ({
        //   ...prev,
        //   isGenerating: false,
        //   reportSuccess: true,
        // }));
        dispatch({
          type: SET_API_DATA,
          payload: {
            generatingReport: {
              ...generatingReport,
              isGenerating: false,
              reportSuccess: true,
            },
          },
        });
        dispatch({
          type: SET_API_DATA,
          payload: {
            downloadVasanaTypeReport: {
              url,
              filename,
            },
          },
        });
      } else {
        clearInterval(interval.current);
        // setGeneratingReport((prev) => ({
        //   ...prev,
        //   reportSuccess: null,
        // }));
        dispatch({
          type: SET_API_DATA,
          payload: {
            generatingReport: {
              ...generatingReport,
              reportSuccess: null,
            },
          },
        });
      }
    }
  };

  const getAssessments = async () => {
    const assessmentListResponse = await api({
      method: "GET",
      endPoint: `assessment/list`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessmentList",
      needFullResponse: true,
    });
    if (assessmentListResponse?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentList: assessmentListResponse?.data?.data,
        },
      });
    }
  };

  useEffect(() => {
    if (token && pathname !== "/assessments") getAssessments();
  }, [token]);

  useEffect(() => {
    if (vasanaTypeAssessment?.pdf_exists) {
      // setGeneratingReport((prev) => ({
      //   ...prev,
      //   reportSuccess: true,
      // }));
      dispatch({
        type: SET_API_DATA,
        payload: {
          generatingReport: {
            ...generatingReport,
            reportSuccess: true,
          },
        },
      });
    }
  }, [vasanaTypeAssessment]);

  useEffect(() => {
    const { isGenerating, reportSuccess } = generatingReport;
    if (isGenerating && reportSuccess === false) {
      interval.current = setInterval(generateReport, 5000);
    }
  }, [generatingReport.reportSuccess]);

  useEffect(() => {
    if (!generatingReport?.isGenerating && !vasanaTypeAssessment?.pdf_exists) {
      // setGeneratingReport((prev) => ({
      //   ...prev,
      //   reportSuccess: true,
      // }));
      dispatch({
        type: SET_API_DATA,
        payload: {
          generatingReport: {
            ...generatingReport,
            reportSuccess: true,
          },
        },
      });
    }
  }, [generatingReport?.isGenerating]);

  useEffect(() => {
    if (
      length(keys(vasanaTypeAssessment)) &&
      vasanaTypeAssessment?.submission_id
    ) {
      generateReport();
    }
  }, [vasanaTypeAssessment]);

  useEffect(() => {
    if (count >= 36 && !generatingReport?.reportSuccess) {
      showToast();
      clearInterval(interval.current);
      setCount(0);
    }
  }, [count]);

  useEffect(() => {
    return () => {
      clearTimeout(interval.current);
    };
  }, []);

  return (
    <>
      {!notShow?.includes(pathname) && <Header />}
      <Box mt={notShow?.includes(pathname) || isLandingPage ? 0 : 8}>
        {children}
      </Box>
      {!notShow?.includes(pathname) && <Footer />}
    </>
  );
};

export default AppLayout;
