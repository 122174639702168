export const exploreData = [
  {
    title: "Base Map",
    key: "baseMap",
    oneWordDesc: [
      { name: "Re" },
      { name: "Ga" },
      { name: "Ma" },
      { name: "Sa" },
    ],
    subDescription:
      "We've identified four primary Vasana Types that characterize individuals' interactions with the world, organized into a system called maps. This framework aims to enhance self-awareness and interpersonal understanding, not to stereotype or limit personal growth. Misuse of these insights, such as weaponizing them to criticize others (using Vasana Types as a sword) or evading responsibility using Vasana Types as a shield, contradicts our goals. Instead, recognizing our own patterns and strengths encourages constructive behaviors and collaborations. Appreciating the diversity and timing of each Vasana Type's contributions avoids judgment and fosters a more harmonious and effective community. These maps are tools for personal and collective improvement, designed to reduce stress, increase efficiency, and eliminate wasted efforts in achieving goals.",
  },
  {
    title: "Survival Strategy",
    key: "survivalStrategy",
    points: [
      {
        title: "Under Pressure",
        key: "underPressure",
        subDescription:
          "Each Vasana type has a distinct approach it uses to protect itself from real risk. In a real risk environment, this approach would be the best chance for each type to live. However, the game of business is not a real risk game, meaning that in the absence of awareness your Survival Strategy may actually damage your reputation. Once identiﬁed for you and others around you, people can choose whether to engage their biological response, or move to another more appropriate strategy, creating more ﬂexibility in the environment for how we all handle pressure. ",
        oneWordDesc: [
          { name: "Migrate" },
          { name: "Tolerate" },
          { name: "Hibernate" },
          { name: "Dictate" },
        ],
        typeDescriptions: [
          {
            key: "Dictate",
            desc: "Without the certainty of the direction of a project, Sa types can complain that a team has no clear strategy, and they don’t know where things are going. They start dictating in such a scenario.",
          },
          {
            key: "Migrate",
            desc: "Re types may complain that they feel “fenced in” or “restricted”, and their tendency is to look for options and “outs” in order to calm their biology. They may tend to go one direction one day, then another the next day, which can be useful in a chaotic time, but destabilizing if what the team needs is structure.",
          },
          {
            key: "Tolerate",
            desc: "Ga types tend to live in the short term (say 90 days into the future) and are more focused on producing tangible results generally than the other types. This perspective can lead them to complain that “we are changing things too often” or “we are looking too far down the road.” When these complaints start, Ga types tend to want more stability to calm their biology.",
          },
          {
            key: "Hibernate",
            desc: "When things are “moving too fast” Ma types will instinctively want to slow things down to calm their biology and ensure the right decisions are being made. In this way Ma types feel more secure.",
          },
        ],
      },
      {
        title: "Conversation Patterns Under Pressure",
        key: "conversationPatterns",
        subDescription:
          "When the pressure hits, internal and unsaid conversations heat up. Each Type has its own version of a Survival Conversation, which means that even though the situation is not actually life-or-death, we begin to feel as though there is real risk in the environment. The major concern is that if you don’t have a handle on your Survival Conversation, it will begin to take over your life and, as a consequence, your actions could be detrimental to your intended future because there is no real risk in the game of business and you are playing as if there is.",
        oneWordDesc: [
          { name: "Avoid Domination" },
          { name: "Be Included" },
          { name: "No Commitment" },
          { name: "Already Know" },
        ],
        typeDescriptions: [
          {
            key: "Avoid Domination",
            desc: "If I have to do what other people tell me to do, I will die.",
          },
          {
            key: "Be Included",
            desc: "If I am not included, I will die.",
          },
          {
            key: "No Commitment",
            desc: "If I have to choose without all of the information under the sun, I will die.",
          },
          {
            key: "Already Know",
            desc: "If other people know that I don’t know, I will die.",
          },
        ],
      },
      {
        title: "Survival Concern",
        key: "survivalConcern",
        subDescription:
          "Human beings invented language, and one of the usages of language is to describe “why we do what we do” under pressure. This means that each Vasana Type’s Survival Strategy is interpreted in language as a Survival Concern, the primary thing that upsets each of us and throws us into our habitual response to pressure. Once we have awareness of our Survival Concern, we can again choose whether to allow it to inform our next moves, or we can choose to focus on something else knowing we won’t die in the game of business.",
        oneWordDesc: [
          { name: "Freedom" },
          { name: "Stability" },
          { name: "Security" },
          { name: "Certainty" },
        ],
        typeDescriptions: [
          {
            key: "Freedom",
            desc: "Re types thrive in chaotic environments. They are personable and spontaneous yet challenging daily work partners. Their commitment to freedom leads them to frequently switch projects or jobs, but when positioned correctly, their ability to engage others kickstarts numerous projects.",
          },
          {
            key: "Stability",
            desc: "Ga types tend to be more pragmatic than the other types, focusing more on the structure for how we are going to get things done than where exactly we are going. This commitment to stability may lead Ga types to take on too much work in order to feel busy (therefore stable), leading other types to overload them unintentionally.",
          },
          {
            key: "Security",
            desc: "Ma types tend to want the details and to make sure they understand everything from every angle. Their commitment to security of the outcome may lead others to see them as “risk averse” but when positioned appropriately, their perspective can help a team avoid needless problems by seeing where there are issues before delivering a project to our customers.",
          },
          {
            key: "Certainty",
            desc: "Certainty is not a concern for the final outcome (which we would define as security); instead, it is a concern for an understanding for where things are going. Positioned appropriately, their perspective can help others feel that there is a long term strategy in place.",
          },
        ],
      },
    ],
  },
  {
    title: "Comfort Zone",
    key: "comfortZone",
    points: [
      {
        title: "Learns by",
        key: "learnsBy",
        subDescription:
          "When it comes to learning, there is no right or wrong way to do it. However, each Vasana Type appears to have a primary way in which to learn. This does not mean that they won’t learn in other ways, but as often as we can as a team, we should be attempting to give each type what they need in order to maximize growth and accomplishment.",
        oneWordDesc: [
          { name: "Talking" },
          { name: "Doing" },
          { name: "Reading" },
          { name: "Thinking" },
        ],
        typeDescriptions: [
          {
            key: "Talking",
            desc: "Re types, who primarily learn through talking, are possibility-driven and often think out loud, rapidly shifting between ideas in conversations. Their conversational learning style makes them the most flexible, easily adapting to and moving between new ideas.",
          },
          {
            key: "Doing",
            desc: "Ga types adopt a black and white perspective, refraining from speculation until they undertake a task, and primarily focus on achieving goals within the next 90 days. However, this approach can lead them to overcommit, often underestimating the workload involved.",
          },
          {
            key: "Reading",
            desc: "Ma types are the most analytical among the types, preferring to read extensively and often requesting written materials to review before decision-making, reflecting their focus on the past. However, their tendency to write lengthy emails and correspondences can frustrate other types who are less inclined to extensive reading.",
          },
          {
            key: "Thinking",
            desc: "Sa types constantly seek innovative strategies to achieve their long-term goals or to brainstorm business growth ideas. However, they tend to expect others to intuitively understand their thoughts, forgetting that their internal ideas are not communicated to their colleagues.",
          },
        ],
      },
      {
        title: "Sight Lines",
        key: "sightLines",
        subDescription:
          "As we all know, how each Vasana Type sees the world is very different - that’s the essence of what a type is at the core. When we stand back and put each Type’s perspective into a sight line conversation, it is easy to see that all of us are viewing life through a lens that might be completely different than those around us. This can lead to frustration and misunderstanding, until we grow to respect each other’s sight line into the world.",
        oneWordDesc: [
          { name: "Insight" },
          { name: "Oversight" },
          { name: "Hindsight" },
          { name: "Foresight" },
        ],
        typeDescriptions: [
          {
            key: "Insight",
            desc: "Re types focus on immediate actions that can enhance business relationships in the present moment.",
          },
          {
            key: "Oversight",
            desc: "Ga types are pragmatic, constantly questioning current tasks, responsibilities, and additional steps needed for achieving results.",
          },
          {
            key: "Hindsight",
            desc: "Ma types emphasize learning from past successes and failures to improve future strategies and decisions.",
          },
          {
            key: "Foresight",
            desc: "Sa types primarily adopt a long-term perspective, concentrating on strategies and actions for the next five years that align with the company's future goals.",
          },
        ],
      },
      {
        title: "Timeframes",
        key: "timeframes",
        subDescription:
          "Each Vasana Type has a distinct timeframe in which they automatically approach performance. In order to orchestrate a project that keeps a positive tempo, it is essential to acknowledge this “timing” element. Once identiﬁed for you and others around you, you will be able to more gracefully contribute when it is most appropriate, and then step aside so others can effectively participate.",
        oneWordDesc: [
          { name: "The Present" },
          { name: "90 Days" },
          { name: "The Past" },
          { name: "Five Years" },
        ],
        typeDescriptions: [
          {
            key: "The Present",
            desc: "Re types, living moment-to-moment, excel in chaotic situations and spontaneously execute tasks, but can falter in structured, repetitive environments, potentially creating chaos where there is none.",
          },
          {
            key: "90 Days",
            desc: "Ga types, with a 90-day future outlook, drive teams forward through endless to-do lists, but can become overwhelmed when prioritizing, leading to late-night work and crisis management.",
          },
          {
            key: "The Past",
            desc: "Ma types, anchored in the past, excel in evaluating past performances to enhance future outcomes, but they may delay projects or falter when focusing on future strategies, which is not their strength.",
          },
          {
            key: "Five Years",
            desc: "Sa types focus on a five-year future, creating new opportunities by overlooking daily emergencies, yet they may struggle to take immediate actions and risk missing opportunities by waiting for perfection.",
          },
        ],
      },
      {
        title: "Go To Phrases",
        key: "goToPhrases",
        subDescription:
          "How we approach conversations with others is colored by an unspoken conservation unique to our own Vasana Type. Learning to identify your own unspoken conversation and seeing how it affects others, allows you to blend more gracefully with those around you, especially when coordinating action. Further, such insight enables you to take things less personally when others attempt to coordinate with you.",
        oneWordDesc: [
          { name: "Could" },
          { name: "Have to" },
          { name: "Should Have" },
          { name: "Should" },
        ],
        typeDescriptions: [
          {
            key: "Could",
            desc: "Re types, operating moment-to-moment, speak in terms of could, adept at making quick decisions in chaotic situations, but their flexibility might be perceived as flakiness.",
          },
          {
            key: "Have to",
            desc: "Ga types speak from a perspective of have to, committed to producing tangible results, yet they risk overcommitment.",
          },
          {
            key: "Should Have",
            desc: "Ma types speak in terms of should have been, assessing past performances to improve future outcomes",
          },
          {
            key: "Should",
            desc: "Sa types  often speak in terms of should, guiding others based on their vision of how things should be, which can sometimes come across as arrogant.",
          },
        ],
      },
      {
        title: "Building Trust",
        key: "buildingTrust",
        subDescription:
          "Trust, a cornerstone of successful collaboration, hinges on competence and sincerity. Competence reflects confidence in another's ability, while sincerity denotes a genuine connection and authenticity. Trust forms when both elements are recognized. Yet, influenced by our Vasana Type, we typically prioritize one over the other, especially during pivotal moments like first meetings or crises. Understanding our primary trust concern, as well as that of our counterparts, is key to fostering trust more effectively.",
        oneWordDesc: [
          { name: "Sincerity" },
          { name: "Sincerity" },
          { name: "Competence" },
          { name: "Competence" },
        ],
        typeDescriptions: [
          {
            key: "Could",
            desc: "Re types and Ga types value sincerity foremost, seeking authentic personal connections and loyalty over immediate competence assessment.",
          },
          {
            key: "Have to",
            desc: "Re types and Ga types value sincerity foremost, seeking authentic personal connections and loyalty over immediate competence assessment.",
          },
          {
            key: "Should Have",
            desc: "Sa types and Ma types prioritize competence in trust, requiring proof of others' understanding or a track record of results before forming a connection.",
          },
          {
            key: "Should",
            desc: "Sa types and Ma types prioritize competence in trust, requiring proof of others' understanding or a track record of results before forming a connection.",
          },
        ],
      },
    ],
  },
  {
    title: "50/50s",
    key: "50/50s",
    points: [
      {
        title: "Position on the Field With Respect to Projects",
        key: "positionOnTheFieldWithRespectToProjects",
        subDescription:
          "All projects have a beginning and an end point. We all know this. As it relates to Vasana Types, it is important to understand that two of the Types are more naturally inclined to be STARTERS and the other two Types are more naturally inclined to be FINISHERS. We need both positions on the ﬁeld, having the right people in the right position increases our chances for success.",
        oneWordDesc: [
          { name: "Creational" },
          { name: "Preservational" },
          { name: "Preservational" },
          { name: "Creational" },
        ],
        typeDescriptions: [
          {
            key: "Creational",
            desc: "Sa types  and Re types are creational, tending to initiate projects and relationships with enthusiasm but often losing interest over time, which risks them being seen as only having good intentions.",
          },
          {
            key: "Preservational",
            desc: "Ga types and Ma types are preservational, preferring to commit fully to projects they start and gaining interest as they progress, with a focus on endurance and task completion. However, their cautious approach to new ventures can lead to resistance to change if not managed carefully.",
          },
          {
            key: "Preservational",
            desc: "Ga types and Ma types are preservational, preferring to commit fully to projects they start and gaining interest as they progress, with a focus on endurance and task completion. However, their cautious approach to new ventures can lead to resistance to change if not managed carefully.",
          },
          {
            key: "Creational",
            desc: "Sa types  and Re types are creational, tending to initiate projects and relationships with enthusiasm but often losing interest over time, which risks them being seen as only having good intentions.",
          },
        ],
      },
      {
        title: "Making Progress",
        key: "makingProgress",
        subDescription:
          "Making progress in our daily business lives means balancing planning with doing. As it relates to Vasana Types, which ones are more inclined to think before they act and which ones might be more inclined to act before they think? There is no better or worse here – only awareness of each Type’s natural predisposition for how to make progress when under pressure. Understanding this distinction enables all of us to take advantage of each Type’s most natural reaction.",
        oneWordDesc: [
          { name: "Action" },
          { name: "Action" },
          { name: "Thought" },
          { name: "Thought" },
        ],
        typeDescriptions: [
          {
            key: "Action",
            desc: "Re types and Ga types are action-oriented, tending to act first and think later, with Re types eager to start new initiatives and Ga types quick to address immediate tasks.",
          },
          {
            key: "Action",
            desc: "Re types and Ga types are action-oriented, tending to act first and think later, with Re types eager to start new initiatives and Ga types quick to address immediate tasks.",
          },
          {
            key: "Thought",
            desc: "Sa types and Ma types, when under pressure, are thought-oriented, preferring to think before acting, with Sa focusing on future moves and Ma reviewing past events, leading to more calculated actions.",
          },
          {
            key: "Thought",
            desc: "Sa types and Ma types, when under pressure, are thought-oriented, preferring to think before acting, with Sa focusing on future moves and Ma reviewing past events, leading to more calculated actions.",
          },
        ],
      },
      {
        title: "Primary Personal Focus",
        key: "primaryPersonalFocus",
        subDescription:
          "When things don’t go as planned, the different Vasana Types tend to bring their attention either to themselves or to others. If unobserved, this automatic, primary personal focus can interfere in our attempts to work effectively with others, especially when experiencing a breakdown.",
        oneWordDesc: [
          { name: "Self" },
          { name: "Others" },
          { name: "Self" },
          { name: "Others" },
        ],
        typeDescriptions: [
          {
            key: "Self",
            desc: "Conversely, Re types and Ma types tend to look inward: Remay over-promise to maintain sincerity, while Ma emphasize their own competence, often providing extensive evidence to justify their actions.",
          },
          {
            key: "Others",
            desc: "Under pressure, Sa types and Ga types typically focus on others, with Sa types often criticizing others' actions, potentially appearing arrogant, and Ga types concentrating on others' sincerity or loyalty, leading to frustration.",
          },
          {
            key: "Self",
            desc: "Conversely, Re types and Ma types tend to look inward: Remay over-promise to maintain sincerity, while Ma emphasize their own competence, often providing extensive evidence to justify their actions.",
          },
          {
            key: "Others",
            desc: "Under pressure, Sa types and Ga types typically focus on others, with Sa types often criticizing others' actions, potentially appearing arrogant, and Ga types concentrating on others' sincerity or loyalty, leading to frustration.",
          },
        ],
      },
    ],
  },
  {
    title: "Assets",
    key: "assets",
    points: [
      {
        title: "Natural Business Positions",
        key: "naturalBusinessPositions",
        subDescription:
          "When discussing assets, we refer to the innate strengths of each Vasana Type. This isn't about what a type can or cannot do—all types are capable. Rather, it's about natural predispositions. Each Vasana Type is inherently suited for one of four business roles, regardless of their actual job title. Understanding this natural alignment is key.",
        oneWordDesc: [
          { name: "Sales" },
          { name: "Production" },
          { name: "Administration" },
          { name: "Marketing" },
        ],
        typeDescriptions: [
          {
            key: "Sales",
            desc: "Re types excel in sales, using their people-focused and action-oriented traits to build relationships and meet client needs",
          },
          {
            key: "Production",
            desc: "Ga types are natural fits for production roles, focusing on tangible results and efficient structures to fulfill client promises, with a strong emphasis on teamwork and short-term goals",
          },
          {
            key: "Administration",
            desc: "Ma types suit administrative positions, where their detail-oriented, reflective approach ensures quality and effective performance evaluation.",
          },
          {
            key: "Marketing",
            desc: "Sa types thrive in marketing, leveraging their future-oriented and creative mindset to build brand identity. Sa enjoy the process of finding and simplifying new ideas that could satisfy needs.",
          },
        ],
      },
      {
        title: "Positions of Accountability",
        key: "positionsOfAccountability",
        subDescription:
          "In both business and life, we engage in projects to shape a future distinct from our current state. Successful projects necessitate clear accountabilities, with each Vasana Type naturally inclined towards specific roles. Recognizing our own strengths allows us to excel in suitable tasks, while identifying others' inclinations helps position them effectively for success.",
        oneWordDesc: [
          { name: "Mission" },
          { name: "Structure" },
          { name: "Standards" },
          { name: "System" },
        ],
        typeDescriptions: [
          {
            key: "Mission",
            desc: "Re types are responsible for the mission, thriving on challenges and being action-oriented, they initiate new projects, especially in unexplored markets, with a strong emphasis on relationships.",
          },
          {
            key: "Structure",
            desc: "Ga types are accountable for the structure, focusing on maintaining workflow mechanisms and ensuring the delivery of promises, while being mindful of not overburdening themselves or the system.",
          },
          {
            key: "Standards",
            desc: "Ma types focus on standards, using their detail-oriented nature to continuously seek improvement and foster an environment of learning and growth, provided they present their insights without personal bias.",
          },
          {
            key: "System",
            desc: "Sa types are accountable for the system, excelling in overseeing projects from a high-level perspective and devising strategic plans without necessarily involving themselves in implementation.",
          },
        ],
      },
      {
        title: "Opportunity Accountability",
        key: "opportunityAccountability",
        subDescription:
          "Opportunities fuel business growth, involving discovery, market integration, execution, and evaluation. Each Vasana Type holds a key role in identifying and capitalizing on opportunities. The excitement in business arises from recognizing and utilizing the strengths of each Vasana Type, enhancing our ability to create superior opportunities tomorrow compared to today.",
        oneWordDesc: [
          { name: "Binders" },
          { name: "Grinders" },
          { name: "Minders" },
          { name: "Finders" },
        ],
        typeDescriptions: [
          {
            key: "Binders",
            desc: "Re types are the opportunity binders, adept at navigating chaotic situations and building relationships with clients, often pursuing risky opportunities for the thrill of the challenge.",
          },
          {
            key: "Grinders",
            desc: "Ga types are the opportunity grinders, focused on producing tangible results and persisting when others might falter, essential for delivering real value to clients.",
          },
          {
            key: "Minders",
            desc: "Ma types are the opportunity minders, meticulously analyzing decisions to ensure a return on investment and maintaining quality standards, guiding the team towards its goals.",
          },
          {
            key: "Finders",
            desc: "Sa types are the opportunity finders, envisioning future market trends and encouraging innovative thinking, thus driving growth and innovation.",
          },
        ],
      },
      {
        title: "Leadership Accountability",
        key: "leadershipAccountability",
        subDescription:
          "Leadership involves clear, learnable actions, drawing those with ambition towards it. While we can adapt to various leadership roles, each Vasana Type has an innate affinity for one of four key leadership domains. Mastering the art of positioning the right individuals in leadership roles at the appropriate times enhances team dynamics and guides everyone more smoothly towards the intended goals.",
        oneWordDesc: [
          { name: "Mood" },
          { name: "Alliances" },
          { name: "Confrontation" },
          { name: "Intention" },
        ],
        typeDescriptions: [
          {
            key: "Binders",
            desc: "Re types manage the mood, attuned to the morale and beliefs of the team, ensuring a positive and productive environment but must be wary of how their mood impacts the team.",
          },
          {
            key: "Grinders",
            desc: "Ga types focus on building alliances, fostering strong teams through inclusivity and loyalty, ensuring everyone works in unison towards common goals.",
          },
          {
            key: "Minders",
            desc: "Ma types are accountable for confrontation, addressing performance issues to align with the community's intentions, emphasizing learning from mistakes rather than assigning blame.",
          },
          {
            key: "Finders",
            desc: "Sa types are responsible for setting the intention, using their visionary nature to guide the direction of the community.",
          },
        ],
      },
    ],
  },
  {
    title: "Liabilities",
    key: "liabilities",
    points: [
      {
        title: "Confuses with Accomplishment",
        key: "confusesWithAccomplishment",
        subDescription:
          "Accomplishment leads to satisfaction, yet each Vasana Type risks being sidetracked by the pursuit of this positive sensation rather than achieving real outcomes. Recognizing this tendency in ourselves and others allows us to correct course and support one another in maintaining focus on tangible results.",
        oneWordDesc: [
          { name: "Relationships" },
          { name: "Repetition" },
          { name: "Reflection" },
          { name: "Ideas" },
        ],
        typeDescriptions: [
          {
            key: "Relationships",
            desc: "Re types might confuse building relationships with achievement, sometimes prioritizing relationships over beneficial transactions, and potentially sacrificing their own goals, which can lead to being exploited for their relationship-focused approach.",
          },
          {
            key: "Repetition",
            desc: "Ga types can equate repetitive action with success, often focusing on tasks without assessing their effectiveness, which might result in tolerating incompetence and overburdening themselves.",
          },
          {
            key: "Reflection",
            desc: "Ma types often mistake reflection for action, leading to prolonged introspection and potential 'analysis paralysis', causing a lack of momentum in their teams.",
          },
          {
            key: "Ideas",
            desc: "Sa types may mistake ideation for accomplishment, often creating visionary ideas without effective follow-through, leading to a perception of inconsistency.",
          },
        ],
      },
      {
        title: "Sacrifices Accomplishment for",
        key: "sacrificesAccomplishmentFor",
        subDescription:
          "Under pressure, each Vasana Type may instinctively gravitate towards actions that could harm their and their team's future. Recognizing these automatic responses provides a chance to prevent self-destructive behavior, either by self-intervention or with help from others.",
        oneWordDesc: [
          { name: "Being Free" },
          { name: "Having work to do" },
          { name: "Having an impact" },
          { name: "Being Right" },
        ],
        typeDescriptions: [
          {
            key: "Being Free",
            desc: "Re types prefer freedom and spontaneity, which can lead to inconsistency and perceived unreliability if they frequently switch projects or resist input from others.",
          },
          {
            key: "Having work to do",
            desc: "Ga types focus intensely on work, but their lack of prioritization and tendency to rely on effort alone can lead to frustration and potential outbursts against their team or clients.",
          },
          {
            key: "Having an impact",
            desc: "Ma types seek to make an impact, often through critical assessments or last-minute changes, which can alienate them from their team or clients if perceived as overly negative or obstructive.",
          },
          {
            key: "Being Right",
            desc: "Sa types may prioritize being right over practicality, engaging in prolonged debates to prove their point, potentially leading to conflicts within their team.",
          },
        ],
      },
      {
        title: "Projected Emotions in Breakdown",
        key: "projectedEmotionsInBreakdown",
        subDescription:
          "Ambitious individuals inevitably face breakdowns—the more initiatives undertaken, the more likely disruptions occur. A breakdown halts desired progress, and each Vasana Type tends to respond in a predictable manner. Recognizing the impact we have on others during these times, and anticipating their reactions, allows us to depersonalize the situation and proactively manage it.",
        oneWordDesc: [
          { name: "Impatience" },
          { name: "Frustration" },
          { name: "Indifference" },
          { name: "Arrogance" },
        ],
        typeDescriptions: [
          {
            key: "Impatience",
            desc: "Re types are perceived as impatient, pushing for action and momentum, sometimes unsettling other styles with their urgency and perceived recklessness.",
          },
          {
            key: "Frustration",
            desc: "Ga types are viewed as frustrated, taking on excessive work and complaining about it, which can be misinterpreted as bragging and lead to further isolation.",
          },
          {
            key: "Indifference",
            desc: "Ma types seem indifferent, reflecting internally on their role in the breakdown, potentially leading to disengagement from the team and being perceived as uninterested in the outcome.",
          },
          {
            key: "Arrogance",
            desc: "Sa types are seen as arrogant, often giving unsolicited advice and focusing on others' actions, which can come across as making others wrong. ",
          },
        ],
      },
      {
        title: "Negative Reputations",
        key: "negativeReputations",
        subDescription:
          "Letting survival strategies dictate your business actions can damage your reputation. When individuals of different Vasana Types are either oblivious to their pressure responses or knowingly ignore them, they inevitably foster negative perceptions among peers.",
        oneWordDesc: [
          { name: "Flaky" },
          { name: "Pushovers" },
          { name: "Roadblock" },
          { name: "Bully" },
        ],
        typeDescriptions: [
          {
            key: "Flaky",
            desc: "Re types risk being labeled as flaky, as they often seek new relationships rather than focusing on existing tasks, which can be seen as unreliability. They can counter this by staying disciplined and committed to their responsibilities.",
          },
          {
            key: "Pushovers",
            desc: "Ga types might be viewed as pushovers, overly accommodating and taking on too much work, leading to bottlenecks and scapegoating. They can overcome this by being more selective about their tasks and learning to say no.",
          },
          {
            key: "Roadblock",
            desc: "Ma types often come across as roadblocks, hesitating in decision-making and extensively analyzing information, which can stall progress. They can improve their image by being more decisive and timely in their actions.",
          },
          {
            key: "Bully",
            desc: "Sa types may be seen as bullies, dictating actions and appearing arrogant, leading to high turnover due to their perceived lack of empathy. They can improve by valuing others' input and acknowledging that they are not always right.",
          },
        ],
      },
      {
        title: "Bad Behaviors",
        key: "badBehaviors",
        subDescription:
          "The more prolonged the pressure, the more detrimental the behavior of each Vasana Type can become. Without awareness, these default actions can harm both community cohesion and personal identity. Identifying such behaviors in ourselves, although often easier to spot in others, enables the choice to halt them. This decision can pivot us from failure to success and from merely breaking even to achieving breakthroughs in our business endeavors.",
        oneWordDesc: [
          { name: "Sacrifice" },
          { name: "Undermine" },
          { name: "Capitulate" },
          { name: "Manipulate" },
        ],
        typeDescriptions: [
          {
            key: "Sacrifice",
            desc: "Re types could sacrifice their own sincerity, making excessive promises to maintain momentum, potentially leading to broken relationships and perceived insincerity.",
          },
          {
            key: "Undermine",
            desc: "Ga types may undermine their colleagues, tolerating issues until frustration leads them to question others' contributions, causing team divisions and trust issues.",
          },
          {
            key: "Capitulate",
            desc: "Ma types tend to capitulate, withdrawing and allowing projects to fail to set up a 'told you so' scenario, which can make others doubt their commitment to team goals.",
          },
          {
            key: "Manipulate",
            desc: "Sa types might resort to manipulation, dictating actions and indirectly influencing others, which can erode trust and authenticity.",
          },
        ],
      },
    ],
  },
];
