import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { IsiResultStyle } from "./isiResultStyle";

const LineChart = ({ score, maxScore, result }) => {
  const classes = IsiResultStyle();
  const [chartData, setChartData] = useState({});
  const [isDisplayX, setIsDisplayX] = useState(true);

  useEffect(() => {
    const updatedChartData = { ...score };
    if (updatedChartData[2022]?.score === null) {
      updatedChartData[2022] = { result: null, score: 0 };
    }
    if (updatedChartData[2023]?.score === null) {
      updatedChartData[2023] = { result: null, score: 0 };
    }
    setChartData(updatedChartData);
  }, [score]);

  const series = [
    {
      data: Object?.values(chartData)?.map((data) => data.score),
    },
  ];

  const options = {
    chart: {
      type: "line",
      group: "social",
      parentHeightOffset: 15,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#82C799"],
    markers: {
      size: [7],
      colors: ["#1780C6"],
    },
    grid: {
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories: Object?.keys(chartData),
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: {
        text: "Score",
      },
      min: 0,
      max: Math.ceil(maxScore / 5) * 5,
    },
    tooltip: {
      x: {
        show: isDisplayX,
      },
      y: {
        title: {
          formatter: (val, obj) => {
            if (obj && obj.dataPointIndex !== undefined) {
              const year = Object?.keys(chartData)[obj.dataPointIndex];
              const currentResult =
                chartData[year]?.result || "No result available";
              setIsDisplayX(chartData[year]?.score !== null);
              return `${year}: ${currentResult}`;
            }
            return "";
          },
        },
      },
    },
  };

  return (
    <div id="chart" className={classes.chartContainer}>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={270}
      />
      <div id="html-dist" />
    </div>
  );
};

export default LineChart;
