import classNames from "classnames";
import React from "react";

const VSNImage = ({
  src,
  alt,
  className,
  onClick,
  style,
  role = "presentation",
  name,
  width,
  height,
  ref,
  cursor,
  ...rest
}) => (
  <img
    className={classNames(className)}
    {...{
      alt,
      ref,
      src,
      style,
      onClick,
      role,
      name,
      width,
      height,
      cursor,
      ...rest,
    }}
    onKeyDown={onClick}
    aria-hidden
  />
);
export default VSNImage;
