import { Box, Collapse, Hidden, Stack } from "@mui/material";
import React from "react";
import { SwiperSlide } from "swiper/react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Close } from "@mui/icons-material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import VSNTypography from "../../../shared/VSNTypography";
import { VSNSwiper } from "../../../shared/VSNSwiper";
import VSNImage from "../../../shared/VSNImage";
import VSNButton from "../../../shared/VSNButton";
import { TeamDashboardStyle } from "./TeamDashboardStyle";
import {
  colorFromResults,
  renderTypesWithImage,
} from "../../../shared/CustomFunc";
import { aspectsOfVasanaType } from "./aspectsData";

const temp = {
  sa: {
    height: 100,
    bgColor: colorFromResults("sa"),
  },
  re: {
    height: 55,
    bgColor: colorFromResults("re"),
  },
  ga: {
    height: 100,
    bgColor: colorFromResults("ga"),
  },
  ma: {
    height: 55,
    bgColor: colorFromResults("ma"),
  },
};

const card = ({ exploreAspects, classes, el }) => (
  <Stack className={classes.slide}>
    <Box mt={4.5} mb={1}>
      <VSNImage src={el?.img} alt="w" />
    </Box>
    <VSNTypography
      variant="h5"
      component="h5"
      color="#424450"
      fontSize={{ sm: 16, xs: 15 }}
      lineHeight="20px"
      fontWeight="700"
      mt={1.4}
      sx={{ position: "relative" }}
    >
      {el?.title}
    </VSNTypography>
    <VSNTypography
      color="#747787"
      fontSize="14px"
      lineHeight="18px"
      my={1}
      sx={{ position: "relative" }}
    >
      {el?.desc}
    </VSNTypography>
    <VSNButton variant="text" handleClick={() => exploreAspects(el)}>
      Explore More
    </VSNButton>
  </Stack>
);

const cardDesc = ({ classes, onClose, currentAspect }) => (
  <Stack className={`${classes.teamViewCard} ${classes.popupTeamView}`} mt={3}>
    <Stack className="innerContent">
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <VSNTypography variant="h3" className={classes.blueHeading} mb={0.5}>
            {currentAspect?.title}
          </VSNTypography>
          <VSNTypography className="desc">{currentAspect?.desc}</VSNTypography>
        </Box>
        <Box className={classes.closeIcon} onClick={onClose}>
          <Close fontSize="14px" />
        </Box>
      </Stack>
      <Stack
        direction={{ lg: "row", md: "column", sm: "row", xs: "column" }}
        justifyContent="space-between"
        px={{ xl: 2, md: 0, xs: 0 }}
        py={{ xl: 3, md: 2, xs: 1 }}
        spacing={{ sm: 0, xs: 2 }}
        height="100%"
      >
        {currentAspect?.aspects?.map((el, index) => (
          <Stack
            direction={{
              lg: "column",
              md: "row",
              sm: "column",
              xs: "row",
            }}
            className={classes.oneLine}
            alignItems="center"
            spacing={1}
            key={index}
          >
            <Box
              className="line"
              sx={{
                backgroundColor: temp[el?.key]?.bgColor,
                height: {
                  lg: temp[el?.key]?.height,
                  md: 2,
                  sm: temp[el?.key]?.height,
                  xs: 2,
                },
                width: { lg: 2, md: 15, sm: 2, xs: 15 },
              }}
            />
            <Box
              className="dot"
              sx={{ backgroundColor: temp[el?.key]?.bgColor }}
            />
            <Box
              className={classes.typeImgWrapper}
              sx={{
                mr: {
                  lg: el?.key === "re" ? "-14px !important" : "",
                  md: "0px !important",
                  sm: el?.key === "re" ? "-14px !important" : "",
                  xs: "0px !important",
                },
              }}
            >
              {renderTypesWithImage({ type: el?.key, className: "typeImg" })}
            </Box>
            <Box className={classes.imgCartoonWrapper}>
              <VSNImage
                src={el?.img}
                className="imgCartoon"
                style={{ width: "100%" }}
                alt="imgCartoon"
              />
            </Box>
            <VSNTypography
              variant="h5"
              textAlign={{
                lg: "center",
                md: "start",
                sm: "center",
                xs: "start",
              }}
            >
              {el?.title}
            </VSNTypography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  </Stack>
);

export default function Aspects({ exploreAspects, currentAspect, onClose }) {
  const classes = TeamDashboardStyle();
  return (
    <Stack position="relative">
      <Stack className={classes.teamViewCard} mt={{ xl: 3, md: 0, xs: 3 }}>
        <Stack direction="row" justifyContent="space-between">
          <VSNTypography variant="h3" className={classes.mdHeading}>
            Different Aspects of Vasana Types
          </VSNTypography>
          <Stack direction="row" spacing={1.4} className={classes.customArrow}>
            <div className="swiper-button image-swiper-button-next">
              <KeyboardArrowLeftIcon />
            </div>
            <div className="swiper-button image-swiper-button-prev">
              <ChevronRightIcon />
            </div>
          </Stack>
        </Stack>
        <div className={classes.vasanaTypesSlider}>
          <VSNSwiper
            pagination
            navigation={{
              prevEl: ".image-swiper-button-next",
              nextEl: ".image-swiper-button-prev",
              disabledClass: "swiper-button-disabled",
            }}
            spaceBetween={20}
            breakpoints={{
              0: {
                slidesPerView: 1.5,
              },
              475: {
                slidesPerView: 1.5,
              },
              991: {
                slidesPerView: 2,
              },
              1280: {
                slidesPerView: 3,
              },
              1536: {
                slidesPerView: 1.5,
              },
              1600: {
                slidesPerView: 2.5,
              },
            }}
          >
            {aspectsOfVasanaType?.map((el, index) => (
              <SwiperSlide key={index}>
                {card({ exploreAspects, classes, el })}
              </SwiperSlide>
            ))}
          </VSNSwiper>
        </div>
      </Stack>
      <Hidden mdDown>
        {currentAspect?.key
          ? cardDesc({ classes, onClose, currentAspect })
          : null}
      </Hidden>
      <Hidden mdUp>
        <Collapse in={currentAspect?.key}>
          {cardDesc({ classes, onClose, currentAspect })}
        </Collapse>
      </Hidden>
    </Stack>
  );
}
