import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, Stack, useMediaQuery } from "@mui/material";
import { MyTeamsStyle } from "./myTeamstyle";
import contactVasanaTeamRotate from "../../assets/Teams/svg/contact-vasana-team-rotate.svg";
import contactVasanaTeam from "../../assets/Teams/svg/contact-vasana-team.svg";
import noItem from "../../assets/Teams/png/no-items-found-img.png";
import teamMember from "../../assets/Teams/svg/member-img.svg";
import shape5 from "../../assets/Teams/svg/purple-rainbow.svg";
import shape1 from "../../assets/Teams/svg/shape-1.svg";
import shape2 from "../../assets/Teams/svg/shape-2.svg";
import shape3 from "../../assets/Teams/svg/shape-3.svg";
import shape4 from "../../assets/Teams/svg/shape-4.svg";
import team from "../../assets/Teams/svg/team-img.svg";
import shape from "../../assets/assesments/svg/assesment-3.svg";
import { joinOrganizationContainer } from "../../container/joinOrganization.container";
import teamContainer from "../../container/team.container";
import { clearFormData, toggleModelShow } from "../../shared/CustomFunc";
import VSNButton from "../../shared/VSNButton";
import VSNErrorMessage from "../../shared/VSNErrorMessage";
import VSNImage from "../../shared/VSNImage";
import VSNLabel from "../../shared/VSNLabel";
import VSNLoader from "../../shared/VSNLoader";
import VSNModel from "../../shared/VSNModel";
import VSNTypography from "../../shared/VSNTypography";
import ControlForm from "../../shared/form/ControlForm";
import { JoinPersonIcon, MemberIcon, UserIcon } from "../../shared/icon";
import CreateTeamModal from "./CreateTeamModal";
import ViewTeams from "./ViewTeams";
import NoTeamToJoinModal from "./NoTeamToJoinModal";
import { organization_location_admin } from "../../utils/constant";

const MyTeams = ({ isJoinTeam }) => {
  const {
    dispatch,
    modelState,
    toggleTeamView,
    gridView,
    setGridView,
    handleClick,
    handleShareLink,
    anchorEl,
    handleClose,
    open,
    teams,
    teamsToJoin,
    formPath,
    loaders,
    handleExitTeam,
    joinTeam,
    selectedTeamMembers,
    onClickParticipants,
    onSuccess,
    getTeamsList,
    onEditTeam,
    onExitTeamConfirm,
    onExitTeamDecline,
    handleDeleteTeam,
    fetched,
    createTeamInfo,
    editButtons,
    handleChange: handleTeamChange,
    isExitTeamAlertOpen,
    handleOk,
    handleCancel,
    handleAddMembers,
    addMembersFields,
    addMemberButtons,
    addMemberInfo,
    handleAddMemberChange,
    onInputChange,
    onEmailSelect,
    myTeams,
  } = teamContainer({
    isJoinTeam,
    isTeamsTab: true,
  });

  const { joinOrganization, handleChange, formErrors, joinOrganizationLoader } =
    joinOrganizationContainer();
  const organization_type_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_type_id
  );
  const classes = MyTeamsStyle();
  const navigate = useNavigate();
  const userRoleData = useSelector((state) => state.app?.userData);
  const isGuestUser = userRoleData?.user_profile?.organization_name === "Guest";
  const isOrgAdmin = userRoleData?.user_profile?.role;
  const matches = useMediaQuery("(max-width:600px)");
  const { formData } = useSelector((state) => state?.form);
  const organization_original_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_original_id
  );
  const department_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.department_id
  );
  const location_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.location_id
  );
  const permissions = useSelector(
    (state) => state?.app?.userData?.user_profile?.permissions
  );
  const manageTeams =
    organization_location_admin?.includes(isOrgAdmin) &&
    organization_type_id === 1 &&
    permissions?.teams?.includes("read_only");
  if (
    !(isGuestUser && !isOrgAdmin) &&
    ((!isJoinTeam && !(fetched?.joinTeam && fetched?.myTeam)) ||
      loaders?.myTeamsLoader ||
      loaders?.joinTeamLoader)
  )
    return <VSNLoader variant="fullPage" />;

  if (isGuestUser && !isOrgAdmin) {
    return (
      <Container maxWidth="xl">
        <VSNTypography variant="h2" className={classes.heading}>
          <span>Vasana</span> helps create working <span>environments</span>{" "}
          where relationships thrive
        </VSNTypography>
        <VSNTypography className={classes.text} mt={2}>
          Enhance interpersonal relationships, reduce friction, build trust,
          accelerate learning, and supercharge your team&apos;s performance.
        </VSNTypography>
        <Box className={classes.mainBox}>
          <VSNImage src={shape} alt="shape" className={classes.shape} />
          <VSNImage
            src={shape5}
            alt="shape"
            className={classes.purpleRainbow}
          />
          <VSNTypography
            color="#747787"
            fontSize="16px"
            fontWeight="500"
            mb={1.5}
          >
            Please enter your organization&apos;s ID to join your team already
            on the Vasana platform or to create a team.
          </VSNTypography>
          <VSNTypography className={classes.subHeading} mb={2}>
            ORGANIZATIONAL DETAILS
          </VSNTypography>
          <form>
            <VSNLabel
              label="Organization ID"
              sx={{ "&.MuiFormLabel-root": { display: "block" } }}
            />
            <ControlForm
              {...{
                control: "input",
                type: "text",
                name: "organization_id",
                placeholder: "Enter your Organization ID",
                sx: {
                  width: { md: "70%", sm: "80%", xs: "100%" },
                  "& .MuiInputBase-root": {
                    "& input.MuiInputBase-input": { fontSize: 14 },
                  },
                },
              }}
              onChange={handleChange}
            />
            <VSNErrorMessage
              {...{
                formError: !!formErrors?.organization_id,
                errorMessage: formErrors?.organization_id,
              }}
            />
            <VSNButton
              className={classes.btn}
              sx={{
                "&.MuiButtonBase-root": {
                  padding: "12px 26px",
                },
              }}
              handleClick={joinOrganization}
              isLoading={joinOrganizationLoader}
            >
              <JoinPersonIcon />
              <span>Join</span>
            </VSNButton>
          </form>
          <Box className={classes.contactBox}>
            <VSNTypography color="#747787" fontSize="16px" fontWeight="500">
              Is your organization not on Vasana yet?{" "}
              <span style={{ color: "#E87637", textDecoration: "underline" }}>
                Please contact the Vasana team
              </span>{" "}
              to join us and supercharge your team.
            </VSNTypography>
            <Box textAlign={{ sm: "left", xs: "center" }}>
              {matches ? (
                <VSNImage src={contactVasanaTeamRotate} alt="work-env" />
              ) : (
                <VSNImage src={contactVasanaTeam} alt="work-env" />
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }

  if (!isOrgAdmin && !department_id && organization_type_id === 1) {
    return (
      <Container maxWidth="xl">
        <VSNTypography variant="h2" className={classes.heading}>
          <span>Vasana</span> helps create working <span>environments</span>{" "}
          where relationships thrive
        </VSNTypography>
        <VSNTypography className={classes.text} mt={2}>
          Enhance interpersonal relationships, reduce friction, build trust,
          accelerate learning, and supercharge your team&apos;s performance.
        </VSNTypography>
        {/* <VSNTypography className={classes.text} mt={2}>
          Please Contact your organization admin
        </VSNTypography> */}
        <Box className={classes.contactBox} sx={{ textAlign: "center" }}>
          <VSNTypography color="#747787" fontSize="16px" fontWeight="500">
            Is your organization not Joined With any Department on Vasana yet?{" "}
            <br />
            Please contact the organization team to join us and supercharge your
            team.
          </VSNTypography>
          <Box textAlign={{ sm: "left", xs: "center" }}>
            {matches ? (
              <VSNImage src={contactVasanaTeamRotate} alt="work-env" />
            ) : (
              <VSNImage src={contactVasanaTeam} alt="work-env" />
            )}
          </Box>
        </Box>
      </Container>
    );
  }

  if (manageTeams && !toggleTeamView) {
    return (
      <Container maxWidth="xl">
        <Stack height="calc(100vh - 145px)">
          <VSNTypography variant="h2" className={classes.heading}>
            <span>Vasana</span> helps create working <span>environments</span>{" "}
            where relationships thrive
          </VSNTypography>
          <VSNTypography className={classes.text} mt={2}>
            Enhance interpersonal relationships, reduce friction, build trust,
            accelerate learning, and supercharge your team&apos;s performance.
          </VSNTypography>
          <Stack alignItems="center" justifyContent="center" flex={1}>
            <VSNImage src={noItem} />
            <VSNTypography
              variant="h4"
              fontSize={24}
              fontWeight={700}
              color="#747787"
              mt={3}
            >
              No Teams Found.
            </VSNTypography>
          </Stack>
        </Stack>
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth="xl">
        {((!modelState?.participants && loaders?.currentSelectedTeamMembers) ||
          loaders?.deleteTeam) && <VSNLoader variant="fullPage" />}
        {!toggleTeamView ? (
          <>
            {userRoleData?.user_profile?.organization_name ? (
              <VSNTypography
                variant="h2"
                className={classes.heading}
                sx={{ mb: 1 }}
              >
                Welcome to{" "}
                <span>{userRoleData?.user_profile?.organization_name}</span> on{" "}
                <span>Vasana</span>
              </VSNTypography>
            ) : null}
            <VSNTypography variant="h2" className={classes.heading}>
              <span>Vasana</span> helps create working <span>environments</span>{" "}
              where relationships thrive
            </VSNTypography>
            <VSNTypography className={classes.text} mt={2}>
              Enhance interpersonal relationships, reduce friction, build trust,
              accelerate learning, and supercharge your team&apos;s performance.
            </VSNTypography>
            <Box mt={4}>
              <Grid
                container
                justifyContent="center"
                spacing={{ sm: 4, xs: 3 }}
                mt={4}
              >
                <Grid item md={4} sm={6} xs={12}>
                  <div className={classes.card}>
                    <img src={team} alt="team" className="icon" />
                    <img
                      src={shape1}
                      alt="shape-1"
                      className={classes.shape1}
                    />
                    <img
                      src={shape2}
                      alt="shape-2"
                      className={classes.shape2}
                    />
                    <VSNButton
                      handleClick={() =>
                        dispatch(toggleModelShow("createTeam"))
                      }
                      className={classes.btn}
                    >
                      <UserIcon />
                      <span>Create a Team</span>
                    </VSNButton>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className={classes.card}>
                    <img src={teamMember} alt="team" className="icon" />
                    <img
                      src={shape3}
                      alt="shape-3"
                      className={classes.shape3}
                    />
                    <img
                      src={shape4}
                      alt="shape-4"
                      className={classes.shape4}
                    />
                    <VSNButton
                      type="submit"
                      handleClick={() =>
                        teamsToJoin?.length
                          ? navigate("/teams/join")
                          : dispatch(toggleModelShow("noTeamsToJoin"))
                      }
                      className={`${classes.btn} ${classes.btnBlue}`}
                    >
                      <MemberIcon />
                      <span>Join a Team</span>
                    </VSNButton>
                  </div>
                </Grid>
              </Grid>
            </Box>
            {modelState?.createTeam && (
              <VSNModel
                open={modelState?.createTeam}
                onClose={() => {
                  if (formData?.teamData?.step === 2) {
                    onSuccess();
                  }
                  dispatch(
                    toggleModelShow("createTeam"),
                    dispatch(clearFormData(formPath.parent))
                  );
                }}
                disableEnforceFocus
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                mobileDrawer
              >
                <CreateTeamModal
                  onSuccess={onSuccess}
                  open={modelState?.createTeam}
                />
              </VSNModel>
            )}
          </>
        ) : (
          <ViewTeams
            isJoinTeam={isJoinTeam}
            gridView={gridView}
            setGridView={setGridView}
            dispatch={dispatch}
            modelState={modelState}
            handleClick={handleClick}
            handleShareLink={handleShareLink}
            anchorEl={anchorEl}
            handleClose={handleClose}
            open={open}
            teams={teams}
            myTeams={teams}
            teamsToJoin={teamsToJoin}
            formPath={formPath}
            handleExitTeam={handleExitTeam}
            joinTeam={joinTeam}
            selectedTeamMembers={selectedTeamMembers}
            onClickParticipants={onClickParticipants}
            onSuccess={onSuccess}
            getTeamsList={getTeamsList}
            onEditTeam={onEditTeam}
            onExitTeamConfirm={onExitTeamConfirm}
            onExitTeamDecline={onExitTeamDecline}
            handleDeleteTeam={handleDeleteTeam}
            createTeamInfo={createTeamInfo}
            handleChange={handleTeamChange}
            loaders={loaders}
            editButtons={editButtons}
            isExitTeamAlertOpen={isExitTeamAlertOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            handleAddMembers={handleAddMembers}
            addMembersFields={addMembersFields}
            addMemberButtons={addMemberButtons}
            addMemberInfo={addMemberInfo}
            handleAddMemberChange={handleAddMemberChange}
            onInputChange={onInputChange}
            onEmailSelect={onEmailSelect}
          />
        )}
      </Container>
      {modelState?.noTeamsToJoin && (
        <VSNModel
          open={modelState?.noTeamsToJoin}
          onClose={() => dispatch(toggleModelShow("noTeamsToJoin"))}
          disableEnforceFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
        >
          <NoTeamToJoinModal teamsToJoin={teamsToJoin} myTeams={myTeams} />
        </VSNModel>
      )}
    </>
  );
};

export default MyTeams;
