import React from "react";
import ODLModel from "./ODLModel";

const AllModel = ({ allModelArray }) => {
  return (
    <>
      {allModelArray.map((item, index) => {
        const { modelState } = item;
        return modelState && <ODLModel {...item} key={index} />;
      })}
    </>
  );
};

export default AllModel;
