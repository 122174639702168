import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getInt } from "../utils/javascript";

const useStyles = makeStyles(() => ({
  progressMain: {
    width: 64,
    height: 64,
    position: "relative",
    display: "inline-flex",
    "& .MuiCircularProgress-root": {
      width: "64px !important",
      height: "64px !important",
      color: "currentcolor",
      "& .MuiCircularProgress-svg": {
        boxShadow: " 0px 0px 0px 4px rgb(244 246 253 / 100%) inset",
        borderRadius: "100%",

        "& .MuiCircularProgress-circle": {
          strokeWidth: "2px",
        },
      },
    },
    "& .MuiTypography-root": {
      fontSize: 14,
      fontWeight: 700,
      color: "#4F4F4F",
      lineHeight: "normal",
    },
  },
  numberRound: {
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    width: 48,
    height: 48,
    borderRadius: "100%",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    background: "#F4F6FD",
  },
}));

const CircularProgressWithLabel = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.progressMain}>
      <CircularProgress variant="determinate" {...props} />
      <Box className={classes.numberRound}>
        <Typography variant="caption" component="div" color="text.secondary">
          {`${getInt(JSON.parse(props.value), "round")}%`}
        </Typography>
      </Box>
    </Box>
  );
};
export default CircularProgressWithLabel;
