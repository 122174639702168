import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { ViewTeamsStyle } from "./viewTeamsStyle";
import noData from "../../assets/common/png/no-data.png";
import { ON_FORM_CHANGE } from "../../redux/constants";
import { clearFormData, toggleModelShow } from "../../shared/CustomFunc";
import VSNButton from "../../shared/VSNButton";
import VSNLoader from "../../shared/VSNLoader";
import VSNMembersAvatar from "../../shared/VSNMembersAvatar";
import VSNMembersAvatarGroup from "../../shared/VSNMembersAvatarGroup";
import VSNModel from "../../shared/VSNModel";
import VSNTypography from "../../shared/VSNTypography";
import {
  HamburgarIcon,
  LeftArrow,
  MemberIcon,
  ParticipationIcon,
  RightSingleIcon,
  SquareIcon,
  UserIcon,
} from "../../shared/icon";
import { getAvatarName } from "../../utils/javascript";
import EditTeamModal from "../orgAdminDashboard/EditTeamModal";
import CreateTeamModal from "./CreateTeamModal";
import ExitConfirmation from "./ExitConfirmation";
import ParticipantsModal from "./ParticipantsModal";
import TeamActions from "./TeamActions";
import VSNBreadcrumbs from "../../shared/VSNBreadcrumbs";
import VSNConfirmBox from "../../shared/VSNConfirmBox";
import AddMembersModal from "../orgAdminDashboard/AddMembersModal";
import { organization_location_admin } from "../../utils/constant";

const ViewTeams = ({
  isJoinTeam,
  gridView,
  setGridView,
  dispatch,
  modelState,
  handleShareLink,
  teams,
  myTeams,
  formPath,
  handleExitTeam,
  joinTeam,
  selectedTeamMembers,
  onClickParticipants,
  onSuccess,
  getTeamsList,
  onEditTeam,
  onExitTeamConfirm,
  onExitTeamDecline,
  teamsToJoin,
  handleDeleteTeam,
  createTeamInfo,
  handleChange,
  loaders,
  editButtons,
  isExitTeamAlertOpen,
  handleOk,
  handleCancel,
  handleAddMembers,
  addMembersFields,
  addMemberButtons,
  addMemberInfo,
  handleAddMemberChange,
  onInputChange,
  onEmailSelect,
}) => {
  const classes = ViewTeamsStyle();
  const navigate = useNavigate();
  const { sub: currentUserId, organization_name } = useSelector(
    (state) => state?.app?.userData?.user_profile
  );
  const { role, organization_type_id, permissions } = useSelector(
    (state) => state?.app?.userData?.user_profile
  );
  const { currentSelectedTeam } = useSelector((state) => state?.api);
  const {
    currentSelectedTeam: currentSelectedTeamLoader,
    defaultProfilePicturesLoader,
    exitTeamLoader,
    outsideMembersLoader,
  } = useSelector((state) => state?.api?.loader) || {};
  const { formData } = useSelector((state) => state?.form);
  const manageTeams = !(
    organization_location_admin?.includes(role) &&
    organization_type_id === 1 &&
    permissions?.teams?.includes("read_only")
  );

  const breadcrumbsRoutes = [
    {
      path: "/teams",
      label: "Teams",
    },
    {
      path: "/teams/join",
      label: "Join Teams",
    },
  ];

  return (
    <>
      {(currentSelectedTeamLoader || defaultProfilePicturesLoader) && (
        <VSNLoader variant="fullPage" />
      )}
      {isJoinTeam && <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />}
      <VSNTypography variant="h2" className={classes.heading}>
        {isJoinTeam ? (
          <>
            <span>{organization_name} - Teams</span> in your{" "}
            <span>organization</span> you can join
          </>
        ) : (
          <>
            <span>{organization_name} - Teams</span> you are a part of
          </>
        )}
      </VSNTypography>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        className={classes.buttonRow}
      >
        <Box className={classes.tabWrapper}>
          <Box
            className={`tabOut ${gridView && "active"}`}
            onClick={() => setGridView(true)}
          >
            <SquareIcon />
          </Box>
          <Box
            className={`tabOut ${gridView || "active"}`}
            onClick={() => setGridView(false)}
          >
            <HamburgarIcon />
          </Box>
        </Box>
        <Stack
          direction="row"
          ml="auto"
          spacing={{ xs: 1, sm: 2 }}
          justifyContent="end"
          display={manageTeams ? "flex" : "none"}
        >
          <VSNButton
            handleClick={() => {
              dispatch({
                type: ON_FORM_CHANGE,
                payload: {
                  formData: {
                    ...formData,
                    teamData: null,
                  },
                },
              });
              dispatch(toggleModelShow("createTeam"));
            }}
            className={classes.btn}
            sx={{ width: { xs: "auto" } }}
          >
            <UserIcon />
            <span>Create a Team</span>
          </VSNButton>
          {isJoinTeam ? (
            <VSNButton
              type="button"
              handleClick={() => navigate("/teams")}
              className={`${classes.btn} ${classes.btnBlue}`}
            >
              <LeftArrow />
              <span>My Teams</span>
            </VSNButton>
          ) : (
            <VSNButton
              type="submit"
              handleClick={() =>
                teamsToJoin?.length
                  ? navigate("/teams/join")
                  : dispatch(toggleModelShow("noTeamsToJoin"))
              }
              className={`${classes.btn} ${classes.btnBlue}`}
            >
              <MemberIcon />
              <span>Join a Team</span>
            </VSNButton>
          )}
        </Stack>
      </Stack>
      {myTeams?.length <= 0 && isJoinTeam ? (
        <Box className={classes.noDataBox}>
          <Box>
            <img src={noData} alt="noData" />
            <VSNTypography variant="h6">
              You are already a member of every team within the organization.
            </VSNTypography>
            <VSNTypography>Create your own team</VSNTypography>
          </Box>
        </Box>
      ) : gridView ? (
        <Grid container rowSpacing={3} columnSpacing={{ sm: 3 }}>
          {myTeams?.map(
            (
              {
                id,
                name,
                goal,
                invitation_link,
                profile_picture,
                members,
                team_id,
                ...rest
              },
              index
            ) => {
              const admin = rest?.admins;
              const adminMembers = members.filter((member) => {
                return (
                  rest?.admins?.filter((val) => val?.user_id === member.user_id)
                    .length > 0
                );
              });
              const nonAdminMembers = members.filter((member) => {
                return (
                  rest?.admins?.filter((val) => val?.user_id !== member.user_id)
                    .length > 0
                );
              });
              const sortedMembers = [...adminMembers, ...nonAdminMembers];
              return (
                <Grid
                  item
                  lg={gridView ? 6 : 12}
                  xs={12}
                  key={`${index}-${id}`}
                >
                  <Box
                    onClick={() => !isJoinTeam && navigate(`/teams/${id}`)}
                    className={`${classes.helpCard} ${
                      !isJoinTeam ? classes.myTeamCard : ""
                    }`}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={5.5}>
                        <Box key={id} className={`${classes.imgWrapper}`}>
                          {!isJoinTeam && manageTeams && (
                            <TeamActions
                              id={id}
                              moduleName="Team"
                              canExitTeam
                              canDeleteTeam={rest?.admins?.some(
                                (admin) => admin?.user_id === currentUserId
                              )}
                              handleShareLink={() =>
                                handleShareLink(invitation_link || "")
                              }
                              handleExitTeam={handleExitTeam}
                              canEditTeam={rest?.admins?.some(
                                (admin) => admin?.user_id === currentUserId
                              )}
                              handleEditTeam={() => onEditTeam({ id })}
                              handleDeleteTeam={handleDeleteTeam}
                              {...rest}
                            />
                          )}
                          <img src={profile_picture} alt="Team" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6.5}>
                        <VSNTypography
                          variant="h3"
                          className={classes.mdHeading}
                        >
                          {name}
                        </VSNTypography>
                        {!!goal && (
                          <VSNTypography className={classes.desc}>
                            {goal}
                          </VSNTypography>
                        )}
                        <Divider
                          sx={{
                            margin: { sm: "14px 0", xs: "10px 0" },
                            borderColor: "#A298CD1A",
                          }}
                        />
                        <Stack direction="row" alignItems="center" pb={1.5}>
                          <ParticipationIcon />
                          <VSNTypography
                            variant="h4"
                            className={classes.smHeading}
                            sx={{ paddingLeft: 0.8 }}
                          >
                            Members
                          </VSNTypography>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          flexWrap="wrap"
                          gap={1}
                        >
                          <VSNMembersAvatarGroup
                            max={5}
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickParticipants({
                                id,
                                name,
                                goal,
                                invitation_link,
                                sortedMembers,
                                team_id,
                                ...rest,
                              });
                            }}
                            variant="circular"
                          >
                            {admin?.map((member, index) => (
                              <Box
                                key={`${member?.user_id}-${index}`}
                                className={`${classes.admin}`}
                              >
                                <VSNMembersAvatar
                                  alt={member?.first_name?.toUpperCase()}
                                  vasanaType={member?.primary_marker}
                                  title={getAvatarName(member)}
                                />
                              </Box>
                            ))}
                            {members?.map((member, index) => (
                              <Box key={`${member?.user_id}-${index}`}>
                                <VSNMembersAvatar
                                  alt={member?.first_name?.toUpperCase()}
                                  vasanaType={member?.primary_marker}
                                  title={getAvatarName(member)}
                                />
                              </Box>
                            ))}
                          </VSNMembersAvatarGroup>
                          {isJoinTeam && (
                            <VSNButton
                              variant="outlined"
                              className={classes.btnJoin}
                              handleClick={() => joinTeam(team_id)}
                            >
                              Join Team &nbsp;
                              <RightSingleIcon />
                            </VSNButton>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            }
          )}
        </Grid>
      ) : (
        <>
          {/* list view  */}
          {myTeams?.map(
            (
              {
                id,
                name,
                goal,
                invitation_link,
                profile_picture,
                members,
                team_id,
                ...rest
              },
              index
            ) => {
              const admin = rest?.admins;
              const adminMembers = members.filter((member) =>
                rest?.admins?.includes(member.user_id)
              );
              const nonAdminMembers = members.filter(
                (member) => !rest?.admins?.includes(member.user_id)
              );
              const sortedMembers = [...adminMembers, ...nonAdminMembers];
              return (
                <Box
                  key={`${id}-${index}`}
                  className={classes.helpCard}
                  mb={2}
                  onClick={() => !isJoinTeam && navigate(`/teams/${id}`)}
                >
                  <Grid
                    container
                    columnSpacing={{ sm: 3, xs: 1 }}
                    alignItems="center"
                  >
                    <Grid item lg={3} md={3} xs={6}>
                      <Box
                        className={`${classes.imgWrapper} ${classes.height}`}
                      >
                        <img
                          src={profile_picture}
                          alt="Team"
                          style={{ objectFit: "contain" }}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={5} md={4} xs={6}>
                      <VSNTypography variant="h3" className={classes.mdHeading}>
                        {name}
                      </VSNTypography>
                      {!!goal && (
                        <VSNTypography className={classes.desc}>
                          {goal}
                        </VSNTypography>
                      )}
                    </Grid>
                    <Grid item lg={4} md={5} xs={12}>
                      <Divider
                        sx={{
                          display: { md: "none", xs: "block" },
                          margin: "12px 0",
                          borderColor: "#A298CD1A",
                        }}
                      />
                      <Stack
                        direction="row"
                        position="relative"
                        alignItems={{ sm: "center", xs: "flex-end" }}
                        justifyContent="space-between"
                      >
                        <Box>
                          <Stack direction="row" alignItems="center" pb={1.5}>
                            <ParticipationIcon />
                            <VSNTypography
                              variant="h4"
                              className={classes.smHeading}
                              sx={{ paddingLeft: 0.8 }}
                            >
                              Members
                            </VSNTypography>
                          </Stack>
                          <VSNMembersAvatarGroup
                            max={5}
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickParticipants({
                                id,
                                name,
                                goal,
                                invitation_link,
                                sortedMembers,
                                team_id,
                                ...rest,
                              });
                            }}
                          >
                            {admin?.map((member, index) => (
                              <Box
                                key={`${member?.user_id}-${index}`}
                                className={`${classes.admin}`}
                              >
                                <VSNMembersAvatar
                                  alt={member?.first_name?.toUpperCase()}
                                  vasanaType={member?.primary_marker}
                                  title={getAvatarName(member)}
                                />
                              </Box>
                            ))}
                            {members?.map((member, index) => (
                              <Box key={`${member?.user_id}-${index}`}>
                                <VSNMembersAvatar
                                  alt={member?.first_name?.toUpperCase()}
                                  vasanaType={member?.primary_marker}
                                  title={getAvatarName(member)}
                                />
                              </Box>
                            ))}
                          </VSNMembersAvatarGroup>
                        </Box>
                        {!isJoinTeam && (
                          <TeamActions
                            id={id}
                            moduleName="Team"
                            canExitTeam
                            canDeleteTeam={rest?.admins?.some(
                              (admin) => admin?.user_id === currentUserId
                            )}
                            handleShareLink={() =>
                              handleShareLink(invitation_link || "")
                            }
                            handleExitTeam={handleExitTeam}
                            isListView
                            canEditTeam={rest?.admins?.some(
                              (admin) => admin?.user_id === currentUserId
                            )}
                            handleEditTeam={() => onEditTeam({ id })}
                            handleDeleteTeam={handleDeleteTeam}
                            {...rest}
                          />
                        )}
                        {isJoinTeam && (
                          <VSNButton
                            variant="outlined"
                            className={classes.btnJoin}
                            handleClick={() => joinTeam(team_id)}
                          >
                            Join Team &nbsp;
                            <RightSingleIcon />
                          </VSNButton>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              );
            }
          )}
        </>
      )}
      {modelState?.participants && (
        <VSNModel
          disableEnforceFocus
          open={modelState?.participants}
          onClose={() => dispatch(toggleModelShow("participants"))}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title="Members"
        >
          <ParticipantsModal
            currentSelectedTeamMembers={selectedTeamMembers}
            currentSelectedTeam={currentSelectedTeam}
            getReports={getTeamsList}
            showAdminIcon
            isTeamView
            hideAddMember={
              !currentSelectedTeam?.admins?.some(
                (admin) => admin?.user_id === currentUserId
              )
            }
            admins={currentSelectedTeam?.admins || []}
            canDelete={currentSelectedTeam?.admins?.some(
              (admin) => admin?.user_id === currentUserId
            )}
            handleAddMembers={handleAddMembers}
          />
        </VSNModel>
      )}
      {modelState?.editTeam && (
        <VSNModel
          disableEnforceFocus
          open={modelState?.editTeam}
          onClose={() => {
            dispatch(toggleModelShow("editTeam"));
            dispatch(clearFormData("teamData"));
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
          title="Edit Team Details"
        >
          <EditTeamModal
            {...{
              formPath,
              createTeamInfo,
              handleChange,
              loaders,
              editButtons,
              onSuccess: getTeamsList,
            }}
          />
        </VSNModel>
      )}
      <Stack direction="row" spacing={2} justifyContent="end">
        {modelState?.createTeam && (
          <VSNModel
            open={modelState?.createTeam}
            onClose={() => {
              if (formData?.teamData?.step === 2) {
                onSuccess();
              }
              dispatch(
                toggleModelShow("createTeam"),
                dispatch(clearFormData(formPath.parent))
              );
            }}
            disableEnforceFocus
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            mobileDrawer
          >
            <CreateTeamModal
              onSuccess={onSuccess}
              open={modelState?.createTeam}
            />
          </VSNModel>
        )}
      </Stack>
      {modelState?.exitTeam && (
        <VSNModel
          disableEnforceFocus
          open={modelState?.exitTeam}
          onClose={() => {
            dispatch(toggleModelShow("exitTeam"));
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          mobileDrawer
        >
          <ExitConfirmation
            onLeave={onExitTeamConfirm}
            onStay={onExitTeamDecline}
            exitTeamLoader={exitTeamLoader}
          />
        </VSNModel>
      )}
      {modelState?.addMembers && (
        <VSNModel
          disableEnforceFocus
          open={modelState?.addMembers}
          onClose={() => {
            dispatch(clearFormData("addMemberData"));
            dispatch(toggleModelShow("addMembers", true));
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          title={currentSelectedTeam?.name || ""}
          mobileDrawer
        >
          <AddMembersModal
            {...currentSelectedTeam}
            handleChange={handleAddMemberChange}
            addMemberFormPath={{ parent: "addMemberData" }}
            addMemberInfo={addMemberInfo}
            buttons={addMemberButtons}
            onInputChange={onInputChange}
            onEmailSelect={onEmailSelect}
            addMembersFields={addMembersFields}
            loaders={{ addeddMembers: outsideMembersLoader }}
          />
        </VSNModel>
      )}
      <VSNConfirmBox
        isOpen={isExitTeamAlertOpen}
        title="Are You Sure?"
        msg="You cannot leave the team. Choose an admin before leaving."
        handleOk={handleOk}
        handleCancel={handleCancel}
        okBtnName="Manage members"
        cancelBtnName="Cancel"
        className={classes.conformModel}
        isLoading={loaders?.currentSelectedTeamMembers}
      />
    </>
  );
};

export default ViewTeams;
