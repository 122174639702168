import React from "react";
import userInfoContainer from "../../container/userInfo.container";
import UserInfoContainer from "./UserInfoContainer";

const UserInfo = () => {
  const {
    formAttribute,
    handleSubmit,
    formPath,
    handleChange,
    handleTermsCheck,
    handleDateChange,
    termsCheck,
    userAPIInfo,
    userInfoData,
    isOnBoarding,
    handleMobileChange,
    ...rest
  } = userInfoContainer();
  return (
    <UserInfoContainer
      {...{
        formAttribute,
        handleSubmit,
        formPath,
        handleChange,
        handleTermsCheck,
        handleDateChange,
        termsCheck,
        userAPIInfo,
        userInfoData,
        isOnBoarding,
        handleMobileChange,
        ...rest,
      }}
    />
  );
};

export default UserInfo;
