import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OtherGenderField,
  userFormAttribute,
} from "../description/userInfo.description";
import { ON_FORM_CHANGE, SET_ERROR, SET_USER_DATA } from "../redux/constants";
import {
  checkValid,
  emailValidation,
  mobileValidation,
} from "../shared/validation";
import { ApiContainer } from "../utils/api";
import {
  assessmentIds,
  assessmentNames,
  assessmentTypes,
  endPoints,
} from "../utils/constant";
import { equal, length, trimmedVal, upperCase } from "../utils/javascript";
import { getAssessmentState, setErrors } from "../shared/CustomFunc";
import ApiCalls from "../utils/apiCall";

const userInfoContainer = () => {
  const { api } = ApiContainer();
  const location = useLocation();
  const { getRoleInfo } = ApiCalls();
  const btnNames = {
    save: location.state?.onBoarding ? "Next" : "Save",
    cancel: location.state?.onBoarding ? "" : "Cancel",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formPath = { parent: "userInfo" };
  const { form } = useSelector((state) => state);

  const userInfoData = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const [termsCheck, setTermsCheck] = useState(false);
  const [otherGenderAdded, setOtherGenderAdded] = useState(true);

  const userInfoError = useSelector(
    (state) => state.form.formErrors?.[formPath.parent]
  );
  const signUpData = useSelector((state) => state.form.formData?.signUp);

  const profileLoader = useSelector(
    (state) => state.api?.loader?.[formPath.parent]
  );
  const userRoleData = useSelector((state) => state.app?.userData);
  const userAPIInfo = userRoleData?.user_profile;
  const vasanaTypeAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.vasanaType,
  });
  const handleNavigate = () => {
    if (vasanaTypeAssessment?.result) navigate("/");
    else {
      localStorage.setItem("currentAssessmentId", assessmentIds?.vasanaType);
      navigate(`/get-started/${assessmentNames[assessmentIds?.vasanaType]}`);
    }
  };

  const getChangedFields = (originalData, newData) => {
    const changedFields = {};
    Object?.keys(newData)?.forEach((key) => {
      if (originalData[key] !== newData[key]) {
        changedFields[key] = newData[key];
      }
    });
    return changedFields;
  };

  const apiCall = async () => {
    const {
      given_name: firstName,
      family_name: lastName,
      gender,
      organization_name: organizationName,
      domain,
      designation,
      organization_id: organizationID,
      phone_number: mobileNumber,
      other_gender,
    } = userInfoData;

    const changedData = getChangedFields(userAPIInfo, userInfoData);

    const payload = {
      ...(changedData.given_name !== undefined && {
        first_name: trimmedVal(firstName),
      }),
      ...(changedData.family_name !== undefined && {
        last_name: trimmedVal(lastName),
      }),
      ...(changedData.gender !== undefined && { gender }),
      ...(changedData.organization_name !== undefined && {
        organization_name: organizationName,
      }),
      ...(changedData.domain !== undefined && { domain }),
      ...(changedData.designation !== undefined && { designation }),
      ...(changedData.organization_id !== undefined && {
        organization_id: upperCase(trimmedVal(organizationID?.toString())),
      }),
      ...(changedData.phone_number !== undefined &&
        mobileValidation(mobileNumber) && { phone_number: mobileNumber }),
      ...(changedData.email !== undefined &&
        emailValidation(signUpData?.username) && {
          email: signUpData?.username,
        }),
      ...(gender === "Other" &&
        changedData.other_gender !== undefined && { other_gender }),
    };

    if (Object?.keys(payload)?.length === 0) {
      return;
    }

    const data = await api({
      method: "PUT",
      endPoint: endPoints?.editProfile,
      data: payload,
      showToastMessage: true,
      urlencoded: false,
      needLoader: true,
      parent: formPath.parent,
    });

    if (data?.status) {
      handleNavigate();
      dispatch(getRoleInfo({ needLoader: true, parent: "getRoleInfo" }));
      dispatch({
        type: SET_USER_DATA,
        payload: { profile_updated: true, ...data?.data },
      });
    }
  };

  useEffect(() => {
    if (!location?.state?.onBoarding && userRoleData) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            [formPath.parent]: userAPIInfo,
          },
        },
      });
    } else if (
      location?.state?.onBoarding &&
      userRoleData?.user_profile?.organization_name === "Guest"
    ) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [formPath?.parent]: {
              ...userInfoData,
              organization_id: "",
            },
          },
        },
      });
    }
  }, [location?.state?.onBoarding, userAPIInfo]);
  const handleTermsCheck = () => {
    setTermsCheck((prev) => !prev);

    if (!termsCheck) {
      dispatch({
        type: SET_ERROR,
        payload: {
          formErrors: {
            ...form.formErrors,
            [formPath.parent]: { ...userInfoError, checkBox: termsCheck },
          },
        },
      });
    }
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...userInfoData,
            checkBox: !termsCheck,
          },
        },
      },
    });
  };
  useEffect(() => {
    const otherGenderFieldExists = userFormAttribute[0].value.some(
      (field) => field.name === "other_gender"
    );

    if (userInfoData?.gender === "Other" && !otherGenderFieldExists) {
      userFormAttribute[0].value.push(OtherGenderField);
      setOtherGenderAdded(true);
    } else if (userInfoData?.gender !== "Other" && otherGenderAdded) {
      userFormAttribute[0].value = userFormAttribute[0].value.filter(
        (field) => field.name !== "other_gender"
      );
      setOtherGenderAdded(false);
    }
  }, [userInfoData, otherGenderAdded]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const cursorPosition = e?.target?.selectionStart;
    let errors = userInfoError;
    if (value) {
      errors = { ...errors, [name]: "" };
      dispatch({
        type: SET_ERROR,
        payload: {
          formErrors: {
            ...form.formErrors,
            [formPath.parent]: { ...errors },
          },
        },
      });
    }

    dispatch({
      type: ON_FORM_CHANGE,

      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...userInfoData,
            [name]: equal(name, "organization_id") ? upperCase(value) : value,
          },
        },
      },
    });
    if (equal(name, "organization_id")) {
      requestAnimationFrame(() => {
        e?.target?.setSelectionRange(cursorPosition, cursorPosition);
      });
    }
  };
  const handleDateChange = (value, name) => {
    let errors = userInfoError;
    if (value) {
      errors = { ...errors, [name]: "" };
      dispatch({
        type: SET_ERROR,
        payload: {
          formErrors: {
            ...form.formErrors,
            [formPath.parent]: { ...errors },
          },
        },
      });
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [formPath.parent]: {
              ...userInfoData,
              [name]: value,
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    dispatch(setErrors({ isClear: true, formPath }));
  }, []);

  useEffect(() => {
    if (userInfoData?.checkBox) {
      setTermsCheck(userInfoData?.checkBox);
    }
  }, [userInfoData]);

  const handleSubmit = () => {
    let errors = {};
    const passwordValue = userInfoData?.password;
    userFormAttribute?.forEach((item) => {
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, getValBy, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                name,
                value:
                  userInfoData?.[name] !== undefined
                    ? userInfoData?.[name]
                    : userInfoData?.[getValBy] || userInfoData?.[name],
                passwordValue,
                label,
              })
            : false,
          checkBox: location?.state?.onBoarding ? !termsCheck : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...form.formErrors,
              [formPath.parent]: { ...errors },
            },
          },
        });
      });
    });
    if (
      errors === undefined ||
      equal(length(Object.values(errors)?.filter(Boolean)))
    ) {
      const noChanges = equal(
        JSON.stringify(userInfoData),
        JSON.stringify(userAPIInfo)
      );
      if (!noChanges) {
        apiCall();
      }
    }
  };

  const handleMobileChange = (phone, name, countryCode) => {
    dispatch(
      setErrors({ errors: userInfoError, value: phone, name, formPath })
    );

    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...userInfoData,
            [name]: `+${phone}`,
          },
        },
      },
    });
  };
  return {
    handleSubmit,
    handleChange,
    formPath,
    handleTermsCheck,
    handleDateChange,
    termsCheck,
    btnNames,
    profileLoader,
    handleNavigate,
    userAPIInfo,
    userInfoData,
    isOnBoarding: location.state?.onBoarding,
    handleMobileChange,
  };
};

export default userInfoContainer;
