import React from "react";
import { Box, Grid, List, ListItem, Stack } from "@mui/material";
import { SwiperSlide } from "swiper/react";
import { PhysicalOnePagerResultStyle } from "./PhysicalOnePagerResultStyle";
import VSNTypography from "../../../shared/VSNTypography";
import physical1 from "../../../assets/assesments/physical/physical/physical-1.svg";
import physical2 from "../../../assets/assesments/physical/physical/physical-2.svg";
import physical3 from "../../../assets/assesments/physical/physical/physical-3.svg";
import physical4 from "../../../assets/assesments/physical/physical/physical-4.svg";
import Heent from "../../../assets/assesments/physical/physical/heent.svg";
import Neck from "../../../assets/assesments/physical/physical/neck.svg";
import Cardiac from "../../../assets/assesments/physical/physical/cardiac.svg";
import Lungs from "../../../assets/assesments/physical/physical/lungs.svg";
import Abdomen from "../../../assets/assesments/physical/physical/abdomen.svg";
import Back from "../../../assets/assesments/physical/physical/back.svg";
import Extremities from "../../../assets/assesments/physical/physical/extremities.svg";
import Genitourinary from "../../../assets/assesments/physical/physical/genitourinary.svg";
import Neurological from "../../../assets/assesments/physical/physical/neurological.svg";
import Skin from "../../../assets/assesments/physical/physical/skin.svg";
import VSNImage from "../../../shared/VSNImage";
import VSNLoader from "../../../shared/VSNLoader";
import VSNBreadcrumbs from "../../../shared/VSNBreadcrumbs";
import onePagerResultContainer from "../../../container/onePager.container";
import SwiperCardComponent from "../../assessment/SwiperCardComponent";
import humanBody from "../../../assets/assesments/physical/physical/humanBody.png";
import { VSNSwiper } from "../../../shared/VSNSwiper";
import { BulletIcon, PurpalFlowerBullet } from "../../../shared/icon";

const arrayOfMockData = [
  {
    icon: Heent,
    header: "HEENT (Head, Eyes, Ears, Nose and Neck)",
    description:
      "PERRL, EOMI. External auditory canals and tympanic membranes clear. Oral cavity and pharynx normal. No suspicious lesions. Teeth and gingiva in good general condition.",
  },
  {
    icon: Neck,
    header: "Neck",
    description: "Neck supple, no lymphadenopathy, masses or thyromegaly",
  },
  {
    icon: Skin,
    header: "Skin",
    description:
      "Normal color. No concerning/suspicious lesions. No surgical scars present.",
  },
  {
    icon: Neurological,
    header: "Neurological",
    description:
      "CN II-XII intact. Strength and sensation intact and symmetric throughout. Reflexes 2+ throughout and symmetric. Cerebellar testingnormal.",
  },
  {
    icon: Back,
    header: "Back",
    description:
      "Full ROM. There is no spinal deformity, tenderness, or muscular spasm.",
  },
  {
    icon: Cardiac,
    header: "Cardiac",
    description: "Normal heart sounds. Rhythm is regular. No murmurs.Lungs",
  },
  {
    icon: Abdomen,
    header: "Abdomen",
    description:
      "Normal bowel sounds. Softand nontender. No guarding or rebound. No masses",
  },
  {
    icon: Lungs,
    header: "Lungs",
    description:
      "Clear to auscultation without rales, rhonchi, wheezing. No diminished breath sounds.",
  },
  {
    icon: Genitourinary,
    header: "Genitourinary (GU)",
    description: "",
  },
  {
    icon: Extremities,
    header: "Extremities",
    description:
      "Normal gait. No significant deformity or joint abnormality. No edema. No varicosities.Full ROM of the upper and lower extremities.",
  },
];

const physical_faqs = [
  {
    icon: "https://vasana-static.s3.amazonaws.com/images/insomnia/icon-2.svg",
    title: "What is a physical examination and why is it important?",
    description:
      "A physical examination is a routine check-up to assess overall health, detect early signs of illness, and track conditions. It helps detect potential health issues early, helping prevent conditions from becoming serious and supporting long-term health planning.",
  },
  {
    icon: "https://vasana-static.s3.amazonaws.com/images/insomnia/icon-2.svg",
    title: "How often should I have a physical examination?",
    description:
      " For public safety professionals, annual physical exams are recommended due to the physical demands and risks of the job. Depending on individual health risks, additional screenings or more frequent check-ups may be advised. Always consult department guidelines or your healthcare provider for specific recommendations.",
  },
];

const swiperData = [
  {
    image: physical1,
    title: "Practice Cardiovascular Health",
    description:
      "Engage in regular aerobic activities like walking, jogging, or swimming to keep your heart healthy, maintain a regular rhythm, and prevent cardiovascular diseases.",
  },
  {
    image: physical2,
    title: "Maintain Good Posture and Back Health",
    description:
      "Regular stretching, strength exercises, and good posture practices can help prevent back pain and maintain a full range of motion (ROM) without deformities or spasms.",
  },
  {
    image: physical3,
    title: "Promote Joint and Muscle Health",
    description:
      "Incorporate yoga, stretching, and strength training to maintain flexibility, a full range of motion (ROM) in your extremities and prevent joint abnormalities or muscle stiffness.",
  },
  {
    image: physical4,
    title: "Support Neurological Health",
    description:
      "Engage in brain-stimulating activities like puzzles or reading, and stay active to support cognitive function and improve brain health.",
  },
];

const keyInsights = [
  { title: "Endocrinology", color: "#8B3F9E", backGround: "#F2E7F5" },
  { title: "Orthopedics", color: "#FFB142", backGround: "#FFF7EC" },
  { title: "Pain Management", color: "#1780C6", backGround: "#E3F1F9" },
  { title: "Pulmonology", color: "#2D8F4E", backGround: "#F0F9F2" },
];

export default function PhysicalExaminationResult({
  endPointToCall,
  showBreadCrumbs = true,
  breadCrumbAndButton,
  releaseButton,
}) {
  const classes = PhysicalOnePagerResultStyle();
  const { onePagerAssessmentResult, loader, currentAssessment } =
    onePagerResultContainer({ endPointToCall });

  const breadcrumbsRoutes = [
    {
      path: "/assessments",
      label: "Assessments",
    },
    {
      path: `/assessments/${currentAssessment?.assessment_id}`,
      label: `Physical Examination and Provider Plan`,
    },
  ];

  // if (loader?.assessmentResult || !onePagerAssessmentResult) {
  //   return <VSNLoader variant="fullPage" />;
  // }

  return (
    <div className={classes.mainLayout}>
      {showBreadCrumbs ? (
        <Box mt={2.5} mb={1.8}>
          <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />
        </Box>
      ) : (
        <Box className={classes.headingButton}>
          <Box>{breadCrumbAndButton}</Box>
          <Box>{onePagerAssessmentResult?.is_locked ? releaseButton : ""}</Box>
        </Box>
      )}

      <VSNTypography
        fontSize={{ xs: 24, sm: 34 }}
        sx={{ fontWeight: 700 }}
        mb={1}
      >
        Your Health Overview: Physical Exam Results and Next Steps
      </VSNTypography>

      <Box sx={{ marginBottom: "24px" }}>
        <Stack className={classes.assessmentReportCard}>
          <Box sx={{ display: "block" }}>
            <VSNTypography
              className={classes.mdHeadingMain}
              dangerouslySetInnerHTML={{
                __html: "Your Physical Examination",
              }}
            />
          </Box>

          <Stack direction={{ md: "row" }} mt={1} spacing={2}>
            <Box flex={1}>
              <VSNTypography component="p" className={classes.desc}>
                This report summarizes your general physical examination
                conducted on MM/DD/YYYY. <br />
                It outlines the findings from your checkup and provides a
                personalized health plan based on your discussion with the
                clinician.
              </VSNTypography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Grid
        container
        spacing={3}
        mb={3}
        sx={{
          flexDirection: { sm: "row", xs: "column-reverse" },
        }}
      >
        <Grid item xl={6} lg={6} xs={12}>
          <Stack spacing={3}>
            <Box>
              <Stack className={classes.assessmentReportCard}>
                <VSNTypography
                  variant="h3"
                  component="h3"
                  className={classes.mdHeadingprimary}
                  mb={1}
                >
                  {`Physician's Findings and Next Steps for Your Health`}
                </VSNTypography>

                <Box py={1} display="flex">
                  <Box sx={{ height: 12, width: 12 }} pr={3}>
                    <BulletIcon />
                  </Box>
                  <VSNTypography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#3A3A40",
                      lineHeight: "24px",
                    }}
                  >
                    Healthy adult male without any significant physical exam
                    findings. We discussed his body composition, audiogram,
                    vision,laboratory results and cardiopulmonary exercise
                    testing at length. On laboratory analysis he was noted to
                    have elevated LDLcholesterol, as well as Apolipoprotein B
                    with low lipoprotein (a). In the general population initial
                    therapy to consider would be ezetimibe 10 mg once daily in
                    combination with Crestor 5 mg once daily. We also discussed
                    zone two aerobic training at length to include heart rate
                    zones, heart rate monitors and the potential benefits in
                    both body composition and longevity. He indicated that he
                    would like to stay away from medications at this time, so we
                    doubled down Zone 2 training and maintaining his diet.
                  </VSNTypography>
                </Box>

                <VSNTypography
                  variant="h3"
                  component="h3"
                  sx={{ fontSize: 16, fontWeight: 700 }}
                  my={1}
                  mt={3}
                  color="#424450"
                >
                  Recommended Referrals
                </VSNTypography>

                <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={2}>
                  {keyInsights?.map((el, index) => (
                    <Box
                      className={classes.notesListTab}
                      sx={{
                        width: "fit-content",
                        backgroundColor: `${el?.backGround}`,
                        border: `1px solid ${el?.color}`,
                      }}
                    >
                      <VSNTypography component="p" color={el?.color}>
                        {el?.title}
                      </VSNTypography>
                    </Box>
                  ))}
                </Box>
              </Stack>
            </Box>
            <Box>
              <Stack className={classes.assessmentReportCardWhite}>
                <VSNTypography
                  variant="h3"
                  component="h3"
                  className={classes.mdHeading}
                  mb={1}
                >
                  Physical Examination: FAQS
                </VSNTypography>
                <VSNTypography component="p" className={classes.desc}>
                  {`Learn why you underwent a complete physical examination and why
                it’s essential for monitoring your health.`}
                </VSNTypography>
                <List className={classes.dailyFunctioning}>
                  {physical_faqs?.map((el, index) => (
                    <ListItem key={index}>
                      <Box mt={0.5}>
                        <PurpalFlowerBullet />
                      </Box>
                      <VSNTypography color="secondary.main">
                        <span style={{ color: "#86650A", fontWeight: 500 }}>
                          {el?.title ?? ""}{" "}
                        </span>
                        <br />
                        {el?.description}
                      </VSNTypography>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Grid item xl={6} lg={6} xs={12}>
          <Stack
            direction={{ sm: "column", md: "row" }}
            justifyContent={{ md: "center", sm: "space-between" }}
            className={classes.assessmentReportCard}
          >
            <Stack flex={1} sx={{ display: { md: "block", xs: "none" } }}>
              <Box className={classes.diagnosticInputs}>
                <List>
                  {arrayOfMockData?.map(
                    ({ icon, header, description }, index) => {
                      if (index % 2 === 0) {
                        return (
                          <ListItem key={index}>
                            <VSNTypography textAlign="right">
                              {header}
                              <br />
                              <span>{description}</span>
                            </VSNTypography>
                            <Stack>
                              <Box
                                sx={{
                                  width: {
                                    xlg: "80px",
                                    lg: "60px",
                                    md: "80px",
                                  },
                                  height: {
                                    xlg: "80px",
                                    lg: "60px",
                                    md: "80px",
                                  },
                                  border: "2px solid #8B3F9E",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <VSNImage src={icon} alt="icon" />
                              </Box>
                            </Stack>
                          </ListItem>
                        );
                      }
                      return null;
                    }
                  )}
                </List>
              </Box>
            </Stack>

            <Stack>
              <Stack height="100%" justifyContent="center">
                <Box display="flex" justifyContent="center">
                  <VSNImage src={humanBody} alt="shape" />
                </Box>
              </Stack>
            </Stack>

            <Stack flex={1} sx={{ display: { md: "block", xs: "none" } }}>
              <Box className={classes.diagnosticInputs}>
                <List>
                  {arrayOfMockData?.map(
                    ({ icon, header, description }, index) => {
                      if (index % 2 !== 0) {
                        return (
                          <ListItem key={index}>
                            <Stack>
                              <Box
                                sx={{
                                  width: {
                                    xlg: "80px",
                                    lg: "60px",
                                    md: "80px",
                                  },
                                  height: {
                                    xlg: "80px",
                                    lg: "60px",
                                    md: "80px",
                                  },
                                  border: "2px solid #8B3F9E",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <VSNImage src={icon} alt="icon" />
                              </Box>
                            </Stack>
                            <VSNTypography textAlign="left">
                              {header}
                              <br />
                              <span>{description}</span>
                            </VSNTypography>
                          </ListItem>
                        );
                      }
                      return null;
                    }
                  )}
                </List>
              </Box>
            </Stack>

            <Box
              sx={{ padding: "20px", display: { md: "none", xs: "block" } }}
              position="relative"
              mt={2}
            >
              <Stack
                direction="row"
                spacing={1.4}
                className={classes.customArrow}
              >
                <div className="swiper-button image-swiper-button-next" />
                <div className="swiper-button image-swiper-button-prev" />
              </Stack>
              <VSNSwiper
                className={classes.containerCard}
                navigation={{
                  prevEl: ".image-swiper-button-next",
                  nextEl: ".image-swiper-button-prev",
                  disabledClass: "swiper-button-disabled",
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                }}
              >
                {arrayOfMockData?.map(
                  ({ icon, header, description }, index) => (
                    <SwiperSlide key={index}>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <Stack>
                          <Box
                            sx={{
                              width: "80px",
                              height: "80px",
                              border: "2px solid #8B3F9E",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <VSNImage src={icon} alt="icon" />
                          </Box>
                        </Stack>
                        <Stack>
                          <VSNTypography
                            variant="h4"
                            component="h4"
                            fontSize={16}
                            fontWeight={600}
                            lineHeight="20px"
                            color="#5D5D5D"
                            // my={{ xts: 1.3, xs: 1.15 }}
                            minHeight="40px"
                            textAlign="left"
                          >
                            {header}
                          </VSNTypography>
                          <VSNTypography
                            variant="h4"
                            component="h4"
                            fontSize={14}
                            fontWeight={400}
                            lineHeight="18px"
                            color="#707070"
                            margin="0 0 auto"
                            textAlign="left"
                          >
                            {description}
                          </VSNTypography>
                        </Stack>
                      </Stack>
                    </SwiperSlide>
                  )
                )}
              </VSNSwiper>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <SwiperCardComponent
        title="Be Proactive: Recommendations for Better Health"
        description="Take charge of your physical health with these essential tips designed to improve flexibility, strength, and overall body function, ensuring you're fit and resilient both on and off the job."
        data={swiperData ?? []}
        phyical
      />
    </div>
  );
}
