import { ReactComponent as Facebook } from "../assets/landing/svg/facebook.svg";
import { ReactComponent as Twitter } from "../assets/landing/svg/twitter.svg";
import { ReactComponent as Linkedin } from "../assets/landing/svg/linkedin.svg";
import { ReactComponent as Instagram } from "../assets/landing/svg/instagram.svg";
import { ReactComponent as Tiktok } from "../assets/landing/svg/tiktok.svg";

export const footerDocLinks = [
  { label: "Terms & Conditions", path: "/terms-conditions" },
  { label: "Privacy Policy", path: "/privacy-policy" },
];

export const mainFooterDocLinks = [
  { label: "Home", path: "/" },
  { label: "Public Safety", path: "/public-safety" },
  { label: "Teams", path: "/for-teams" },
  { label: "Who We Are", path: "/who-we-are" },
  { label: "Contact Us", path: "/contact-us" },
];

export const mainFooterLinks = [
  {
    path: "/",
    icon: <Facebook />,
  },
  {
    path: "/",
    icon: <Instagram />,
  },
  {
    path: "/",
    icon: <Linkedin />,
  },
  {
    path: "/",
    icon: <Tiktok />,
  },
];
