import { useSelector } from "react-redux";

export const formContainer = ({ formPath }) => {
  const formData = useSelector(
    (state) => state.form.formData?.[formPath?.parent]
  );
  const formErrors = useSelector(
    (state) => state.form.formErrors?.[formPath?.parent]
  );
  return { formData, formErrors };
};
