import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  headerLinks,
  routesToShowLinks,
} from "../description/header.description";
import {
  all_admin,
  assessmentIds,
  assessmentNames,
  department_admin_name,
  location_admin_name,
  organization_admin_name,
} from "../utils/constant";
import { equal, keys, length } from "../utils/javascript";
import { getAssessmentState, toggleModelShow } from "../shared/CustomFunc";
import { ApiContainer } from "../utils/api";
import { SET_API_DATA } from "../redux/constants";

const headerContainer = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const noLoginLink = ["/admin", "/signup", "/otp", "/login"];
  const navigator = useNavigate();
  const location = useLocation();
  const [newOrg, setNewOrg] = useState(null);
  const userRoleData = useSelector((state) => state.app?.userData);
  const allOrganization = useSelector((state) => state.api?.allOrganization);
  const changeOrgLoader = useSelector(
    (state) => state.api?.loader?.changingOrg
  );
  const changeOrgModel = useSelector(
    (state) => state?.modelStates?.allOrganization
  );
  const userInfo = userRoleData.user_profile;
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const vasanaTypeAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.vasanaType,
  });

  const isUser = localStorage?.getItem("token");
  const isAdmin =
    localStorage?.getItem("token") && location?.pathname !== "/admin";
  const { pathname, state } = location;

  const [previousPath, setPreviousPath] = useState({
    teamsTab: "teams",
    assessmentsTab: "assessments",
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };
  const role = userInfo?.role;

  useEffect(() => {
    const getOrgData = async () => {
      const response = await api({
        method: "GET",
        endPoint: "user/organizations",
        showToastMessage: false,
        urlencoded: false,
        needFullResponse: false,
      });
      if (response?.status) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            allOrganization: response?.data?.map((item) => ({
              key: item?.name,
              value: item?.original_id,
            })),
          },
        });
      }
    };
    if (all_admin.includes(role)) {
      getOrgData();
    }
  }, [userInfo]);

  useEffect(() => {
    if (routesToShowLinks?.includes(pathname)) setShowLinks(true);
    else setShowLinks(false);
    if (pathname?.includes("teams")) {
      setPreviousPath({ ...previousPath, teamsTab: pathname });
    } else if (pathname?.includes("assessments")) {
      setPreviousPath({ ...previousPath, assessmentsTab: pathname });
    }
  }, [pathname]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setUserLoggedIn(true);
    } else {
      setPreviousPath({
        teamsTab: "teams",
        assessmentsTab: "assessments",
      });
    }
  }, [localStorage.getItem("token")]);

  const handleRegisterClick = () => {
    navigator("/signup");
  };

  const handleLoginClick = () => {
    navigator("/login");
  };
  const navigateUser = () => {
    let nav = "/";
    if (length(keys(userRoleData))) {
      if (userRoleData?.is_demo_user) {
        nav = "/";
      } else if (
        equal(userInfo?.role, "user") ||
        equal(userInfo?.role, "platform_admin")
      ) {
        if (!vasanaTypeAssessment?.result) {
          nav = `/get-started/${assessmentNames[assessmentIds?.vasanaType]}`;
        }
      }
    }

    if (pathname !== nav) {
      navigator(nav);
    }
  };

  const handelChangeOption = (e) => {
    setNewOrg(e?.target?.value);
    dispatch(toggleModelShow("allOrganization"));
  };
  const handelCancelModel = () => dispatch(toggleModelShow("allOrganization"));
  const handelChangeOrg = async () => {
    let dataToSend = {};
    if (organization_admin_name.includes(role)) {
      dataToSend = {
        organization_id: newOrg,
      };
    } else if (location_admin_name.includes(role)) {
      dataToSend = {
        location_id: newOrg,
      };
    } else if (department_admin_name.includes(role)) {
      dataToSend = {
        department_id: newOrg,
      };
    }
    const response = await api({
      method: "POST",
      endPoint: "user/organizations/select",
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: false,
      data: dataToSend,
      parent: "changingOrg",
    });
    if (response?.status) {
      window.location.reload();
      handelCancelModel();
    }
  };

  return {
    userLoggedIn,
    headerLinks,
    handleRegisterClick,
    handleLoginClick,
    showLinks,
    noLoginLink,
    userRoleData,
    isUser,
    isAdmin,
    pathname,
    state,
    navigateUser,
    vasanaTypeAssessment,
    previousPath,
    openDrawer,
    setOpenDrawer,
    toggleDrawer,
    userInfo,
    allOrganization,
    handelChangeOption,
    changeOrgModel,
    handelCancelModel,
    handelChangeOrg,
    changeOrgLoader,
    role,
  };
};
export default headerContainer;
