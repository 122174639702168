import { useDispatch } from "react-redux";
import { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Stack, Grid } from "@mui/material";
import shape1 from "../../assets/admin/png/model-shape-1.png";
import shape2 from "../../assets/admin/png/model-shape-2.png";
import { formContainer } from "../../container/form.container";
import VSNImage from "../../shared/VSNImage";
import { ReactComponent as Round } from "../../assets/admin/svg/org-image.svg";
import { equal, upperCase } from "../../utils/javascript";
import VSNTypography from "../../shared/VSNTypography";
import VSNErrorMessage from "../../shared/VSNErrorMessage";
import ControlForm from "../../shared/form/ControlForm";
import VSNLabel from "../../shared/VSNLabel";
import VSNButton from "../../shared/VSNButton";

const useStyles = makeStyles((theme) => ({
  heading: {
    position: "sticky",
    top: 0,
    borderBottom: "1px solid #FBCEBD",
    padding: "12px 25px",
    margin: "0 -25px",
    backgroundColor: "#ffffff",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 15px",
      margin: "0 -15px",
    },
    "&:after": {
      position: "absolute",
      content: "''",
      left: 0,
      right: 0,
      height: 25,
      width: "100%",
      bottom: "100%",
      background: "white",
      zIndex: 8,
    },
    "& svg": {
      borderRadius: "50%",
      [theme.breakpoints.down("md")]: {
        width: 40,
        height: 40,
      },
      [theme.breakpoints.down("sm")]: {
        width: 30,
        height: 30,
      },
    },
    "& h5": {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "31px",
      marginLeft: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
        lineHeight: "26px",
        maxWidth: "70%",
      },
    },
  },
  detailHeading: {
    "&.MuiTypography-root": {
      color: "rgba(134, 101, 10, 1)",
      fontSize: 14,
      fontWeight: 700,
      marginBottom: 24,
      [theme.breakpoints.down("md")]: {
        marginBottom: 20,
      },
    },
  },
  cancelButton: {
    "&.MuiButtonBase-root": {
      padding: [[12, 62]],
      [theme.breakpoints.down("md")]: {
        padding: [[8, 15]],
      },
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#F6F6FD",
    padding: "20px 25px",
    gap: 10,
    marginLeft: "-25px",
    marginRight: "-25px",
    position: "sticky",
    bottom: 0,
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "15px",
      gap: 10,
      marginLeft: "-15px",
      marginRight: "-15px",
    },
    "& .MuiButtonBase-root": {
      margin: 0,
      padding: "9.5px 25px",
      lineHeight: "21px",
      width: "auto",
      minWidth: "170px",
      borderRadius: 10,
      "& span": {
        fontSize: 16,
        fontWeight: 700,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  createLinkButton: {
    "&.MuiButtonBase-root": {
      padding: "10.5px 25px",
      color: theme.palette.primary.light,
    },
  },
  shape: {
    position: "absolute",
    zIndex: -2,
  },
  shape1: {
    top: "20%",
    right: "10%",
  },
  shape2: {
    bottom: "5%",
    left: "0%",
  },
  formGrid: {
    "& .react-tel-input": {
      "& .form-control": {
        fontSize: 18,
        fontWeight: 700,
        color: "#202024",
      },
    },
    "& .MuiFormControl-root.MuiTextField-root": {
      width: "100%",
    },
  },
}));

const FormInModel = ({
  handleMobileChange,
  handleChange,
  submitButtonName,
  formPath,
  toggleModelShow,
  loaderState,
  titleOfModel,
  formAttribute,
  submit,
  modelName,
  staticValue = {},
  handleDateChange,
  ShowTitle = true,
  disabledBtn,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formData, formErrors } = formContainer({ formPath });
  return (
    <form onSubmit={submit}>
      <Box>
        {ShowTitle && (
          <>
            <VSNImage
              src={shape1}
              alt=""
              className={`${classes.shape} ${classes.shape1}`}
            />
            <VSNImage
              src={shape2}
              alt=""
              className={`${classes.shape} ${classes.shape2}`}
            />
            <Stack
              direction="row"
              alignItems="center"
              className={classes.heading}
            >
              <Stack>
                <Round />
              </Stack>
              <VSNTypography variant="h5" component="h5">
                {titleOfModel}
              </VSNTypography>
            </Stack>
          </>
        )}
        {formAttribute?.map(({ label, value }, index) => (
          <Box my={{ md: 3.8, xs: 2 }} key={index}>
            <VSNTypography variant="body1" className={classes.detailHeading}>
              {upperCase(label)}
            </VSNTypography>
            <Grid
              container
              rowSpacing={{ xs: 0, md: 2 }}
              columnSpacing={4}
              className={classes.formGrid}
            >
              {value?.map((props, index) => {
                const {
                  isRequired,
                  label,
                  errorMessage,
                  name,
                  value,
                  getValBy,
                  md,
                  doNotShow,
                  control,
                  ...attribute
                } = props;
                return (
                  !doNotShow && (
                    <Grid
                      item
                      xs={12}
                      md={md}
                      key={index}
                      mb={{ xs: 2, md: 0 }}
                    >
                      <VSNLabel required={isRequired} label={label} />
                      <ControlForm
                        {...{
                          ...attribute,
                          elementId: index,
                          label,
                          name,
                          handleMobileChange,
                          onChange: handleChange,
                          handleDateChange,
                          value:
                            formData?.[name] ?? (control === "select" ? 0 : ""),
                          control,
                        }}
                      />
                      <VSNErrorMessage
                        {...{ formError: formErrors?.[name], errorMessage }}
                      />
                    </Grid>
                  )
                );
              })}
            </Grid>
          </Box>
        ))}
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={2}
          className={classes.buttons}
        >
          <VSNButton
            handleClick={() => dispatch(toggleModelShow(modelName))}
            variant="outlined"
            className={classes.cancelButton}
          >
            Cancel
          </VSNButton>
          {submitButtonName && (
            <VSNButton
              isLoading={loaderState}
              className={classes.createLinkButton}
              disabled={
                disabledBtn ??
                equal(JSON.stringify(staticValue), JSON.stringify(formData))
              }
              type="submit"
              handleClick={submit}
            >
              {submitButtonName}
            </VSNButton>
          )}
        </Stack>
      </Box>
    </form>
  );
};

export default FormInModel;
