import React, { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Grid,
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  colorPalatte,
  otherTypes,
  types,
  whyVasanaObj,
} from "../../description/type.description";
import VSNImage from "../../shared/VSNImage";
import { TypeShowStyle } from "./typeShowStyle";
import VSNTypography from "../../shared/VSNTypography";
import VSNButton from "../../shared/VSNButton";
import BannerBg from "../../assets/landing/sa-type/png/banner-bg.png";
import { ReactComponent as LeafImage } from "../../assets/landing/svg/leafImage.svg";
import { ReactComponent as Star } from "../../assets/landing/svg/star.svg";
import { ReactComponent as Rays } from "../../assets/landing/svg/rays.svg";
import RightArrow from "../../assets/landing/svg/rightArrow.svg";
import RightArrowWhite from "../../assets/landing/svg/rightArrowWhite.svg";
import RainBow from "../../assets/landing/svg/Rainbow.svg";
import leaf from "../../assets/landing/png/white-leaf.png";
import halfRound from "../../assets/landing/svg/halfRound.svg";
import Flower from "../../assets/landing/svg/flower.svg";
import Spring from "../../assets/landing/svg/spring.svg";
import Footer from "../layout/Footer";
import { changeSaReGaMaStyle } from "../../shared/CustomFunc";
import saType from "../../assets/landing/sa-type/svg/sa1.webp";
import reType from "../../assets/landing/re-type/svg/re1.webp";
import gaType from "../../assets/landing/ga-type/svg/ga1.webp";
import maType from "../../assets/landing/ma-type/svg/ma2.webp";
import saWhyVasana from "../../assets/landing/sa-type/svg/sa2.webp";
import reWhyVasana from "../../assets/landing/re-type/svg/re2.webp";
import gaWhyVasana from "../../assets/landing/ga-type/svg/ga2.webp";
import maWhyVasana from "../../assets/landing/ma-type/svg/ma1.webp";

const TypeShow = () => {
  const location = useLocation();
  const currentType = location?.pathname?.replace("/", "");
  const [data, setData] = useState({});
  const [colorPlate, setColorPlate] = useState(colorPalatte?.[currentType]);
  const classes = TypeShowStyle(colorPlate);
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:900px)");
  const [imagesLoad, setImagesLoad] = useState({
    typeVasana: false,
    askVasana: false,
  });

  useEffect(() => {
    setColorPlate(colorPalatte?.[currentType]);
    setData({ ...types?.[currentType] });
  }, [currentType]);

  const {
    type_image,
    tends_to,
    type,
    who_is_type,
    image,
    workplace_desc,
    quality,
    skills,
    why_vasana_image,
  } = data;
  return (
    <>
      {data?.type?.toLowerCase() === currentType && (
        <>
          <Box className={classes.banner}>
            <img
              src={BannerBg}
              alt="BannerBg"
              className={classes.bannerBgImg}
            />
            <Box className={classes.bannerText}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  marginBottom: { md: 3.75, xs: 0 },
                }}
              >
                <VSNTypography variant="h1" className={classes.heading}>
                  Vasana-Type{" "}
                </VSNTypography>
                <VSNImage
                  src={type_image}
                  alt="headingImage"
                  className={classes.headingImage}
                />
              </Stack>
              <VSNTypography variant="p" className={classes.desc}>
                {tends_to}
              </VSNTypography>
            </Box>
          </Box>
          <Container maxWidth="xl">
            <Grid container mt={{ md: 10, xs: 3 }}>
              <Grid item md={6} sm={12} className={classes.contentBox}>
                <Stack
                  justifyContent="center"
                  height="100%"
                  position="relative"
                >
                  <VSNTypography variant="h3" className={classes.mdHeading}>
                    Who is a{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: changeSaReGaMaStyle(type),
                      }}
                    />
                    -Type Individual?
                  </VSNTypography>
                  <VSNTypography variant="body" className={classes.mdDesc}>
                    {who_is_type}
                  </VSNTypography>
                  <VSNImage
                    src={halfRound}
                    alt="halfRound"
                    className={classes.halfRound}
                  />
                </Stack>
              </Grid>
              {image && (
                <Grid item md={6} sm={12} sx={{ width: "100%" }}>
                  <Box className={classes.imgWrapper}>
                    <VSNImage
                      src={RainBow}
                      alt="RainBow"
                      className={classes.rainbow}
                    />
                    <Box
                      position="relative"
                      className={
                        imagesLoad?.typeVasana ? classes.individual : ""
                      }
                    >
                      <VSNImage
                        src={image}
                        alt="mainImage"
                        className={classes.mainImage}
                      />
                      {imagesLoad?.typeVasana && (
                        <Rays
                          style={{
                            color: colorPalatte?.[currentType]?.icon,
                          }}
                          className={classes.raysImage}
                        />
                      )}
                    </Box>
                    {imagesLoad?.typeVasana && (
                      <Box className={classes.textWrapper}>
                        <img src={type_image} alt="type_image" />
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
            </Grid>
            <Box
              sx={{
                marginTop: { md: 10, xs: 5 },
                textAlign: { md: "center", xs: "left" },
              }}
            >
              <VSNTypography variant="h3" className={classes.mdHeading}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: changeSaReGaMaStyle(type),
                  }}
                />
                -Types in the Workplace
              </VSNTypography>
              <VSNTypography variant="body" className={classes.mdDesc}>
                <span>
                  {" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: changeSaReGaMaStyle(type),
                    }}
                  />
                  -Type
                </span>
                {workplace_desc}
              </VSNTypography>
            </Box>

            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                marginTop: { md: 3, xs: 5 },
                borderRadius: 0,
                boxShadow: "none",
              }}
            >
              {matches ? (
                quality?.map((data, ind) => (
                  <Box key={ind} className={classes.tableBox}>
                    <h6>{data?.header}</h6>
                    {data?.value?.map((val, index) => (
                      <p key={index}>
                        <LeafImage
                          style={{
                            color: colorPalatte?.[currentType]?.dark,
                          }}
                        />
                        &nbsp;&nbsp;
                        {val}
                      </p>
                    ))}
                  </Box>
                ))
              ) : (
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className={classes.WorkplaceTable}
                    sx={{
                      border: "1px solid #CDD0E1",
                      borderBottom: "none",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {quality?.map(({ header }, i) => (
                          <TableCell key={i}>{header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {quality?.map((data, ind) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={ind}>
                          {quality?.map(({ value }, index) => (
                            <TableCell key={index}>
                              <LeafImage
                                style={{
                                  color: colorPalatte?.[currentType]?.dark,
                                }}
                              />
                              &nbsp;&nbsp;
                              {value?.[ind]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
            <Grid
              container
              columnSpacing={{ md: 3, sm: 4, xs: 0 }}
              sx={{ my: { md: 10, xs: 5 } }}
            >
              {skills?.map((obj, i) => (
                <Grid
                  item
                  key={i}
                  lg={3}
                  md={6}
                  xs={12}
                  className={classes.skillBoxGroup}
                >
                  <Box className={classes.skillBox}>
                    <div className={classes.skillImgWrapper}>
                      <VSNImage src={obj?.image} alt="skillImg" />
                    </div>
                    <Box px={3}>
                      <VSNTypography variant="h4">{obj?.desc}</VSNTypography>
                      <VSNTypography>{obj?.subDesc}</VSNTypography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
          <Box
            className={classes.otherSection}
            sx={{
              background: `linear-gradient(270deg, ${colorPlate.gradient1} 5.58%, ${colorPlate.gradient2} 100%);`,
            }}
          >
            <VSNImage src={Flower} className={classes.flower} alt="flower" />
            <VSNImage
              src={halfRound}
              alt="halfRound2"
              className={classes.halfRound2}
            />
            <VSNImage src={Spring} alt="spring" className={classes.spring} />
            <VSNImage src={leaf} alt="leafLeft" className={classes.leafLeft} />
            <VSNImage
              src={leaf}
              alt="leafRight"
              className={classes.leafRight}
            />
            <VSNImage
              src={RainBow}
              alt="otherRainBow"
              className={classes.otherRainBow}
            />
            <Container maxWidth="xl">
              <Box>
                <VSNTypography variant="h3" className={classes.mdHeading}>
                  {otherTypes?.title}
                </VSNTypography>
                <VSNTypography
                  variant="body"
                  className={classes.mdDesc}
                  sx={{ maxWidth: 1050, margin: "auto" }}
                >
                  {otherTypes?.desc}
                </VSNTypography>
                <Grid
                  container
                  justifyContent="center"
                  columnSpacing={{ md: 6 }}
                >
                  {otherTypes?.types?.map(
                    (obj, i) =>
                      obj?.type !== currentType && (
                        <Grid
                          item
                          key={i}
                          lg={3}
                          md={4}
                          sm={12}
                          className={classes.cards}
                          sx={{ mb: { md: 0, xs: 3.8 } }}
                        >
                          <Box
                            className={`${classes.card} ${
                              classes?.[obj?.type]
                            }`}
                          >
                            <Box className={classes.headLogoOut}>
                              <VSNImage src={obj?.img} alt={obj?.type} />
                            </Box>
                            <Box className={classes.contentWrapper}>
                              <Box className={classes.cardDescription}>
                                <Box className="image-out">
                                  <Star
                                    style={{
                                      color: colorPalatte?.[obj?.type]?.icon,
                                    }}
                                  />
                                </Box>
                                <VSNTypography
                                  variant="body"
                                  className={classes.mdCardText}
                                >
                                  {obj?.firstPoint}
                                </VSNTypography>
                              </Box>
                              <Box className={classes.cardDescription} mb={2}>
                                <Box className="image-out">
                                  <Star
                                    style={{
                                      color: colorPalatte?.[obj?.type]?.icon,
                                    }}
                                  />
                                </Box>
                                <VSNTypography
                                  variant="body"
                                  className={classes.mdCardText}
                                >
                                  {obj?.secondPoint}
                                </VSNTypography>
                              </Box>
                              <VSNButton
                                variant="outlined"
                                handleClick={() => navigate(`/${obj?.type}`)}
                              >
                                Learn More
                                <VSNImage src={RightArrow} alt="RightArrow" />
                              </VSNButton>
                            </Box>
                          </Box>
                        </Grid>
                      )
                  )}
                </Grid>
              </Box>
            </Container>
          </Box>
          <Container maxWidth="xl">
            <Grid container columnSpacing={5} sx={{ my: { md: 10, xs: 3.8 } }}>
              <Grid item md={6} sm={12}>
                <Box
                  position="relative"
                  className={
                    imagesLoad?.askVasana ? classes.TypeSectionImg : ""
                  }
                >
                  <VSNImage
                    src={why_vasana_image}
                    alt="why_vasana_image"
                    className={classes.whyImage}
                  />
                  {imagesLoad?.askVasana && (
                    <Rays
                      style={{
                        color: colorPalatte?.[currentType]?.icon,
                      }}
                      className={classes.whyRaysImage}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item md={6} sm={12}>
                <Box className={classes.whyLearnSection}>
                  <VSNTypography variant="h3" className={classes.mdHeading}>
                    {whyVasanaObj?.title}
                  </VSNTypography>
                  <VSNTypography variant="body" className={classes.mdDesc}>
                    {whyVasanaObj?.desc1}
                  </VSNTypography>
                  <VSNTypography variant="body" className={classes.whyVasana}>
                    {whyVasanaObj?.desc2}
                  </VSNTypography>
                  <VSNButton
                    variant="contained"
                    handleClick={() => navigate("/")}
                    sx={{ width: { xs: "max-content", md: "unset" } }}
                  >
                    Go to Dashboard
                    <VSNImage src={RightArrowWhite} alt="RightArrowWhite" />
                  </VSNButton>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Box style={{ display: "none" }}>
            {[
              saType,
              reType,
              gaType,
              maType,
              saWhyVasana,
              reWhyVasana,
              gaWhyVasana,
              maWhyVasana,
            ]?.map((obj, index) => (
              <VSNImage key={index} src={obj} alt={index} />
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default TypeShow;
