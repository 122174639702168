import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import blueRound from "../../assets/Teams/svg/blue-round.svg";
import lining from "../../assets/Teams/svg/lining.svg";
import blueFlower from "../../assets/Teams/svg/blue-flower.svg";
import bgFlower from "../../assets/Teams/png/team-bg-banner.png";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    height: "100%",
    position: "relative",
    zIndex: 2,
    minHeight: "calc(100vh - 105px)",
    background: `url(${bgFlower}), linear-gradient(180deg, rgba(249, 250, 252, 0) 0%, #F9FAFC 34.38%)`,
    backgroundRepeat: "no-repeat",
  },
  blueRound: {
    position: "absolute",
    left: "11%",
    top: "21%",
    [theme.breakpoints.down("md")]: {
      top: 140,
    },
  },
  lining: {
    position: "absolute",
    top: 50,
    right: "28%",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  blueFlower: {
    position: "absolute",
    right: "20%",
    top: "21%",
    [theme.breakpoints.down("md")]: {
      top: 140,
    },
    [theme.breakpoints.down("sm")]: {
      top: 199,
      right: "unset",
      left: 40,
      height: 14,
      width: 14,
    },
  },
  wrapper: {
    padding: "56px 0 0 0",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0 0 0",
    },
  },
}));

const AssessmentLayout = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainWrapper}>
      <img src={blueRound} alt="blueRound" className={classes.blueRound} />
      {/* <img src={lining} alt="lining" className={classes.lining} /> */}
      <img src={blueFlower} alt="blueFlower" className={classes.blueFlower} />
      {/* <div className={classes.wrapper}> */}
      <Outlet />
      {/* </div> */}
    </Box>
  );
};

export default AssessmentLayout;
