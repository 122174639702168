import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const VSNMobileInput = ({
  type,
  id,
  name,
  handleMobileChange,
  value,
  inputStyle,
  containerStyle,
  placeHolder,
  buttonStyle,
  dropdownStyle,
  readOnly,
  ...props
}) => {
  return (
    <PhoneInput
      value={value}
      country="us"
      onChange={(phone, countryCode) =>
        handleMobileChange(phone, name, countryCode)
      }
      inputProps={{
        name,
        id: "phone",
        autoComplete: "tel",
      }}
      disabled={readOnly}
      inputStyle={inputStyle}
      buttonStyle={buttonStyle}
      containerStyle={containerStyle}
      dropdownStyle={dropdownStyle}
      placeholder={placeHolder}
      autocompleteSearch
      countryCodeEditable={false}
      {...props}
    />
  );
};

export default VSNMobileInput;
