import React from "react";
import { A11y, Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { Swiper } from "swiper/react";
import "swiper/swiper-bundle.css";

export const VSNSwiper = ({ children, ...rest }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay, EffectFade, A11y]}
      {...rest}
    >
      {children}
    </Swiper>
  );
};
