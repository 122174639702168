import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Play } from "../assets/quickView/svg/PlayIcon.svg";

const useStyles = makeStyles((theme) => ({
  playIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& .MuiSvgIcon-root": {
      height: 40,
      width: 40,
      fill: "#000000b3",
    },
  },
  videoWrapper: {
    position: "relative",
  },
  video: {
    height: "100%",
    width: "100%",
    borderRadius: 8,
    maxHeight: "100%",
    overflow: "hidden",
  },
}));

const VSNVideo = ({
  url,
  thumbnail,
  isPlaying,
  onPlay,
  setCurrentPlayingIndex,
}) => {
  const classes = useStyles();
  const videoRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handlePlayandPause = () => {
    const video = videoRef?.current;
    if (isPlaying) {
      video?.pause();
    } else {
      video?.play();
    }
    onPlay();
  };

  const checkFullScreen = () => {
    setIsFullScreen(
      Boolean(document.fullscreenElement || document.webkitFullscreenElement)
    );
  };

  const handleVideoEnded = () => {
    setCurrentPlayingIndex(null);
    videoRef.current.currentTime = 0;
    videoRef.current.load();
  };

  const handleScroll = () => {
    const video = videoRef?.current;
    const rect = video?.getBoundingClientRect();
    const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
    if (!isVisible) {
      video?.pause();
      setCurrentPlayingIndex(null);
    }
  };

  useEffect(() => {
    document?.addEventListener("fullscreenchange", checkFullScreen);
    document?.addEventListener("webkitfullscreenchange", checkFullScreen);
    window?.addEventListener("scroll", handleScroll);
    videoRef?.current?.addEventListener("ended", handleVideoEnded);

    return () => {
      document?.removeEventListener("fullscreenchange", checkFullScreen);
      document?.removeEventListener("webkitfullscreenchange", checkFullScreen);
      window?.removeEventListener("scroll", handleScroll);
      videoRef?.current?.removeEventListener("ended", handleVideoEnded);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    videoRef.current.style.objectFit =
      isPlaying && isFullScreen ? "contain" : "cover";
  }, [isFullScreen, isPlaying]);

  const handleSliderTouchStart = () => {
    videoRef.current.parentNode.style.touchAction = "none";
  };

  const handleSliderTouchEnd = () => {
    videoRef.current.parentNode.style.touchAction = "auto";
  };

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <div
      className={classes.videoWrapper}
      onTouchStart={handleSliderTouchStart}
      onTouchEnd={handleSliderTouchEnd}
    >
      <video
        ref={videoRef}
        controls={isPlaying}
        playsInline
        controlsList="nodownload"
        preload="metadata"
        poster={thumbnail}
        className={classes.video}
      >
        <source src={url} type="video/mp4" />
        <track src="" kind="captions" label="English" />
      </video>
      {!isPlaying && (
        <Play onClick={handlePlayandPause} className={classes.playIcon} />
      )}

      {/* {!isPlaying && (
        <div className={classes.playIcon}>
          {isPlaying ? (
            <PauseCircle onClick={handlePlayandPause} />
          ) : (
            <PlayCircle onClick={handlePlayandPause} />
          )}
        </div>
      )} */}
    </div>
  );
};

export default VSNVideo;
