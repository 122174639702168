import flower from "../../../assets/common/svg/flower-img.svg";
import star from "../../../assets/common/svg/star.svg";
import chex from "../../../assets/common/svg/chex.svg";

export const quickGuide = {
  sa: {
    sa: [
      {
        img: flower,
        title: "Embrace Collaboration",
        desc: "Recognize the value in others' ideas. Instead of competing, collaborate to merge the best aspects of both strategies.",
      },
      {
        img: star,
        title: "Respect Authority",
        desc: "If reporting to another Control, respect their leadership. Offer constructive feedback instead of undermining efforts.",
      },
      {
        img: chex,
        title: "Share Leadership",
        desc: "Explore opportunities for shared leadership or decision-making, especially in projects involving multiple Controls.",
      },
    ],
    re: [
      {
        img: flower,
        title: "Balance Action with Strategy",
        desc: "Combine quick action and strategic planning. Offer to help in evaluating strategies before execution.",
      },
      {
        img: star,
        title: "Build Trust",
        desc: "Work on building trust with Re types by acknowledging the strengths of their proactive approach while gently guiding them towards a more measured strategy. ",
      },
      {
        img: chex,
        title: "Be Adaptable",
        desc: "Be open to occasionally moving at a faster pace and appreciate the dynamism of the Re type.",
      },
    ],
    ga: [
      {
        img: flower,
        title: "Align Priorities",
        desc: "Help Ga types identify and align priorities at the start of a project. This can guide their focus and effort effectively.",
      },
      {
        img: star,
        title: "Introduce Change Gradually",
        desc: "Since Ga types may resist abrupt changes, introduce new ideas or methods gradually and with clear benefits.",
      },
      {
        img: chex,
        title: "Be Flexible in Approach",
        desc: "Demonstrate how flexibility and adaptability can enhance their established routines, highlighting potential improvements.",
      },
    ],
    ma: [
      {
        img: flower,
        title: "Emphasize the Big Picture",
        desc: "While discussing projects, emphasize the broader vision or end goal to engage Ma types.",
      },
      {
        img: star,
        title: "Have Data-Informed Discussions",
        desc: "Present your ideas with supporting data and evidence to resonate with their preference for detailed information.",
      },
      {
        img: chex,
        title: "Be Patient and Detail Oriented",
        desc: "Practice patience and appreciate their need for thoroughness. Offer concise and relevant data to aid decision-making.",
      },
    ],
  },
  re: {
    sa: [
      {
        img: flower,
        title: "Communicate Openly",
        desc: "When dealing with Sa types, communicate openly about your ideas and the need for creative freedom before execution.",
      },
      {
        img: star,
        title: "Understand Leadership Styles",
        desc: "Recognize and respect the directive style of Sa types. Find ways to incorporate their strengths in a way that complements your approach.",
      },
      {
        img: chex,
        title: "Practice Inclusive Leadership",
        desc: "Avoid excluding Controls from leadership roles; instead, find a middle ground that allows for both structure and creativity.",
      },
    ],
    re: [
      {
        img: flower,
        title: "Balance Competition and Outcome",
        desc: "While engaging in friendly competition with other Re types, focus on the primary goals and project outcomes.",
      },
      {
        img: star,
        title: "Offer Acknowledgment",
        desc: "Avoid the struggle for acknowledgment by recognizing and appreciating the contributions of all team members.",
      },
      {
        img: chex,
        title: "Focus on Project Goals",
        desc: "Keep friendly rivalries in check by regularly revisiting and emphasizing the project's main objectives.",
      },
    ],
    ga: [
      {
        img: flower,
        title: "Appreciate Structure",
        desc: "Understand the importance of structure and organization that Ga types bring to projects.",
      },
      {
        img: star,
        title: "Balance Speed and Structure",
        desc: "Find a balance between your emphasis on speed and relationships and their need for structured approaches.",
      },
      {
        img: chex,
        title: "Integrate Lists and Plans",
        desc: "Try to incorporate lists and structured planning in a way that still allows for relationship-building and fun.",
      },
    ],
    ma: [
      {
        img: flower,
        title: "Recognize the Importance of Data",
        desc: "Acknowledge the importance of data and thorough decision-making processes preferred by Ma types.",
      },
      {
        img: star,
        title: "Pace Projects",
        desc: "Work on balancing your fast-paced approach and the slow, data-driven approach of Ma types.",
      },
      {
        img: chex,
        title: "Communicate Effectively",
        desc: "Communicate your need for quicker decision-making while understanding your need for detailed information and analysis.",
      },
    ],
  },
  ga: {
    sa: [
      {
        img: flower,
        title: "Respect Big-Picture Perspectives",
        desc: "Understand and appreciate the big-picture perspective of Sa types, and find ways to integrate their strategic views into hands-on tasks.",
      },
      {
        img: star,
        title: "Communicate Directly",
        desc: "Adapt to the blunt communication style of Sa types by not taking it personally and focusing on the content of the message.",
      },
      {
        img: chex,
        title: "Balance Flexibility and Structure",
        desc: "Be flexible with the Sa type's tendency to change their minds. Suggest setting milestones to review and adapt strategies, allowing for controlled deviations.",
      },
    ],
    re: [
      {
        img: flower,
        title: "Manage Chaos and Directional Changes",
        desc: "Establish clear goals and milestones to help manage the chaos and frequent changes in direction typical of Re types.",
      },
      {
        img: star,
        title: "Balance Relationships and Business",
        desc: "Encourage a balance between personal and business-focused conversations. Set clear agendas for meetings to ensure productivity.",
      },
      {
        img: chex,
        title: "Appreciate Focus on Relationships",
        desc: "Recognize the value in building relationships, even if it takes time, and find ways to integrate this into business strategies.",
      },
    ],
    ga: [
      {
        img: flower,
        title: "Balance Productivity and Quality",
        desc: "While working with other Ga types, focus on balancing productivity and quality. Ensure that the emphasis on quantity doesn't overshadow the need for attention to detail.",
      },
      {
        img: star,
        title: "Assess ROI",
        desc: "Regularly assess projects' return on investment (ROI) to ensure that efforts align with the desired outcomes.",
      },
    ],
    ma: [
      {
        img: flower,
        title: "Balance Progress and Perfection",
        desc: "Acknowledge the Ma type's need for thoroughness and perfection. Discuss and agree on realistic standards and milestones for project completion.",
      },
      {
        img: star,
        title: "Manage Action Halts",
        desc: "Implement strategies to keep the project moving, such as phased rollouts or interim reviews, to accommodate the Authority's need for detailed review without stalling progress.",
      },
      {
        img: chex,
        title: "Engage Constructively",
        desc: "Instead of avoiding engagement with Ma types, seek constructive dialogues to understand their perspectives and find common ground for moving forward.",
      },
    ],
  },
  ma: {
    sa: [
      {
        img: flower,
        title: "Adapt to Swift Decisions",
        desc: "Understand and adapt to the quick decision-making style of Sa types. Seek to balance their forward-looking approach with your preference for thorough evidence.",
      },
      {
        img: star,
        title: "Communicate Concerns Clearly",
        desc: "Openly communicate any unease about the rapid pace of decision-making, suggesting a compromise that includes both rapid action and sufficient data analysis.",
      },
      {
        img: chex,
        title: "Build a Future-Focused Perspective",
        desc: "Appreciate the future-oriented approach of Controls and try to incorporate some of their forward-thinking into your decision-making process.",
      },
    ],
    re: [
      {
        img: flower,
        title: "Harmonize Commitments and Opportunities",
        desc: "Work towards finding common ground between your detail-oriented approach and the Re type's tendency to seek new opportunities.",
      },
      {
        img: star,
        title: "Balance Instinct and Analysis",
        desc: "Recognize the value in the Re type's instinctual actions while advocating for a more reflective approach when necessary.",
      },
      {
        img: chex,
        title: "Communicate Effectively",
        desc: "Communicate the importance of fulfilling commitments and the benefits of a more balanced approach to undertaking tasks.",
      },
    ],
    ga: [
      {
        img: flower,
        title: "Balance Quality and Quantity",
        desc: "Address the concern of quality being compromised for quantity with Ga types. Propose strategies that balance the need for speed with the desire for perfection.",
      },
      {
        img: star,
        title: "Set Realistic Standards",
        desc: "Discuss and establish realistic standards and timelines for project completion that satisfy your need for perfection and their preference for quick execution.",
      },
      {
        img: chex,
        title: "Understand Different Paces",
        desc: "Acknowledge the fast-paced nature of Ga types, and suggest phased approaches or interim milestones to ensure quality is maintained throughout the project lifecycle",
      },
    ],
    ma: [
      {
        img: flower,
        title: "Balance Data Analysis",
        desc: "Avoid excessive data analysis while engaging with other Authorities. Implement strategies like setting clear deadlines for decision-making to prevent analysis paralysis.",
      },
      {
        img: star,
        title: "Prioritize Decisiveness",
        desc: "Encourage a culture of timely decision-making among fellow Ma types, balancing the need for thorough analysis with the necessity of moving projects forward.",
      },
      {
        img: chex,
        title: "Balance Professional Rapport with Personal Interactions",
        desc: "Continue maintaining a professional rapport, but also consider integrating brief personal interactions to foster a more rounded work environment.",
      },
    ],
  },
};
