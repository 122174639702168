import { useDispatch, useSelector } from "react-redux";
import { ON_FORM_CHANGE } from "../redux/constants";
import { toggleModelShow } from "../shared/CustomFunc";
import { emailValidation } from "../shared/validation";
import { ApiContainer } from "../utils/api";
import { adminPoints } from "../utils/constant";

export const copyLinkContainer = () => {
  const dispatch = useDispatch();
  const { api } = ApiContainer();
  const formPath = { parent: "createdOrganization" };
  const sendLinkLoader = useSelector((state) => state?.api?.loader?.sendLink);
  const createdOrgInfo = useSelector((state) => state.api?.[formPath.parent]);

  const { form } = useSelector((state) => state);
  const orgData = useSelector(
    (state) => state.form.formData?.[formPath?.parent]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: { ...orgData, [name]: value },
        },
      },
    });
  };

  const handleCancel = () => {
    dispatch(toggleModelShow("sendLink"));
  };
  return {
    orgLink: createdOrgInfo?.organization_link,
    sendLinkLoader,
    handleChange,
    formPath,
    handleCancel,
  };
};
