import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ERROR_MESSAGE } from "../../description/errorMessage.description";
import { setErrors, toggleModelShow } from "../../shared/CustomFunc";
import {
  ON_FLAG_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
} from "../../redux/constants";
import {
  organization_admin,
  organization_admin_name,
} from "../../utils/constant";
import { ApiContainer } from "../../utils/api";

const changeDepartment = () => {
  const changeDepartmentUserData = useSelector(
    (state) => state?.api.changeDepartment
  );
  const dispatch = useDispatch();
  const formPath = { parent: "changeDepartmentInfo" };
  const { form } = useSelector((state) => state);
  const { api } = ApiContainer();
  const changeDepartmentInfo = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const { role, organization_original_id, location_id } = useSelector(
    (state) => state.app?.userData?.user_profile
  );
  const isLoadingChangeDepartment = useSelector(
    (state) => state?.api?.loader?.changeDepartment
  );
  const locationFilters = useSelector((state) => state.api?.locationFilters);
  const changeDepartmentInfoError = useSelector(
    (state) => state.form.formErrors?.[formPath.parent]
  );

  const changeDepartmentFilter = useSelector(
    (state) =>
      state?.api?.[
        organization_admin.includes(role)
          ? "changeDepartmentFilter"
          : "departmentFilters"
      ]
  );
  const setFormData = (name, value) => {
    dispatch(
      setErrors({ errors: changeDepartmentInfoError, value, name, formPath })
    );
    if (name === "location") {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [formPath.parent]: {
              ...changeDepartmentInfo,
              [name]: value,
              department: undefined,
            },
          },
        },
      });
    } else {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [formPath.parent]: {
              ...changeDepartmentInfo,
              [name]: value,
            },
          },
        },
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(name, value);
  };

  const formAttribute = [
    {
      value: [
        organization_admin.includes(role)
          ? {
              control: "select",
              label: "Location",
              name: "location",
              value: 0,
              validateAs: "selectMenu",
              isRequired: true,
              fullWidth: true,
              placeHolder: "Location",
              errorMessage: ERROR_MESSAGE?.department_type,
              options: locationFilters?.length
                ? locationFilters
                : [{ key: "No Department Found", value: "" }],
              md: 12,
              variant: "standard",
            }
          : { doNotShow: true },
        {
          control: "select",
          label: "Department",
          name: "department",
          value: 0,
          validateAs: "selectMenu",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Department",
          errorMessage: ERROR_MESSAGE?.department_type,
          options: changeDepartmentFilter?.length
            ? changeDepartmentFilter
            : [{ key: "No Department Found", value: "" }],
          md: 12,
          variant: "standard",
        },
      ],
    },
  ];

  useEffect(() => {
    if (changeDepartmentInfo?.location && organization_admin.includes(role)) {
      const calledDepartmentList = async () => {
        const data = await api({
          method: "GET",
          endPoint: `organization/${organization_original_id}/locations/${changeDepartmentInfo?.location}/departments/list`,
          showToastMessage: false,
        });
        if (data?.status) {
          dispatch({
            type: SET_API_DATA,
            payload: {
              changeDepartmentFilter: data.data.map(
                ({ department_name, department_original_id }) => ({
                  key: `${department_name} (${department_original_id})`,
                  value: department_original_id,
                })
              ),
            },
          });
        } else {
          dispatch({
            type: SET_API_DATA,
            payload: {
              changeDepartmentFilter: [
                { key: "No Department Found", value: "" },
              ],
            },
          });
        }
      };
      calledDepartmentList();
    }
  }, [changeDepartmentInfo?.location]);

  const handelClick = async (e) => {
    if (e?.preventDefault()) {
      e.preventDefault();
    }
    let payload = {
      current_department_id: changeDepartmentUserData?.department_original_id,
      new_department_id: changeDepartmentInfo?.department,
    };
    if (organization_admin_name.includes(role)) {
      payload = {
        ...payload,
        current_location_id: changeDepartmentUserData?.location_original_id,
        new_location_id: changeDepartmentInfo?.location,
      };
    }
    const data = await api({
      method: "PUT",
      endPoint: organization_admin_name.includes(role)
        ? `organization/${organization_original_id}/users/${changeDepartmentUserData?.user_id}/location/change`
        : `organization/${organization_original_id}/locations/${changeDepartmentInfo?.location}/users/${changeDepartmentUserData?.user_id}/departments/change`,
      showToastMessage: true,
      data: payload,
      needLoader: true,
      parent: "changeDepartment",
    });
    if (data?.status) {
      dispatch(toggleModelShow("changeDepartment"));
      dispatch({
        type: ON_FLAG_CHANGE,
        payload: { refetchData: true },
      });
    }
  };

  return {
    formAttribute,
    handleChange,
    changeDepartmentInfoError,
    changeDepartmentInfo,
    formPath,
    handelClick,
    isLoadingChangeDepartment,
  };
};

export default changeDepartment;
