export const ON_FORM_CHANGE = "ON_FORM_CHANGE";

export const ON_MODEL_STATE_CHANGE = "ON_MODEL_STATE_CHANGE";

export const SET_ERROR = "SET_ERROR";

export const SET_INITIAL_FIELD = "SET_INITIAL_FIELD";

export const SET_API_DATA = "SET_API_DATA";

export const LOADING_CHANGE = "LOADING_CHANGE";

export const TAB_CHANGE = "TAB_CHANGE";

export const LOGOUT = "LOGOUT";

export const ON_FLAG_CHANGE = "ON_FLAG_CHANGE";

export const SET_USER_DATA = "SET_USER_DATA";
