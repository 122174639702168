import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import greenRound from "../../assets/Teams/svg/green-round.svg";
import orangeFlower from "../../assets/Teams/svg/orange-flower.svg";
import orangeRainbow from "../../assets/Teams/svg/orange-rainbow.svg";
import bgFlower from "../../assets/Teams/png/team-bg-banner.png";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    height: "100%",
    position: "relative",
    zIndex: 2,
    background:
      "linear-gradient(180deg, rgba(249, 250, 252, 0) 0%, #F9FAFC 34.38%)",
    backgroundSize: "cover",
    minHeight: "calc(100vh - 105px)",
    "&:before": {
      position: "absolute",
      content: "''",
      left: 0,
      right: 0,
      background: `url(${bgFlower})`,
      zIndex: -1,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      minHeight: 310,
      [theme.breakpoints.down("sm")]: {
        minHeight: 140,
        backgroundSize: "cover",
        backgroundPosition: "0 -10px",
      },
    },
  },
  greenRound: {
    position: "absolute",
    right: "18%",
    bottom: "20%",
  },
  orangeFlower: {
    position: "absolute",
    left: "12%",
    top: "50%",
    transform: "translateY(-50%)",
  },
  orangeRainbow: {
    position: "absolute",
    left: "50%",
    bottom: "12%",
    transform: "translateX(-50%)",
  },
  wrapper: {
    padding: "20px 0",
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      background: "linear-gradient(to bottom, #00000010, transparent)",
      height: "25%",
      zIndex: -1,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "18px 0",
    },
  },
}));

const TeamLayout = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.mainWrapper}>
        <img src={greenRound} alt="greenRound" className={classes.greenRound} />
        <img
          src={orangeRainbow}
          alt="orangeRainbow"
          className={classes.orangeRainbow}
        />
        <div className={classes.wrapper}>
          <Outlet />
        </div>
      </Box>
    </>
  );
};

export default TeamLayout;
