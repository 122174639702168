import { ON_FORM_CHANGE, SET_ERROR, SET_INITIAL_FIELD } from "../constants";

const initialState = {
  formData: {},
  formError: {},
};

const Form = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_FIELD:
      return {
        ...state,
        ...payload,
        formData: payload.formData,
      };
    case ON_FORM_CHANGE:
      return {
        ...state,
        ...payload,
        formData: payload.formData,
      };
    case SET_ERROR:
      return {
        ...state,
        ...payload,
        formError: payload.formError,
      };

    default:
      return state;
  }
};

export default Form;
