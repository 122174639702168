import { useDispatch, useSelector } from "react-redux";
import { ON_FORM_CHANGE } from "../../redux/constants";
import { setErrors } from "../../shared/CustomFunc";

const CreateEditContainer = ({ formPath, loaderState }) => {
  const dispatch = useDispatch();

  const formData = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const loader = useSelector((state) => state.api?.loader?.[loaderState]);

  const { form } = useSelector((state) => state);

  const formDataError = useSelector(
    (state) => state.form.formErrors?.[formPath.parent]
  );

  const setFormData = (name, value) => {
    dispatch(setErrors({ errors: formDataError, value, name, formPath }));
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: { ...formData, [name]: value },
        },
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(name, value);
  };

  const handleMobileChange = (mobile, name) => {
    setFormData(name, `+${mobile}`);
  };
  const handleDateChange = (value, name) => {
    setFormData(name, new Date(value).toLocaleDateString("en-US"));
  };

  return {
    handleChange,
    handleMobileChange,
    formData,
    loader,
    handleDateChange,
  };
};

export default CreateEditContainer;
