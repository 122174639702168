import React from "react";
import CreateLocationContainer from "./CreateLocationContainer";
import { createLocationContainer } from "../../../container/createLocation.container";

const CreateLocationModal = ({ toggleModelShow, mode }) => {
  const { handleMobileChange, ...rest } = createLocationContainer({
    toggleModelShow,
  });
  return (
    <CreateLocationContainer
      {...{ handleMobileChange, mode, ...rest, toggleModelShow }}
    />
  );
};

export default CreateLocationModal;
