import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ON_FLAG_CHANGE,
  ON_FORM_CHANGE,
  ON_MODEL_STATE_CHANGE,
  SET_API_DATA,
  SET_ERROR,
} from "../redux/constants";
import { ApiContainer } from "../utils/api";
import { clearFormData, getEndpoint, setErrors } from "../shared/CustomFunc";
import { copyToClipboard, equal, keys } from "../utils/javascript";
import { LinkIcon } from "../shared/icon";
import { emailValidation } from "../shared/validation";
import {
  location_admin_name,
  organization_admin_name,
  organization_location_admin,
} from "../utils/constant";

export const inviteMembersModalContainer = (props) => {
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const organization_original_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_original_id
  );
  const location_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.location_id
  );
  const department_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.department_id
  );
  const modelState = useSelector((state) => state.modelStates);
  const form = useSelector((state) => state?.form);
  const { formData, formErrors } = form;
  const formPath = { parent: "inviteMembersForm" };
  const inviteMembersData = formData?.[formPath.parent] ?? {};
  const inviteMembersError = formErrors?.[formPath.parent];
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);
  const loaders = useSelector((state) => ({
    orgSummaryLoader: state.api?.loader?.organization_summary,
    inviteMembers: state.api?.loader?.inviteMembers,
  }));
  const inviteDepartments = useSelector(
    (state) =>
      state?.api?.[
        organization_admin_name?.includes(role)
          ? "inviteDepartmentFilters"
          : "departmentFilters"
      ]
  );
  const inviteLocation = useSelector((state) => state?.api?.locationFilters);
  const invitationLink = useSelector(
    (state) => state?.api?.organizationSummary?.invitation_link
  );
  const payloadForEndPoint = {
    organization_original_id,
    location_id,
    department_id,
    role,
  };

  const [highlighted, setHighlighted] = useState(false);

  const toggleModelShow = (model) => {
    dispatch(clearFormData("inviteMembersForm"));
    dispatch({
      type: SET_API_DATA,
      payload: {
        inviteMembersForm: "",
      },
    });
    const newModelState = { ...modelState };
    keys(modelState)?.forEach((key) => {
      if (equal(model, key)) {
        newModelState[key] = !newModelState[key];
      } else {
        newModelState[key] = false;
      }
    });
    dispatch({ type: ON_MODEL_STATE_CHANGE, payload: newModelState });
  };

  const handleClick = async (button) => {
    if (button === "Send Invite") {
      const formData = new FormData();
      if (inviteMembersData?.csv_file) {
        formData.append("members_csv", inviteMembersData?.csv_file_data);
      }
      if (inviteMembersData?.emails?.length) {
        formData.append(
          "members_to_invite",
          inviteMembersData?.emails?.toString()
        );
      }
      const data = await api({
        method: "POST",
        endPoint: `organization/${organization_original_id}/locations/${
          inviteMembersData?.location ?? location_id
        }/departments/${
          inviteMembersData?.department ?? department_id
        }/invite-members`,
        data: formData,
        showToastMessage: true,
        urlencoded: false,
        header: { "Content-Type": "multipart/form-data" },
        needLoader: true,
        parent: "inviteMembers",
      });
      if (data.status) {
        toggleModelShow("inviteMembers");
        dispatch({ type: ON_FLAG_CHANGE, payload: { refetchData: true } });
        dispatch({
          type: ON_FLAG_CHANGE,
          payload: { reFetchSummary: true },
        });
      }
    } else if (button === "Copy Invitation link") {
      copyToClipboard(invitationLink);
    }
  };

  const buttonsContainer = [
    {
      label: "Send Invite",
      handleClick,
      className: "invite",
      disabled: !(
        (inviteMembersData?.emails?.length ||
          inviteMembersData?.csv_file_data) &&
        !inviteMembersError?.csv_file &&
        (organization_location_admin?.includes(role)
          ? inviteMembersData?.department
          : true)
      ),
      hasLoader: true,
    },
    // {
    //   label: "Copy Invitation link",
    //   className: "link",
    //   link: <LinkIcon />,
    //   handleClick,
    // },
  ];

  const setFormData = ({ name, value, files }) => {
    const isValidCSvFile = files?.[0]?.name?.endsWith(".csv");
    if (name === "csv_file" && !isValidCSvFile) {
      dispatch({
        type: SET_ERROR,
        payload: {
          formErrors: {
            ...form.formErrors,
            [formPath.parent]: {
              ...inviteMembersError,
              [name]: "Please upload a CSV file",
            },
          },
        },
      });
    } else {
      dispatch(
        setErrors({ errors: inviteMembersError, value, name, formPath })
      );
    }
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...inviteMembersData,
            [name]:
              name === "csv_file" &&
              !isValidCSvFile &&
              inviteMembersData?.csv_file
                ? inviteMembersData?.csv_file
                : value,
            ...(name === "csv_file"
              ? {
                  csv_file_data:
                    !isValidCSvFile && inviteMembersData?.csv_file_data
                      ? inviteMembersData?.csv_file_data
                      : files?.[0] || "",
                }
              : {}),
          },
        },
      },
    });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({ name, value, files });
    if (highlighted) setHighlighted(false);
  };

  const getDepartmentData = async () => {
    const data = await api({
      method: "GET",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: `locations/${inviteMembersData?.location}/departments/list`,
      }),
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "departmentFilters",
    });
    if (data?.status) {
      let departmentFilters = [];
      departmentFilters = [
        ...departmentFilters,
        ...data.data.map(({ department_name, department_original_id }) => ({
          key: `${department_name} (${department_original_id})`,
          value: department_original_id,
        })),
      ];
      dispatch({
        type: SET_API_DATA,
        payload: {
          inviteDepartmentFilters: departmentFilters,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          inviteDepartmentFilters: [{ key: "No Department Found", value: "" }],
        },
      });
    }
  };

  useEffect(() => {
    if (inviteMembersData?.location) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [formPath.parent]: { ...inviteMembersData, department: 0 },
          },
        },
      });
      getDepartmentData();
    }
  }, [inviteMembersData?.location]);
  const handelSelectBoxChange = (e) => {
    const { name, value } = e.target;
    const setFormData = () => {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [formPath.parent]: { ...inviteMembersData, [name]: value },
          },
        },
      });
    };
    setFormData();
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    setHighlighted(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    setHighlighted(false);
  };

  const onInputChange = (event, value) => {
    const isValidMail =
      emailValidation(value) &&
      !inviteMembersData?.emailOptions?.includes(value);
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...inviteMembersData,
            emails_input_value: value,
            ...(isValidMail ? { emailOptions: [value] } : {}),
          },
        },
      },
    });
  };

  const onEmailSelect = (event, value) => {
    if (value) {
      const errorEmail = value.find((email) => !emailValidation(email));
      if (errorEmail) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [formPath.parent]: {
                ...inviteMembersData,
                emails_input_value: value[value?.length - 1],
              },
            },
          },
        });
      } else {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [formPath.parent]: {
                ...inviteMembersData,
                emails: errorEmail
                  ? [...(inviteMembersData?.emails || [])]
                  : value,
                emails_input_value: "",
              },
            },
          },
        });
      }
    }
  };

  const removeUploadedFile = () => {
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...inviteMembersData,
            csv_file: null,
            csv_file_data: null,
          },
        },
        formErrors: {
          ...form.formErrors,
          [formPath.parent]: {
            ...inviteMembersError,
            ["csv_file"]: "",
          },
        },
      },
    });
  };

  const downloadLocalFile = async () => {
    const fileUrl =
      "https://vasana-static.s3.amazonaws.com/invite-bulk-members.csv";
    const fileName = "Bulk Invite Members.csv";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    link.click();
  };

  return {
    loaders,
    formPath,
    inviteMembersData,
    handleChange,
    onDragEnter,
    onDragLeave,
    highlighted,
    buttonsContainer,
    onInputChange,
    onEmailSelect,
    downloadLocalFile,
    removeUploadedFile,
    inviteDepartments,
    handelSelectBoxChange,
    role,
    inviteLocation,
  };
};
