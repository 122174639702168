import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import VSNButton from "../../shared/VSNButton";
import VSNTypography from "../../shared/VSNTypography";
import VSNPaper from "../../shared/VSNPaper";
import { ReactComponent as Clock } from "../../assets/assesments/svg/clock.svg";
import VSNImage from "../../shared/VSNImage";
import shape1 from "../../assets/assesments/svg/quick-shape-3.svg";
import shape2 from "../../assets/assesments/svg/quick-shape-2.svg";
import shape4 from "../../assets/assesments/svg/quick-shape-4.svg";
import shape3 from "../../assets/assesments/svg/quick-shape-1.svg";
import SEO from "../../shared/SEO";
import { getAssessmentState, getSlug } from "../../shared/CustomFunc";
import { assessmentIds } from "../../utils/constant";

const useStyles = makeStyles((theme) => ({
  quickNoteInfo: {
    background: theme.palette.primary.light,
    position: "relative",
    overflow: "hidden",

    "& h5": {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.black.main,
      marginBottom: 24,
      lineHeight: "31px",
    },
    "& p": {
      color: theme.palette.secondary.main,
      fontWeight: 400,
      marginBottom: 32,
      lineHeight: "21px",
      [theme.breakpoints.down("md")]: {
        padding: [[0]],
        marginBottom: 20,
      },

      "& span": {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
    },
    "& a": {
      color: "#E87637",
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "23px",
    },
  },
  quickNoteMain: {
    padding: 16,
    [theme.breakpoints.down("lg")]: {
      marginTop: 0,
    },
  },
  shape: {
    position: "absolute",
    marginBottom: 0,
  },
  shapeOne: {
    top: "0%",
    right: "-6%",
    [theme.breakpoints.down("md")]: {
      width: 90,
    },
  },
  shapeTwo: {
    top: "10%",
    left: "0%",
    [theme.breakpoints.down("md")]: {
      width: 50,
    },
  },
  shapeFour: {
    bottom: "0%",
    left: "-8%",
    [theme.breakpoints.down("md")]: {
      width: 80,
      bottom: "5%",
    },
  },
  shapeThree: {
    bottom: "0%",
    right: "0%",
    [theme.breakpoints.down("md")]: {
      width: 80,
    },
  },
  questionSetBox: {
    maxWidth: 627,
    padding: [[40, 70]],
    [theme.breakpoints.down("sm")]: {
      padding: [[40, 16]],
    },
  },
  clockImage: {
    height: 109,
    marginBottom: 32,
    [theme.breakpoints.down("md")]: {
      marginBottom: 20,
    },
  },
}));

const QuickNote = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const currentAssessmentId = localStorage.getItem("currentAssessmentId");
  const { userData } = useSelector((state) => state?.app);
  const { markerInfo, assessmentList } = useSelector((state) => state?.api);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (currentAssessmentId?.toString() === assessmentIds?.vasanaType) {
      setInfo({
        quick_note_content: markerInfo?.get_started?.quick_note_content,
        quick_note_recommendation:
          markerInfo?.get_started?.quick_note_recommendation,
      });
    } else {
      const currentAssessment = getAssessmentState({
        assessments: assessmentList,
        id: currentAssessmentId,
      });
      setInfo(currentAssessment?.quick_note);
    }
  }, [userData, markerInfo, assessmentList]);

  const slug = getSlug(assessmentList, currentAssessmentId);

  return (
    <Box className={classes.quickNoteMain}>
      <SEO contentKey="quickNote" />

      <VSNPaper className={classes.questionSetBox}>
        <Box className={classes.quickNoteInfo}>
          <Box className={classes.clockImage}>
            <Clock />
          </Box>
          <VSNTypography variant="h5" component="h5">
            A Quick Note
          </VSNTypography>
          <div
            style={{ textAlign: "left" }}
            dangerouslySetInnerHTML={{
              __html: info?.quick_note_content,
            }}
          />
          <VSNTypography
            variant="body1"
            component="p"
            sx={{ textAlign: "left" }}
          >
            {info?.quick_note_recommendation}
          </VSNTypography>
          <Box mb={4} display="flex" justifyContent="center">
            <VSNButton handleClick={() => navigate(`/assessment/${slug}`)}>
              Continue
            </VSNButton>
          </Box>
          <Link to={`/get-started/${slug}`}> Go Back</Link>
        </Box>
        <VSNImage
          src={shape1}
          className={`${classes.shape} ${classes.shapeOne}`}
          alt="shape1"
        />
        <VSNImage
          src={shape2}
          className={`${classes.shape} ${classes.shapeTwo}`}
          alt="shape2"
        />
        <VSNImage
          src={shape4}
          className={`${classes.shape} ${classes.shapeFour}`}
          alt="shape4"
        />
        <VSNImage
          src={shape3}
          className={`${classes.shape} ${classes.shapeThree}`}
          alt="shape3"
        />
      </VSNPaper>
    </Box>
  );
};

export default QuickNote;
