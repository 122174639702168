import React from "react";
import { Box, Grid, List, ListItem, Stack, Tabs, Tab } from "@mui/material";
import { OnePagerResultStyle } from "./onePagerResultStyle";
import ProQOLResultContainer from "../../container/ProQOL.container";
import VSNTypography from "../../shared/VSNTypography";
import blueFlower from "../../assets/assesments/png/half-blue-flower.png";
import pinkFlower from "../../assets/assesments/png/pink-flower.png";
import threeLines from "../../assets/assesments/png/orange-three-line.png";
import threeLinesGray from "../../assets/assesments/png/gray-three-line.png";
import VSNImage from "../../shared/VSNImage";
import LineChart from "./LineChart";
import VSNLoader from "../../shared/VSNLoader";
import VSNBreadcrumbs from "../../shared/VSNBreadcrumbs";
import SwiperCardComponent from "./SwiperCardComponent";
import Box1 from "../../assets/assesments/svg/anxity-1.svg";
import Box3 from "../../assets/assesments/svg/anxity-3.svg";
import Box4 from "../../assets/assesments/svg/anxity-4.svg";
import Star from "../../assets/assesments/svg/star-yellow.svg";
import BarChart from "./BarChart";

export default function ProQOLResult({
  endPointToCall,
  showBreadCrumbs = true,
  breadCrumbAndButton,
  releaseButton,
}) {
  const classes = OnePagerResultStyle();
  const {
    proqolAssessmentResult,
    loader,
    currentAssessment,
    value,
    handleChange,
    result,
  } = ProQOLResultContainer({ endPointToCall });

  const breadcrumbsRoutes = [
    {
      path: "/assessments",
      label: "Assessments",
    },
    {
      path: `/assessments/${currentAssessment?.assessment_id}`,
      label: `${currentAssessment?.result} Dashboard`,
    },
  ];

  if (loader?.assessmentResult || !proqolAssessmentResult) {
    return <VSNLoader variant="fullPage" />;
  }

  return (
    <div className={classes.mainLayout}>
      {showBreadCrumbs ? (
        <Box mt={2.5} mb={1.2}>
          <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />
        </Box>
      ) : (
        <Box
          sx={{
            marginBottom: 2.4,
          }}
          className={classes.headingButton}
        >
          <Box>{breadCrumbAndButton}</Box>
          <Box>{result?.is_locked ? releaseButton : ""}</Box>
        </Box>
      )}

      {proqolAssessmentResult?.heading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            gap: 1.5,
            "& img": { marginTop: "4px" },
          }}
        >
          <VSNImage src={Star} alt="star" />
          <VSNTypography
            className={classes.headDesc}
            display="inline"
            dangerouslySetInnerHTML={{
              __html: proqolAssessmentResult?.heading,
            }}
          />
        </Box>
      ) : null}

      <Box sx={{ width: "100%" }} pb="3px" pl={{ sm: "25px", xs: "0px" }}>
        <Tabs
          className={classes.CustomTabs}
          value={value}
          onChange={handleChange}
        >
          <Tab disableRipple label="Compassion Satisfaction" />
          <Tab disableRipple label="Total Burnout" />
          <Tab disableRipple label="Secondary Traumatic Stress" />
        </Tabs>
      </Box>

      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Stack className={classes.assessmentReportCard}>
            <Box sx={{ display: "block" }}>
              <VSNTypography
                className={classes.mdHeadingMain}
                dangerouslySetInnerHTML={{
                  __html: proqolAssessmentResult?.introduction?.heading_line,
                }}
              />
              <VSNTypography
                fontSize={{ sm: 20, xts: 18 }}
                lineHeight="26px"
                color="#555FDD"
                fontWeight={700}
                display="inline"
                pl={0.5}
              >
                {`(${proqolAssessmentResult?.latest_score || 0})`}
              </VSNTypography>
            </Box>

            <Stack direction={{ md: "row" }} mt={1} spacing={2}>
              <Box flex={1}>
                <VSNTypography component="p" className={classes.desc}>
                  {proqolAssessmentResult?.introduction?.description}
                </VSNTypography>
                {proqolAssessmentResult?.introduction?.objectives?.length ? (
                  <List
                    className={
                      proqolAssessmentResult?.introduction?.objectives
                        ?.length === 1
                        ? classes.notesListOne
                        : classes.notesList
                    }
                  >
                    {proqolAssessmentResult?.introduction?.objectives?.map(
                      (el, index) => (
                        <React.Fragment key={index}>
                          <ListItem>{el?.description}</ListItem>
                        </React.Fragment>
                      )
                    )}
                  </List>
                ) : null}
              </Box>
            </Stack>

            <VSNImage src={Box1} alt="box1" className={classes.box1} />
            <VSNImage src={Box3} alt="box3" className={classes.box3} />
            <VSNImage src={Box4} alt="box4" className={classes.box4} />
          </Stack>
        </Grid>
        <Grid item xl={6} lg={6} xs={12}>
          <Stack className={classes.assessmentReportCard}>
            <VSNTypography
              variant="h3"
              component="h3"
              className={classes.mdHeading}
              mb={1}
            >
              {proqolAssessmentResult?.summary?.title}
            </VSNTypography>
            <Stack
              direction={{ md: "row" }}
              mt={2}
              spacing={2}
              textAlign="center"
            >
              <Box flex={1}>
                <LineChart
                  score={
                    proqolAssessmentResult?.summary?.score_over_the_years?.data
                  }
                  maxScore={proqolAssessmentResult?.max_score ?? 100}
                  result={proqolAssessmentResult?.result}
                />
                <VSNTypography
                  sx={{ color: "#86650A", fontWeight: 600, marginTop: -1 }}
                >
                  {proqolAssessmentResult?.summary?.score_over_the_years?.title}
                </VSNTypography>
              </Box>
              <Box flex={1}>
                <BarChart
                  score={proqolAssessmentResult?.latest_score ?? 0}
                  maxScore={proqolAssessmentResult?.max_score ?? 100}
                  //   score={
                  //     proqolAssessmentResult?.summary?.comparative_score?.score
                  //   }
                />
                <VSNTypography
                  sx={{ color: "#86650A", fontWeight: 600, marginTop: -1 }}
                >
                  {proqolAssessmentResult?.summary?.comparative_score?.title}
                </VSNTypography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xl={6} lg={6} xs={12}>
          <Stack className={classes.assessmentReportCard}>
            <VSNTypography
              variant="h3"
              component="h3"
              className={classes.mdHeading}
              mb={1}
            >
              {proqolAssessmentResult?.impact_on_health_and_wellbeing?.title}
            </VSNTypography>
            <VSNTypography component="p" className={classes.desc}>
              {
                proqolAssessmentResult?.impact_on_health_and_wellbeing
                  ?.description
              }
            </VSNTypography>
            <List className={classes.dailyFunctioning}>
              {proqolAssessmentResult?.impact_on_health_and_wellbeing?.objectives?.map(
                (el, index) => (
                  <ListItem key={index}>
                    <Box color="#E55733" mt={0.5}>
                      <VSNImage src={el?.icon} alt="icon" />
                    </Box>
                    <VSNTypography color="secondary.main">
                      <span style={{ color: "#86650A", fontWeight: 500 }}>
                        {el?.title ?? ""}{" "}
                      </span>
                      {el?.description}
                    </VSNTypography>
                  </ListItem>
                )
              )}
            </List>
          </Stack>
        </Grid>
        <SwiperCardComponent
          title={proqolAssessmentResult?.recommendation?.title ?? ""}
          description={
            proqolAssessmentResult?.recommendation?.description ?? ""
          }
          data={proqolAssessmentResult?.recommendation?.objectives}
        />

        {proqolAssessmentResult?.when_to_seek_help?.title ? (
          <Grid item md={12} xs={12}>
            <Stack
              className={classes.assessmentReportCard}
              position="relative"
              overflow="hidden"
            >
              <VSNImage
                src={blueFlower}
                alt="blueFlower"
                className={classes.blueFlower}
              />
              <VSNImage
                src={pinkFlower}
                alt="pinkFlower"
                className={classes.pinkFlower}
              />
              <VSNImage
                src={threeLines}
                alt="threeLines"
                className={classes.threeLines}
              />
              <VSNImage
                src={threeLinesGray}
                alt="threeLinesGray"
                className={classes.threeLinesgray}
              />

              <VSNTypography
                variant="h3"
                component="h3"
                className={classes.mdHeading}
                mb={1}
              >
                {proqolAssessmentResult?.when_to_seek_help?.title ??
                  "When to Seek Help"}
              </VSNTypography>
              <Stack
                direction={{ sm: "row" }}
                alignItems="center"
                spacing={4}
                mt={1.75}
              >
                <Box flex="0 0 auto" mb={{ sm: 0, xs: 2 }}>
                  <VSNImage
                    src={proqolAssessmentResult?.when_to_seek_help?.image}
                    alt="seek"
                  />
                </Box>
                <div
                  className={classes.headDesc}
                  dangerouslySetInnerHTML={{
                    __html:
                      proqolAssessmentResult?.when_to_seek_help?.description,
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
