import { createTheme } from "@mui/material";
import toast from "react-hot-toast";
import { ReactComponent as Success } from "../assets/toastIcons/svg/success.svg";
import { ReactComponent as Error } from "../assets/toastIcons/svg/error.svg";
import { ReactComponent as Info } from "../assets/toastIcons/svg/info.svg";
import { ReactComponent as Warning } from "../assets/toastIcons/svg/warning.svg";

const theme = createTheme();
const toastIcons = {
  success: { icon: <Success />, color: "#34A259" },
  error: { icon: <Error />, color: "#F54346" },
  warning: { icon: <Warning />, color: "#F7A23B" },
  info: { icon: <Info />, color: "#555FDD" },
};
export const showToast = (
  message = "Something went wrong",
  messageType = "error"
) =>
  toast.custom(message, {
    type: messageType,
    className: "toasterMessage",
    style: {
      color: `${toastIcons?.[messageType].color}`,
      maxWidth: "600px",
      backgroundColor: "rgba(255, 255, 255)",
    },
    icon: toastIcons[messageType].icon,
    iconTheme: {
      primary: `${theme.palette[messageType].main}`,
      secondary: `#FFF`,
    },
    duration: 5000,
  });
