import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  List,
  ListItem,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { TeamDashboardStyle } from "./TeamDashboardStyle";
import Charts from "./Charts";
import VSNTypography from "../../../shared/VSNTypography";
import { exploreData } from "./exploreData";

export default function Explore() {
  const classes = TeamDashboardStyle();

  const [open, setOpen] = useState("baseMap");
  const [subTitle, setSubTitle] = useState("");
  const [active, setActive] = useState();
  const [allMemberApiData, setAllMemberApiData] = useState([]);
  const [filteredData, setFilteredData] = useState({
    sa: [],
    re: [],
    ga: [],
    ma: [],
  });
  const matches = useMediaQuery("(max-width:600px)");
  const [exploreDataState, setExploreDataState] = useState({
    headingDescription: null,
    subHeadingDescription: null,
    baseMapDescription: null,
  });
  const markers = ["sa", "re", "ga", "ma"];
  const allMembers = useSelector((state) => state?.api?.allMembers);
  const exploreMembersLoader = useSelector(
    (state) => state?.api?.loader?.exploreMembers
  );

  const getRandomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  };
  const getRandom = () => {
    return matches
      ? { bottom: getRandomNumber(10, 50), left: getRandomNumber(10, 50) }
      : { bottom: getRandomNumber(0, 65), left: getRandomNumber(0, 65) };
  };

  const onExpand = (key) => {
    if (key === "baseMap") {
      setOpen(key);
    } else {
      const tempOpen = open === key ? "baseMap" : key;
      setOpen(tempOpen);
      const current = exploreData?.find((el) => el?.key === key);
      setSubTitle(current?.points?.[0]?.key);
      const baseMapDescription = exploreData?.find(
        (item) => item?.key === tempOpen
      );
      const headingDescription = baseMapDescription?.points?.find(
        (point) => point?.key === current?.points?.[0]?.key
      );
      const subHeadingDescription = headingDescription?.typeDescriptions?.find(
        (type) => type?.key === null
      );

      setExploreDataState({
        headingDescription,
        subHeadingDescription,
        baseMapDescription,
      });
    }
    setActive(null);
  };

  const onChildDataList = (childKey) => {
    setSubTitle(childKey);
    setActive(null);
    const baseMapDescription = exploreData?.find((item) => item?.key === open);
    const headingDescription = baseMapDescription?.points?.find(
      (point) => point?.key === childKey
    );
    const subHeadingDescription = headingDescription?.typeDescriptions?.find(
      (type) => type?.key === null
    );

    setExploreDataState({
      headingDescription,
      subHeadingDescription,
      baseMapDescription,
    });
  };
  const handleChartPieceToggle = (key) => {
    setActive((prevActive) => (prevActive === key ? null : key));
    const baseMapDescription = exploreData?.find((item) => item?.key === open);
    const headingDescription = baseMapDescription?.points?.find(
      (point) => point?.key === subTitle
    );
    const subHeadingDescription = headingDescription?.typeDescriptions?.find(
      (type) => type?.key === key
    );

    setExploreDataState({
      headingDescription,
      subHeadingDescription,
      baseMapDescription,
    });
  };

  useEffect(() => {
    const newData = allMembers?.data?.map((obj) => ({
      ...obj,
      ...getRandom(),
    }));
    setAllMemberApiData(newData);
  }, [allMembers]);

  useEffect(() => {
    const filtered = markers?.reduce((acc, marker) => {
      acc[marker] =
        allMemberApiData?.filter(
          (el) => el?.primary_marker?.toLowerCase() === marker
        ) || [];
      return acc;
    }, {});
    setFilteredData(filtered || {});
  }, [allMemberApiData]);

  useEffect(() => {
    const baseMapDescription = exploreData?.find(
      (item) => item?.key === "baseMap"
    );
    const headingDescription = baseMapDescription?.points?.find(
      (point) => point?.key === subTitle
    );
    const subHeadingDescription = headingDescription?.typeDescriptions?.find(
      (type) => type?.key === active
    );

    setExploreDataState({
      headingDescription,
      subHeadingDescription,
      baseMapDescription,
    });
  }, []);

  return (
    <Stack className={classes.teamViewCard} mt={3} flex={1}>
      <VSNTypography variant="h3" mb={2} className={classes.mdHeading}>
        Explore your team’s personalities
      </VSNTypography>
      {exploreMembersLoader ? (
        <CircularProgress size={24} color="inherit" sx={{ m: "auto" }} />
      ) : (
        <Grid container spacing={{ lg: 3, xs: 2 }}>
          <Grid item md={3} sm={4} xs={12}>
            <VSNTypography
              color="#86650A"
              fontWeight={700}
              mb={1}
              onClick={() => onExpand("baseMap")}
            >
              Base Map
            </VSNTypography>
            <List className={classes.baseMapList}>
              {exploreData?.map((el) =>
                el?.key === "baseMap" ? null : (
                  <ListItem key={el?.key}>
                    <VSNTypography
                      onClick={() => onExpand(el?.key)}
                      className={open === el?.key ? classes.active : ""}
                      color="secondary.main"
                      fontWeight={700}
                    >
                      {el?.title} <ExpandMoreIcon />
                    </VSNTypography>
                    <Collapse in={open === el?.key}>
                      <List className={classes.baseList}>
                        {el?.points?.map((point, i) => (
                          <ListItem
                            key={i}
                            onClick={() => onChildDataList(point?.key)}
                            className={
                              subTitle === point?.key ? classes.activeMap : ""
                            }
                          >
                            {point?.title}
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </ListItem>
                )
              )}
            </List>
          </Grid>
          <Grid item md={9} sm={8} xs={12}>
            <Box className={classes.personalities}>
              <Grid container spacing={2}>
                <Grid item md={7} xs={12}>
                  <Stack position="relative">
                    <Charts
                      active={active}
                      handleChartPieceToggle={handleChartPieceToggle}
                      exploreData={exploreData}
                      open={open}
                      subTitle={subTitle}
                      filteredData={filteredData}
                      matches={matches}
                    />

                    {!matches || open === "baseMap" ? (
                      <Box className={classes.personalitiesType}>
                        <VSNTypography
                          sx={{
                            backgroundColor: "#F2E7F5",
                            color: "#8B3F9E",
                          }}
                        >
                          Sa
                        </VSNTypography>
                        <VSNTypography
                          sx={{
                            backgroundColor: "#E3F1F9",
                            color: "#1780C6",
                          }}
                        >
                          Re
                        </VSNTypography>
                        <VSNTypography
                          sx={{
                            backgroundColor: "#FFF7EC",
                            color: "#FFB142",
                          }}
                        >
                          Ga
                        </VSNTypography>
                        <VSNTypography
                          sx={{
                            backgroundColor: "#F0F9F2",
                            color: "#2D8F4E",
                            paddingRight: 20,
                          }}
                        >
                          Ma
                        </VSNTypography>
                      </Box>
                    ) : (
                      exploreData?.map((item, index) => (
                        <div key={index}>
                          {item?.points?.map((point, pointIndex) =>
                            point?.key === subTitle ? (
                              <Stack
                                direction={{ sm: "column", xs: "row" }}
                                flexWrap="wrap"
                                key={pointIndex}
                              >
                                {point?.oneWordDesc?.map(
                                  (category, categoryIndex) => {
                                    const backgroundColors = [
                                      "#F2E7F5",
                                      "#E3F1F9",
                                      "#FFF7EC",
                                      "#F0F9F2",
                                    ];
                                    const textColors = [
                                      "#8B3F9E",
                                      "#1780C6",
                                      "#FFB142",
                                      "#2D8F4E",
                                    ];
                                    const labels = ["Sa", "Re", "Ga", "Ma"];
                                    return (
                                      <Box
                                        key={categoryIndex}
                                        className={classes.personalitiesType}
                                        position={{
                                          sm: "absolute",
                                          xs: "static !important",
                                        }}
                                      >
                                        {categoryIndex < 4 && (
                                          <>
                                            <VSNTypography
                                              sx={{
                                                backgroundColor:
                                                  backgroundColors[
                                                    categoryIndex
                                                  ],
                                                color:
                                                  textColors[categoryIndex],
                                              }}
                                            >
                                              {`${labels[categoryIndex]}`}
                                            </VSNTypography>
                                            <VSNTypography
                                              component="span"
                                              sx={{
                                                color:
                                                  textColors[categoryIndex],
                                                fontFamily:
                                                  "DM SANS !important",
                                              }}
                                            >
                                              {labels[categoryIndex] === "Sa"
                                                ? point?.oneWordDesc?.[3]
                                                    ?.name || ""
                                                : labels[categoryIndex] === "Re"
                                                ? point?.oneWordDesc?.[0]
                                                    ?.name || ""
                                                : labels[categoryIndex] === "Ga"
                                                ? point?.oneWordDesc?.[1]
                                                    ?.name || ""
                                                : point?.oneWordDesc?.[2]
                                                    ?.name || ""}
                                            </VSNTypography>
                                          </>
                                        )}
                                      </Box>
                                    );
                                  }
                                )}
                              </Stack>
                            ) : null
                          )}
                        </div>
                      ))
                    )}
                  </Stack>
                </Grid>
                {exploreDataState?.baseMapDescription?.key === "baseMap" && (
                  <Grid item md={5} xs={12}>
                    <div className={classes.personalitiesDesc}>
                      <VSNTypography variant="h6" mb={1}>
                        {exploreDataState?.baseMapDescription?.title}
                      </VSNTypography>
                      <VSNTypography color="secondary.grey">
                        {exploreDataState?.baseMapDescription?.subDescription}
                      </VSNTypography>
                    </div>
                  </Grid>
                )}
                {exploreDataState?.subHeadingDescription &&
                exploreDataState?.subHeadingDescription?.key === active ? (
                  <Grid item md={5} xs={12}>
                    <div className={classes.personalitiesDesc}>
                      <VSNTypography variant="h6" mb={1}>
                        {exploreDataState?.subHeadingDescription?.key}
                      </VSNTypography>
                      <VSNTypography color="secondary.grey">
                        {exploreDataState?.subHeadingDescription?.desc}
                      </VSNTypography>
                    </div>
                  </Grid>
                ) : (
                  exploreDataState?.headingDescription && (
                    <Grid item md={5} xs={12}>
                      <div className={classes.personalitiesDesc}>
                        <VSNTypography variant="h6" mb={1}>
                          {exploreDataState?.headingDescription?.title}
                        </VSNTypography>
                        <VSNTypography color="secondary.grey">
                          {exploreDataState?.headingDescription?.subDescription}
                        </VSNTypography>
                      </div>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
}
