import React from "react";
import { makeStyles } from "@mui/styles";
import bgOne from "../../assets/assesments/svg/assesment-3.svg";
import bgTwo from "../../assets/termsCondition/svg/bg2.svg";
import bgThree from "../../assets/termsCondition/svg/bg3.svg";
import bgFour from "../../assets/termsCondition/svg/bg4.svg";
import bgFifth from "../../assets/termsCondition/svg/bg5.svg";
import VSNImage from "../../shared/VSNImage";

const useStyles = makeStyles((theme) => ({
  bg: {
    position: "absolute",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bgOne: {
    top: 70,
    transform: "rotate(30deg)",
    left: 9,
  },
  bgTwo: {
    top: "18%",
    right: "10%",
    "&.bgPrivacyTwo": {
      top: "23%",
    },
  },
  bgThree: {
    top: "38%",
    left: "25%",
    opacity: "0.2",
    "&.bgPrivacyThree": {
      top: "50%",
    },
  },
  bgFour: {
    bottom: 0,
    left: 0,
    opacity: 0.5,
  },
  bgFifth: {
    right: 0,
    bottom: 92,
  },
}));

const PageBgImg = ({ isPrivacyPage }) => {
  const classes = useStyles();
  return (
    <>
      <VSNImage
        src={bgOne}
        className={`${classes.bg} ${classes.bgOne}`}
        alt="bgOne"
      />
      <VSNImage
        src={bgTwo}
        className={`${classes.bg} ${classes.bgTwo} ${
          isPrivacyPage && `bgPrivacyTwo`
        }`}
        alt="bgTwo"
      />
      <VSNImage
        src={bgThree}
        className={`${classes.bg} ${classes.bgThree} ${
          isPrivacyPage && `bgPrivacyThree`
        } `}
        alt="bgThree"
      />
      <VSNImage
        src={bgFour}
        className={`${classes.bg} ${classes.bgFour}`}
        alt="bgFour"
      />
      <VSNImage
        src={bgFifth}
        className={`${classes.bg} ${classes.bgFifth}`}
        alt="bgFifth"
      />
    </>
  );
};

export default PageBgImg;
