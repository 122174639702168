import { Box, Chip, CircularProgress, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { upperCase } from "lodash";
import * as React from "react";
import { renderTypesWithImage } from "./CustomFunc";
import VSNMembersAvatar from "./VSNMembersAvatar";
import VSNTypography from "./VSNTypography";
import { CheckIcon } from "./icon";
import { getAvatarName } from "../utils/javascript";

const useStyles = makeStyles(() => ({
  avtarOut: {
    "& .MuiAvatar-root.MuiAvatar-circular": { border: "2px solid #fff" },
  },
  smHeading: {
    "&.MuiTypography-root": {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: 700,
      color: "#424450",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  desc: {
    "&.MuiTypography-root": {
      fontSize: 14,
      lineHeight: "18px",
      color: "#747787",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  imageType: {
    height: 25,
    minWidth: 60,
    "& img": {
      height: "100%",
      width: "auto",
    },
  },
}));

export default function MembersAutoComplete({
  placeholder,
  multiple,
  options,
  label,
  name,
  onChange,
  onInputChange,
  inputValue,
  sx,
  loader,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      fullWidth
      options={options}
      renderTags={(tagValue, getTagProps) => {
        return (
          tagValue?.map((tag, index) => (
            <Chip key={index} label={tag?.email} {...getTagProps({ index })} />
          )) || []
        );
      }}
      getOptionLabel={(option) =>
        `${option.first_name} ${option.last_name} ${option.email}`
      }
      slotProps={{
        popper: {
          sx: {
            width: "auto !important",
            minWidth: 322,
            maxWidth: 400,
          },
        },
      }}
      sx={{
        ...sx,
        "& .MuiChip-label": {
          maxWidth: 200,
        },
        "& .MuiAutocomplete-inputRoot": {
          "& .MuiAutocomplete-input": {
            minWidth: "max-content",
          },
        },
      }}
      renderOption={(props, option) => {
        if (option?.isLoading) {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <CircularProgress size={24} color="inherit" />
            </Stack>
          );
        }
        return (
          <Stack direction="row" alignItems="center" {...props}>
            <Box className={classes.avtarOut}>
              <VSNMembersAvatar
                alt={option?.first_name || ""}
                src="/static/images/avatar/1.jpg"
                vasanaType={option?.primary_marker}
                title={getAvatarName(option)}
              />
            </Box>
            <Box ml={1.25} mr={0.5} sx={{ width: "100%", maxWidth: 130 }}>
              <VSNTypography variant="h4" className={classes.smHeading}>
                {option.first_name} {option.last_name}
              </VSNTypography>
              <VSNTypography className={classes.desc}>
                {option.email}
              </VSNTypography>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              my="auto"
              ml="auto"
              className={classes.imageType}
            >
              {renderTypesWithImage({ type: option.primary_marker })}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              flex="0 0 auto"
              ml={0.5}
              width={18}
              my="auto"
            >
              {props["aria-selected"] && <CheckIcon />}
            </Stack>
          </Stack>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loader === true ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            variant="outlined"
            placeholder={placeholder}
          />
        );
      }}
      onChange={onChange}
      onInputChange={onInputChange}
      {...rest}
    />
  );
}
