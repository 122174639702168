import React from "react";
import { Box, Grid, List, ListItem, Stack } from "@mui/material";
import { OnePagerResultStyle } from "./onePagerResultStyle";
import VSNTypography from "../../shared/VSNTypography";
import blueFlower from "../../assets/assesments/png/half-blue-flower.png";
import pinkFlower from "../../assets/assesments/png/pink-flower.png";
import threeLines from "../../assets/assesments/png/orange-three-line.png";
import threeLinesGray from "../../assets/assesments/png/gray-three-line.png";
import VSNImage from "../../shared/VSNImage";
import LineChart from "./LineChart";
import VSNLoader from "../../shared/VSNLoader";
import VSNBreadcrumbs from "../../shared/VSNBreadcrumbs";
import onePagerResultContainer from "../../container/onePager.container";
import SwiperCardComponent from "./SwiperCardComponent";
import Box1 from "../../assets/assesments/svg/anxity-1.svg";
import Box3 from "../../assets/assesments/svg/anxity-3.svg";
import Box4 from "../../assets/assesments/svg/anxity-4.svg";
import BarChart from "./BarChart";

export default function OnePagerResult({
  endPointToCall,
  showBreadCrumbs = true,
  breadCrumbAndButton,
  releaseButton,
}) {
  const classes = OnePagerResultStyle();
  const { onePagerAssessmentResult, loader, currentAssessment } =
    onePagerResultContainer({ endPointToCall });

  const breadcrumbsRoutes = [
    {
      path: "/assessments",
      label: "Assessments",
    },
    {
      path: `/assessments/${currentAssessment?.assessment_id}`,
      label: `${currentAssessment?.result} Dashboard`,
    },
  ];

  if (loader?.assessmentResult || !onePagerAssessmentResult) {
    return <VSNLoader variant="fullPage" />;
  }

  return (
    <div className={classes.mainLayout}>
      {showBreadCrumbs ? (
        <Box mt={2.5} mb={1.8}>
          <VSNBreadcrumbs {...{ breadcrumbsRoutes }} />
        </Box>
      ) : (
        <Box className={classes.headingButton}>
          <Box>{breadCrumbAndButton}</Box>
          <Box>{onePagerAssessmentResult?.is_locked ? releaseButton : ""}</Box>
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Stack className={classes.assessmentReportCard}>
            <Box sx={{ display: "block" }}>
              <VSNTypography
                className={classes.mdHeadingMain}
                dangerouslySetInnerHTML={{
                  __html: onePagerAssessmentResult?.introduction?.heading_line,
                }}
              />
              <VSNTypography
                fontSize={{ sm: 22, xts: 20 }}
                lineHeight="26px"
                color="#555FDD"
                fontWeight={700}
                display="inline"
                pl={0.5}
              >
                {`(${onePagerAssessmentResult?.latest_score || 0})`}
              </VSNTypography>
            </Box>

            <Stack direction={{ md: "row" }} mt={1} spacing={2}>
              <Box flex={1}>
                <VSNTypography component="p" className={classes.desc}>
                  {onePagerAssessmentResult?.introduction?.description}
                </VSNTypography>
                <List
                  className={
                    onePagerAssessmentResult?.introduction?.objectives
                      ?.length === 1
                      ? classes.notesListOne
                      : classes.notesList
                  }
                >
                  {onePagerAssessmentResult?.introduction?.objectives?.map(
                    (el, index) => (
                      <React.Fragment key={index}>
                        <ListItem>{el?.description}</ListItem>
                      </React.Fragment>
                    )
                  )}
                </List>
              </Box>
            </Stack>
            {onePagerAssessmentResult?.perceived_social_support_categories
              .length ? (
              <>
                <Box my={1.5}>
                  <VSNTypography
                    fontSize={{ sm: 18, xts: 16 }}
                    lineHeight={{ sm: "24px", xts: "20px" }}
                    fontWeight={700}
                    display="inline"
                    color="#86650A"
                  >
                    Perceived Social Support Categories
                  </VSNTypography>
                </Box>
                <Grid container spacing={3}>
                  {onePagerAssessmentResult?.perceived_social_support_categories?.map(
                    (el, index) => (
                      <Grid item lg={4} md={6} xs={12} key={index}>
                        <Box>
                          <Box height="100%" className={classes.optionInfo}>
                            <Box>
                              <Stack
                                direction="row"
                                pb="10px"
                                alignItems="center"
                              >
                                <Box
                                  color="#E55733"
                                  px={0.5}
                                  sx={{ lineHeight: 0 }}
                                >
                                  <VSNImage src={el?.icon} alt="icon" />
                                </Box>
                                <VSNTypography
                                  fontSize={{ sm: 18, xts: 14 }}
                                  lineHeight={{ sm: "24px", xts: "20px" }}
                                  fontWeight={700}
                                  pl={2}
                                  dangerouslySetInnerHTML={{
                                    __html: el?.title,
                                  }}
                                />
                              </Stack>
                              <Box>
                                <Stack direction="row" alignItems="center">
                                  <VSNTypography
                                    fontSize={{ sm: 16, xts: 14 }}
                                    lineHeight={{ sm: "21px", xts: "19px" }}
                                    fontWeight={400}
                                    pl={5}
                                    color="#747787"
                                    style={{ zIndex: 1 }}
                                  >
                                    {el?.description}
                                  </VSNTypography>
                                </Stack>
                              </Box>
                            </Box>
                            <VSNImage
                              src={el?.image}
                              alt="ImgOne"
                              className={classes.ImgOne}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    )
                  )}
                </Grid>
              </>
            ) : null}

            {onePagerAssessmentResult?.important_note &&
            onePagerAssessmentResult?.important_note?.description ? (
              <Box className={classes.importantNote}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <VSNImage
                    src={onePagerAssessmentResult?.important_note?.icon}
                    alt="important_note_icon"
                  />
                  <VSNTypography
                    fontSize={{ sm: 20, xts: 18 }}
                    lineHeight={{ sm: "24px", xts: "20px" }}
                    fontWeight={700}
                    display="inline"
                    color="#E87637"
                  >
                    {onePagerAssessmentResult?.important_note?.title}
                  </VSNTypography>
                </Box>

                <VSNTypography
                  mt={1}
                  color="#424450"
                  className={classes.desc}
                  dangerouslySetInnerHTML={{
                    __html:
                      onePagerAssessmentResult?.important_note?.description,
                  }}
                />
              </Box>
            ) : null}

            <VSNImage src={Box1} alt="box1" className={classes.box1} />
            <VSNImage
              src={Box3}
              alt="perceived_social"
              className={
                !onePagerAssessmentResult?.perceived_social_support_categories
                  .length
                  ? classes.box3
                  : classes.box2
              }
            />
            <VSNImage src={Box4} alt="box4" className={classes.box4} />
          </Stack>
        </Grid>
        <Grid item xl={6} lg={6} xs={12}>
          <Stack className={classes.assessmentReportCard}>
            <VSNTypography
              variant="h3"
              component="h3"
              className={classes.mdHeading}
              mb={1}
            >
              {onePagerAssessmentResult?.summary?.title}
            </VSNTypography>
            <Stack
              direction={{ md: "row" }}
              mt={2}
              spacing={2}
              textAlign="center"
            >
              <Box flex={1}>
                <LineChart
                  score={
                    onePagerAssessmentResult?.summary?.score_over_the_years
                      ?.data
                  }
                  maxScore={onePagerAssessmentResult?.max_score}
                  result={onePagerAssessmentResult?.result}
                />
                <VSNTypography
                  sx={{ color: "#86650A", fontWeight: 600, marginTop: -1 }}
                >
                  {
                    onePagerAssessmentResult?.summary?.score_over_the_years
                      ?.title
                  }
                </VSNTypography>
              </Box>
              <Box flex={1}>
                <BarChart
                  score={onePagerAssessmentResult?.latest_score}
                  maxScore={onePagerAssessmentResult?.max_score}
                  //   score={
                  //     onePagerAssessmentResult?.summary?.comparative_score?.score
                  //   }
                />
                <VSNTypography
                  sx={{ color: "#86650A", fontWeight: 600, marginTop: -1 }}
                >
                  {onePagerAssessmentResult?.summary?.comparative_score?.title}
                </VSNTypography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xl={6} lg={6} xs={12}>
          <Stack className={classes.assessmentReportCard}>
            <VSNTypography
              variant="h3"
              component="h3"
              className={classes.mdHeading}
              mb={1}
            >
              {onePagerAssessmentResult?.impact_on_health_and_wellbeing?.title}
            </VSNTypography>
            <VSNTypography component="p" className={classes.desc}>
              {
                onePagerAssessmentResult?.impact_on_health_and_wellbeing
                  ?.description
              }
            </VSNTypography>
            <List className={classes.dailyFunctioning}>
              {onePagerAssessmentResult?.impact_on_health_and_wellbeing?.objectives?.map(
                (el, index) => (
                  <ListItem key={index}>
                    <Box mt={0.5}>
                      <VSNImage src={el?.icon} alt="icon" />
                    </Box>
                    <VSNTypography color="secondary.main">
                      <span style={{ color: "#86650A", fontWeight: 500 }}>
                        {el?.title ?? ""}{" "}
                      </span>
                      {el?.description}
                    </VSNTypography>
                  </ListItem>
                )
              )}
            </List>
          </Stack>
        </Grid>
        <SwiperCardComponent
          title={onePagerAssessmentResult?.recommendation?.title ?? ""}
          description={
            onePagerAssessmentResult?.recommendation?.description ?? ""
          }
          data={onePagerAssessmentResult?.recommendation?.objectives ?? []}
        />
        <Grid item md={12} xs={12}>
          <Stack
            className={classes.assessmentReportCard}
            position="relative"
            overflow="hidden"
          >
            <VSNImage
              src={blueFlower}
              alt="blueFlower"
              className={classes.blueFlower}
            />
            <VSNImage
              src={pinkFlower}
              alt="pinkFlower"
              className={classes.pinkFlower}
            />
            <VSNImage
              src={threeLines}
              alt="threeLines"
              className={classes.threeLines}
            />
            <VSNImage
              src={threeLinesGray}
              alt="threeLinesGray"
              className={classes.threeLinesgray}
            />

            <VSNTypography
              variant="h3"
              component="h3"
              className={classes.mdHeading}
              mb={1}
            >
              {onePagerAssessmentResult?.when_to_seek_help?.title ??
                "When to Seek Help"}
            </VSNTypography>
            <Stack
              direction={{ sm: "row" }}
              alignItems="center"
              spacing={4}
              mt={1.75}
            >
              <Box flex="0 0 auto" mb={{ sm: 0, xs: 2 }}>
                <VSNImage
                  src={onePagerAssessmentResult?.when_to_seek_help?.image}
                  alt="seek"
                />
              </Box>
              <div
                className={classes.headDesc}
                dangerouslySetInnerHTML={{
                  __html:
                    onePagerAssessmentResult?.when_to_seek_help?.description,
                }}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
