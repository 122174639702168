import { Box } from "@mui/material";
import React from "react";
import { SwiperSlide } from "swiper/react";
import { VSNSwiper } from "../../../shared/VSNSwiper";
import VSNPaper from "../../../shared/VSNPaper";
import VSNVideo from "../../../shared/VSNVideo";
import VSNTypography from "../../../shared/VSNTypography";
import { TeamDashboardStyle } from "./TeamDashboardStyle";

export default function VideoSection({
  teamProfile,
  currentPlayingIndex,
  setCurrentPlayingIndex,
  handleVideoPlay,
}) {
  const classes = TeamDashboardStyle();
  return (
    <Box className={`${classes.yourPersonalitySliderMain}`}>
      <VSNSwiper
        className={classes.personalityMain}
        pagination
        spaceBetween={20}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          500: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          },
          1450: {
            slidesPerView: 4,
          },
        }}
      >
        {teamProfile?.vasana_content_to_accelerate_your_teams_performance?.map(
          (el, i) => (
            <SwiperSlide className={classes.personalityBoxMain} key={i}>
              <VSNPaper className={classes.videosBox}>
                <Box className={classes.videoOut}>
                  <VSNVideo
                    key={i}
                    url={el?.url}
                    thumbnail={el?.thumbnail}
                    isPlaying={currentPlayingIndex === i}
                    onPlay={() => handleVideoPlay(i)}
                    setCurrentPlayingIndex={setCurrentPlayingIndex}
                  />
                </Box>
                <Box className={classes.contentDescription}>
                  <VSNTypography variant="h6" component="h6">
                    {el?.title}
                  </VSNTypography>
                  <VSNTypography variant="body1" component="p">
                    {el?.description}
                  </VSNTypography>
                </Box>
              </VSNPaper>
            </SwiperSlide>
          )
        )}
      </VSNSwiper>
    </Box>
  );
}
