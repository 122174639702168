import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";
import { handleNavigation } from "../../shared/CustomFunc";

function PublicRoute() {
  const navigate = useNavigate();
  const userRoleData = useSelector((state) => state.app?.userData);

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   const userName = localStorage.getItem("user-name");
  //   if ((token) && userRoleData?.profile_updated) {
  //     navigate("/get-started");
  //   }
  // }, [localStorage]);

  useEffect(() => {
    handleNavigation(navigate, userRoleData);
  }, [userRoleData]);

  return <Outlet />;
}

export default PublicRoute;
