import React from "react";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import classNames from "classnames";
import VSNImage from "./VSNImage";
import ShapeOne from "../assets/errorPage/svg/bg-shape-1.svg";
import ShapeTwo from "../assets/errorPage/svg/bg-shape-2.svg";
import ShapeThree from "../assets/errorPage/svg/bg-shape-3.svg";
import ShapeFour from "../assets/errorPage/svg/bg-shape-4.svg";
import VSNTypography from "./VSNTypography";

const useStyles = makeStyles((theme) => ({
  titleHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: [[0, 20]],
    height: "calc(100vh - 105px)",
    "& svg": {
      fontSize: 96,
      marginBottom: 40,
      [theme.breakpoints.down("md")]: {
        fontSize: 70,
        marginBottom: 20,
      },
    },
  },
  contentWrapper: {
    maxWidth: "1105px",
    width: "100%",
    margin: "auto",
    textAlign: "center",
    position: "relative",
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      padding: 0,
    },
  },
  shapeOne: {
    position: "absolute",
    top: "60%",
    left: -25,
    zIndex: -1,
    transform: "translateY(-50%)",
    [theme.breakpoints.down("sm")]: {
      top: "30%",
      left: "0%",
      height: 14,
      width: "auto",
    },
  },
  shapeTwo: {
    position: "absolute",
    top: "52%",
    right: -50,
    transform: "translateY(-50%)",
    zIndex: -1,
    height: 32,
    width: 33,
  },
  shapeThree: {
    position: "absolute",
    top: "12%",
    right: "25%",
    transform: "translate(-50%)",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      top: "0",
      right: "12%",
      height: 14,
      width: "auto",
    },
  },
  shapeFour: {
    position: "absolute",
    top: "14%",
    left: "25%",
    transform: "translate(-50%)",
    zIndex: -1,
    opacity: 0.15,
    [theme.breakpoints.down("sm")]: {
      left: "12%",
      height: 14,
      width: "auto",
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 46,
      lineHeight: "60px",
      color: "#E87637",
      fontWeight: 700,
      marginTop: 25,
      [theme.breakpoints.down("md")]: {
        fontSize: 34,
        lineHeight: "45px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 25,
        lineHeight: "35px",
      },
    },
  },
  desc: {
    "&.MuiTypography-root": {
      fontSize: 18,
      lineHeight: "33px",
      color: "#747787",
      marginTop: 10,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        lineHeight: "24px",
      },
      "& a": {
        color: "#E87637",
      },
    },
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 16,
    gap: 10,
    "& .MuiTypography-root": {
      color: "#E87637",
      fontSize: 18,
      fontWeight: 500,
    },
    "& .MuiSvgIcon-root": {
      marginBottom: 0,
      height: 24,
      width: "auto",
      color: "#E87637",
    },
  },
  imgWrapper: {
    marginRight: 90,
    [theme.breakpoints.down("475")]: {
      marginRight: 50,
    },
    [theme.breakpoints.down("375")]: {
      marginRight: 20,
    },
  },
}));
const ErrorPage = ({ image, title, location }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.titleHead}>
        <div className={classes.contentWrapper}>
          <VSNImage
            src={ShapeOne}
            className={`${classes.shape} ${classes.shapeOne}`}
            alt="ShapeOne"
          />
          <VSNImage
            src={ShapeTwo}
            className={`${classes.shape} ${classes.shapeTwo}`}
            alt="ShapeTwo"
          />
          <VSNImage
            src={ShapeThree}
            className={`${classes.shape} ${classes.shapeThree}`}
            alt="ShapeThree"
          />
          <VSNImage
            src={ShapeFour}
            className={`${classes.shape} ${classes.shapeFour}`}
            alt="ShapeFour"
          />
          <div
            className={classNames({
              [classes.imgWrapper]: location !== "error",
            })}
          >
            <VSNImage src={image} alt="main" />
          </div>
          <VSNTypography variant="h3" className={classes.heading}>
            <i>{title}</i>
          </VSNTypography>
          {location === "error" ? (
            <>
              <VSNTypography className={classes.desc}>
                {`Something seems to be wrong here. An error has occured and we're working to fix the problem! We'll be up and running shortly. If you need help urgently, contact us at `}{" "}
                <Link to="/">huma@vasana.ai</Link> and we will help out.
              </VSNTypography>
              <Link to={-1} className={classes.btn}>
                <KeyboardBackspaceIcon />
                <VSNTypography>Go Back</VSNTypography>
              </Link>{" "}
            </>
          ) : (
            <>
              <VSNTypography
                className={classes.desc}
              >{`We looked all over but this page seems to be lost in cyberspace. Let's find a better place for you.`}</VSNTypography>
              <Link to="/" className={classes.btn}>
                <VSNTypography>Vasana Home</VSNTypography>
                <TrendingFlatIcon />
              </Link>{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
