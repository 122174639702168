import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LOADING_CHANGE,
  ON_FLAG_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_ERROR,
} from "../../redux/constants";
import { checkValid } from "../../shared/validation";
import { ApiContainer } from "../../utils/api";
import { clearFormData, getEndpoint, setErrors } from "../../shared/CustomFunc";
import { equal, length, values } from "../../utils/javascript";
import ApiCalls from "../../utils/apiCall";
import { department_admin, organization_admin } from "../../utils/constant";
import { ERROR_MESSAGE } from "../../description/errorMessage.description";

export const createDepartmentContainer = ({ toggleModelShow }) => {
  const { api } = ApiContainer();
  const { getRoleInfo } = ApiCalls();
  const dispatch = useDispatch();
  const formPath = { parent: "departmentInfo" };
  const { form } = useSelector((state) => state);
  const departmentInfo = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const createDepartmentModel = useSelector(
    (state) => state?.modelStates?.createDepartment
  );

  const organization_original_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_original_id
  );
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);
  const location_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.location_id
  );
  const department_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.department_id
  );
  const refetchLocationData = useSelector(
    (state) => state?.app?.flags?.refetchLocationData
  );
  const departmentToEdit = useSelector((state) => state.api?.departmentToEdit);
  const editDepartment = useSelector(
    (state) => state.modelStates?.editDepartment
  );
  const locationFiltersLoader = useSelector(
    (state) => state.api?.loader?.locationFilters
  );
  const locationFilters = useSelector((state) => state.api?.locationFilters);
  const editModelState = useSelector(
    (state) => state.modelStates?.editDepartment
  );
  const departmentType = useSelector((state) => state.api?.departmentType);
  const payloadForEndPoint = {
    organization_original_id,
    location_id,
    department_id,
    role,
  };

  const apiCall = async () => {
    const { department_name } = departmentInfo;
    const dataToSend = {
      name: department_name,
      address_line_1: departmentInfo?.address_line_1,
      address_line_2: departmentInfo?.address_line_2,
      city: departmentInfo?.city,
      zip_code: departmentInfo?.zip_code,
      type_id: departmentInfo?.type_id,
    };
    if (departmentToEdit?.department_original_id) {
      const data = await api({
        method: "PUT",
        endPoint: `organization/${organization_original_id}/locations/${departmentToEdit?.location_original_id}/departments/${departmentToEdit?.department_original_id}`,
        data: dataToSend,
        showToastMessage: true,
        urlencoded: false,
        needLoader: true,
        parent: "createDepartment",
      });
      if (data?.status) {
        dispatch(toggleModelShow("editDepartment"));
        dispatch(clearFormData(formPath.parent));
        dispatch({ type: LOADING_CHANGE, payload: { refetchOrgData: true } });
        dispatch({ type: ON_FLAG_CHANGE, payload: { refetchData: true } });
        if (role === department_admin) {
          dispatch(getRoleInfo({ needLoader: true, parent: "getRoleInfo" }));
        }
      }
    } else {
      const data = await api({
        method: "POST",
        endPoint: getEndpoint({
          ...payloadForEndPoint,
          endPoint: "departments",
        }),
        data: departmentInfo,
        showToastMessage: true,
        urlencoded: false,
        parent: "createDepartment",
        needLoader: true,
      });
      if (data?.status) {
        dispatch(toggleModelShow("createDepartment"));
        dispatch(clearFormData(formPath.parent));
        dispatch({
          type: SET_API_DATA,
          payload: { createdOrganization: data.data },
        });
        dispatch({ type: LOADING_CHANGE, payload: { refetchOrgData: true } });
        dispatch({ type: ON_FLAG_CHANGE, payload: { refetchData: true } });
      }
    }
  };

  const getLocationFilter = async () => {
    if (role !== organization_admin) return;
    const data = await api({
      method: "GET",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "locations/list",
      }),
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "locationFilters",
    });
    if (data?.status) {
      let locationFilters = [];
      locationFilters = [
        ...locationFilters,
        ...data.data.map(({ location_name, location_original_id }) => ({
          key: `${location_name} (${location_original_id})`,
          value: location_original_id,
        })),
      ];
      dispatch({
        type: SET_API_DATA,
        payload: {
          locationFilters,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          locationFilters: [],
        },
      });
    }
  };

  const getDepartmentType = async () => {
    const departmentType = await api({
      method: "GET",
      endPoint: `organization/department-types`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessmentSummary1",
    });
    if (departmentType?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          departmentType: departmentType?.data?.map(({ id, type }) => ({
            key: type,
            value: id,
          })),
        },
      });
    }
  };

  useEffect(() => {
    if (refetchLocationData) {
      getLocationFilter(true);
    }
  }, [refetchLocationData, createDepartmentModel]);

  useEffect(() => {
    if (role !== department_admin && organization_original_id) {
      getLocationFilter(true);
    }
  }, [organization_original_id]);
  useEffect(() => {
    if (!departmentType) getDepartmentType();
  }, []);

  const createDepartmentAttribute = [
    {
      label: "DEPARTMENT DETAILS",
      value: [
        {
          control: "input",
          label: "Department Name",
          type: "text",
          name: "name",
          value: "",
          isRequired: true,
          validateAs: "required",
          placeHolder: "Enter Department name",
          errorMessage: ERROR_MESSAGE?.department_name,
          fullWidth: true,
          md: role === organization_admin ? 4 : 6,
        },
        {
          control: "select",
          label: "Department Type",
          name: "type_id",
          value: 0,
          validateAs: "selectMenu",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Department Type",
          errorMessage: ERROR_MESSAGE?.department_type,
          loading: locationFiltersLoader,
          options: departmentType ?? [],
          md: role === organization_admin ? 4 : 6,
          variant: "standard",
        },
        role !== organization_admin
          ? { doNotShow: true }
          : {
              control: "select",
              label: "Location",
              name: "location_id",
              value: 0,
              validateAs: "selectMenu",
              isRequired: true,
              fullWidth: true,
              placeHolder: "Location",
              errorMessage: ERROR_MESSAGE?.location,
              options: locationFiltersLoader
                ? [{ isLoading: true }]
                : locationFilters?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              md: 4,
              variant: "standard",
            },
        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
        },
      ],
    },
    {
      label: "DEPARTMENT ADMIN DETAILS",
      value: [
        {
          control: "input",
          label: "First Name",
          type: "text",
          name: "first_name",
          value: "",
          validateAs: "required",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Enter first name",
          errorMessage: ERROR_MESSAGE?.first_name,
          md: 6,
        },
        {
          control: "input",
          label: "Last Name",
          type: "text",
          name: "last_name",
          value: "",
          validateAs: "required",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Enter last name",
          errorMessage: ERROR_MESSAGE?.last_name,
          md: 6,
        },
        {
          control: "mobile",
          label: "Mobile Number",
          type: "tel",
          name: "phone_number",
          value: "",
          validateAs: "mobile",
          isRequired: true,
          fullWidth: true,
          placeHolder: "",
          errorMessage: ERROR_MESSAGE?.mobile_number,
          inputStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            borderRadius: "0",
            maxWidth: "100%",
            width: "100%",
          },
          buttonStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            backgroundColor: "transparent",
            borderRadius: "0",
          },
          containerStyle: {
            marginTop: 7,
          },
          dropdownStyle: {
            bottom: "100%",
          },
          md: 6,
        },
        {
          control: "input",
          label: "Email Address",
          type: "email",
          name: "email",
          value: "",
          isRequired: true,
          fullWidth: true,
          validateAs: "email",
          placeHolder: "Enter email address",
          md: 6,
          errorMessage: ERROR_MESSAGE?.email_address,
        },
      ],
    },
  ];

  const editDepartmentAttribute = [
    {
      label: "DEPARTMENT DETAILS",
      value: [
        {
          control: "input",
          label: "Department Name",
          type: "text",
          name: "department_name",
          value: "",
          isRequired: true,
          validateAs: "required",
          placeHolder: "Enter Department name",
          errorMessage: ERROR_MESSAGE?.department_name,
          fullWidth: true,
          md: 6,
        },
        {
          control: "select",
          label: "Department Type",
          name: "type_id",
          value: 0,
          validateAs: "selectMenu",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Department Type",
          errorMessage: ERROR_MESSAGE?.location,
          options: departmentType ?? [],
          md: 6,
          variant: "standard",
        },
        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
        },
      ],
    },
  ];

  const handleCreateDepartment = (e) => {
    e.preventDefault();
    let errors = {};

    (editModelState
      ? editDepartmentAttribute
      : createDepartmentAttribute
    )?.forEach((item) => {
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                name,
                value: departmentInfo?.[name],
                label,
              })
            : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...form.formErrors,
              [formPath.parent]: { ...errors },
            },
          },
        });
      });
    });

    if (
      equal(errors, undefined) ||
      equal(length(values(errors)?.filter(Boolean)))
    ) {
      const noChanges = equal(
        JSON.stringify(departmentInfo),
        JSON.stringify(departmentToEdit)
      );
      if (!noChanges) {
        apiCall();
      }
    }
  };

  useEffect(() => {
    if (!locationFilters || !locationFilters?.length > 0) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          locationFilters: [{ key: "No Location Found.", value: "" }],
        },
      });
    }
  }, [locationFilters]);

  useEffect(() => {
    if (!editDepartment) {
      dispatch({ type: SET_API_DATA, departmentToEdit: {} });
    }
  }, [editDepartment]);

  return {
    handleCreateDepartment,
    editDepartmentAttribute,
    departmentToEdit,
    createDepartmentAttribute,
  };
};
