import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LOADING_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_ERROR,
} from "../redux/constants";
import { checkValid } from "../shared/validation";
import { ApiContainer } from "../utils/api";
import {
  clearFormData,
  setErrors,
  toggleModelShow,
} from "../shared/CustomFunc";
import { equal, length, trimmedVal, values } from "../utils/javascript";
import { adminPoints } from "../utils/constant";
import { ERROR_MESSAGE } from "../description/errorMessage.description";

export const createOrgContainer = () => {
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const formPath = { parent: "organizationInfo" };
  const { form } = useSelector((state) => state);
  const organizationInfo = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const orgType = useSelector((state) => state.api?.orgType);
  const organizationInfoError = useSelector(
    (state) => state.form.formErrors?.[formPath.parent]
  );
  const orgToEdit = useSelector((state) => state.api?.orgToEdit);
  const createOrgLoader = useSelector((state) => state.api?.loader?.createOrg);
  const getAssessmentLoader = useSelector(
    (state) => state.api?.loader?.getAssessment
  );
  const organizationTypesLoader = useSelector(
    (state) => state.api?.loader?.organizationTypesLoader
  );
  const [step, setStep] = useState(1);

  const setFormData = (name, value) => {
    dispatch(
      setErrors({ errors: organizationInfoError, value, name, formPath })
    );
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: { ...organizationInfo, [name]: value },
        },
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(name, value);
  };

  const handleMobileChange = (mobile, name) => {
    setFormData(name, `+${mobile}`);
  };

  const getAssessmentPreference = async (organization_id) => {
    const reportsData = await api({
      method: "GET",
      endPoint: `platform-admin/organization/${organization_id}/assessments`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "getAssessment",
    });
    const clonedAssessmentReports = [];
    if (reportsData.status) {
      reportsData.data?.mandatory_assessments.forEach((el) => {
        clonedAssessmentReports.push({
          ...el,
          enabled: true,
          nonChangeable: true,
        });
      });
      reportsData.data?.required_assessments.forEach((el) => {
        clonedAssessmentReports.push({
          ...el,
          enabled: true,
        });
      });
      reportsData.data?.optional_assessments.forEach((el) => {
        clonedAssessmentReports.push({
          ...el,
          enabled: false,
        });
      });
    }
    dispatch({
      type: SET_API_DATA,
      payload: {
        accessOfAssessmentsForOrg: {
          assessments: clonedAssessmentReports || [],
          organizationId: organization_id,
        },
      },
    });
  };

  const apiCall = async () => {
    const { first_name, last_name } = organizationInfo;
    const orgData = {
      ...organizationInfo,
      first_name: trimmedVal(first_name),
      last_name: trimmedVal(last_name),
    };
    if (orgToEdit?.original_id) {
      const data = await api({
        method: "PUT",
        endPoint: `platform-admin/organizations/${orgToEdit?.original_id}`,
        data: {
          ...orgData,
        },
        showToastMessage: true,
        urlencoded: false,
        needLoader: true,
        parent: "createOrg",
      });
      if (data?.status) {
        dispatch(toggleModelShow("editOrg"));
        dispatch(clearFormData(formPath.parent));
        dispatch({ type: LOADING_CHANGE, payload: { refetchOrgData: true } });
      }
    } else {
      const data = await api({
        method: "POST",
        endPoint: adminPoints?.createOrg,
        data: {
          ...orgData,
        },
        showToastMessage: true,
        urlencoded: false,
        parent: "createOrg",
        needLoader: true,
      });
      if (data?.status) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            createdOrganization: data.data,
          },
        });
        dispatch({ type: LOADING_CHANGE, payload: { refetchOrgData: true } });
        await getAssessmentPreference(data?.data?.original_id);
        setStep(2);
        dispatch(clearFormData(formPath.parent));
      }
    }
  };

  // useEffect(() => {
  //   const a = async () => {
  //     await getAssessmentPreference("O0ZXGIGZ");
  //     setStep(2);
  //     // dispatch(toggleModelShow("assessmentPreferences"));
  //   };
  //   a();
  // }, []);

  const createOrgAttribute = [
    {
      label: "ORGANIZATION DETAILS",
      value: [
        {
          control: "input",
          label: "Organization Name",
          type: "text",
          name: "name",
          value: "",
          isRequired: true,
          validateAs: "required",
          placeHolder: "Enter organization name",
          errorMessage: ERROR_MESSAGE?.organization_name,
          fullWidth: true,
          md: 6,
        },
        {
          control: "select",
          label: "Organization Type",
          name: "type_id",
          value: 0,
          validateAs: "selectMenu",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Organization Type",
          errorMessage: ERROR_MESSAGE?.organization_type,
          options: organizationTypesLoader
            ? [{ isLoading: true }]
            : orgType?.map(({ key, value }) => ({
                key,
                value,
              })),
          md: 6,
          variant: "standard",
        },
        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
        },
      ],
    },
    {
      label: "ORGANIZATION ADMIN DETAILS",
      value: [
        {
          control: "input",
          label: "First Name",
          type: "text",
          name: "first_name",
          value: "",
          validateAs: "required",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Enter first name",
          errorMessage: ERROR_MESSAGE?.first_name,
          getValBy: "given_name",
          md: 6,
        },
        {
          control: "input",
          label: "Last Name",
          type: "text",
          name: "last_name",
          value: "",
          validateAs: "required",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Enter last name",
          errorMessage: ERROR_MESSAGE?.last_name,
          getValBy: "family_name",
          md: 6,
        },
        {
          control: "mobile",
          label: "Mobile Number",
          type: "tel",
          name: "contact_number",
          value: "",
          validateAs: "mobile",
          isRequired: true,
          fullWidth: true,
          placeHolder: "",
          errorMessage: ERROR_MESSAGE?.mobile_number,
          errorValidationMessage: "Enter valid mobile number",
          inputStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            borderRadius: "0",
            maxWidth: "100%",
            width: "100%",
          },
          buttonStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            backgroundColor: "transparent",
            borderRadius: "0",
          },
          containerStyle: {
            marginTop: 7,
          },
          dropdownStyle: {
            bottom: "100%",
          },
          md: 6,
        },
        {
          control: "input",
          label: "Email Address",
          type: "email",
          name: "email",
          value: "",
          isRequired: true,
          fullWidth: true,
          validateAs: "email",
          placeHolder: "Enter email address",
          md: 6,
          errorMessage: ERROR_MESSAGE?.email_address,
        },
      ],
    },
  ];

  const createOrg = (e) => {
    e.preventDefault();
    let errors = {};

    createOrgAttribute?.forEach((item) => {
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                name,
                value: organizationInfo?.[name],
                label,
              })
            : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...form.formErrors,
              [formPath.parent]: { ...errors },
            },
          },
        });
      });
    });
    if (
      equal(errors, undefined) ||
      equal(length(values(errors)?.filter(Boolean)))
    ) {
      const noChanges = equal(
        JSON.stringify(organizationInfo),
        JSON.stringify(orgToEdit)
      );
      if (!noChanges) {
        apiCall();
      }
    }
  };

  return {
    handleMobileChange,
    createOrg,
    handleChange,
    formPath,
    organizationInfo,
    createOrgLoader: createOrgLoader || getAssessmentLoader,
    orgToEdit,
    createOrgAttribute,
    step,
  };
};
