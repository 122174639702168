import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ApiContainer } from "../utils/api";
import { SET_API_DATA } from "../redux/constants";

const onePagerResultContainer = ({ endPointToCall }) => {
  const { assessmentId } = useParams();
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const userRoleData = useSelector((state) => state.app.userData);
  const {
    assessmentList,
    assessmentResult: allAssessmentResults,
    loader,
  } = useSelector((state) => state.api);
  const result = useSelector(
    (state) => state.api.assessmentResult?.[assessmentId]
  );
  const [currentAssessment, setCurrentAssessment] = useState({});

  const getReport = async () => {
    try {
      const response = await api({
        method: "GET",
        endPoint: endPointToCall ?? `assessment/${assessmentId}/one-pager`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "assessmentResult",
      });

      if (response?.status) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            assessmentResult: {
              ...allAssessmentResults,
              [assessmentId]: response.data,
            },
          },
        });
      }
    } catch (error) {
      console.error("Failed to fetch report:", error);
    }
  };

  useEffect(() => {
    const assessment = [
      ...(userRoleData?.assessments || []),
      ...(assessmentList || []),
    ]?.find(
      (data) => data?.assessment_id?.toString() === assessmentId && data?.result
    );
    setCurrentAssessment(assessment);
  }, [userRoleData, assessmentList, assessmentId]);

  useEffect(() => {
    if (currentAssessment?.result && !result && !endPointToCall) {
      getReport();
    }
  }, [currentAssessment, result, assessmentId]);
  useEffect(() => {
    if (endPointToCall) {
      getReport();
    }
  }, []);

  return {
    onePagerAssessmentResult: result,
    loader,
    currentAssessment,
  };
};

export default onePagerResultContainer;
