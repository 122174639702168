import { Password } from "@mui/icons-material";

export const ERROR_MESSAGE = {
  first_name: "First name is required.",
  last_name: "Last name is required.",
  email_address: "Enter valid email address.",
  mobile_number: "Mobile Number is required.",
  address_line_1: "Address Line 1 is required.",
  address_line_2: "Address Line 2 is required.",
  city: "City is required.",
  zip_code: "Zip code is required.",
  team_name: "Team name is required.",
  team_goal: "Team goal is required.",
  photo: "Photo upload is required.",
  organization_name: "Organization name is required.",
  department_name: "Department name is required.",
  location_name: "Location name is required.",
  location: "Location is required.",
  organization: "Organization is required.",
  organization_type: "Organization Type is required.",
  department_type: "Department Type is required.",
  role: "Role is required.",
  gender: "Gender is required.",
  other_gender: "Other Gender is required.",
  start_date: "Start Date is required.",
  end_date: "End Date is required.",
  password: "Enter valid password.",
};
