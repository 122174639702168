// ✅ Good: top-level in a function component
import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleNavigation } from "../../shared/CustomFunc";
import VSNLoader from "../../shared/VSNLoader";

import ApiCalls from "../../utils/apiCall";

const User = () => {
  const { getRoleInfo } = ApiCalls();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage?.getItem("token");
  const userRoleInfoLoader = useSelector(
    (state) => state.api.loader?.getRoleInfo
  );
  const userRoleData = useSelector((state) => state.app?.userData);

  const userInfoData = useSelector((state) => state.form.formData?.userInfo);
  const navigateUser = async () => {
    dispatch(
      getRoleInfo({
        needLoader: true,
        parent: "getRoleInfo",
      })
    );
  };

  useEffect(() => {
    handleNavigation(navigate, userRoleData);
  }, [userRoleData]);

  useLayoutEffect(() => {
    if (token) {
      navigateUser();
    }
  }, [token]);

  return (
    <div>
      {(!token || userRoleInfoLoader || !userInfoData) && (
        <VSNLoader variant="fullPage" />
      )}
    </div>
  );
};

export default User;
