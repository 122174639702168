import { makeStyles } from "@mui/styles";

export const ChartStyle = makeStyles((theme) => ({
  donutContainer: {
    width: 340,
    height: 340,
    backgroundColor: "#fff",
    borderRadius: "50%",
    overflow: "hidden",
    margin: "0 auto",
    [theme.breakpoints.down("lg")]: {
      width: 320,
      height: 320,
    },
    [theme.breakpoints.down("sm")]: {
      width: 320,
      height: 320,
    },
    [theme.breakpoints.down("xts")]: {
      width: 230,
      height: 230,
    },
  },
  donut: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    borderRadius: "50%",
    overflow: "hidden",
    transform: "rotate(45deg)",
  },
  segment: {
    position: "absolute",
    width: "49%",
    height: "49%",
    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    "& .MuiBox-root": {
      position: "absolute",
      width: 10,
      height: 10,
      display: "block",
      borderRadius: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 6,
        height: 6,
      },
    },
    "&:nth-child(1)": {
      "& .MuiBox-root": {
        backgroundColor: "#1780c6",
        boxShadow: "0 0 0 3px #97c8e9",
        [theme.breakpoints.down("sm")]: {
          boxShadow: "0 0 0 2px #97c8e9",
        },
      },
      "&$active, &:hover": {
        backgroundColor: "#1780c6",
        boxShadow: "0 0 0 3px rgba(139, 63, 158, 0.20)",
        [theme.breakpoints.down("sm")]: {
          boxShadow: "0 0 0 2px rgba(139, 63, 158, 0.20)",
        },
        "& $label1": {
          color: "#fff",
        },
        "& .MuiBox-root": {
          backgroundColor: "#fff",
          boxShadow: "0 0 0 3px rgba(255,255,255,0.20)",
          [theme.breakpoints.down("sm")]: {
            boxShadow: "0 0 0 2px rgba(255,255,255,0.20)",
          },
        },
      },
    },
    "&:nth-child(2)": {
      "& .MuiBox-root": {
        backgroundColor: "#ffb142",
        boxShadow: "0 0 0 3px #ffe0b4",
        [theme.breakpoints.down("sm")]: {
          boxShadow: "0 0 0 2px #ffe0b4",
        },
      },
      "&$active, &:hover": {
        backgroundColor: "#ffb142",
        boxShadow: "0 0 0 3px rgba(139, 63, 158, 0.20)",
        [theme.breakpoints.down("sm")]: {
          boxShadow: "0 0 0 2px rgba(139, 63, 158, 0.20)",
        },
        "& $label2": {
          color: "#fff",
        },
        "& .MuiBox-root": {
          backgroundColor: "#fff",
          boxShadow: "0 0 0 3px rgba(255,255,255,0.20)",
          [theme.breakpoints.down("sm")]: {
            boxShadow: "0 0 0 2px rgba(255,255,255,0.20)",
          },
        },
      },
    },
    "&:nth-child(3)": {
      "& .MuiBox-root": {
        backgroundColor: "#2d8f4e",
        boxShadow: "0 0 0 3px #82c799",
        [theme.breakpoints.down("sm")]: {
          boxShadow: "0 0 0 2px #82c799",
        },
      },
      "&$active, &:hover": {
        backgroundColor: "#2d8f4e",
        boxShadow: "0 0 0 3px rgba(139, 63, 158, 0.20)",
        [theme.breakpoints.down("sm")]: {
          boxShadow: "0 0 0 2px rgba(139, 63, 158, 0.20)",
        },
        "& $label3": {
          color: "#fff",
        },
        "& .MuiBox-root": {
          backgroundColor: "#fff",
          boxShadow: "0 0 0 3px rgba(255,255,255,0.20)",
          [theme.breakpoints.down("sm")]: {
            boxShadow: "0 0 0 2px rgba(255,255,255,0.20)",
          },
        },
      },
    },
    "&:nth-child(4)": {
      "& .MuiBox-root": {
        backgroundColor: "#8b3f9e",
        boxShadow: "0 0 0 3px #dab9e2",
        [theme.breakpoints.down("sm")]: {
          boxShadow: "0 0 0 2px #dab9e2",
        },
      },
      "&$active, &:hover": {
        backgroundColor: "#8B3F9E",
        boxShadow: "0 0 0 3px rgba(139, 63, 158, 0.20)",
        [theme.breakpoints.down("sm")]: {
          boxShadow: "0 0 0 2px rgba(139, 63, 158, 0.20)",
        },
        "& $label4": {
          color: "#fff",
        },
        "& .MuiBox-root": {
          backgroundColor: "#fff",
          boxShadow: "0 0 0 3px rgba(255,255,255,0.20)",
          [theme.breakpoints.down("sm")]: {
            boxShadow: "0 0 0 2px rgba(255,255,255,0.20)",
          },
        },
      },
    },
  },
  inActive: {
    opacity: 0.5,
  },
  active: {
    "&:before": {
      content: "normal",
    },
  },
  segment1: {
    backgroundColor: "#e3f1f9",
    transform: "rotate(0deg)",
    right: 0,
  },
  segment2: {
    backgroundColor: "#fff7ec",
    transform: "rotate(90deg)",
    right: 0,
    bottom: 0,
  },
  segment3: {
    backgroundColor: "#f0f9f2",
    transform: "rotate(180deg)",
    left: 0,
    bottom: 0,
  },
  segment4: {
    backgroundColor: "#f2e7f5",
    transform: "rotate(270deg)",
  },
  circle: {
    width: 90,
    height: 90,
    backgroundColor: "#fff",
    position: "absolute",
    left: "50%",
    top: "50%",
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
    },
    "&::before": {
      content: '""',
      width: 75,
      height: 75,
      backgroundColor: "#fff",
      position: "absolute",
      left: "50%",
      top: "50%",
      borderRadius: "50%",
      transform: "translate(-50%, -50%)",
      filter:
        "drop-shadow(0px 5px 11px rgba(216, 202, 202, 0.1)) drop-shadow(0px 20px 20px rgba(216, 202, 202, 0.09))",
      backdropFilter: "blur(5px)",
      [theme.breakpoints.down("sm")]: {
        width: 70,
        height: 70,
      },
    },
    "& span": {
      position: "relative",
      transform: "rotate(-45deg)",
      fontSize: 30,
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: 26,
      },
    },
  },
  dot: {
    height: "100%",
    width: "100%",
    padding: "51px 11px 36px 45px",
    [theme.breakpoints.down("sm")]: {
      padding: "41px 5px 20px 40px",
    },
    "& > span": {
      position: "absolute",
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
  },
  dotWrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
  },

  label1: {
    left: 0,
    color: "#1780c6",
    bottom: 0,
    transform: "rotate(-90deg)",
    textAlign: "center",
    fontWeight: 700,
    transformOrigin: "4% 4%",
    width: 120,
    height: 50,
    overflow: "hidden",
    WebkitLineClamp: 3,
    display: "-webkit-box",
    wordBreak: "break-word",
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.down("sm")]: {
      width: 60,
      transformOrigin: "7% 6%",
    },
  },
  label2: {
    left: 0,
    bottom: 0,
    textAlign: "center",
    transformOrigin: "4% 4%",
    transform: "rotate(270deg)",
    color: "#ffb142",
    fontWeight: 700,
    width: 120,
    height: 50,
    overflow: "hidden",
    WebkitLineClamp: 3,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      transformOrigin: "9% 10%",
      bottom: 2,
      width: 60,
    },
  },
  label3: {
    left: 0,
    bottom: 61,
    transform: "rotate(90deg)",
    color: "#2d8f4e",
    fontWeight: 700,
    width: 120,
    height: 50,
    textAlign: "center",
    transformOrigin: "44% 0%",
    overflow: "hidden",
    WebkitLineClamp: 3,
    display: "flex",
    WebkitBoxOrient: "vertical",
    alignItems: "flex-end",
    justifyContent: "center",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      bottom: 60,
      width: 60,
      transformOrigin: "38% 56%",
    },
  },
  label4: {
    left: 0,
    bottom: 60,
    transform: "rotate(90deg)",
    color: "#8b3f9e",
    fontWeight: 700,
    textAlign: "center",
    transformOrigin: "44% 0%",
    width: 120,
    height: 50,
    overflow: "hidden",
    WebkitLineClamp: 3,
    display: "flex",
    WebkitBoxOrient: "vertical",
    alignItems: "flex-end",
    justifyContent: "center",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      bottom: 60,
      width: 60,
      transformOrigin: "38% 55%",
    },
  },
}));
