import { Box, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import { ReactSortable } from "react-sortablejs";
import { useSelector } from "react-redux";
import { DragIcon, InfoIcon, DragDisableIcon } from "../../shared/icon";
import VSNTypography from "../../shared/VSNTypography";
import { ApiContainer } from "../../utils/api";
import VSNToolTip from "../../shared/VSNToolTip";
import { organization_admin } from "../../utils/constant";
import { getEndpoint } from "../../shared/CustomFunc";

const useStyles = makeStyles((theme) => ({
  assessmentTableCard: {
    width: "100%",
    flex: 1,
    backgroundColor: "white",
    cursor: "move",
    "& .MuiTypography-root": {
      color: theme.palette.secondary.grey,
      padding: 10,
      "&:first-child": {
        width: "40%",
      },
      "&:nth-child(2)": {
        width: "18%",
      },
    },
    "& svg": {
      flex: "none",
    },
    "&:hover": {
      backgroundColor: "#f1f0f0",
    },
  },
  assessmentTableCards: {
    // padding: 20,
    paddingLeft: 5,
    minWidth: 800,
  },
  assessmentTableCardsTitle: {
    alignItems: "center",
    "& p": {
      fontSize: 18,
      padding: 10,
      "& svg": {
        verticalAlign: "middle",
      },
      "&:first-child": {
        width: "40%",
      },
      "&:nth-child(2)": {
        width: "18%",
      },
      "&:nth-child(3)": {
        width: "12%",
        textAlign: "center",
      },
      "&:nth-child(4)": {
        width: "13%",
        textAlign: "center",
      },
      "&:nth-child(5)": {
        width: "12%",
        textAlign: "center",
      },
      "&:last-child": {
        width: "5%",
      },
    },
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  "&.MuiSwitch-root": {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          background: "#555FDD",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.3,
          background: "#555FDD",
        },
      },
      "& + .MuiSwitch-track": {
        background: "#00000040",
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: "all 0.4s ease-in-out",
    },
    "& .MuiSwitch-track": {
      borderRadius: 10,
      opacity: 1,
      boxSizing: "border-box",
    },
  },
}));

let count;
const Item = ({
  item,
  draggable,
  classes,
  handleSwitchChange,
  mandatory,
  role,
  onSignUpSwitchChange,
  onLockSwitchChange,
}) => {
  count += 1;
  return (
    <Stack
      direction="row"
      sx={{ cursor: draggable ? "move !important" : "auto !important" }}
      className={classes.assessmentTableCard}
    >
      <VSNTypography>{item?.name || ""}</VSNTypography>
      <VSNTypography
        sx={{
          color: `${
            item?.assessment_type === "Behavioral" ? "#2D8F4E" : "#555FDD"
          } !important`,
        }}
      >
        {item?.assessment_type || ""} Assessment
      </VSNTypography>

      <Stack
        direction="row"
        alignItems="center"
        // ml="auto"
        width="12%"
        flex="none"
        spacing={1}
        p="10px 20px 10px 10px"
        justifyContent="center"
      >
        <AntSwitch
          sx={item?.nonChangeable ? { pointerEvents: "none" } : {}}
          disabled={item?.nonChangeable || mandatory}
          checked={item?.enabled || false}
          onChange={(event) =>
            !item?.nonChangeable ? handleSwitchChange(event, item) : null
          }
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        // ml="auto"
        width="13%"
        flex="none"
        spacing={1}
        p="10px 20px 10px 10px"
        justifyContent="center"
      >
        <AntSwitch
          sx={item?.nonChangeable ? { pointerEvents: "none" } : {}}
          disabled={item?.nonChangeable || !item?.required || mandatory}
          checked={item?.signUpFlow || false}
          onChange={(event) =>
            !item?.nonChangeable ? onSignUpSwitchChange(event, item) : null
          }
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        // ml="auto"
        width="12%"
        flex="none"
        spacing={1}
        p="10px 20px 10px 10px"
        justifyContent="center"
      >
        <AntSwitch
          sx={item?.nonChangeable ? { pointerEvents: "none" } : {}}
          disabled={item?.nonChangeable || mandatory}
          checked={mandatory ? false : item?.locked || false}
          onChange={(event) =>
            !item?.nonChangeable ? onLockSwitchChange(event, item) : null
          }
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        width="5%"
        // ml="auto"
        flex="none"
        spacing={1}
        p="10px 20px 10px 10px"
        justifyContent="center"
      >
        {!mandatory && draggable ? (
          <DragIcon />
        ) : (
          <VSNToolTip
            placement="bottom"
            arrow
            title={
              mandatory
                ? "You can not change this assessment order."
                : "Enable toggle button to reorder assessment as required."
            }
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "#fff",
                    boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                    color: "secondary.main",
                    py: 1,
                    px: 1.5,
                    fontSize: 14,
                    borderRadius: 3,
                    "& .MuiTooltip-arrow": {
                      color: "#fff",
                    },
                  },
                },
              },
            }}
          >
            <span>
              <DragDisableIcon />
            </span>
          </VSNToolTip>
        )}
      </Stack>
    </Stack>
  );
};

export default function AssessmentsTab({ data }) {
  count = 0;
  const classes = useStyles();
  const { api } = ApiContainer();
  const organization_original_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_original_id
  );
  const location_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.location_id
  );
  const department_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.department_id
  );
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);
  const [assessments, setAssessments] = useState({});
  const payloadForEndPoint = {
    organization_original_id,
    location_id,
    department_id,
    role,
  };
  useEffect(() => {
    setAssessments(data || {});
  }, [data]);

  const onUpdateIndex = async ({ updatedItems }) => {
    await api({
      method: "POST",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "assessments/change-order",
      }),
      data: {
        assessments: updatedItems,
      },
      showToastMessage: true,
      urlencoded: false,
    });
  };

  const handleSetData = (value) => {
    if (value?.oldIndex === value?.newIndex) return;
    const draggedItem = assessments?.required_assessments?.[value?.oldIndex];
    const newItems = [...(assessments?.required_assessments || [])];
    newItems.splice(value?.oldIndex, 1);
    newItems.splice(value?.newIndex, 0, draggedItem);
    const temp = { ...assessments, required_assessments: newItems };
    setAssessments(temp);
    const combinedAssessments = [
      ...temp.mandatory_assessments,
      ...temp.required_assessments,
      ...temp.optional_assessments,
    ];
    const updatedItems = combinedAssessments?.map((item, index) => ({
      assessment_id: item.id,
      index,
    }));
    onUpdateIndex({ updatedItems });
  };

  const onSwitchChange = async (event, item) => {
    let required_assessments = [...(assessments?.required_assessments || [])];
    let optional_assessments = [...(assessments?.optional_assessments || [])];
    const mandatory_assessments = [
      ...(assessments?.mandatory_assessments || []),
    ];
    if (event?.target?.checked) {
      const assessment = {
        ...item,
        required: true,
        enabled: event.target.checked,
      };
      required_assessments = [
        ...(assessments?.required_assessments || []),
        { ...assessment },
      ];
      const index = optional_assessments?.findIndex(
        (el) => el?.id === item?.id
      );
      optional_assessments.splice(index, 1);
    } else {
      const assessment = {
        ...item,
        signUpFlow: false,
        required: false,
        enabled: event.target.checked,
      };
      const index = required_assessments?.findIndex(
        (el) => el?.id === item?.id
      );
      optional_assessments = [
        { ...assessment },
        ...(assessments?.optional_assessments || []),
      ];
      required_assessments.splice(index, 1);
      const updatedStateArray = [
        ...mandatory_assessments,
        ...required_assessments,
      ]?.filter((val) => val?.signUpFlow);

      const arrayToSend = updatedStateArray?.map((val) => val?.id);

      api({
        method: "POST",
        endPoint: getEndpoint({
          ...payloadForEndPoint,
          endPoint: "assessments/required-for-signup",
        }),
        data: {
          required_for_signup: arrayToSend,
        },
        showToastMessage: true,
        urlencoded: false,
      });
    }
    setAssessments({
      required_assessments: required_assessments || [],
      optional_assessments: optional_assessments || [],
      mandatory_assessments: mandatory_assessments || [],
    });
    const updatedStateArray = [
      ...mandatory_assessments,
      ...required_assessments,
    ]?.map((val) => val?.id);
    await api({
      method: "POST",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "assessments",
      }),
      data: {
        required_assessments: updatedStateArray,
      },
      showToastMessage: true,
      urlencoded: false,
    });
  };

  const onSignUpSwitchChange = async (event, item) => {
    let required_assessments = [...(assessments?.required_assessments || [])];
    const optional_assessments = [...(assessments?.optional_assessments || [])];
    const mandatory_assessments = [
      ...(assessments?.mandatory_assessments || []),
    ];
    if (event?.target?.checked) {
      const updatedAssessment = required_assessments?.map((el) => {
        if (el?.id === item?.id) {
          return {
            ...el,
            signUpFlow: event.target.checked,
          };
        }
        return el;
      });
      required_assessments = updatedAssessment;
    } else {
      const updatedAssessment = required_assessments?.map((el) => {
        if (el?.id === item?.id) {
          return {
            ...el,
            signUpFlow: event.target.checked,
          };
        }
        return el;
      });
      required_assessments = updatedAssessment;
    }
    setAssessments({
      required_assessments: required_assessments || [],
      optional_assessments: optional_assessments || [],
      mandatory_assessments: mandatory_assessments || [],
    });
    const updatedStateArray = [
      ...mandatory_assessments,
      ...required_assessments,
    ]?.filter((val) => val?.signUpFlow);

    const arrayToSend = updatedStateArray?.map((val) => val?.id);
    await api({
      method: "POST",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "assessments/required-for-signup",
      }),
      data: {
        required_for_signup: arrayToSend,
      },
      showToastMessage: true,
      urlencoded: false,
    });
  };
  const onLockSwitchChange = async (event, item) => {
    let required_assessments = [...(assessments?.required_assessments || [])];
    let optional_assessments = [...(assessments?.optional_assessments || [])];
    const mandatory_assessments = [
      ...(assessments?.mandatory_assessments || []),
    ];
    const updatedAssessment = required_assessments?.map((el) => {
      if (el?.id === item?.id) {
        return {
          ...el,
          locked: event.target.checked,
        };
      }
      return el;
    });
    required_assessments = updatedAssessment;
    const updatedOptionalAssessmentAssessment = optional_assessments?.map(
      (el) => {
        if (el?.id === item?.id) {
          return {
            ...el,
            locked: event.target.checked,
          };
        }
        return el;
      }
    );
    optional_assessments = updatedOptionalAssessmentAssessment;
    setAssessments({
      required_assessments: required_assessments || [],
      optional_assessments: optional_assessments || [],
      mandatory_assessments: mandatory_assessments || [],
    });
    await api({
      method: "POST",
      endPoint: getEndpoint({
        ...payloadForEndPoint,
        endPoint: "assessments/is-locked",
      }),
      data: {
        assessment_id: item?.id,
        is_locked: event?.target?.checked,
      },
      showToastMessage: true,
      urlencoded: false,
    });
  };

  return (
    <Box className={classes.assessmentTableCards}>
      <Stack className={classes.assessmentTableCardsTitle} direction="row">
        <Typography variant="body1">Assessment Name</Typography>
        <Typography variant="body1">Assessment Type</Typography>
        <Typography variant="body1">
          Required
          <VSNToolTip
            placement="top"
            arrow
            title="This assessment should require for all the users. To rearrange the order of assessments, click on the six-dot icon beside each assessment and drag it to the desired position."
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "#fff",
                    boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                    color: "secondary.main",
                    py: 1,
                    px: 1.5,
                    fontSize: 14,
                    borderRadius: 3,
                    "& .MuiTooltip-arrow": {
                      color: "#fff",
                    },
                  },
                },
              },
            }}
          >
            <span>
              {" "}
              <InfoIcon />
            </span>
          </VSNToolTip>
        </Typography>
        <Typography variant="body1">
          Signup Flow
          <VSNToolTip
            placement="top"
            arrow
            title="This assessment comes while user signup."
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "#fff",
                    boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                    color: "secondary.main",
                    py: 1,
                    px: 1.5,
                    fontSize: 14,
                    borderRadius: 3,
                    "& .MuiTooltip-arrow": {
                      color: "#fff",
                    },
                  },
                },
              },
            }}
          >
            <span>
              {" "}
              <InfoIcon />
            </span>
          </VSNToolTip>
        </Typography>
        <Typography variant="body1">
          Lock
          <VSNToolTip
            placement="top"
            arrow
            title="User can't see this results until clinician release reports."
            slotProps={{
              popper: {
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "#fff",
                    boxShadow: "0px 6.447px 8px 0px rgba(0, 0, 0, 0.10)",
                    color: "secondary.main",
                    py: 1,
                    px: 1.5,
                    fontSize: 14,
                    borderRadius: 3,
                    "& .MuiTooltip-arrow": {
                      color: "#fff",
                    },
                  },
                },
              },
            }}
          >
            <span>
              {" "}
              <InfoIcon />
            </span>
          </VSNToolTip>
        </Typography>
        <Typography> </Typography>
        {/* <Typography variant="body1"> </Typography> */}
      </Stack>
      {assessments?.mandatory_assessments?.map((item, index) => (
        <Item
          key={index}
          item={item}
          classes={classes}
          mandatory
          handleSwitchChange={(event, item) => onSwitchChange(event, item)}
          onSignUpSwitchChange={(event, item) =>
            onSignUpSwitchChange(event, item)
          }
          onLockSwitchChange={(event, item) => onLockSwitchChange(event, item)}
          role={role}
        />
      ))}
      <ReactSortable
        filter=".addImageButtonContainer"
        dragClass="sortableDrag"
        list={assessments?.required_assessments || []}
        setList={() => {}}
        onEnd={handleSetData}
        animation="200"
        easing="ease-out"
      >
        {assessments?.required_assessments?.map((item, index) => (
          <Item
            key={index}
            item={item}
            draggable
            classes={classes}
            handleSwitchChange={(event, item) => onSwitchChange(event, item)}
            onSignUpSwitchChange={(event, item) =>
              onSignUpSwitchChange(event, item)
            }
            onLockSwitchChange={(event, item) =>
              onLockSwitchChange(event, item)
            }
            role={role}
          />
        ))}
      </ReactSortable>
      {assessments?.optional_assessments?.map((item, index) => (
        <Item
          key={index}
          item={item}
          classes={classes}
          handleSwitchChange={(event, item) => onSwitchChange(event, item)}
          onSignUpSwitchChange={(event, item) =>
            onSignUpSwitchChange(event, item)
          }
          onLockSwitchChange={(event, item) => onLockSwitchChange(event, item)}
          role={role}
        />
      ))}
    </Box>
  );
}
