import { ON_FLAG_CHANGE, SET_USER_DATA } from "../constants";

const initialState = {
  userData: {},
  flags: {},
};

const App = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: { ...state?.userData, ...payload },
      };
    case ON_FLAG_CHANGE:
      return {
        ...state,
        flags: { ...state?.flags, ...payload },
      };
    default:
      return state;
  }
};

export default App;
