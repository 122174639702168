import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { ON_FLAG_CHANGE, SET_API_DATA, SET_ERROR } from "../../redux/constants";
import { checkValid } from "../../shared/validation";
import { equal, length, sendData, values } from "../../utils/javascript";
import { clearFormData, toggleModelShow } from "../../shared/CustomFunc";
import { ApiContainer } from "../../utils/api";
import { department_admin } from "../../utils/constant";
import { ERROR_MESSAGE } from "../../description/errorMessage.description";

const formPath = { parent: "eventScheduleInfo" };
const editDataSend = [
  "lab_draws_start_date",
  "lab_draws_end_date",
  "lab_draws_address_line_1",
  "lab_draws_address_line_2",
  "lab_draws_city",
  "lab_draws_zip_code",
  "diagnostic_testing_start_date",
  "diagnostic_testing_end_date",
  "diagnostic_testing_address_line_1",
  "diagnostic_testing_address_line_2",
  "diagnostic_testing_city",
  "diagnostic_testing_zip_code",
  "provider_exam_start_date",
  "provider_exam_end_date",
  "provider_exam_address_line_1",
  "provider_exam_address_line_2",
  "provider_exam_city",
  "provider_exam_zip_code",
];

const CreateEventScheduleContainer = () => {
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state);
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);
  const eventScheduleInfo = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const eventScheduleData = useSelector(
    (state) => state.api?.eventScheduleData
  );
  const eventScheduleToEdit = useSelector(
    (state) => state.api?.eventScheduleToEdit
  );
  const organization_original_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_original_id
  );
  const { api } = ApiContainer();
  const createEventScheduleModel = useSelector(
    (state) => state?.modelStates?.createEventSchedule
  );

  const createEventScheduleAttribute = [
    {
      label: "Lab Draws",
      value: [
        {
          control: "date",
          label: "Start Date",
          name: "lab_draws_start_date",
          value: null,
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.start_date,
          fullWidth: true,
          md: 6,
          disabledPast: true,
          minDate: dayjs(),
          readOnly: role === department_admin,
        },
        {
          control: "date",
          label: "End Date",
          name: "lab_draws_end_date",
          value: "",
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.end_date,
          fullWidth: true,
          md: 6,
          minDate: dayjs(eventScheduleInfo?.lab_draws_start_date),
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "lab_draws_address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "lab_draws_address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "lab_draws_city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE.city,
          fullWidth: true,
          md: 6,
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "lab_draws_zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
          readOnly: role === department_admin,
        },
      ],
    },
    {
      label: "Diagnostic Testing",
      value: [
        {
          control: "date",
          label: "Start Date",
          name: "diagnostic_testing_start_date",
          value: null,
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.start_date,
          fullWidth: true,
          md: 6,
          disabledPast: true,
          minDate: dayjs(),
          readOnly: role === department_admin,
        },
        {
          control: "date",
          label: "End Date",
          name: "diagnostic_testing_end_date",
          value: "",
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.end_date,
          fullWidth: true,
          md: 6,
          minDate: dayjs(eventScheduleInfo?.diagnostic_testing_start_date),
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "diagnostic_testing_address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          readOnly: role === department_admin,
          md: 6,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "diagnostic_testing_address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "diagnostic_testing_city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "diagnostic_testing_zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
          readOnly: role === department_admin,
        },
      ],
    },
    {
      label: "Provider Exam",
      value: [
        {
          control: "date",
          label: "Start Date",
          name: "provider_exam_start_date",
          value: null,
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.start_date,
          fullWidth: true,
          md: 6,
          disabledPast: true,
          minDate: dayjs(),
          readOnly: role === department_admin,
        },
        {
          control: "date",
          label: "End Date",
          name: "provider_exam_end_date",
          value: "",
          isRequired: true,
          validateAs: "date",
          errorMessage: ERROR_MESSAGE?.end_date,
          fullWidth: true,
          md: 6,
          minDate: dayjs(eventScheduleInfo?.provider_exam_start_date),
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "Address Line 1",
          type: "text",
          name: "provider_exam_address_line_1",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter Address Line 1",
          errorMessage: ERROR_MESSAGE?.address_line_1,
          fullWidth: true,
          md: 6,
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "Address Line 2",
          type: "text",
          name: "provider_exam_address_line_2",
          value: "",
          placeHolder: "Enter Address Line 2",
          errorMessage: ERROR_MESSAGE?.address_line_2,
          fullWidth: true,
          md: 6,
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "City",
          type: "text",
          name: "provider_exam_city",
          value: "",
          isRequired: true,
          validateAs: "name",
          placeHolder: "Enter city",
          errorMessage: ERROR_MESSAGE?.city,
          fullWidth: true,
          md: 6,
          readOnly: role === department_admin,
        },
        {
          control: "input",
          label: "Zip Code",
          type: "number",
          name: "provider_exam_zip_code",
          value: "",
          isRequired: true,
          validateAs: "zipCode",
          placeHolder: "Enter zip code",
          errorMessage: ERROR_MESSAGE?.zip_code,
          fullWidth: true,
          md: 6,
          sx: {
            '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
              {
                WebkitAppearance: "none",
              },
          },
          readOnly: role === department_admin,
        },
      ],
    },
  ];

  const apiCall = async () => {
    if (eventScheduleToEdit && eventScheduleToEdit?.id) {
      const dataToSend = sendData(editDataSend, eventScheduleInfo);
      const data = await api({
        method: "PUT",
        endPoint: `organization/${organization_original_id}/locations/${eventScheduleData?.location_original_id}/departments/${eventScheduleData?.department_original_id}/event-schedule/${eventScheduleToEdit?.id}`,
        data: dataToSend,
        showToastMessage: true,
        urlencoded: false,
        needLoader: true,
        parent: "createEventSchedule",
      });
    } else {
      const data = await api({
        method: "POST",
        // endPoint: `${eventScheduleData}`,
        endPoint: `organization/${organization_original_id}/locations/${eventScheduleData?.location_original_id}/departments/${eventScheduleData?.department_original_id}/event-schedule`,
        data: eventScheduleInfo,
        showToastMessage: true,
        urlencoded: false,
        needLoader: true,
        parent: "createEventSchedule",
      });
    }
    dispatch(toggleModelShow("createEventSchedule"));
    dispatch({ type: ON_FLAG_CHANGE, payload: { refetchData: true } });
  };
  const handleCreateEventSchedule = (e) => {
    e.preventDefault();
    let errors = {};

    createEventScheduleAttribute?.forEach((item) => {
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                name,
                value: eventScheduleInfo?.[name],
                label,
              })
            : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...form.formErrors,
              [formPath.parent]: { ...errors },
            },
          },
        });
      });
    });

    if (
      equal(errors, undefined) ||
      equal(length(values(errors)?.filter(Boolean)))
    ) {
      apiCall();
    }
  };

  useEffect(() => {
    return () => {
      if (createEventScheduleModel) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            eventScheduleData: null,
            eventScheduleToEdit: null,
          },
        });
        // dispatch({ type: SET_API_DATA, eventScheduleToEdit: {} });
        // dispatch({ type: SET_API_DATA, eventScheduleData: {} });
        clearFormData("eventScheduleInfo");
      }
    };
  }, [createEventScheduleModel]);
  return {
    createEventScheduleAttribute,
    handleCreateEventSchedule,
    eventScheduleToEdit,
  };
};

export default CreateEventScheduleContainer;
