import { combineReducers } from "redux";
import App from "./app.reducer";
import Form from "./form.reducer";
import Api from "./api.reducer";
import Model from "./model.reducer";
import { LOGOUT } from "../constants";

const rootReducer = combineReducers({
  app: App,
  form: Form,
  api: Api,
  modelStates: Model,
});

const appReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return rootReducer(state, action);
};

export default appReducer;
