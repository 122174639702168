import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

export const sortBy = {
  name: "sortBy",
  label: "Sort By",
  placeHolder: "Date",
  IconComponent: () => <KeyboardArrowDownRoundedIcon />,
  options: [
    { key: "Registered date (Old first)", value: "registered_date" },
    { key: "Registered date (New first)", value: "-registered_date" },
    { key: "Completed date (Old first)", value: "completed_date" },
    { key: "Completed date (New first)", value: "-completed_date" },
  ],
  value: 0,
};
export const assessmentStatusFilter = {
  name: "assessment_given",
  label: "Status",
  placeHolder: "Assessment Status",
  IconComponent: () => <KeyboardArrowDownRoundedIcon />,
  options: [
    { key: "Assesment Given", value: "given" },
    { key: "Assesment Not Given", value: "not-given" },
  ],
  value: 0,
};

export const dateRange = {
  name: "date_filter",
  label: "Assessment Completed",
  placeHolder: "Assessment Status",
  IconComponent: () => <KeyboardArrowDownRoundedIcon />,
  value: 0,
};
export const dateRangeSignin = {
  name: "last_signin_at",
  label: "Last Visited",
  placeHolder: "Last Visited",
  IconComponent: () => <KeyboardArrowDownRoundedIcon />,
  value: 0,
};
export const orgLinkSortBy = {
  ...sortBy,
  name: "orgLinkSortBy",
  options: [
    { key: "Created date (Old first)", value: "created_date" },
    { key: "Created date (New first)", value: "-created_date" },
  ],
};

export const organizationsFiler = {
  name: "organization",
  label: "Organization",
  placeHolder: "Organizations",
  IconComponent: () => <KeyboardArrowDownRoundedIcon />,
  value: 0,
  options: [],
};

export const locationFiler = {
  name: "location",
  label: "Location",
  placeHolder: "Location",
  IconComponent: () => <KeyboardArrowDownRoundedIcon />,
  value: 0,
  options: [],
};

export const organizationsTypeFiler = {
  name: "organizationType",
  label: "Organizations Type",
  placeHolder: "Organizations Type",
  IconComponent: () => <KeyboardArrowDownRoundedIcon />,
  value: 0,
  options: [],
};

export const defaultOptions = [{ key: "All", value: 0 }];

export const pagination = {
  variant: "outlined",
  shape: "rounded",
};
