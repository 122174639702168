import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getAssessmentState, getSlug } from "../shared/CustomFunc";
import { assessmentIds } from "../utils/constant";
import { ApiContainer } from "../utils/api";
import { SET_API_DATA } from "../redux/constants";
import { showToast } from "../utils/toastService";
import { keys, length } from "../utils/javascript";

const introExtroResultContainer = ({ endPointToCall }) => {
  const navigate = useNavigate();
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const userRoleData = useSelector((state) => state.app.userData);
  const allAssessmentResults = useSelector(
    (state) => state?.api?.assessmentResult
  );
  const result = useSelector(
    (state) => state?.api?.assessmentResult?.[assessmentIds?.introExtro]
  );
  const { markerInfo, assessmentsSection, assessmentList } = useSelector(
    (state) => state?.api
  );
  const loaders = useSelector((state) => state?.api?.loader);
  const downloadReportData = useSelector(
    (state) => state.api?.downloadVasanaTypeReport
  );
  const downloadIntroExtroReportData = useSelector(
    (state) => state.api?.downloadIntroExtroReport
  );

  const [generatingIntroExtroReport, setGeneratingIntroExtroReport] = useState({
    isGenerating: false,
    reportSuccess: true,
    download: false,
  });
  const [vasanaTypeCount, setVasanaTypeCount] = useState(0);
  const [introExtroCount, setIntroExtroCount] = useState(0);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [reportLoaders, setReportLoaders] = useState({});
  const vasanaTypeInterval = useRef(null);
  const introExtroInterval = useRef(null);

  const vasanaTypeAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.vasanaType,
  });
  const introExtroAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.introExtro,
  });

  useEffect(() => {
    const assessment = [
      ...(userRoleData?.assessments || []),
      ...(assessmentList || []),
    ]?.find(
      (data) =>
        data?.assessment_id?.toString() === assessmentIds?.introExtro &&
        data?.result
    );
    setCurrentAssessment(assessment);
  }, [userRoleData, assessmentList]);

  const getReport = async () => {
    const response = await api({
      method: "GET",
      endPoint:
        endPointToCall ??
        `assessment/ie-details/${currentAssessment?.result?.toLowerCase()}`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessmentResult",
    });
    if (response?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentResult: {
            ...allAssessmentResults,
            [assessmentIds?.introExtro]: response?.data,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!endPointToCall && currentAssessment?.result) {
      getReport();
    }
  }, [currentAssessment]);
  useEffect(() => {
    if (endPointToCall) {
      getReport();
    }
  }, []);

  useEffect(() => {
    if (introExtroCount >= 36 && !generatingIntroExtroReport?.reportSuccess) {
      showToast();
      clearInterval(introExtroInterval.current);
      setIntroExtroCount(0);
    }
  }, [introExtroCount]);

  useEffect(() => {
    if (introExtroAssessment?.pdf_exists) {
      setGeneratingIntroExtroReport((prev) => ({
        ...prev,
        reportSuccess: true,
      }));
    }
  }, [introExtroAssessment]);

  useEffect(() => {
    if (
      !generatingIntroExtroReport?.isGenerating &&
      !introExtroAssessment?.pdf_exists
    ) {
      setGeneratingIntroExtroReport((prev) => ({
        ...prev,
        reportSuccess: true,
      }));
    }
  }, [generatingIntroExtroReport?.isGenerating]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAssessmentDashboardData = async () => {
    const response = await api({
      method: "GET",
      endPoint: "assessment/in-dashboard",
      showToastMessage: false,
      urlencoded: false,
    });
    if (response?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentsSection:
            response?.data?.message === "No Data Found"
              ? []
              : response?.data || [],
        },
      });
    }
  };

  useEffect(() => {
    getAssessmentDashboardData();
  }, []);

  const generateIntroExtroReport = async () => {
    setGeneratingIntroExtroReport((prev) => ({
      ...prev,
      isGenerating: true,
    }));
    if (localStorage?.getItem("token") && !downloadIntroExtroReportData) {
      const reportData = await api({
        method: "GET",
        endPoint: `assessment/${assessmentIds?.introExtro}/download-report?submission_id=${introExtroAssessment?.submission_id}`,
      });
      setGeneratingIntroExtroReport((prev) => ({
        ...prev,
        isGenerating: true,
        reportSuccess: reportData?.data?.status,
      }));
      setVasanaTypeCount((prevcount) => prevcount + 1);
      if (reportData?.data?.status === "Generating Full Report.") {
        setGeneratingIntroExtroReport((prev) => ({
          ...prev,
          isGenerating: true,
          reportSuccess: false,
          download: false,
        }));
      } else if (reportData?.data[0]?.url) {
        const url = reportData?.data[0]?.url;
        const filename = reportData?.data[0]?.file_name;
        clearInterval(introExtroInterval.current);
        setGeneratingIntroExtroReport((prev) => ({
          ...prev,
          isGenerating: false,
          reportSuccess: true,
        }));
        dispatch({
          type: SET_API_DATA,
          payload: {
            downloadIntroExtroReport: {
              url,
              filename,
            },
          },
        });
      } else {
        clearInterval(introExtroInterval.current);
        setGeneratingIntroExtroReport((prev) => ({
          ...prev,
          reportSuccess: null,
        }));
      }
    }
  };

  useEffect(() => {
    if (
      length(keys(introExtroAssessment)) &&
      introExtroAssessment?.submission_id
    ) {
      generateIntroExtroReport();
    }
  }, [introExtroAssessment]);

  useEffect(() => {
    const { isGenerating, reportSuccess } = generatingIntroExtroReport;
    if (isGenerating && reportSuccess === false) {
      introExtroInterval.current = setInterval(generateIntroExtroReport, 5000);
    }
  }, [generatingIntroExtroReport.reportSuccess]);

  const handleAssessmentClick = ({ assessmentId, type }) => {
    localStorage.setItem("currentAssessmentId", assessmentId);
    const slug = getSlug(assessmentList, assessmentId);
    navigate(`/get-started/${slug}`);
  };

  const toggleReport = ({ assessmentId }) => {
    navigate(`/`);
  };

  const downloadIntroExtroReport = () => {
    setGeneratingIntroExtroReport((prev) => ({
      ...prev,
      isGenerating: false,
      download: true,
    }));

    fetch(downloadIntroExtroReportData?.url)
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement("a");
        const filename =
          downloadIntroExtroReportData?.filename.replace(/"/g, "") ||
          userRoleData?.user_profile?.given_name
            ? `${userRoleData?.user_profile?.given_name}'s Introvert vs Extrovert Report.pdf`
            : downloadIntroExtroReportData?.filename.replace(/"/g, "");
        a.download = filename;
        const pdfBlobUrl = URL.createObjectURL(blob);
        a.href = pdfBlobUrl;
        a.click();
        setGeneratingIntroExtroReport((prev) => ({
          ...prev,
          download: false,
        }));
      });
  };

  const changeTextForIntroExtro = async () => {
    if (!downloadIntroExtroReportData) {
      setGeneratingIntroExtroReport((prev) => ({
        ...prev,
        reportSuccess: null,
        download: true,
      }));
    }
  };

  const downloadAllReports = (value) => {
    setReportLoaders((prev) => ({
      ...prev,
      [value?.assessment_id]: true,
    }));

    fetch(value?.pdf_link)
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement("a");
        const filename =
          value?.pdf_name.replace(/"/g, "") ||
          userRoleData?.user_profile?.given_name
            ? value?.pdf_name
            : value?.pdf_name.replace(/"/g, "");
        a.download = filename;
        const pdfBlobUrl = URL.createObjectURL(blob);
        a.href = pdfBlobUrl;
        a.click();
        setReportLoaders((prev) => ({
          ...prev,
          [value?.assessment_id]: false,
        }));
      });
  };

  return {
    introExtroAssessment: result,
    userRoleData,
    result: currentAssessment?.result,
    vasanaTypeAssessment,
    loaders,
    markerInfo,
    generatingIntroExtroReport,
    downloadReportData,
    downloadIntroExtroReportData,
    assessmentsSection,
    reportLoaders,
    navigate,
    changeTextForIntroExtro,
    downloadIntroExtroReport,
    downloadAllReports,
    handleAssessmentClick,
    toggleReport,
    introExtroAssessmentData: introExtroAssessment,
  };
};
export default introExtroResultContainer;
