import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ON_FORM_CHANGE, SET_ERROR } from "../redux/constants";
import { checkValid } from "../shared/validation";
import { ApiContainer } from "../utils/api";
import { clearFormData, setErrors } from "../shared/CustomFunc";
import { equal, length, values } from "../utils/javascript";
import { endPoints } from "../utils/constant";
import { ERROR_MESSAGE } from "../description/errorMessage.description";

export const contactUsContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const formPath = { parent: "contactUs" };
  const { form } = useSelector((state) => state);
  const contactUs = useSelector(
    (state) => state.form.formData?.[formPath.parent]
  );
  const contactInfoError = useSelector(
    (state) => state.form.formErrors?.[formPath.parent]
  );
  const createContactLoader = useSelector(
    (state) => state.api?.loader?.createContactUs
  );
  const [Display, setDisplay] = useState(false);

  const setFormData = (name, value) => {
    dispatch(setErrors({ errors: contactInfoError, value, name, formPath }));
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: { ...contactUs, [name]: value },
        },
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(name, value);
  };

  const handleMobileChange = (mobile, name) => {
    setFormData(name, `+${mobile}`);
  };

  const apiCall = async () => {
    const dataToSend = {
      ...contactUs,
    };
    if (Display) {
      dataToSend.interest = dataToSend.OtherInterest;
      delete dataToSend.OtherInterest;
    } else if (dataToSend.OtherInterest) {
      delete dataToSend.OtherInterest;
    }
    const data = await api({
      method: "POST",
      endPoint: endPoints?.contactUs,
      data: dataToSend,
      showToastMessage: true,
      urlencoded: false,
      parent: "createContactUs",
      needLoader: true,
    });
    if (data?.status) {
      const keys = Object?.keys(contactUs);
      keys.forEach((val) => {
        dataToSend[val] = "";
      });
      dispatch(clearFormData(formPath.parent));
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [formPath.parent]: dataToSend,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (contactUs?.interest === "Other") {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [contactUs?.interest]);

  const contactUsAttribute = [
    {
      value: [
        {
          control: "input",
          label: "First Name",
          type: "text",
          name: "first_name",
          value: "",
          isRequired: true,
          validateAs: "required",
          placeHolder: "Your First Name",
          errorMessage: ERROR_MESSAGE?.first_name,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Last Name",
          type: "text",
          name: "last_name",
          value: "",
          isRequired: true,
          validateAs: "required",
          placeHolder: "Your Last Name",
          errorMessage: ERROR_MESSAGE?.last_name,
          fullWidth: true,
          md: 6,
        },
        {
          control: "input",
          label: "Email Address",
          type: "email",
          name: "email",
          value: "",
          isRequired: true,
          fullWidth: true,
          validateAs: "email",
          placeHolder: "Enter email address",
          md: 6,
          errorMessage: ERROR_MESSAGE?.email_address,
        },
        {
          control: "mobile",
          label: "Mobile Number",
          type: "tel",
          name: "contact_number",
          value: "",
          validateAs: contactUs?.contact_number ? "mobile" : "",
          isRequired: true,
          fullWidth: true,
          placeHolder: "",
          errorMessage: ERROR_MESSAGE?.mobile_number,
          inputStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            borderRadius: "0",
            maxWidth: "100%",
            width: "100%",
          },
          buttonStyle: {
            border: "none",
            borderBottom: "1px solid #cbcbcf",
            backgroundColor: "transparent",
            borderRadius: "0",
          },
          containerStyle: {
            marginTop: 7,
          },
          dropdownStyle: {
            bottom: "100%",
          },
          md: 6,
        },
        {
          control: "input",
          label: "Job Title",
          type: "text",
          name: "job_title",
          value: "",
          validateAs: contactUs?.job_title ? "required" : "",
          fullWidth: true,
          placeHolder: "Your Job Title",
          md: 6,
        },
        {
          control: "input",
          label: "Organization",
          type: "text",
          name: "organization_name",
          value: "",
          validateAs: contactUs?.organization_name ? "required" : "",
          fullWidth: true,
          placeHolder: "Your Organization name",
          md: 6,
        },
        {
          control: "select",
          label: "I am interested in",
          name: "interest",
          value: 0,
          validateAs: "selectMenu",
          isRequired: true,
          fullWidth: true,
          placeHolder: "Select Option",
          errorMessage: "You Must Select any Option",
          options: [
            { key: "Signing up for Vasana", value: "Signing up for Vasana" },
            {
              key: "Partnering with Vasana for Public Safety Departments",
              value: "Partnering with Vasana for Public Safety Departments",
            },
            {
              key: "Booking a discovery call for my organization",
              value: "Booking a discovery call for my organization",
            },
            {
              key: "General Inquiry",
              value: "General Inquiry",
            },
            { key: "Other", value: "Other" },
          ],
          variant: "standard",
        },

        Display && {
          control: "input",
          label: "Other",
          type: "text",
          name: "OtherInterest",
          value: "",
          fullWidth: true,
          placeHolder: "Other Intrest",
          validateAs: "required",
          isRequired: true,
          errorMessage: "Other Intrest is required",
        },

        {
          control: "input",
          label: "Message",
          type: "text",
          name: "message",
          value: "",
          validateAs: contactUs?.message ? "required" : "",
          fullWidth: true,
          multiline: true,
          rows: "4",
        },
      ],
    },
  ];

  const handleCreateContact = (e) => {
    e.preventDefault();
    let errors = {};

    contactUsAttribute?.forEach((item) => {
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                name,
                value: contactUs?.[name],
                label,
              })
            : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...form.formErrors,
              [formPath.parent]: { ...errors },
            },
          },
        });
      });
    });

    if (
      equal(errors, undefined) ||
      equal(length(values(errors)?.filter(Boolean)))
    ) {
      apiCall();
    }
  };
  useEffect(() => {
    if (searchParams.get("interested") === "frontlines") {
      setFormData(
        "interest",
        "Partnering with Vasana for Public Safety Departments"
      );
    } else if (searchParams.get("interested") === "teams") {
      setFormData("interest", "Booking a discovery call for my organization");
    }
  }, []);

  return {
    handleMobileChange,
    handleCreateContact,
    handleChange,
    formPath,
    contactUs,
    createContactLoader,
    contactUsAttribute,
  };
};
