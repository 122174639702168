import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import zigZag from "../../assets/Teams/svg/zig-zag.svg";
import { ParticipantsModalStyle } from "./participantsModalStyle";
import jellyFish from "../../assets/Teams/svg/jelly-fish.svg";
import crown from "../../assets/organizationAdmin/svg/crown.svg";
import { participantsModalContainer } from "../../container/participantsModal.container";
import { renderTypesWithImage } from "../../shared/CustomFunc";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import VSNMembersAvatar from "../../shared/VSNMembersAvatar";
import VSNPagination from "../../shared/VSNPagination";
import VSNSearchBox from "../../shared/VSNSearchBox";
import VSNSelect from "../../shared/VSNSelect";
import VSNTypography from "../../shared/VSNTypography";
import { AddPersonIcon, AdminIcon, DeleteIcon } from "../../shared/icon";
import { getAvatarName, upperCase } from "../../utils/javascript";
import VSNConfirmBox from "../../shared/VSNConfirmBox";
import { organization_location_admin } from "../../utils/constant";

const ParticipantsModal = ({
  currentSelectedTeamMembers,
  getReports,
  handleAddMembers,
  currentSelectedTeam,
  isOrgAdmin,
  isTeamDashboard,
  showAdminIcon,
  admins,
  canDelete,
  isTeamView,
  hideAddMember,
}) => {
  const classes = ParticipantsModalStyle();
  const matches = useMediaQuery("(max-width:600px)");
  const {
    manageParticipantsActions,
    filters,
    searchRef,
    loader,
    showConfirmPromote,
    promoteUserLoader,
  } = participantsModalContainer({ getReports, isOrgAdmin });
  const {
    onSearchChange,
    onFilterChange,
    onPageChange,
    onDeleteParticipant,
    onPromoteParticipant,
    handlePromote,
    handleCancel,
  } = manageParticipantsActions;
  const { role, organization_type_id, permissions } = useSelector(
    (state) => state?.app?.userData?.user_profile
  );
  const manageTeams = !(
    organization_location_admin?.includes(role) &&
    organization_type_id === 1 &&
    permissions?.teams?.includes("read_only")
  );

  const sortBy = {
    name: "sort_by",
    defaultValue: 0,
    placeHolder: "Sort by",

    //  IconComponent: () => <KeyboardArrowDownRoundedIcon />,
    options: [
      {
        key: (
          <VSNImage
            src={`${process.env.REACT_APP_MEDIA_URL}sa/colored-logo.svg`}
            alt="Sa"
          />
        ),
        value: "sa",
      },
      {
        key: (
          <VSNImage
            src={`${process.env.REACT_APP_MEDIA_URL}re/colored-logo.svg`}
            alt="Ra"
          />
        ),
        value: "re",
      },
      {
        key: (
          <VSNImage
            src={`${process.env.REACT_APP_MEDIA_URL}ga/colored-logo.svg`}
            alt="Ga"
          />
        ),
        value: "ga",
      },
      {
        key: (
          <VSNImage
            src={`${process.env.REACT_APP_MEDIA_URL}ma/colored-logo.svg`}
            alt="Ma"
          />
        ),
        value: "ma",
      },
    ],
  };

  return (
    <>
      <Box position="relative">
        <VSNImage
          src={jellyFish}
          alt="jellyFish"
          className={classes.jellyFish}
        />
        <VSNImage src={zigZag} alt="zigZag" className={classes.zigZag} />
        <Stack
          direction="row"
          justifyContent="space-between"
          pt={1.25}
          gap={2}
          className={
            isOrgAdmin || isTeamDashboard || isTeamView
              ? classes.filterStack
              : ""
          }
        >
          <div
            className={`${classes.search} ${
              isOrgAdmin || isTeamDashboard || isTeamView
                ? classes.filterStacksearch
                : ""
            }`}
          >
            <VSNSearchBox
              onChange={onSearchChange}
              name="keyword"
              inputRef={searchRef}
            />
          </div>
          <div className={classes.sortFilter}>
            {(isOrgAdmin || isTeamDashboard || isTeamView) && (
              <VSNTypography variant="h6">
                {manageTeams ? "Manage Members" : "Members"}
              </VSNTypography>
            )}
            <Stack direction="row">
              <VSNSelect
                {...{
                  ...sortBy,
                  onChange: onFilterChange,
                  classes: { popover: classes.filterDropDown },
                  value: filters?.sort_by,
                }}
              />
              {manageTeams &&
              !hideAddMember &&
              (isOrgAdmin || isTeamDashboard || isTeamView) ? (
                <VSNButton
                  className={classes.button}
                  handleClick={() => handleAddMembers(currentSelectedTeam)}
                >
                  <Stack direction="row" alignItems="center">
                    <AddPersonIcon /> &nbsp;Add Members
                  </Stack>
                </VSNButton>
              ) : null}
            </Stack>
          </div>
        </Stack>
        <TableContainer>
          <Table>
            <TableBody className={classes.memberList}>
              <TableRow className={classes.tableHeadRow}>
                <TableCell width="60%">Name</TableCell>
                <TableCell width="11%">
                  <Box>Primary</Box>
                </TableCell>
                {!matches ? (
                  <TableCell width="11%">
                    <Box>Secondary</Box>
                  </TableCell>
                ) : null}
              </TableRow>
              {loader ? (
                <TableRow>
                  <TableCell colSpan={3} align="center" sx={{ height: 100 }}>
                    <CircularProgress size={24} />
                  </TableCell>
                </TableRow>
              ) : currentSelectedTeamMembers?.data?.length ? (
                currentSelectedTeamMembers?.data?.map((participant, index) => {
                  const isAdmin = admins?.filter(
                    (admin) => admin?.user_id === participant?.user_id
                  )?.length;
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Stack direction="row" alignItems="center">
                          <Box
                            className={`${classes.avtarOut} ${
                              showAdminIcon && isAdmin ? classes.admin : ""
                            }`}
                          >
                            <VSNMembersAvatar
                              alt={participant?.first_name || ""}
                              src="/static/images/avatar/1.jpg"
                              vasanaType={participant?.primary_marker}
                              title={getAvatarName(participant)}
                            />
                          </Box>
                          <Box ml={1.25} sx={{ maxWidth: 200, width: "100%" }}>
                            <VSNTypography
                              variant="h4"
                              className={classes.smHeading}
                            >
                              {participant.first_name} {participant.last_name}
                            </VSNTypography>
                            <VSNTypography
                              className={classes.desc}
                              sx={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {participant.email}
                            </VSNTypography>
                          </Box>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Box className={classes.participantImage}>
                          {renderTypesWithImage({
                            type: participant.primary_marker,
                          })}
                        </Box>
                      </TableCell>
                      {!matches ? (
                        <TableCell>
                          <Box className={classes.participantImage}>
                            {renderTypesWithImage({
                              type: participant.secondary_marker,
                              isBlackImage: true,
                            })}
                          </Box>
                        </TableCell>
                      ) : null}
                      {canDelete &&
                        (isOrgAdmin || isTeamDashboard || isTeamView) && (
                          <TableCell width="10%">
                            <Stack display="flex" flexDirection="row">
                              <Stack
                                direction="row"
                                className={classes.btnDelete}
                                onClick={() =>
                                  isAdmin
                                    ? null
                                    : onDeleteParticipant(participant)
                                }
                                sx={
                                  isAdmin
                                    ? {
                                        cursor: "not-allowed",
                                        backgroundColor: "#F4F6FD",
                                        color: "#747787",
                                      }
                                    : {
                                        backgroundColor: "#F543460D",
                                      }
                                }
                              >
                                <DeleteIcon />
                              </Stack>
                              <Stack
                                direction="row"
                                className={classes.btnPromote}
                                onClick={() =>
                                  isAdmin
                                    ? null
                                    : onPromoteParticipant(participant)
                                }
                                sx={
                                  isAdmin
                                    ? {
                                        cursor: "not-allowed",
                                        backgroundColor: "#F4F6FD",
                                        color: "#747787",
                                        svg: {
                                          path: {
                                            fill: "#747787",
                                            stroke: "#747787",
                                            strokeWidth: 0.2,
                                          },
                                        },
                                      }
                                    : {
                                        backgroundColor: "#F543460D",
                                      }
                                }
                              >
                                <AdminIcon />
                              </Stack>
                            </Stack>
                          </TableCell>
                        )}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" sx={{ height: 100 }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
              {showAdminIcon && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Stack direction="row">
                      <VSNImage src={crown} alt="crown" height={16} />
                      <VSNTypography sx={{ fontSize: 12 }}>
                        The Crown Icon indicates that the user holds the role of
                        Team Admin.
                      </VSNTypography>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {filters?.pages ? (
          <Stack spacing={2} className={classes.paginationFooter}>
            <Stack spacing={2}>
              <VSNPagination
                count={filters?.pages}
                variant="outlined"
                shape="rounded"
                onChange={onPageChange}
                page={filters?.current_page || 1}
              />
            </Stack>
          </Stack>
        ) : null}
      </Box>
      <VSNConfirmBox
        isOpen={showConfirmPromote}
        title="Are You Sure?"
        msg="Are you sure you want to make this user an admin? You won't be able to manage your team anymore."
        handleOk={handlePromote}
        handleCancel={handleCancel}
        okBtnName="Promote"
        cancelBtnName="Cancel"
        className={classes.conformModel}
        isLoading={promoteUserLoader}
      />
    </>
  );
};

export default ParticipantsModal;
