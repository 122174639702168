import { Box, Hidden, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import VSNButton from "../../shared/VSNButton";
import EmailAutoComplete from "../../shared/VSNEmailAutoComplete";
import VSNLabel from "../../shared/VSNLabel";
import MembersAutoComplete from "../../shared/VSNMembersAutoComplete";
import VSNTypography from "../../shared/VSNTypography";
import { useStyles } from "../teams/CreateTeamModal";

const AddMembersModal = ({
  addMemberInfo,
  buttons,
  onEmailSelect,
  addMembersFields,
  loaders,
}) => {
  const classes = useStyles();
  const isAddingMember = useSelector(
    (state) => state.api?.loader?.addMembersLoader
  );

  return (
    <Box>
      <Stack
        direction={{ sm: "row" }}
        position="relative"
        gap={{ sm: 10, xs: 1 }}
      >
        <Hidden smDown>
          <Stack
            direction={{ sm: "row" }}
            justifyContent="center"
            alignItems="center"
            className={`${classes.divider} ${classes.positionDivider}`}
          >
            <span>OR</span>
          </Stack>
        </Hidden>
        {addMembersFields?.data?.map(
          (
            {
              question,
              description,
              value,
              subtitle,
              subdescription,
              isRequired,
              isOrSplit,
            },
            index
          ) => {
            return (
              <React.Fragment key={index}>
                <Box mt={2} mb={{ md: 3.8, xs: 2 }} key={index} width="100%">
                  <VSNTypography className={classes.question} variant="h4">
                    {isRequired && <VSNLabel required />} {question}
                  </VSNTypography>
                  <VSNTypography className={classes.description} variant="h6">
                    {description}
                  </VSNTypography>
                  {value?.map(
                    (
                      { name, onInputChange, options, placeholder, ...rest },
                      index
                    ) => {
                      const AutoComplete =
                        name === "added_members"
                          ? MembersAutoComplete
                          : EmailAutoComplete;

                      return (
                        <AutoComplete
                          key={index}
                          className={classes.UnderlineDropdown}
                          options={options}
                          onChange={(e, value) => onEmailSelect(name, value)}
                          onInputChange={onInputChange}
                          placeholder={placeholder}
                          autoHighlight={
                            !(name === "added_members"
                              ? loaders?.addeddMembers
                              : false)
                          }
                          value={addMemberInfo?.[name] || []}
                          {...(name !== "added_members"
                            ? {
                                inputValue:
                                  addMemberInfo?.[`${name}_input_value`] || "",
                              }
                            : {})}
                          filterSelectedOptions={name === "invited_members"}
                          slotProps={
                            name === "invited_members"
                              ? {
                                  popper: {
                                    sx: {
                                      overflow: "auto",
                                      "& ul": {
                                        width: "max-content",
                                        minWidth: "100%",
                                        "& .MuiAutocomplete-option": {
                                          width: "100%",
                                          overflow: "visible",
                                        },
                                      },
                                    },
                                  },
                                }
                              : {}
                          }
                          sx={{
                            marginTop: 2,
                            "& .MuiChip-label": {
                              maxWidth: 200,
                            },
                            "& .MuiAutocomplete-inputRoot": {
                              "& .MuiAutocomplete-input": {
                                minWidth: "max-content",
                              },
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "black",
                            },
                            "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#EAEDF0",
                              },
                            "&:focus-within .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EAEDF0 !important",
                              borderRadius: "8px 8px 0 0",
                            },
                            "&:focus-within .MuiOutlinedInput-notchedOutline:hover":
                              {
                                borderColor: "#EAEDF0",
                              },
                          }}
                          {...rest}
                        />
                      );
                    }
                  )}
                </Box>
                {isOrSplit && (
                  <Stack
                    direction={{ sm: "row" }}
                    justifyContent="center"
                    alignItems="center"
                    className={`${classes.divider} ${classes.positionDivider}`}
                  >
                    <span>OR</span>
                  </Stack>
                )}
              </React.Fragment>
            );
          }
        )}
      </Stack>
      <Box className={classes.button_groups}>
        {buttons &&
          buttons.map(
            (
              {
                label,
                handleClick,
                hasLoader,
                icon,
                className,
                link,
                disabled,
              },
              index
            ) => (
              <VSNButton
                isLoading={hasLoader && isAddingMember}
                className={`${classes.button} ${className}`}
                key={index}
                handleClick={handleClick}
                disabled={disabled}
              >
                <span className="linkIcon">{link}</span>
                {label}
                <span className="icon">{icon}</span>
              </VSNButton>
            )
          )}
      </Box>
    </Box>
  );
};

export default AddMembersModal;
