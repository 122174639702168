import React from "react";
import VSNButton from "./shared/VSNButton";

const DemoError = () => {
  const handleChange = (second) => {};
  return (
    <div>
      <VSNButton handleClick={handleChange()}> Error</VSNButton>
    </div>
  );
};

export default DemoError;
