import { Box, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import bg from "../../assets/Teams/png/exit-team-bg.png";
import blueSquare from "../../assets/Teams/svg/blue-square-flower.svg";
import exitconfirmation from "../../assets/Teams/svg/exitconfirmation.svg";
import greenFlower from "../../assets/Teams/svg/green-flower.svg";
import lightPink from "../../assets/Teams/svg/light-pink-flower.svg";
import halfOrange from "../../assets/Teams/svg/orange-half-flower.svg";
import shape4 from "../../assets/Teams/svg/shape-4.svg";
import yellow from "../../assets/Teams/svg/yellow-flower.svg";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import VSNTypography from "../../shared/VSNTypography";

const useStyles = makeStyles((theme) => ({
  bgFlower: {
    position: "absolute",
    top: 0,
    left: -25,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      left: -15,
    },
  },
  shape4: {
    position: "absolute",
    top: 30,
    right: "16%",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  yellow: {
    position: "absolute",
    top: 22,
    left: "10%",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  halfOrange: {
    position: "absolute",
    bottom: "18%",
    right: -25,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      right: -15,
    },
  },
  blueSquare: {
    position: "absolute",
    bottom: 40,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lightPink: {
    position: "absolute",
    top: -10,
    left: "60%",
    transform: "translateX(-50%)",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  greenFlower: {
    position: "absolute",
    bottom: 20,
    left: "15%",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonRow: {
    "& .MuiButton-root": {
      maxWidth: 122,
      width: "100%",
      padding: 10,
      "& .content": {
        fontWeight: 500,
        fontSize: 16,
      },
    },
  },
}));

export default function ExitConfirmation({ onLeave, onStay, exitTeamLoader }) {
  const classes = useStyles();
  return (
    <Box position="relative">
      <VSNImage src={bg} alt="bg" className={classes.bgFlower} />
      <VSNImage src={shape4} alt="shape4" className={classes.shape4} />
      <VSNImage
        src={blueSquare}
        alt="blueSquare"
        className={classes.blueSquare}
      />
      <VSNImage src={yellow} alt="yellow" className={classes.yellow} />
      <VSNImage
        src={halfOrange}
        alt="halfOrange"
        className={classes.halfOrange}
      />
      <VSNImage src={lightPink} alt="lightPink" className={classes.lightPink} />
      <VSNImage
        src={greenFlower}
        alt="greenFlower"
        className={classes.greenFlower}
      />
      <Grid
        container
        columnSpacing={{ md: 9, xs: 4 }}
        rowSpacing={{ md: 0, xs: 4 }}
        pb={{ sm: 10, xs: 3 }}
        pt={{ sm: 10, xs: 6 }}
      >
        <Grid
          item
          md={4.5}
          sm={5}
          xs={12}
          textAlign={{ sm: "right", xs: "center" }}
        >
          <VSNImage src={exitconfirmation} alt="exitconfirmation" />
        </Grid>
        <Grid item md={7.5} sm={7} xs={12}>
          <VSNTypography
            textAlign={{ sm: "start", xs: "center" }}
            variant="h4"
            color="secondary.main"
            fontSize={{ sm: "28px", xs: "26px" }}
            fontWeight="700"
            lineHeight={{ sm: "36px", xs: "30px" }}
          >
            Are you sure you want to exit the team?
          </VSNTypography>
          <VSNTypography
            textAlign={{ sm: "start", xs: "center" }}
            color="secondary.grey"
            fontSize={{ sm: "16px", xs: "14px" }}
            fontWeight="500"
            lineHeight={{ sm: "20px", xs: "18px" }}
            my={{ sm: 2.4, xs: 1.2 }}
          >
            Exiting the team involves departing from your current group or
            organization, signaling the end of your active participation and
            collaboration within that specific team.{" "}
          </VSNTypography>
          <Stack
            direction="row"
            className={classes.buttonRow}
            justifyContent={{ sm: "start", xs: "center" }}
            spacing={2}
            mt={{ sm: 0, xs: 2.5 }}
          >
            <VSNButton
              variant="contained"
              handleClick={onStay}
              disabled={exitTeamLoader}
            >
              Stay
            </VSNButton>
            <VSNButton
              variant="outlined"
              handleClick={onLeave}
              disabled={exitTeamLoader}
              isLoading={exitTeamLoader}
            >
              Leave
            </VSNButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
