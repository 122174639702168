import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LOADING_CHANGE,
  ON_FLAG_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_ERROR,
} from "../redux/constants";
import {
  clearFormData,
  setErrors,
  toggleModelShow,
} from "../shared/CustomFunc";
import { LinkIcon, RightArrow } from "../shared/icon";
import { checkValid, emailValidation } from "../shared/validation";
import { ApiContainer } from "../utils/api";
import {
  department_admin,
  endPoints,
  location_admin_name,
  organization_admin,
} from "../utils/constant";
import { copyToClipboard, equal, length, values } from "../utils/javascript";
import { ERROR_MESSAGE } from "../description/errorMessage.description";

export const createTeamModalContainer = ({
  onSuccess,
  handleCreateTeam,
  inOrgAdmin,
}) => {
  const [createdTeam, setCreatedTeam] = useState();
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const formPath = { parent: "teamData" };
  const { form } = useSelector((state) => state);
  const { formData, formErrors } = useSelector((state) => state?.form);

  const createTeamInfo = formData?.teamData ?? {};
  const organizationMembers =
    useSelector((state) => state.api?.organizationMembers?.data) || [];
  const { organization_original_id } = useSelector(
    (state) => state?.app?.userData?.user_profile
  );
  const location_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.location_id
  );
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);
  const department_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.department_id
  );
  const organization_type_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_type_id
  );

  const defaultProfilePictures =
    useSelector((state) => state.api?.defaultProfilePictures?.data) || [];
  const userRoleData = useSelector((state) => state.app?.userData);
  const isGuestUser = userRoleData?.user_profile?.organization_name === "Guest";
  const isOrgAdmin = userRoleData?.user_profile?.role;
  const loaders = useSelector((state) => {
    return {
      createTeamLoader: state.api?.loader?.createTeamLoader,
      membersOfOrg: state.api?.loader?.membersOfOrg,
    };
  });
  const departmentFilters = useSelector(
    (state) => state.api?.getDepartmentFilters
  );
  const locationFilters = useSelector((state) => state.api?.getLocationFilters);
  const currentUserId = useSelector(
    (state) => state?.app?.userData?.user_profile?.sub
  );
  const createTeamInfoError = formErrors?.teamData;
  const currentLocation = formErrors?.teamData?.location;

  const getMembers = async (createdTeam) => {
    if (!organization_original_id) return;
    if (!createdTeam) return;
    const membersData = await api({
      method: "GET",
      endPoint: `teams/${createdTeam?.id}/members/outside`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "membersOfOrg",
    });

    if (membersData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          organizationMembers: membersData.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          organizationMembers: [],
        },
      });
    }
  };

  const getDefaultProfilePictures = async () => {
    const data = await api({
      method: "GET",
      endPoint: endPoints.defaultProfilePictures,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "defaultProfilePicturesLoader",
    });

    if (data?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          defaultProfilePictures: data.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          defaultProfilePictures: [],
        },
      });
    }
  };

  const setAddMembersData = ({ name, value, files, isRemove, checked }) => {
    const isCheckbox = name === "profile_picture" && !files?.[0];

    const isValidImageFormat = [".png", ".jpg", ".jpeg", ".webp"]?.some(
      (type) => files?.[0]?.name?.toLowerCase()?.endsWith(type)
    );
    const isValidImageSize = files?.[0]?.size / 1024 < 15360;
    if (
      !isRemove &&
      name === "photo_upload" &&
      (!isValidImageFormat || !isValidImageSize)
    ) {
      dispatch({
        type: SET_ERROR,
        payload: {
          formErrors: {
            ...form.formErrors,
            [formPath.parent]: {
              ...createTeamInfoError,
              [name]:
                "Please upload JPEG, PNG, JPG or WEBP files with max size 15 MB.",
            },
          },
        },
      });
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            teamData: {
              ...createTeamInfo,
              photo_upload: null,
              photo_upload_url: null,
              profile_picture_file: null,
              profile_picture:
                createTeamInfo?.profile_picture ||
                defaultProfilePictures?.[0]?.profile_picture,
            },
          },
        },
      });
      return;
    }

    dispatch(
      setErrors({
        errors: createTeamInfoError,
        value,
        name,
        formPath: { parent: "teamData" },
      })
    );
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          teamData: {
            ...createTeamInfo,
            [name]: value,
            ...(isCheckbox && checked
              ? {
                  photo_upload_url: null,
                  profile_picture_file: null,
                }
              : {}),
            ...((name === "photo_upload" || name === "profile_picture") &&
            files?.[0]
              ? {
                  photo_upload_url: URL.createObjectURL(files?.[0]),
                  profile_picture_file: files?.[0],
                  profile_picture: null,
                }
              : {
                  photo_upload_url: isRemove
                    ? ""
                    : formData?.teamData?.photo_upload_url,
                  ...(isCheckbox
                    ? {}
                    : {
                        profile_picture: createTeamInfo?.profile_picture,
                      }),
                }),
          },
        },
      },
    });
  };

  const onEmailSelect = (event, selectedOption) => {
    if (selectedOption) {
      const errorEmail = selectedOption.find(
        (email) => !emailValidation(email?.email || email)
      );
      if (errorEmail) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [formPath.parent]: {
                ...createTeamInfo,
                added_members_input_value:
                  selectedOption[selectedOption?.length - 1],
              },
            },
          },
        });
      } else {
        const isCurrentUser =
          !createTeamInfo?.admin &&
          selectedOption?.length === 1 &&
          selectedOption?.find((el) => el?.user_id === currentUserId);
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [formPath.parent]: {
                ...createTeamInfo,
                added_members: errorEmail
                  ? [...(createTeamInfo?.added_members || [])]
                  : selectedOption,
                added_members_input_value: "",
                ...(inOrgAdmin
                  ? selectedOption?.length
                    ? selectedOption?.find(
                        (el) => el?.email === createTeamInfo?.admin
                      )
                      ? { admin: createTeamInfo?.admin || "" }
                      : isCurrentUser
                      ? { admin: isCurrentUser?.email || "" }
                      : { admin: "noAdmin" }
                    : { admin: "" }
                  : {}),
              },
            },
          },
        });
      }
    }
  };

  const onInviteEmailSelect = (event, selectedOption) => {
    if (selectedOption) {
      const errorEmail = selectedOption.find(
        (email) => !emailValidation(email?.email || email)
      );
      if (errorEmail) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [formPath.parent]: {
                ...createTeamInfo,
                invited_members_input_value:
                  selectedOption[selectedOption?.length - 1],
              },
            },
          },
        });
      } else {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [formPath.parent]: {
                ...createTeamInfo,
                invited_members: errorEmail
                  ? [...(createTeamInfo?.invited_members || [])]
                  : selectedOption,
                invited_members_input_value: "",
              },
            },
          },
        });
      }
    }
  };
  const setFormData = (name, value) => {
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...createTeamInfo,
            [name]: value,
          },
        },
      },
    });
  };

  const createTeamContainer = {
    1: {
      label: "Create a New Team",
      data: [
        {
          question: "Team Name",
          description: "Give your team a name that is easy to identify.",
          value: [
            {
              control: "input",
              type: "text",
              name: "name",
              label: "name",
              value: "",
              isRequired: true,
              validateAs: "required",
              placeholder: "Enter your Team Name",
              errorMessage: ERROR_MESSAGE?.team_name,
              fullWidth: true,
            },
          ],
          isRequired: true,
        },

        {
          question: "Team Goal",
          description:
            "Let people know what your team is all about and what you are trying to achieve.",
          isRequired: true,
          value: [
            {
              control: "input",
              type: "text",
              name: "goal",
              label: "goal",
              value: "",
              validateAs: "required",
              isRequired: true,
              placeholder: "Enter your Team Goal",
              errorMessage: ERROR_MESSAGE?.team_goal,
              fullWidth: true,
            },
          ],
        },
        {
          question: "Select Location",
          description:
            "Choose a Location to create a team in a specific Location (optional).",
          hide: !(organization_type_id === 2 && role === organization_admin),
          value: [
            {
              control: "select",
              type: "select",
              name: "location",
              label: "location",
              options:
                locationFilters && locationFilters?.length !== 0
                  ? locationFilters
                  : [{ key: "No Location Found.", value: undefined }],
              value: "",
              isRequired: false,
              placeholder: "Select location",
              fullWidth: true,
              variant: "standard",
            },
          ],
          xs: 12,
          md: 6,
        },
        {
          question: "Select Department",
          description:
            "Choose a Department to create a team in a specific Department (optional).",
          hide: !(
            organization_type_id === 2 &&
            Boolean(role) &&
            role !== department_admin
          ),
          value: [
            {
              control: "select",
              type: "select",
              name: "department",
              options:
                departmentFilters && departmentFilters?.length !== 0
                  ? departmentFilters
                  : [{ key: "No Department Found.", value: 0 }],
              label: "department",
              value: "",
              isRequired: false,
              placeholder: "Select department",
              fullWidth: true,
              variant: "standard",
            },
          ],
          xs: 12,
          md: 6,
        },

        {
          question: "Upload Photo",
          description:
            "Add a photograph to make your Vasana team easily identifiable.",
          value: [
            {
              control: "input",
              type: "file",
              name: "photo_upload",
              value: "",
              placeholder: "Upload your Team Photo",
              errorMessage: ERROR_MESSAGE?.photo,
              fullWidth: true,
              isRequired: true,
              validateAs: "name",
            },
          ],
          subdescription:
            "Only JPEG, PNG, JPG and WEBP files with max size 15 MB.",
        },
      ],
    },
    2: {
      optionalSplit: true,
      label: `Add Members - ${createTeamInfo?.name}`,
      data: [
        {
          question: "Add Members",
          description:
            "Start typing a name to add members to your team or select members from the dropdown.",
          isOrSplit: true,
          value: [
            {
              control: "autoComplete",
              placeholder: "Enter your Team Name",
              fullWidth: true,
              name: "added_members",
              defaultValue: "",
              options: loaders?.membersOfOrg
                ? [{ isLoading: true }]
                : inOrgAdmin
                ? organizationMembers
                : organizationMembers?.filter(
                    (el) => el?.user_id !== currentUserId
                  ),
              variant: "standard",
              type: "text",
              validateAs: "email",
            },
          ],
          xs: 12,
          md: 6,
        },
        {
          question: "Invite a Members",
          description:
            "Add people who aren't on Organization by typing their email address.",
          value: [
            {
              control: "input",
              placeholder:
                "Example mailto:anamika@gmail.com, mailto:jekson234@taglineinfotech.com",
              fullWidth: true,
              type: "email",
              name: "invited_members",
              validateAs: "email",
            },
          ],
          xs: 12,
          md: 6,
        },
        {
          question: "Select admin for team",
          hide: !inOrgAdmin,
          value: [
            {
              control: "select",
              label: "Select admin for team",
              name: "admin",
              value: createTeamInfo?.admin || "noAdmin",
              validateAs: "name",
              isRequired: true,
              fullWidth: true,
              placeHolder: "Select Admin",
              options: [
                {
                  value: "noAdmin",
                  key: "No admin",
                },
                ...(createTeamInfo?.added_members?.map((val) => {
                  return { value: val?.email || "", key: val?.email || "" };
                }) || []),
              ],
              md: 6,
              variant: "standard",
              disabled: !organizationMembers?.filter((el) =>
                createTeamInfo?.added_members?.some(
                  (member) => member?.user_id === el?.user_id
                )
              )?.length,
            },
          ],
        },
      ],
    },
  };

  const handleChange = (e, isRemove) => {
    const { name, value, files, checked } = e.target;
    setAddMembersData({ name, value, files, isRemove, checked });
  };

  const apiCall = async () => {
    dispatch({
      type: LOADING_CHANGE,
      payload: { createTeamLoader: true },
    });
    const formData = new FormData();
    formData.append("name", createTeamInfo?.name ?? "");
    formData.append("goal", createTeamInfo?.goal ?? "");
    formData.append(
      "is_custom_profile_picture",
      !!createTeamInfo?.profile_picture_file
    );
    formData.append(
      "profile_picture",
      createTeamInfo?.profile_picture_file ?? createTeamInfo?.profile_picture
    );
    formData.append("created_from_organization", !!inOrgAdmin);
    if (createTeamInfo?.location) {
      formData.append("location_id", createTeamInfo?.location);
    }
    if (createTeamInfo?.department) {
      formData.append("department_id", createTeamInfo?.department);
    }
    if (role && role === department_admin) {
      formData.append("location_id", location_id);
      formData.append("department_id", department_id);
    }
    if (location_admin_name?.includes(role)) {
      formData.append("location_id", location_id);
    }
    const data = await api({
      method: "POST",
      endPoint: endPoints?.createTeam,
      data: formData,
      showToastMessage: true,
      urlencoded: false,
      parent: "teamData",
      needLoader: true,
      header: { "Content-Type": "multipart/form-data" },
    });
    if (!data?.error) {
      // dispatch(toggleModelShow("createTeam"));
      // dispatch(clearFormData("teamData"));
      getMembers(data?.data);
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form?.formData,
            teamData: {
              ...createTeamInfo,
              step: 2,
            },
          },
        },
      });
      dispatch({
        type: LOADING_CHANGE,
        payload: { createTeamLoader: false },
      });
      setCreatedTeam(data?.data);
    } else {
      dispatch({
        type: LOADING_CHANGE,
        payload: { createTeamLoader: false },
      });
    }
  };

  const createTeam = () => {
    let errors = {};
    createTeamContainer?.[createTeamInfo?.step]?.data?.forEach((item) => {
      if (!item) {
        return;
      }
      Object.values(item.value)?.forEach((control) => {
        const { name, validateAs, label } = control;
        errors = {
          ...errors,
          [name]: validateAs
            ? checkValid({
                validateAs,
                value:
                  name === "photo_upload"
                    ? createTeamInfo?.profile_picture ||
                      createTeamInfo?.photo_upload
                    : createTeamInfo?.[name],
                label,
              })
            : false,
        };

        dispatch({
          type: SET_ERROR,
          payload: {
            formErrors: {
              ...formErrors,
              teamData: { ...errors },
            },
          },
        });
      });
    });
    let hasError;
    if (
      equal(errors, undefined) ||
      equal(length(values(errors)?.filter(Boolean)))
    ) {
      hasError = equal(JSON.stringify(createTeamInfo));
      if (!hasError) {
        apiCall();
      }
    }
  };

  const inviteMembers = async () => {
    const { added_members, invited_members, admin } = createTeamInfo || {};
    const data = await api({
      method: "POST",
      endPoint: `teams/team/${createdTeam?.id}/manage-member`,
      data: {
        members: added_members?.map(({ user_id }) => user_id),
        action: "add",
        invited_members,
        manage_by_admin: !!inOrgAdmin,
        ...(inOrgAdmin
          ? {
              admin:
                added_members?.find(({ email }) => email === admin)?.user_id ||
                0,
            }
          : {}),
      },
      showToastMessage: true,
      urlencoded: false,
      parent: "createTeamLoader",
      needLoader: true,
    });
    if (!data?.data?.error) {
      if (handleCreateTeam) handleCreateTeam();
      if (onSuccess) onSuccess();
      dispatch(toggleModelShow("createTeam"));
      dispatch(clearFormData("teamData"));
    }
  };

  const copyInvitationLink = () => {
    copyToClipboard(createdTeam?.invitation_link);
  };

  const onCreateTeam = () => {
    createTeam();
  };

  const buttonsContainer = {
    1: [
      {
        label: "Create Team",
        icon: <RightArrow />,
        handleClick: onCreateTeam,
        hasLoader: true,
      },
    ],
    2: [
      {
        label: "Add Team Members",
        handleClick: inviteMembers,
        hasLoader: true,
        disabled: !(
          createTeamInfo?.added_members?.length ||
          createTeamInfo?.invited_members?.length
        ),
      },
      {
        label: "Copy Invitation link",
        handleClick: copyInvitationLink,
        className: "link",
        link: <LinkIcon />,
      },
    ],
  };

  const onInputChange = (event, value) => {
    const isValidMail =
      emailValidation(value) && !createTeamInfo?.emailOptions?.includes(value);
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.parent]: {
            ...createTeamInfo,
            invited_members_input_value: value,
            ...(isValidMail ? { emailOptions: [value] } : {}),
          },
        },
      },
    });
  };

  useEffect(() => {
    if ((!isGuestUser || isOrgAdmin) && !_.isEmpty(userRoleData)) {
      getDefaultProfilePictures();
    }
    if (createTeamInfo?.step !== 1) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            teamData: {
              ...createTeamInfo,
              step: 1,
            },
          },
        },
      });
    }
    dispatch({
      type: ON_FLAG_CHANGE,
      payload: { fetchLocation: true },
    });
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...formData,
          [formPath.parent]: {
            ...formData[formPath.parent],
            step: 1,
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    if (defaultProfilePictures?.length && !createTeamInfo?.profile_picture) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            teamData: {
              ...createTeamInfo,
              step: 1,
              profile_picture: defaultProfilePictures?.[0]?.profile_picture,
              department: "",
            },
          },
        },
      });
    }
  }, [defaultProfilePictures]);
  const getLocationFilter = async () => {
    const data = await api({
      method: "GET",
      endPoint: `organization/${organization_original_id}/locations/list`,
      showToastMessage: false,
      urlencoded: false,
      parent: "locationFilters",
    });
    if (data?.status) {
      let getLocationFilters = [];
      getLocationFilters = [
        ...getLocationFilters,
        ...data.data.map(({ location_name, location_original_id }) => ({
          key: `${location_name} (${location_original_id})`,
          value: location_original_id,
        })),
      ];
      dispatch({
        type: SET_API_DATA,
        payload: {
          getLocationFilters,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          getLocationFilters: [],
        },
      });
    }
  };
  const getDepartmentFilter = async () => {
    const data = await api({
      method: "GET",
      endPoint: `organization/${organization_original_id}/locations/${
        createTeamInfo?.location || location_id
      }/departments/list`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "departmentFilters",
    });
    if (data?.status) {
      let getDepartmentFilters = [];
      getDepartmentFilters = [
        ...getDepartmentFilters,
        ...data.data.map(({ department_name, department_original_id }) => ({
          key: `${department_name} (${department_original_id})`,
          value: department_original_id,
        })),
      ];
      dispatch({
        type: SET_API_DATA,
        payload: {
          getDepartmentFilters,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          getDepartmentFilters: [],
        },
      });
    }
  };
  useEffect(() => {
    if (role === organization_admin) {
      getLocationFilter();
    }
  }, []);
  useEffect(() => {
    if (location_admin_name?.includes(role)) {
      getDepartmentFilter();
    }
  }, [location_id]);
  useEffect(() => {
    if (createTeamInfo?.location) {
      getDepartmentFilter();
    } else {
      // dispatch({
      //   type: ON_FORM_CHANGE,
      //   payload: {
      //     formData: {
      //       ...formData,
      //       teamData: {
      //         ...createTeamInfo,
      //         department: "",
      //       },
      //     },
      //   },
      // });
    }
  }, [createTeamInfo?.location]);

  return {
    formPath,
    createTeamInfo,
    handleChange,
    loaders,
    buttonsContainer,
    createTeamContainer,
    onEmailSelect,
    organizationMembers,
    onInviteEmailSelect,
    onInputChange,
    defaultProfilePictures,
  };
};
