import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import cn from "classnames";
import {
  vasanaTypeBackgroundColor,
  vasanaTypeColor,
} from "../description/orgDashboard.description";

const useStyles = makeStyles(() => ({
  avatar: {
    cursor: "pointer",
    "&.MuiAvatar-root.MuiAvatar-circular": {
      outline: "none !important",
      boxShadow: ({ vasanaType, defaultPrimaryBackgroundColor }) =>
        vasanaTypeColor[vasanaType]?.color
          ? `0 0 0 2px ${vasanaTypeColor[vasanaType]?.color} !important`
          : `0 0 0 2px ${
              defaultPrimaryBackgroundColor || "#EAEDF0"
            } !important`,
      backgroundColor: ({ vasanaType, defaultSecondaryBackgroundColor }) =>
        vasanaTypeBackgroundColor[vasanaType]?.color
          ? `${vasanaTypeBackgroundColor[vasanaType]?.color} !important`
          : `${defaultSecondaryBackgroundColor || "#FCFCFD"} !important`,
      color: ({ vasanaType, defaultColor }) =>
        vasanaTypeColor[vasanaType]?.color
          ? `${vasanaTypeColor[vasanaType]?.color} !important`
          : `${defaultColor || "#E87637"} !important`,
    },
  },
}));

const VSNMembersAvatar = ({
  className,
  vasanaType,
  title,
  defaultColor,
  defaultPrimaryBackgroundColor,
  defaultSecondaryBackgroundColor,
  ...rest
}) => {
  const classes = useStyles({
    vasanaType,
    defaultColor,
    defaultPrimaryBackgroundColor,
    defaultSecondaryBackgroundColor,
  });
  return (
    <>
      {title ? (
        <Avatar
          className={cn(classes.avatar, className)}
          alt="avatar"
          {...rest}
        >
          {title}
        </Avatar>
      ) : (
        <Avatar className={cn(classes.avatar, className)} {...rest} />
      )}
    </>
  );
};

export default VSNMembersAvatar;
