import { makeStyles } from "@mui/styles";
import star from "../../../assets/assesments/svg/yellow-star.svg";
import arrow from "../../../assets/quickView/svg/swiper-arrow.svg";
import whiteArrow from "../../../assets/quickView/svg/whiteArrow.svg";

export const PhysicalOnePagerResultStyle = makeStyles((theme) => ({
  mainLayout: {
    overflow: "hidden",
    padding: [[0, 30, 35, 30]],
    position: "relative",
    height: "100%",
    zIndex: 2,
    backgroundSize: "cover",
    minHeight: "calc(100vh - 105px)",
    [theme.breakpoints.down("sm")]: {
      padding: [[0, 16, 42, 16]],
    },
  },
  dailyFunctioningHeight: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: "100% !important",
    },
  },
  assessmentReportCard: {
    overflow: "hidden",
    padding: "20px 25px",
    borderRadius: 10,
    background: `#F7F3FB`,
    boxShadow: "0px 7.7982px 17.32934px 0px rgba(81, 125, 132, 0.07)",
    position: "relative",
    zIndex: 3,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  assessmentReportCardWhite: {
    overflow: "hidden",
    padding: "20px 25px",
    borderRadius: 10,
    background: "#FCFCFD",
    boxShadow: "0px 7.7982px 17.32934px 0px rgba(81, 125, 132, 0.07)",
    position: "relative",
    zIndex: 3,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  box1: {
    position: "absolute",
    top: 6,
    right: "40%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  box2: {
    transform: "rotate(90deg)",
    position: "absolute",
    top: 140,
    right: 140,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  box3: {
    position: "absolute",
    top: 50,
    right: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  box4: {
    position: "absolute",
    bottom: "-2%",
    right: "15%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      lineHeight: "26px",
      fontWeight: 700,
      color: theme.palette.secondary.main,
      position: "relative",
      "&:before": {
        position: "absolute",
        content: "''",
        top: 2,
        height: 22,
        left: -25,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: theme.palette.primary.basic,
        [theme.breakpoints.down("sm")]: {
          left: -15,
        },
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 20,
        lineHeight: "24px",
      },
    },
  },
  mdHeadingprimary: {
    "&.MuiTypography-root": {
      fontSize: 20,
      lineHeight: "26px",
      fontWeight: 700,
      color: "#8B3F9E",
      position: "relative",
      "&:before": {
        position: "absolute",
        content: "''",
        top: 2,
        height: 22,
        left: -25,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: "#8B3F9E",
        [theme.breakpoints.down("sm")]: {
          left: -15,
        },
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 20,
        lineHeight: "24px",
      },
    },
  },
  mdHeadingMain: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: 700,
      color: "#8B3F9E",
      position: "relative",
      display: "inline",
      "& p": {
        display: "inline",
        fontSize: 22,
        [theme.breakpoints.down("sm")]: {
          fontSize: 20,
          lineHeight: "26px",
        },
      },
      "& span": {
        color: "#555FDD",
        fontSize: 22,
        [theme.breakpoints.down("1200")]: {
          fontSize: 20,
          lineHeight: "26px",
        },
      },
      "&:before": {
        position: "absolute",
        content: "''",
        top: 2,
        height: 22,
        left: -25,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: "#8B3F9E",
        [theme.breakpoints.down("sm")]: {
          left: -15,
        },
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 20,
        lineHeight: "22px",
      },
    },
  },
  desc: {
    fontSize: 16,
    lineHeight: "20px",
    color: theme.palette.secondary.grey,
    fontWeight: 400,
    "& span": {
      color: "#86650A",
    },
  },
  headDesc: {
    fontSize: 16,
    lineHeight: "20px",
    color: theme.palette.secondary.grey,
    fontWeight: 500,
    "& span": {
      color: "#86650A",
      fontWeight: 700,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "22px",
    },
  },
  notesList: {
    "&.MuiList-root": {
      zIndex: 1,
      display: "flex",
      flexWrap: "wrap",
      padding: "10px 17px 10px 20px",
      // background: `#FFFFFF`,
      borderRadius: 10,
      marginTop: 13,
      [theme.breakpoints.down("md")]: {
        padding: 10,
        display: "block",
      },
      "& li": {
        display: "inline-block",
        position: "relative",
        paddingLeft: 24,
        paddingRight: 24,
        width: "50%",
        color: theme.palette.secondary.main,
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        "&:before": {
          position: "absolute",
          content: `url(${star})`,
          left: 0,
          top: 10,
        },
        // "&:nth-child(even)": {
        //   "&::after": {
        //     content: "''",
        //     position: "absolute",
        //     left: -10,
        //     width: 1,
        //     top: 4,
        //     bottom: 4,
        //     background: "rgba(0, 0, 0, 0.12)",
        //     [theme.breakpoints.down("md")]: {
        //       display: "none",
        //     },
        //   },
        // },
      },
    },
  },
  notesListOne: {
    "&.MuiList-root": {
      zIndex: 1,
      display: "flex",
      flexWrap: "wrap",
      padding: "10px 17px 10px 20px",
      // background: `#FFFFFF`,
      borderRadius: 10,
      marginTop: 13,
      [theme.breakpoints.down("md")]: {
        padding: 10,
        display: "block",
      },
      // "& li": {
      //   display: "inline-block",
      //   position: "relative",
      //   paddingLeft: 24,
      //   paddingRight: 24,
      //   width: "100%",
      //   color: theme.palette.secondary.main,
      //   [theme.breakpoints.down("md")]: {
      //     width: "100%",
      //   },
      //   "&:before": {
      //     position: "absolute",
      //     content: `url(${star})`,
      //     left: 0,
      //     top: 10,
      //   },
      // },
    },
  },
  notesListMulti: {
    "&.MuiBox-root": {
      zIndex: 1,
      display: "flex",
      padding: "16px",
      borderRadius: 10,
      marginTop: 8,

      "& p": {
        position: "relative",
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.secondary.main,
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },
      },
      "& span": {
        position: "relative",
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.secondary.grey,
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },
      },
    },
  },
  notesListTab: {
    "&.MuiBox-root": {
      zIndex: 1,
      display: "flex",
      flexWrap: "wrap",
      padding: "16px",
      // background: `#FFFFFF`,
      borderRadius: 8,
      width: "fit-content",
      [theme.breakpoints.down("md")]: {
        padding: 10,
        display: "block",
      },
      "& p": {
        display: "inline-block",
        position: "relative",
        width: "100%",
        fontSize: 16,
        fontWeight: 600,
        // color: theme.palette.secondary.grey,
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      },
    },
  },
  importantNote: {
    padding: "15px 20px",
    marginTop: 15,
    backgroundColor: "#FFF",
    border: "1px solid #EAEDF0",
    borderRadius: "10px",
    zIndex: 999,
  },
  dailyFunctioning: {
    "&.MuiList-root": {
      marginTop: 11,
      background: "#FCFCFD",
      paddingRight: 10,
      padding: 0,
      overflowY: "auto",
      maxHeight: 290,
      [theme.breakpoints.down("md")]: {
        maxHeight: "100%",
      },

      "& li": {
        padding: "4px 10px",
        alignItems: "start",
        "& img": {
          minWidth: 18,
          maxWidth: 18,
          height: 18,
        },
        "& p": {
          paddingLeft: 12,
          fontSize: 16,
          fontWeight: 400,
          color: "#424450",
        },
        "& + li": {
          marginTop: 12,
        },
      },
      "&::-webkit-scrollbar": {
        width: 8,
        padding: 4,
      },
      "&::-webkit-scrollbar-track": {
        background: "#F6F6FD",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 6,
        background: "#E87637",
        border: "1px solid #ffffff",
      },
    },
  },
  chartContainer: {
    "& .apexcharts-gridline": {
      strokeDasharray: "4 !important",
    },
  },
  blueFlower: {
    position: "absolute",
    bottom: -15,
    right: "35%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pinkFlower: {
    position: "absolute",
    top: 5,
    left: "15%",
    [theme.breakpoints.down("sm")]: {
      left: "10%",
      top: "35%",
      height: 50,
      width: "auto",
    },
  },
  threeLines: {
    position: "absolute",
    top: 40,
    right: 13,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  threeLinesgray: {
    position: "absolute",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      height: 38,
      display: "block",
      top: "20%",
      right: 20,
      width: "auto",
    },
  },
  optionInfo: {
    zIndex: 99,
    background: `linear-gradient(90deg, #FCF0FF 11.61%, #F2FAFF 72.11%)`,
    borderRadius: 10,
    margin: [[0]],
    lineHeight: "24px",
    padding: [[15, 5, 15, 15]],
    position: "relative",
    width: "100%",
    maxWidth: "100%",
    minHeight: 210,
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      minHeight: 210,
    },
    [theme.breakpoints.down("1400")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      minHeight: 155,
      flexDirection: "row",
    },
    [theme.breakpoints.down("450")]: {
      flexDirection: "column",
    },
  },
  ImgOne: {
    [theme.breakpoints.down("1400")]: {
      height: 147,
      minHeight: 147,
      marginLeft: "auto",
    },
  },
  CustomTabs: {
    "& .MuiTabs-flexContainer": {
      gap: "10px",
      padding: "2px 0 0 2px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
      },
      "& .MuiButtonBase-root": {
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
        },
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-root": {
      textTransform: "none",
      color: "#747787",
      background: "linear-gradient(180deg, #FEFEFE 2.13%, #E6F1FA 96.23%)",
      borderRadius: "10px 10px 0px 0px",
      fontSize: 16,
      fontWeight: 500,
      minWidth: 120,
      "&.Mui-selected": {
        fontWeight: 700,
        color: "#3F51B5",
        backgroundColor: "white",
        background: "#FCFCFD",
        // borderWidth: "1px 1px 0px 1px",
        // borderStyle: "solid",
        // borderImage:
        //   "linear-gradient(180deg, #555FDD -18.09%, rgba(85, 95, 221, 0) 85.11%)",
        // borderImageSlice: 1,
        position: "relative",
        overflow: "visible",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "calc(100% + 2px)",
          width: "calc(100% + 3px)",
          zIndex: -1,
          background:
            "linear-gradient(to bottom, #555FDD -10%, transparent 80%)",
          borderRadius: "10px 10px 0 0",
        },
      },
    },
  },
  headingButton: {
    display: "flex",
    justifyContent: "space-between",
    gap: 14,
    alignItems: "center",
    marginBottom: 10,
    [theme.breakpoints.down("600")]: {
      alignItems: "end",
      "& .MuiButtonBase-root": {
        marginBottom: 10,
      },
    },
    [theme.breakpoints.down("450")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      "& .MuiBox-root": {
        width: "100%",
      },
    },
  },
  containerCard: {
    width: "100%",
    "& .divider": {
      position: "relative",
      maxWidth: "100%",
      "&:after": {
        position: "absolute",
        content: "''",
        top: 0,
        left: "calc(100% + 12px)",
        background: "#fff",
        height: "100%",
        width: 2,
      },
    },
    "& .swiper-pagination": {
      bottom: 0,
      "& .swiper-pagination-bullet": {
        width: 24,
        height: 4,
        background: "#dfe1f899",
        borderRadius: 12,
        transition: "all 0.3s ease-in-out",
        "&.swiper-pagination-bullet-active": {
          background: "#E87637",
          width: 11,
        },
      },
    },
    "& .swiper-button-prev, .swiper-button-next": {
      top: "50%",
      "&:after": {
        background: `url(${arrow})`,
        fontSize: 0,
        height: 27,
        width: 27,
        backgroundSize: "contain",
      },
    },
    "& .swiper-button-next": {
      transform: "rotate(180deg)",
    },
  },
  containerCardDeatils: {
    minHeight: "260px",
    background: "#fff",
    boxShadow: "0px 0px 14px 0px #0000001A",
    padding: "16px",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: 0,
  },
  containerCardDeatilsGrid: {
    minHeight: "230px",
    background: "#fff",
    boxShadow: "0px 0px 14px 0px #0000001A",
    padding: "16px",
    overflow: "hidden",
    position: "relative",
    borderRadius: "10px",
    border: 0,
  },
  bgShape: {
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  customArrow: {
    position: "absolute",
    right: -10,
    left: -10,
    top: "45%",
    transform: "translateY(-50%)",
    justifyContent: "space-between",
    zIndex: 2,
    height: 0,
    "& .swiper-button": {
      cursor: "pointer",
      background: `url(${whiteArrow})`,
      borderRadius: "50%",
      color: "#000",
      height: 24,
      width: 24,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "10px",
      transform: "rotate(0deg)",
      [theme.breakpoints.down("sm")]: {
        height: 24,
        width: 24,
      },
      "&.image-swiper-button-prev": {
        transform: "rotate(180deg)",
      },
      "&.swiper-button-disabled": {
        opacity: 0.5,
      },
    },
  },
  customArrowBottom: {
    position: "absolute",
    right: -12,
    left: -12,
    top: "50%",
    transform: "translateY(-50%)",
    justifyContent: "space-between",
    zIndex: 2,
    height: 0,
    "& .swiper-button1": {
      cursor: "pointer",
      background: `url(${whiteArrow})`,
      borderRadius: "50%",
      color: "#000",
      height: 24,
      width: 24,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "10px",
      transform: "rotate(0deg)",
      [theme.breakpoints.down("sm")]: {
        height: 24,
        width: 24,
      },
      "&.image-swiper-button-prev1": {
        transform: "rotate(180deg)",
      },
      "&.swiper-button-disabled1": {
        opacity: 0.5,
      },
    },
  },
  intelligenceSlider: {
    height: 450,
    "& .swiper-slide-active": {
      "& .MuiStack-root": {
        height: 450,
        "& p": {
          maxHeight: "fit-content",
          visibility: "visible",
        },
      },
    },
  },
  intelligenceCard: {
    height: 182,
    width: 182,
    flex: 1,
    border: "1px solid",
    borderRadius: 8,
    padding: 24,
    justifyContent: "flex-end",
    position: "relative",
    backgroundPosition: "center center",
    transition: "all 0.5s",
    backgroundSize: "cover",
    [theme.breakpoints.down("lg")]: {
      padding: 16,
    },
    "& h4": {
      fontSize: 24,
      lineHeight: "30px",
      fontWeight: "bold",
      position: "relative",
      marginBottom: 10,
      [theme.breakpoints.down("lg")]: {
        fontSize: 22,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
    },
    "& p": {
      maxHeight: 0,
      visibility: "hidden",
      position: "relative",
      transition: "maxHeight 0.5s",
      color: "#747787",
      fontSize: 20,
      [theme.breakpoints.down("lg")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "&:before": {
      position: "absolute",
      content: "''",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 8,
    },
    "&.intelligence0": {
      "&:before": {
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(242, 231, 245, 0.75) 50%, rgba(242, 231, 245, 0.85) 70%, #F2E7F5 85%, #F2E7F5 100%)",
      },
    },
    "&.intelligence1": {
      "&:before": {
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(227, 241, 249, 0.75) 50%, rgba(227, 241, 249, 0.85) 70%, #E3F1F9 85%, #E3F1F9 100%)",
      },
    },
    "&.intelligence2": {
      "&:before": {
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 251, 246, 0.7) 50%, rgba(255, 249, 241, 0.85) 70%, #FFF8EE 85%, #FFF7EC 100%)",
      },
    },
    "&.intelligence3": {
      "&:before": {
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(248, 252, 249, 0.7) 50%, rgba(244, 251, 245, 0.85) 70%, #F2FAF4 85%, #F0F9F2 100%)",
      },
    },
    "&.active": {
      flex: "0 0 30%",
      width: 430,
      height: 430,
      overflow: "hidden",
      "& p": {
        maxHeight: "fit-content",
        visibility: "visible",
      },
    },
  },
  diagnosticInputs: {
    position: "relative",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    "& p": {
      color: "#2D3652",
      fontWeight: 700,
    },
    "& .MuiList-root": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flex: 1,
      padding: 0,
      "& .MuiListItem-root": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "relative",
        marginTop: 25,
        padding: [[10]],
        gap: 8,
        [theme.breakpoints.down("1300")]: {
          marginTop: 0,
          padding: [[0]],
        },
        [theme.breakpoints.down("1200")]: {
          marginTop: 25,
          padding: [[10]],
        },
        "& p": {
          width: "100%",
          fontSize: 14,
          color: theme.palette.black.darkGray,
          fontWeight: 700,
          "& span": {
            fontSize: "14px",
            fontWeight: 400,
          },
        },
        "& img": {
          color: "#2D3652",
          fontWeight: 700,
        },
      },
    },
  },
  PrintButton: {
    "&.MuiButton-root": {
      padding: "10px 14px",
      fontWeight: 700,
      minWidth: 150,
      "& .content": {
        fontSize: 18,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
}));
