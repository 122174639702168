import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ApiContainer } from "../../utils/api";
import { SET_API_DATA } from "../../redux/constants";

const adminAssessment = () => {
  const { locationId, userId } = useParams();
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const { organization_original_id, permissions } =
    useSelector((state) => state?.app?.userData?.user_profile) ?? {};
  const { AssessmentReport: AssessmentReportLoader, releaseAllLoader } =
    useSelector((state) => state.api.loader);
  const { AssessmentReport: AssessmentResult, assessmentUserInfo } =
    useSelector((state) => state.api);

  const getAssessmentReport = async () => {
    const response = await api({
      method: "GET",
      endPoint: `organization/${organization_original_id}/locations/${locationId}/members/${userId}/assessments`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "AssessmentReport",
    });

    if (response?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          AssessmentReport: response.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          AssessmentReport: [],
        },
      });
    }
  };

  const releaseAllAssessment = async () => {
    const response = await api({
      method: "POST",
      endPoint: `organization/${organization_original_id}/locations/${locationId}/members/${userId}/assessments/release-all`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "releaseAllLoader",
    });
    getAssessmentReport();
  };
  const getUserDetail = async () => {
    const response = await api({
      method: "GET",
      endPoint: `user/${userId}/info`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "releaseAllLoader",
    });
    if (response?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentUserInfo: response.data,
        },
      });
    }
    // else {
    //   dispatch({
    //     type: SET_API_DATA,
    //     payload: {
    //       assessmentUserInfo: [],
    //     },
    //   });
    // }
  };
  useEffect(() => {
    if (locationId && userId && organization_original_id) {
      getUserDetail();
      getAssessmentReport();
    }
  }, [organization_original_id]);

  return {
    AssessmentResult,
    AssessmentReportLoader,
    locationId,
    userId,
    releaseAllAssessment,
    releaseAllLoader,
    assessmentUserInfo,
    permissions,
  };
};

export default adminAssessment;
