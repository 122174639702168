import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import small2 from "../../../assets/assesments/svg/small-2.svg";
import small6 from "../../../assets/assesments/svg/small-6.svg";
import VSNButton from "../../../shared/VSNButton";
import VSNImage from "../../../shared/VSNImage";
import VSNTypography from "../../../shared/VSNTypography";

const useStyles = makeStyles((theme) => ({
  selectMain: {
    "&.MuiStack-root": {
      gap: 5,
      marginTop: 8,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    "& .MuiStack-root": {
      height: "100%",
      minWidth: 160,
      backgroundColor: theme.palette.primary.light,
      borderRadius: 5,
      color: theme.palette.secondary.grey,
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      [theme.breakpoints.down("md")]: {
        minWidth: "100%",
        width: "100%",
      },
      "&:hover": {
        color: theme.palette.primary.basic,
        borderColor: theme.palette.primary.basic,
      },
      "&.active": {
        borderColor: theme.palette.primary.basic,
        color: theme.palette.primary.basic,
        boxShadow: "0px 9px 20px 0px #968FB01A",
      },
    },
  },
  information: {
    position: "relative",
    minHeight: 160,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: 1210,
    width: "100%",
    margin: "0 auto",
    padding: "0 15px",
    "& h4": {
      fontSize: 22,
      lineHeight: "26px",
      fontWeight: 600,
      color: theme.palette.secondary.darkGrey,
      textAlign: "center",
      maxWidth: 800,
      margin: "0 auto 30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        padding: [[0]],
        marginBottom: 45,
      },
    },
  },
  optionInfo: {
    "&.MuiButtonBase-root": {
      background: "#F7F3FB",
      color: theme.palette.secondary.darkGrey,
      margin: [[0]],
      lineHeight: "23px",
      padding: [[26, 66]],
      position: "relative",
      width: 500,
      maxWidth: "100%",
      minHeight: 100,
      [theme.breakpoints.down("lg")]: {
        minHeight: 122,
      },
      [theme.breakpoints.down("md")]: {
        padding: [[16, 18]],
        fontSize: 16,
        minHeight: 80,
      },
      "&:hover": {
        background: "#EAEBFB",
      },
      "&:active": {
        background: "#EAEBFB",
        transition: "auto",
        WebkitTransition: "auto",
      },
      "&:after": {
        position: "absolute",
        content: '""',
        borderRadius: 8,
        width: "100%",
        height: "100%",
        border: "1px solid #CCCBFF",
        top: 10,
        left: 10,
        zIndex: -1,
      },
    },
  },
  small: {
    position: "absolute",
  },
  small2: {
    top: "-6%",
    left: "10%",
    zIndex: -1,
    [theme.breakpoints.down("1200")]: {
      left: "9%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  small6: {
    top: "-14%",
    right: "17%",
    zIndex: -1,
    [theme.breakpoints.down("1200")]: {
      right: "9%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  orOption: {
    padding: [[0, 60]],
    color: "#86650A",
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      padding: [[28, 0, 16, 0]],
    },
  },
}));

const DoNotAllowReSubmissionSelect = ({
  question,
  onChange,
  value,
  handelNextQuestion,
  currentQuestionIndex,
  allow_resubmission,
}) => {
  const classes = useStyles();
  return (
    <Box mb={5} className={classes.information}>
      <VSNImage
        src={small2}
        alt="small2"
        className={`${classes.small2} ${classes.small}`}
      />
      <VSNImage
        src={small6}
        alt="small6"
        className={`${classes.small6} ${classes.small}`}
      />
      <VSNTypography variant="h4" component="h4">
        {question?.phrase}
      </VSNTypography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={`${classes.selectMain}`}
      >
        {question?.choices?.map((item, key) => (
          <Fragment key={key}>
            <Stack
              justifyContent="center"
              alignItems="center"
              mb={2}
              className={value?.value?.id === item?.result ? "active  " : ""}
              onClick={() => {
                onChange(item, question);
                return (
                  !allow_resubmission &&
                  handelNextQuestion(currentQuestionIndex + 1, item)
                );
              }}
            >
              <VSNButton
                // handleClick={() => nextQuestion(el, currentQuestion)}
                className={classes.optionInfo}
              >
                {item?.phrase}
              </VSNButton>
            </Stack>
            {key < question?.choices?.length - 1 ? (
              <span className={classes.orOption}>OR</span>
            ) : null}
          </Fragment>
        ))}
      </Stack>
    </Box>
  );
};

export default DoNotAllowReSubmissionSelect;
