import { Box, Checkbox, Grid, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { formContainer } from "../../container/form.container";
import teamContainer from "../../container/team.container";
import VSNButton from "../../shared/VSNButton";
import VSNErrorMessage from "../../shared/VSNErrorMessage";
import VSNImage from "../../shared/VSNImage";
import VSNLabel from "../../shared/VSNLabel";
import VSNTypography from "../../shared/VSNTypography";
import ControlForm from "../../shared/form/ControlForm";
import {
  CheckedIcon,
  CloseIcon,
  EditIcon,
  ImageIcon,
  WithoutCheckIcon,
} from "../../shared/icon";
import { useStyles } from "../teams/CreateTeamModal";

const EditTeamModal = ({ onSuccess }) => {
  const classes = useStyles();

  const {
    formPath,
    createTeamInfo,
    handleTeamChange,
    loaders,
    editButtons: buttons,
    editTeamContainer,
  } = teamContainer({ isEditTeam: true });
  const { formData, formErrors } = formContainer({ formPath });
  const defaultProfilePictures =
    useSelector((state) => state.api?.defaultProfilePictures?.data) || [];

  return (
    <Box>
      {editTeamContainer?.data?.map(
        ({ question, value, subtitle, subdescription, isRequired }, index) => {
          return (
            <Box mt={2} mb={{ md: 3.8, xs: 2 }} key={index} width="100%">
              <VSNTypography className={classes.question} variant="h4">
                {isRequired && <VSNLabel required />} {question}
              </VSNTypography>
              {value?.map(
                (
                  { isRequired, label, errorMessage, name, ...attribute },

                  index
                ) => {
                  if (question === "Edit Photo") {
                    return (
                      <Stack
                        direction={{ sm: "row", xs: "column" }}
                        alignItems={{ sm: "center", xs: "start" }}
                        my={2.5}
                        key={index}
                      >
                        <Box className={classes.mainBox}>
                          <Stack direction={{ sm: "row", xs: "column" }}>
                            <Stack className={classes.innerBox}>
                              <Box className={classes.borderBox}>
                                <Grid container spacing={1}>
                                  {defaultProfilePictures.map(
                                    ({ id, profile_picture }) => (
                                      <Grid item xs={4} key={id}>
                                        <Box className={classes.TeamImageBox}>
                                          <Checkbox
                                            sx={{
                                              "&:hover": {
                                                bgcolor: "transparent",
                                              },
                                            }}
                                            disableRipple
                                            color="default"
                                            name="profile_picture"
                                            value={profile_picture}
                                            checkedIcon={<CheckedIcon />}
                                            icon={<WithoutCheckIcon />}
                                            inputProps={{
                                              "aria-label": "Checkbox demo",
                                            }}
                                            onChange={handleTeamChange}
                                            checked={
                                              createTeamInfo?.profile_picture ===
                                              profile_picture
                                            }
                                          />
                                          <VSNImage
                                            src={profile_picture}
                                            alt="team-photo-1"
                                          />
                                        </Box>
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </Box>
                              <VSNTypography className={classes.uploadText}>
                                Select your team photo
                              </VSNTypography>
                            </Stack>
                            <Stack
                              alignItems="center"
                              justifyContent="center"
                              className={classes.divider}
                            >
                              <span>OR</span>
                            </Stack>
                            <Stack className={classes.innerBox}>
                              <Box className={classes.borderBox}>
                                <Stack
                                  alignItems="center"
                                  justifyContent="center"
                                  color="#E87637"
                                  className={classes.uploadOut}
                                >
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id="photo_upload-undefined"
                                    onChange={handleTeamChange}
                                    className={classes.inputUpload}
                                    value=""
                                    name={name}
                                  />
                                  <VSNLabel
                                    htmlFor="photo_upload-undefined"
                                    className={classes.uplodLabel}
                                    label={
                                      formData?.photo_upload_url ? (
                                        <Stack
                                          alignItems="center"
                                          justifyContent="center"
                                          className={classes.editIcon}
                                        >
                                          <EditIcon />
                                        </Stack>
                                      ) : (
                                        ""
                                      )
                                    }
                                  />
                                  <VSNTypography
                                    className={classes.questionTitle}
                                    variant="h6"
                                  >
                                    {subtitle}
                                  </VSNTypography>
                                  {formData?.photo_upload_url ? (
                                    <>
                                      <img
                                        src={formData?.photo_upload_url}
                                        alt="img"
                                      />
                                      <Stack
                                        alignItems="center"
                                        justifyContent="center"
                                        className={classes.closeIcon}
                                        onClick={() =>
                                          handleTeamChange(
                                            {
                                              target: { name, value: "" },
                                            },
                                            true
                                          )
                                        }
                                      >
                                        <CloseIcon />
                                      </Stack>
                                    </>
                                  ) : (
                                    <>
                                      <ImageIcon />
                                      <VSNTypography
                                        variant="span"
                                        className={classes.uploadClick}
                                      >
                                        {" "}
                                        Click here to upload
                                      </VSNTypography>
                                    </>
                                  )}
                                </Stack>
                              </Box>
                              <VSNTypography className={classes.uploadText}>
                                {subdescription}
                              </VSNTypography>
                              <VSNErrorMessage
                                {...{
                                  formError: formErrors?.[name],
                                  errorMessage,
                                }}
                              />
                            </Stack>
                          </Stack>
                        </Box>
                      </Stack>
                    );
                  }
                  return (
                    <Box key={index} width="100%">
                      <ControlForm
                        className={classes.input}
                        {...{
                          ...attribute,
                          onChange: handleTeamChange,
                          name,
                          value: createTeamInfo?.[name] || "",
                        }}
                      />
                      <VSNErrorMessage
                        {...{ formError: formErrors?.[name], errorMessage }}
                      />
                      <VSNTypography
                        className={classes.questionTitle}
                        variant="h6"
                      >
                        {subtitle}
                      </VSNTypography>
                    </Box>
                  );
                }
              )}
            </Box>
          );
        }
      )}
      <Box className={classes.button_groups}>
        {buttons &&
          buttons.map(({ label, handleClick, hasLoader, className }, index) => (
            <VSNButton
              isLoading={hasLoader && loaders?.saveTeamLoader}
              className={`${classes.button} ${className}`}
              key={index}
              handleClick={() =>
                handleClick(() =>
                  onSuccess({
                    formPath: { parent: "teams" },
                    needLoader: true,
                  })
                )
              }
            >
              {label}
            </VSNButton>
          ))}
      </Box>
    </Box>
  );
};

export default EditTeamModal;
