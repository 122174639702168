import { MenuItem, Select } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import VSNFormControl from "./VSNFormControl";

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "23px",
    color: "#202024",
    padding: "8px 0",
    "&:focus": {
      background: "transparent",
    },
  },
  "&.MuiInputBase-root": {
    "&::before": {
      borderBottom: "1px solid #CBCBCF !important",
    },
  },
  "& .MuiSelect-icon": {
    top: "inherit",
  },
  "& .MuiPaper-root": {
    maxHeight: 300,
  },
  "& .MuiPopover-root": {
    "& .MuiPaper-root": {
      position: "static",
    },
  },
});

const VSNSelect = (props) => {
  const {
    value = 0,
    options,
    label,
    onChange,
    elementId,
    name,
    fullWidth,
    variant,
    classes,
    placeHolder,
    disabled = false,
    readOnly,
    ...rest
  } = props;
  return (
    <VSNFormControl fullWidth={fullWidth}>
      <StyledSelect
        MenuProps={{
          PopoverClasses: { root: classes?.popover },
        }}
        labelId={`${name}-${elementId}`}
        id={`${name}-${elementId}`}
        value={value}
        name={name}
        onChange={onChange}
        variant={variant}
        disabled={disabled || readOnly}
        renderValue={(selected) => {
          if (selected === 0) {
            return (
              <span style={{ color: "rgb(198 198 205)", fontWeight: 400 }}>
                {placeHolder}
              </span>
            );
          }
          return options?.find((item) => item.value === selected)?.key;
        }}
        {...rest}
      >
        {options?.map(({ value, key }, index) => {
          return (
            <MenuItem value={value} key={index}>
              {key}
            </MenuItem>
          );
        })}
      </StyledSelect>
    </VSNFormControl>
  );
};

export default VSNSelect;
