import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Collapse, Grid, Hidden, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import { PulseLoader } from "react-spinners";
import diffrent2 from "../../assets/assesments/svg/assesment-3.svg";
import Type6 from "../../assets/common/svg/shape-3.svg";
import { ReactComponent as Type5 } from "../../assets/quickView/svg/box-1-img-6.svg";
import { ReactComponent as Combination } from "../../assets/quickView/svg/combination.svg";
import greenFlower from "../../assets/quickView/svg/green-half.svg";
import halfRound from "../../assets/quickView/svg/half-round-card.svg";
import Ushape from "../../assets/quickView/svg/u-shape.svg";
import { vasanaTypeResultContainer } from "../../container/vasanaTypeResult.container";
import {
  bgImages,
  bgImagesMain,
} from "../../description/assessment.description";
import { changeSaReGaMaStyle } from "../../shared/CustomFunc";
import SEO from "../../shared/SEO";
import VSNButton from "../../shared/VSNButton";
import CircularProgressWithLabel from "../../shared/VSNCircularProgressWithLabel";
import VSNImage from "../../shared/VSNImage";
import VSNLoader from "../../shared/VSNLoader";
import VSNPaper from "../../shared/VSNPaper";
import { VSNSwiper } from "../../shared/VSNSwiper";
import VSNTypography from "../../shared/VSNTypography";
import VSNVideo from "../../shared/VSNVideo";
import { RightArrow } from "../../shared/icon";
import compositionIcon from "../../assets/quickView/png/compositionIcon.png";
import { VasanaTypeResultStyle } from "./VasanaTypeResultStyle";
import AssessmentsSection from "./AssessmentsSection";
import { assessmentIds } from "../../utils/constant";

const VasanaTypeResult = () => {
  const {
    result,
    markerInfo,
    downloadReport,
    readMore,
    toggleReadMore,
    resultInfo,
    userRoleData,
    generatingReport,
    changeText,
    downloadReportData,
    currentPlayingIndex,
    handleVideoPlay,
    setCurrentPlayingIndex,
    vasanaTypeAssessment,
    onActiveIndexChange,
    activeCards,
    downloadAllReports,
    assessmentsSection,
    reportLoaders,
    handleAssessmentClick,
    toggleReport,
  } = vasanaTypeResultContainer();
  const classes = VasanaTypeResultStyle();
  const navigate = useNavigate();
  const vasanaTypeComposition =
    "Your personality type is a composition of Sa, Re, Ga and Ma and only one of them is a dominant type.";
  const { reportSuccess, download, isGenerating } = generatingReport;
  if (!markerInfo || !result) {
    return <VSNLoader variant="fullPage" />;
  }

  const downloadReportBtn = (
    <VSNButton
      className={classes.downloadButton}
      isLoading={download && vasanaTypeAssessment?.pdf_exists}
      handleClick={() =>
        isGenerating && !downloadReportData ? changeText() : downloadReport()
      }
      disabled={!reportSuccess}
    >
      {!reportSuccess ? (
        <PulseLoader size={10} color="#ffffff" />
      ) : (
        "Download Full Report"
      )}
    </VSNButton>
  );
  const retakeAssessmentBtn = (
    <VSNButton
      className={classes.downloadButton}
      handleClick={() => navigate("/assessments")}
      variant="outlined"
    >
      Retake Assessment
    </VSNButton>
  );
  const getClass = {
    1: classes.stickOne,
    2: classes.stickTwo,
    3: classes.stickThree,
    4: classes.stickFour,
  };

  const splitArray = (str) => {
    const data = str?.map((obj) =>
      obj?.includes(" ") ? splitArray(obj?.split(" ")) : obj
    );
    return data;
  };

  return (
    <div className={classes.mainLayout}>
      <SEO contentKey="quickView" />

      {/* {bgImagesMain?.map((data, i) => (
        <span
          key={i}
          className={`${classes.shape}  ${classes?.[data?.className]}`}
        >
          {data.image}
        </span>
      ))} */}
      <Grid container spacing={3}>
        <Grid item md={7} lg={8} xs={12}>
          <Box mb={{ xs: 3, md: 4 }}>
            <VSNPaper className={classes.firtBox}>
              {bgImages?.map(
                (data, i) =>
                  (!data?.notShow || readMore) && (
                    <span
                      key={i}
                      className={`${classes.shape} ${
                        classes?.[data?.className]
                      }`}
                    >
                      {data.image}
                    </span>
                  )
              )}
              <Stack
                direction="row"
                justifyContent="space-between"
                className={classes.vasanatype}
                alignItems="center"
              >
                <VSNTypography variant="h4" component="h4">
                  Your Vasana Type is{" "}
                  <Box className={classes.vasanaTypeImage}>
                    <VSNImage src={resultInfo?.large_icon} alt="large_icon" />
                  </Box>
                </VSNTypography>{" "}
                <Hidden lgDown>
                  <Stack
                    spacing={2}
                    direction="row"
                    className={classes.btnWrapper}
                  >
                    {downloadReportBtn}
                    {userRoleData?.is_demo_user && retakeAssessmentBtn}
                  </Stack>
                </Hidden>
              </Stack>
              <Box>
                <VSNTypography variant="h6" component="h6">
                  {resultInfo?.tends_to_str?.split(" ")?.map((val, i) => (
                    <VSNTypography
                      key={i}
                      variant="span"
                      component="span"
                      className={
                        splitArray(resultInfo?.tends_to)
                          ?.flat()
                          ?.includes(val?.replace(",", "").replace(".", ""))
                          ? classes.marketTends
                          : ""
                      }
                    >
                      {" "}
                      {val}
                    </VSNTypography>
                  ))}
                </VSNTypography>{" "}
                <VSNTypography
                  variant="body1"
                  component="p"
                  className={classes.marketInfo}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: changeSaReGaMaStyle(
                        resultInfo?.vasana_info?.summery
                      )?.replace(
                        resultInfo?.vasana_role?.secondary,
                        `<span style="color:#86650A;font-weight:700">${resultInfo?.vasana_role?.secondary}</span>`
                      ),
                    }}
                  />
                </VSNTypography>{" "}
              </Box>
              <Collapse in={readMore}>
                <Box className={classes.vasanaTypeAccordian}>
                  <Type5 className={classes.flowerBgImg} />
                  <VSNTypography
                    variant="body1"
                    component="p"
                    className={classes.marketInfo}
                  >
                    {resultInfo?.vasana_info?.description}
                  </VSNTypography>{" "}
                </Box>
              </Collapse>
              <Box className={classes.readmoreButtonsMain}>
                <Button
                  onClick={() => toggleReadMore()}
                  className={classes.readMoreButtons}
                  disableRipple
                >
                  {readMore ? (
                    <>
                      Read less <KeyboardArrowUpIcon />
                    </>
                  ) : (
                    <>
                      Read more <KeyboardArrowDownIcon />
                    </>
                  )}
                </Button>
              </Box>{" "}
              <Hidden lgUp>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  className={classes.DownBtnWrapper}
                >
                  {downloadReportBtn}
                  {userRoleData?.is_demo_user && retakeAssessmentBtn}
                </Stack>
              </Hidden>
            </VSNPaper>
          </Box>

          <Stack sx={{ display: { md: "none", xs: "block" } }}>
            <Box mb={3.5}>
              <VSNPaper className={classes.combinationMain}>
                <Stack direction="row" className={classes.combinationHeading}>
                  <VSNImage
                    src={compositionIcon}
                    alt="compositionIcon"
                    height={40}
                    width="auto"
                  />
                  <Box>
                    <VSNTypography variant="h5" component="h5">
                      Your Vasana Type Composition
                    </VSNTypography>
                    <VSNTypography variant="body1" component="p">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: changeSaReGaMaStyle(vasanaTypeComposition),
                        }}
                      />
                    </VSNTypography>
                  </Box>
                </Stack>
                <Box>
                  {Object?.entries(markerInfo)
                    ?.filter(([key, val]) =>
                      ["sa", "re", "ga", "ma"].includes(key)
                    )
                    ?.map(([key, val], i) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        className={`${classes.personalityRange} ${
                          classes?.[`${key}Per`]
                        }`}
                        onClick={() => navigate(`/${key}`)}
                        key={i}
                        mt={2}
                      >
                        <Box
                          style={{ color: val?.color_code?.toString() }}
                          className={`${classes.range}`}
                        >
                          <CircularProgressWithLabel
                            {...{ value: result?.[`${key}_percentage`] }}
                          />
                        </Box>
                        <VSNTypography variant="h4" component="h4">
                          <Box>
                            <img src={val?.small_icon} alt="yss" />
                          </Box>
                        </VSNTypography>
                        <VSNTypography variant="body1" component="p">
                          {val.quality?.join(", ")}
                        </VSNTypography>
                      </Stack>
                    ))}
                  <VSNTypography
                    mt={1.6}
                    mb={1}
                    ml={{ lg: 3, xs: 0 }}
                    color="#857f7f"
                    sx={{ textAlign: "start" }}
                  >
                    Click the Vasana Type to learn more
                  </VSNTypography>
                </Box>
              </VSNPaper>
            </Box>
          </Stack>

          <Box mb={3.5}>
            <Box className={classes.personalitySlider}>
              <VSNTypography
                variant="h5"
                component="h5"
                className={classes.lgHeading}
                mb={1}
              >
                Aspects of Your Personality
              </VSNTypography>{" "}
              <Box className={classes.yourPersonalitySliderMain}>
                <VSNSwiper
                  className={classes.personalityMain}
                  pagination
                  navigation
                  spaceBetween={20}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    500: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 2,
                    },
                    1200: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                  onActiveIndexChange={onActiveIndexChange}
                  onResize={onActiveIndexChange}
                >
                  {resultInfo?.vasana_type_in_brief?.map((obj, i) => (
                    <SwiperSlide
                      className={`${classes.personalityBoxMain}  ${
                        activeCards?.start === i || activeCards?.end === i
                          ? classes.activeCard
                          : ""
                      }`}
                      key={i}
                    >
                      <VSNPaper className={classes.personalityBoxes}>
                        <Box>
                          <VSNTypography variant="h5" component="h5">
                            <VSNImage src={obj?.icon} alt="icon" />
                            {obj?.title}
                          </VSNTypography>
                        </Box>
                        {obj?.summery?.map((item, i) => (
                          <VSNTypography variant="body1" component="p" key={i}>
                            {item}
                          </VSNTypography>
                        ))}
                      </VSNPaper>
                    </SwiperSlide>
                  ))}
                  {resultInfo?.natural_viewpoints?.map((obj, i) => (
                    <SwiperSlide
                      key={i}
                      className={`${classes.personalityBoxMain}  ${
                        activeCards?.start ===
                          i + resultInfo?.vasana_type_in_brief?.length ||
                        activeCards?.end ===
                          i + resultInfo?.vasana_type_in_brief?.length
                          ? classes.activeCard
                          : ""
                      }`}
                    >
                      <VSNPaper className={classes.personalityBoxes}>
                        <Box>
                          <VSNTypography variant="h5" component="h5">
                            <VSNImage src={obj?.icon} alt="icon" />
                            {obj?.title}
                          </VSNTypography>
                        </Box>
                        <VSNTypography variant="body1" component="p">
                          {obj.summery}
                        </VSNTypography>
                      </VSNPaper>
                    </SwiperSlide>
                  ))}
                </VSNSwiper>
              </Box>
            </Box>
          </Box>

          <Box mb={3.5}>
            <Box className={classes.videosMain}>
              <Hidden mdDown>
                <VSNTypography
                  variant="h5"
                  component="h5"
                  className={classes.lgHeading}
                  mb={1}
                >
                  Some Content Curated For You
                </VSNTypography>
              </Hidden>
              <Hidden mdUp>
                <VSNTypography
                  variant="h5"
                  component="h5"
                  className={classes.lgHeading}
                  mb={1}
                >
                  Some Content Curated For You
                </VSNTypography>
              </Hidden>{" "}
              <Box
                className={`${classes.yourPersonalitySliderMain} ${classes.videoSlider}`}
              >
                <VSNSwiper
                  pagination
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    600: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    992: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 25,
                    },
                  }}
                >
                  {markerInfo?.[
                    result?.result?.toLowerCase()
                  ]?.vasana_content_to_accelerate_your_performance?.map(
                    ({ url, description, title, thumbnail }, i) => (
                      <SwiperSlide
                        className={classes.personalityBoxMain}
                        key={i}
                      >
                        <VSNPaper className={classes.videosBox}>
                          <VSNVideo
                            url={url}
                            thumbnail={thumbnail}
                            key={i}
                            isPlaying={currentPlayingIndex === i}
                            onPlay={() => handleVideoPlay(i)}
                            setCurrentPlayingIndex={setCurrentPlayingIndex}
                          />
                          <Box className={classes.contentDescription}>
                            <VSNTypography
                              variant="h6"
                              component="h6"
                              className={classes.mdHeading}
                              color="secondary.darkGrey"
                            >
                              {title}
                            </VSNTypography>
                            <VSNTypography
                              variant="body1"
                              component="p"
                              className={classes.desc}
                              mt={0.5}
                            >
                              {description}
                            </VSNTypography>
                          </Box>
                        </VSNPaper>
                      </SwiperSlide>
                    )
                  )}
                </VSNSwiper>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={5} lg={4} xs={12} className={classes.quickViewContent2}>
          <Stack sx={{ display: { md: "block", xs: "none" } }}>
            <Box mb={3.5}>
              <VSNPaper className={classes.combinationMain}>
                <Stack direction="row" className={classes.combinationHeading}>
                  <VSNImage
                    src={compositionIcon}
                    alt="compositionIcon"
                    height={40}
                    width="auto"
                  />
                  <Box>
                    <VSNTypography
                      variant="h5"
                      component="h5"
                      className={classes.lgHeading}
                      mb={1}
                    >
                      Your Vasana Type Composition
                    </VSNTypography>
                    <VSNTypography variant="body1" component="p">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: changeSaReGaMaStyle(vasanaTypeComposition),
                        }}
                      />
                    </VSNTypography>
                  </Box>
                </Stack>
                <Box>
                  {Object?.entries(markerInfo)
                    ?.filter(([key, val]) =>
                      ["sa", "re", "ga", "ma"].includes(key)
                    )
                    ?.map(([key, val], i) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        className={`${classes.personalityRange} ${`${key}Per`}`}
                        key={i}
                        onClick={() => navigate(`/${key}`)}
                        mt={2}
                      >
                        <Box
                          style={{ color: val?.color_code?.toString() }}
                          className={`${classes.range}`}
                        >
                          <CircularProgressWithLabel
                            {...{ value: result?.[`${key}_percentage`] }}
                          />
                        </Box>
                        <VSNTypography variant="h4" component="h4">
                          {/* {key} */}
                          <Box>
                            <img src={val?.small_icon} alt="yss" />
                          </Box>
                        </VSNTypography>
                        <VSNTypography variant="body1" component="p">
                          {val.quality?.join(", ")}
                        </VSNTypography>
                      </Stack>
                    ))}
                  <VSNTypography
                    mt={1.6}
                    ml={{ lg: 3, xs: 0 }}
                    color="#857f7f"
                    sx={{ textAlign: "start" }}
                  >
                    Click the Vasana Type to learn more
                  </VSNTypography>
                </Box>
              </VSNPaper>
            </Box>
          </Stack>
          <AssessmentsSection
            assessmentsSection={(
              [
                ...(assessmentsSection || [])?.filter(
                  (el) =>
                    el?.assessment_id?.toString() !== assessmentIds?.vasanaType
                ),
              ] || []
            )?.slice(0, 2)}
            downloadAllReports={downloadAllReports}
            reportLoaders={reportLoaders}
            handleAssessmentClick={handleAssessmentClick}
            toggleReport={toggleReport}
          />
        </Grid>
      </Grid>
      <Box className={classes.diffrentAspectPersonalityMain}>
        <VSNTypography
          variant="h5"
          component="h5"
          className={classes.lgHeading}
          mb={1}
        >
          Different Aspects Of Your Personality
        </VSNTypography>
        <VSNPaper className={classes.diffrentPersonalityBox}>
          <VSNImage
            className={classes.diffrentAspectShape1}
            src={Type6}
            alt="Type6"
          />
          <VSNImage
            className={classes.diffrentAspectShape2}
            src={diffrent2}
            alt="diffrent2"
          />
          <VSNImage
            className={classes.diffrentAspectShape3}
            src={Ushape}
            alt="Ushape"
          />
          <Stack
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: 4, md: 1 }}
            justifyContent="space-around"
          >
            {resultInfo?.aspects_of_personality?.map((data, i) => (
              <Box className={classes.positionPersonality} key={i}>
                <Box
                  className={`${classes.stick} ${
                    (i + 1) % 4 === 0 ? getClass[4] : getClass[(i + 1) % 4]
                  }`}
                >
                  {/* {data.stick} */}
                </Box>
                <Box className={classes.roundShape}>{data.round}</Box>
                <Box className={classes.imgOut}>
                  <VSNImage src={data.icon} alt="icon" />
                  <VSNTypography variant="body1" component="p">
                    {" "}
                    {data.title}
                  </VSNTypography>
                  <VSNTypography variant="h6" component="h6">
                    {" "}
                    {data.summery}
                  </VSNTypography>
                </Box>
              </Box>
            ))}
          </Stack>
        </VSNPaper>
      </Box>
    </div>
  );
};

export default VasanaTypeResult;
