import { ERROR_MESSAGE } from "./errorMessage.description";

export const sendOrg = [
  {
    control: "input",
    label: "Email Address",
    type: "email",
    name: "email",
    value: "",
    fullWidth: true,
    validateAs: "email",
    md: 6,
    errorMessage: ERROR_MESSAGE?.email_address,
  },
];
