import {
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_USER_DATA,
} from "../redux/constants";
import { ApiContainer } from "./api";
import { endPoints } from "./constant";

const ApiCalls = () => {
  const { api } = ApiContainer();
  const signInCall = async ({
    userData,
    showToastMessage,
    setUserSession,
    endPoint,
    ...rest
  }) => {
    const data = await api({
      method: "POST",
      endPoint,
      data: {
        ...userData,
      },
      showToastMessage,
      urlencoded: false,
      ...rest,
    });
    if (data?.status) {
      localStorage.setItem("user-name", userData?.user_name);
      localStorage.setItem("user-session", JSON.stringify(data.data.session));
      if (setUserSession) {
        setUserSession(data.data.session);
      }
    }
    return data;
  };

  const signUpCall = async (signUpData, showToastMessage = false) => {
    const data = await api({
      method: "POST",
      endPoint: endPoints?.signup,
      data: { username: signUpData?.username },
      showToastMessage,
      urlencoded: false,
    });
    if (data?.status) {
      localStorage.setItem("user-name", signUpData?.username);
    }
    return data;
  };
  const getRoleInfo =
    ({ needLoader, parent, ...rest }) =>
    async (dispatch, getState) => {
      const userData = await api({
        method: "GET",
        endPoint: endPoints?.session,
        showToastMessage: false,
        urlencoded: false,
        needLoader,
        parent,
        ...rest,
      });
      if (userData?.status) {
        dispatch({ type: SET_USER_DATA, payload: userData?.data });
        localStorage.setItem(
          "isAdmin",
          userData?.data?.user_profile?.role === "platform_admin"
        );
      }

      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...getState().form.formData,
            ["userInfo"]: userData?.data?.user_profile,
          },
        },
      });
    };

  const getMarkerDetails = () => async (dispatch) => {
    const response = await api({
      method: "GET",
      endPoint: "assessment/markers-details",
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "markerInfo",
    });

    if (response?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          markerInfo: response?.data,
        },
      });
    }
  };

  return {
    signInCall,
    signUpCall,
    getRoleInfo,
    getMarkerDetails,
  };
};
export default ApiCalls;
