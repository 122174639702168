import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { otpContainer } from "../../container/otp.container";
import { otpDescription } from "../../description/otp.description";
import VSNTypography from "../../shared/VSNTypography";
import OTP from "./OTP";
import SEO from "../../shared/SEO";

const useStyles = makeStyles((theme) => ({
  titleHead: {
    "&.MuiTypography-root": {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "26px",
      color: theme.palette.secondary.main,
      // maxWidth: 504,
      marginBottom: 96,
      paddingTop: 16,
      [theme.breakpoints.down("md")]: {
        marginBottom: 26,
        paddingTop: 0,
      },
    },
  },
  OTPContainer: {
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      marginTop: 61,
    },
  },
}));

const OTPSection = ({ codeLength }) => {
  const { handleNext, ...rest } = otpContainer();

  const classes = useStyles();
  return (
    <>
      <SEO contentKey="OTP" />
      <VSNTypography variant="h4" className={classes.titleHead}>
        {otpDescription}
      </VSNTypography>
      <OTP
        {...{
          handleNext,
          className: classes.OTPContainer,
          codeLength,
          ...rest,
        }}
      />
    </>
  );
};

export default OTPSection;
