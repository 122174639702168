import { useEffect, useState } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import "./App.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import AllRoutes from "./routes";
import AppLayout from "./presentation/layout/AppLayout";
import { LOGOUT, ON_FORM_CHANGE } from "./redux/constants";
import ApiCalls from "./utils/apiCall";
import SentryWrapper from "./Sentry";
import {
  clearLocalStorage,
  equal,
  getItem,
  includes,
} from "./utils/javascript";
import { showToast } from "./utils/toastService";
import { clearAllModal, getAssessmentState } from "./shared/CustomFunc";
import HumaChatBot from "./presentation/HumaChatBot";
import { assessmentIds } from "./utils/constant";

import "animate.css/animate.compat.css";
import CookieConsent from "./presentation/cookiesConsent/CookieConsent";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
    responseType: "token",
  },
});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xts: 450,
      xsm: 475,
      sm: 600,
      smd: 767,
      md: 900,
      lmd: 1000,
      slg: 992,
      mlg: 1024,
      lg: 1200,
      xlg: 1366,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#555FDD",
      light: "#fff",
      basic: "#E87637",
    },
    secondary: {
      main: "#424450",
      grey: "#747787",
      darkGrey: "#2d2f39",
    },
    black: {
      main: "#000",
      lightGray: "#c6c6cd",
      darkGray: "#575966",
    },
    purple: {
      main: "#8B3F9E",
    },
    white: {
      main: "#fff",
      light: "#f0f0f0",
    },
  },
  typography: {
    fontFamily: ["DM Sans, sans-serif"].join(","),
  },
});

function App() {
  const { getRoleInfo, getMarkerDetails } = ApiCalls();
  const [cookieConsent, setCookieConsent] = useState(
    () => getItem("cookieAccept") || null
  );

  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const userRoleData = useSelector((state) => state.app?.userData);
  const token = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("isAdmin");
  const { pathname } = useLocation();

  const vasanaTypeAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.vasanaType,
  });

  const setSSOUser = (currentUser) => {
    const token = currentUser?.signInUserSession?.accessToken?.jwtToken;
    if (token) {
      localStorage?.setItem("token", token);
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            userInfo: { ...currentUser?.attributes },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (equal(isAdmin, "false") && includes(pathname, "/admin")) {
      showToast("Access Denied", "error");
    }
  }, [isAdmin]);

  useEffect(() => {
    dispatch(clearAllModal());
  }, [pathname]);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case "oAuthSignOut":
          clearLocalStorage();
          dispatch({ type: LOGOUT });

          break;
        case "default":
          break;
      }
    });
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setSSOUser(currentUser);
      })
      .catch((err) => console.log("Not signed in", err));

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getRoleInfo({ needLoader: true, parent: "getRoleInfo" }));
    }
  }, []);

  useEffect(() => {
    if (token) dispatch(getMarkerDetails());
  }, [token]);

  useEffect(() => {
    if (!token) {
      const controller = new AbortController();
      controller.abort();
    }
  }, [token]);

  useEffect(() => {
    if (cookieConsent?.Analytics) {
      if (
        process.env.REACT_APP_GTM_ID &&
        process.env.REACT_APP_ENVIRONMENT === "production"
      ) {
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_ID}`;
        document.head.appendChild(script);
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line no-inner-declarations
        function gtag() {
          // eslint-disable-next-line no-undef, prefer-rest-params
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", process.env.REACT_APP_GTM_ID);
      }
    }
  }, [cookieConsent]);

  const showChatBot =
    token &&
    vasanaTypeAssessment?.result &&
    pathname?.includes("") &&
    !["/get-started", "/quick-note", "/edit-profile"]?.some((path) =>
      pathname?.includes(path)
    ) &&
    !(pathname?.includes("assessment") && !pathname?.includes("/assessments"));

  return (
    <>
      <SentryWrapper>
        <ThemeProvider theme={theme}>
          <AppLayout>
            <AllRoutes />
            {/* {showChatBot && <HumaChatBot />} */}
          </AppLayout>
          <Toaster position="top-center" containerStyle={{ top: "64px" }} />
          <CookieConsent
            setCookieConsent={setCookieConsent}
            cookieConsent={cookieConsent}
          />
        </ThemeProvider>
      </SentryWrapper>
    </>
  );
}

export default App;
