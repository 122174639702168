import { Box, Grid, Hidden } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { formContainer } from "../../container/form.container";
import {
  knowYouBetter,
  userFormAttribute,
} from "../../description/userInfo.description";
import ControlForm from "../../shared/form/ControlForm";
import VSNButton from "../../shared/VSNButton";
import VSNCheckbox from "../../shared/VSNCheckbox";
import VSNErrorMessage from "../../shared/VSNErrorMessage";
import VSNLabel from "../../shared/VSNLabel";
import VSNTypography from "../../shared/VSNTypography";
import { equal, upperCase } from "../../utils/javascript";
import { userInfoBannerDesc } from "../../description/banner.description";
import SEO from "../../shared/SEO";

const useStyles = makeStyles((theme) => ({
  headTitle: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      fontSize: 34,
      lineHeight: "44px",
      color: "#2D2F39",
      marginBottom: 40,
    },
  },
  detailTitle: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "18px",
      color: "#86650A",
      marginBottom: 28,
      [theme.breakpoints.down("md")]: {
        marginBottom: 16,
      },
    },
  },
  skipText: {
    "&.MuiTypography-root": {
      margin: [[20, 0]],
      color: "#E87637",
      fontSize: "18px",
      fontWeight: 700,
      cursor: "pointer",
    },
  },
  termsText: {
    "& .MuiButtonBase-root": {
      "& .MuiSvgIcon-root": {
        "& path": {
          // color: '#A5A9BD'
        },
      },
    },
    "& a": {
      color: "#555FDD",
    },
    "& p.MuiTypography-root": {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "21px",
      color: theme.palette.secondary.grey,
      marginLeft: 18,
      [theme.breakpoints.down("md")]: {
        marginLeft: 12,
      },
    },
  },
  formGrid: {
    "& .react-tel-input": {
      "& .form-control": {
        fontSize: 18,
        fontWeight: 700,
        color: "#202024",
      },
    },
  },
}));

const UserInfoContainer = ({
  handleSubmit,
  formPath,
  handleChange,
  handleTermsCheck,
  handleDateChange,
  termsCheck,
  btnNames,
  profileLoader,
  handleNavigate,
  userAPIInfo,
  userInfoData,
  isOnBoarding,
  handleMobileChange,
}) => {
  const classes = useStyles();
  const { formData, formErrors } = formContainer({ formPath });
  return (
    <>
      <SEO contentKey="profile" />

      <>
        <VSNTypography className={classes.headTitle}>
          {knowYouBetter}
          <Hidden mdUp>
            <VSNTypography
              sx={{ color: "#424450", lineHeight: "20px", marginTop: "5px" }}
            >
              {userInfoBannerDesc}
            </VSNTypography>
          </Hidden>
        </VSNTypography>

        {userFormAttribute?.map(({ label, value }, index) =>
          isOnBoarding && label === "Organization Details" ? null : (
            <Box mb={{ md: 3, xs: 1 }} key={index}>
              <VSNTypography className={classes.detailTitle}>
                {upperCase(label)}
              </VSNTypography>
              <Grid
                container
                spacing={3}
                rowSpacing={{ md: 2, xs: 0 }}
                className={classes.formGrid}
              >
                {value?.map(
                  (
                    {
                      isRequired,
                      label,
                      errorMessage,
                      name,
                      value,
                      getValBy,
                      md,
                      errorValidationMessage,
                      readOnly,
                      ...attribute
                    },
                    index
                  ) => (
                    <Grid
                      item
                      xs={12}
                      md={md}
                      key={index}
                      mb={{ xs: 2, md: 0 }}
                    >
                      <VSNLabel required={isRequired} label={label} />
                      <ControlForm
                        {...{
                          ...attribute,
                          onChange: handleChange,
                          elementId: index,
                          label,
                          value:
                            formData?.[name] !== undefined
                              ? formData?.[name]
                              : formData?.[getValBy] ||
                                formData?.[name] ||
                                value,
                          name,
                          readOnly,
                          handleDateChange,
                          handleMobileChange,
                        }}
                      />
                      <VSNErrorMessage
                        {...{
                          formError: formErrors?.[name],
                          errorMessage,
                          errorValidationMessage,
                          formData: formData?.[name],
                        }}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          )
        )}
        {isOnBoarding && (
          <>
            <Box
              display="flex"
              alignItems="flex-start"
              mt={{ md: 6, xs: 0 }}
              className={classes.termsText}
            >
              <VSNCheckbox checked={termsCheck} onChange={handleTermsCheck} />
              <VSNTypography variant="body1">
                I have read and agree to abide by Vasana&apos;s{" "}
                <NavLink
                  to="/terms-conditions"
                  state={{
                    onBoarding: true,
                  }}
                >
                  Terms and Conditions
                </NavLink>{" "}
                and{" "}
                <NavLink
                  to="/privacy-policy"
                  state={{
                    onBoarding: true,
                  }}
                >
                  Privacy Policy.
                </NavLink>
              </VSNTypography>
            </Box>
            <Box sx={{ minHeight: 24 }}>
              {formErrors?.checkBox && (
                <VSNTypography sx={{ color: "red", marginLeft: 6 }}>
                  {" "}
                  Acceptance of terms and conditions and privacy policy is
                  required
                </VSNTypography>
              )}
            </Box>
          </>
        )}
        <Box display="flex" flexDirection="column" alignItems="center" mt={6}>
          <VSNButton
            handleClick={() => handleSubmit()}
            isLoading={profileLoader}
            disabled={
              equal(
                JSON.stringify(userInfoData),
                JSON.stringify(userAPIInfo)
              ) && !isOnBoarding
            }
          >
            {btnNames.save}
          </VSNButton>
          {btnNames.cancel ? (
            <VSNTypography
              onClick={() => handleNavigate()}
              className={classes.skipText}
            >
              {btnNames.cancel}
            </VSNTypography>
          ) : null}
        </Box>
      </>
    </>
  );
};

export default UserInfoContainer;
