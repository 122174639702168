import { makeStyles } from "@mui/styles";
import bgFlower from "../../../assets/Teams/png/team-bg-banner.png";
import arrow from "../../../assets/quickView/svg/swiper-arrow.svg";

export const AssessmentForAdminStyle = makeStyles((theme) => ({
  mainLayout: {
    overflow: "hidden",
    padding: [[0, 30, 35, 30]],
    position: "relative",
    height: "100%",
    zIndex: 2,
    minHeight: "calc(100vh - 155px)",
    "&:before": {
      position: "absolute",
      content: "''",
      left: 0,
      right: 0,
      top: 0,
      zIndex: -1,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      minHeight: 310,
      [theme.breakpoints.down("sm")]: {
        minHeight: 140,
        backgroundSize: "cover",
        backgroundPosition: "0 -10px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[10, 16, 42, 16]],
    },
  },
  assessmentCard: {
    padding: "15px 20px",
    borderRadius: 10,
    background: "#fff",
    border: "1px solid #EAEDF0",
    position: "relative",
    marginBottom: 25,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
    "&:hover": {
      borderColor: "transparent",
      "&:before": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
    "& .text": {
      fontWeight: 500,
      marginTop: 8,
      marginBottom: 24,
      "& span": {
        fontWeight: 700,
      },
    },
    "& .cardHeading": {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: "23px",
      "& span": {
        fontSize: 22,
        fontWeight: 700,
        [theme.breakpoints.down("md")]: {
          fontSize: 18,
        },
      },
    },
    "& .imgLineWrapper": {
      display: "flex",
      alignItems: "center",
      marginBottom: 4,
      fontWeight: 500,
      "& p": {
        fontSize: 18,
      },
      "& img": {
        marginTop: -5,
      },
    },
  },
  halfRound: {
    position: "absolute",
    top: "20%",
    left: 0,
  },
  greenCloud: {
    position: "absolute",
    top: "25%",
    left: "5%",
  },
  cloud: {
    position: "absolute",
    bottom: 20,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lightBlueLeaf: {
    position: "absolute",
    bottom: 5,
    right: "2%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontWeight: "bold",
      fontSize: 22,
      lineHeight: "28px",
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        lineHeight: "20px",
      },
    },
  },
  imgWrapper: {
    height: 163,
    width: 183,
    margin: "16px auto 0 auto",
    "& img": {
      height: "100%",
    },
  },
  outLinedBtn: {
    "&.MuiButton-root": {
      width: "100%",
      fontWeight: 500,
      padding: "12px",
      maxWidth: 175,
      height: "100%",
      [theme.breakpoints.between("sm", "md")]: {
        padding: "12px 6px",
      },
      "&:hover": {
        background:
          "linear-gradient(132.61deg, #e87637 32.09%, #ffbe78 163.78%)",
        color: "#fff",
      },
      "& .content": {
        fontSize: 14,
        lineHeight: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
  },
  btnComplete: {
    "&.MuiButton-root": {
      padding: "10px 15px",
      background: "#2D8F4E",
      fontWeight: 500,
      width: "fit-content",
      flex: "0 0 auto",
      [theme.breakpoints.down("sm")]: {
        padding: "7px 10px",
      },
      "& .content": {
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        lineHeight: "16px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
        "& svg": {
          height: 15,
          width: 15,
          marginRight: 8,
        },
      },
      "&:hover": {
        background: "#2D8F4E",
      },
    },
  },
  btnRequired: {
    "&.MuiButton-root": {
      padding: "10px 15px",
      background: "#555FDD0D",
      fontWeight: 500,
      width: "fit-content",
      color: theme.palette.primary.main,
      flex: "0 0 auto",
      [theme.breakpoints.down("sm")]: {
        padding: "7px 10px",
      },
      "& .content": {
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        lineHeight: "16px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
        },
        "& svg": {
          height: 18,
          width: 15,
          marginRight: 8,
        },
      },
      "&:hover": {
        background: "#555FDD0D",
      },
    },
  },
  assessmentCardSlider: {
    width: "100%",
    "& .swiper-pagination": {
      position: "absolute",
      top: 15,
      right: 0,
      bottom: "auto",
      left: "auto",
      width: "fit-content",
      padding: "6px 10px",
      fontSize: 14,
      borderRadius: 5,
      background: "#fcf1ec",
      minWidth: 50,
      color: "#747787",
      "& .swiper-pagination-current": {
        color: "#E87637",
      },
    },
    "& .swiper-button-prev, .swiper-button-next": {
      top: 130,
      "&:after": {
        background: `url(${arrow})`,
        fontSize: 0,
        height: 27,
        width: 27,
        backgroundSize: "contain",
      },
    },
    "& .swiper-button-next": {
      transform: "rotate(180deg)",
    },
    "& .swiper-button-disabled": {
      pointerEvents: "all",
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 30,
      lineHeight: "40px",
      fontWeight: 700,
      color: "#2D2F39",
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
        lineHeight: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
        lineHeight: "28px",
      },
    },
  },
  masonryGrid: {
    display: "flex",
    gap: 25,
    width: "calc(100% - 50px)",
    [theme.breakpoints.down("1024")]: {
      width: "calc(100% - 25px)",
    },
    [theme.breakpoints.down("md")]: {
      gap: 16,
      width: "calc(100% - 16px)",
    },
    [theme.breakpoints.down("650")]: {
      width: "100%",
    },
  },
  headingButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    gap: 14,
    [theme.breakpoints.down("450")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
    },
  },
  releaseButton: {
    "&.MuiButton-root": {
      padding: "8px 12px",
      fontWeight: 400,
      minWidth: 150,
      "& .content": {
        fontSize: 16,
      },
      [theme.breakpoints.down("450")]: {
        width: "100%",
        marginTop: 10,
      },
    },
  },
}));
