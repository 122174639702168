import React from "react";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: "https://8973bbac7e95233a413db82ccc24412d@o4507548653060096.ingest.us.sentry.io/4507548984147968",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [`https://www.vasana.ai`],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT || "local",
  });
}

const SentryWrapper = ({ children }) => {
  return <>{children}</>;
};

export default SentryWrapper;
