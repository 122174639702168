import { Box, Container, Grid, Stack } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import halfOrange from "../../assets/landing/png/halfOrange.png";
import propellingImg from "../../assets/landing/png/propellingImg.webp";
import section1Bg1 from "../../assets/landing/png/section-1-bg-1.png";
import section1Bg2 from "../../assets/landing/png/section-1-bg-2.png";
import section1Bg3 from "../../assets/landing/png/section-1-bg-3.png";
import section1Bg4 from "../../assets/landing/png/section-1-bg-4.png";
import section1Bg5 from "../../assets/landing/png/section-1-bg-5.png";
import shape6 from "../../assets/landing/png/shape6.png";
import shape7 from "../../assets/landing/png/shape7.png";
import teamAboutImg from "../../assets/landing/png/teamAboutImg.webp";
import bannerShape1 from "../../assets/landing/png/bannerShape1.png";
import bannerShape2 from "../../assets/landing/png/bannerShape2.png";
import bannerShape3 from "../../assets/landing/png/bannerShape3.png";
import heroTeamImg1 from "../../assets/landing/png/heroTeamImg1.webp";
import heroTeamImg2 from "../../assets/landing/png/heroTeamImg2.webp";
import heroTeamImg3 from "../../assets/landing/png/heroTeamImg3.webp";
import workplaceImg1 from "../../assets/landing/png/workplace-image1.webp";
import workplaceImg2 from "../../assets/landing/png/workplace-image2.webp";
import workplaceImg3 from "../../assets/landing/png/workplace-image3.webp";
import workplaceImg4 from "../../assets/landing/png/workplace-image4.webp";
import SEO from "../../shared/SEO";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import VSNTypography from "../../shared/VSNTypography";
import {
  Empower,
  Engage,
  Enroll,
  GenomeShape5,
  OnBoard,
  RightArrow,
  WorkFlowBox1Shape,
  WorkFlowBox2Shape,
  WorkFlowBox3Shape,
} from "../../shared/icon";
import AchieveLogo from "./AchieveLogo";
import FooterSlider from "./FooterSlider";
import { LandingDashboardStyles } from "./LandingContainerStyle";
import { VasanaForTeamsStyle } from "./VasanaForTeamsStyle";

export default function VasanaForTeams() {
  const workplaceList = [
    {
      title: "Leading the way in behavioral health",
      description: `Using voice AI, video AI, and NLP (sentiment analysis), we are developing proprietary technology for automated detection of Vasana types and other behavioral markers. We’re working to enable autonomous identification of psychometric behavioral styles to auto determine the next best therapeutic action for a member.`,
      image: workplaceImg1,
      bgColor: "#F8F0FA",
    },
    {
      title: "Evidence-based approach",
      description: `Vasana’s proprietary tools use behavioral science, neuroscience and methodologies that use 45+ frameworks designed to reduce friction, improve collaboration, accelerate performance, and enhance relationships.`,
      image: workplaceImg2,
      bgColor: "#E3F1F9",
    },
    {
      title: "Anytime, anywhere",
      description: `Members have immediate access to their Vasana behavioral type, understanding of their team’s composition,behavior patterns, training content to enhance performance, and text-based coaching from Huma, our AI co-pilot.`,
      image: workplaceImg3,
      bgColor: "#FFF7EC",
    },
    {
      title: "Driving cultural change",
      description: `By putting behavioral health front and center in your organization, we help you create working environments where relationships thrive, thanks to enhanced connections, increased empathy and unshakeable trust.`,
      image: workplaceImg4,
      bgColor: "#F0F9F2",
    },
  ];

  const workFlow = [
    {
      icon: <OnBoard />,
      title: "Onboard",
      description: `A seamless onboarding experience tailored to your organization's needs, with automated data integration, self-service enrollment and real-time tracking, that ensures no one is left behind.`,
      shape: <WorkFlowBox1Shape />,
    },
    {
      icon: <Empower />,
      title: "Empower",
      description: `Members learn about their team's behavioral composition and how to interact with different Vasana types, fostering the journey towards a more cohesive and efficient team environment.`,
      shape: <WorkFlowBox3Shape />,
    },
    {
      icon: <Engage />,
      title: "Engage",
      description: `Sustain behavioral change and continuous learning to improve team dynamics with  AI-power behavioral nudges, personalized training, individual and team coaching, and more.`,
    },
    {
      icon: <Enroll />,
      title: "Enroll",
      description: `Each member takes the proprietary Vasana behavioral assessment and receives a holistic report, helping them understand their own behavioral makeup`,
      shape: <WorkFlowBox2Shape />,
    },
  ];

  const classes = VasanaForTeamsStyle();
  const navigate = useNavigate();
  const landingClasses = LandingDashboardStyles();
  return (
    <>
      <SEO contentKey="ForTeams" />
      <Box>
        <Box className={classes.bannerBg}>
          <Box className={classes.heroSection}>
            <VSNImage
              src={halfOrange}
              alt="cloud-purple"
              className={classes.halfOrange}
            />
            <Grid
              container
              spacing={4}
              alignItems="center"
              flexDirection="row-reverse"
            >
              <Grid item xs={12} md={6} xl={5}>
                <Box className={classes.heroCapsule}>
                  <VSNImage
                    src={bannerShape1}
                    alt="bannerShape1"
                    className={classes.bannerShape1}
                  />
                  <VSNImage
                    src={bannerShape2}
                    alt="bannerShape2"
                    className={classes.bannerShape2}
                  />
                  <VSNImage
                    src={bannerShape3}
                    alt="bannerShape3"
                    className={classes.bannerShape3}
                  />
                  <Box className={classes.heroCapsuleImage}>
                    <ScrollAnimation
                      className="capsule-img"
                      animateIn="fadeInUp"
                      duration="0.2"
                      offset={-100}
                    >
                      <VSNImage src={heroTeamImg1} alt="enterprise-hero-img" />
                    </ScrollAnimation>
                    <ScrollAnimation
                      className="capsule-img"
                      animateIn="fadeInUp"
                      duration="0.3"
                      offset={-100}
                    >
                      <VSNImage src={heroTeamImg2} alt="enterprise-hero-img" />
                    </ScrollAnimation>
                    <ScrollAnimation
                      className="capsule-img"
                      animateIn="fadeInUp"
                      duration="0.4"
                      offset={-100}
                    >
                      <VSNImage src={heroTeamImg3} alt="enterprise-hero-img" />
                    </ScrollAnimation>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={5} sx={{ mx: "auto" }}>
                <Stack>
                  <VSNTypography variant="h1" className={classes.lgHeading}>
                    Thriving <span className="orangeText">Relationships. </span>
                    Stronger <span className="orangeText">Teams. </span>
                    Exceptional <span className="orangeText">Performance.</span>
                  </VSNTypography>
                  <VSNTypography className="desc">
                    Helping mitigate workplace stress, minimize friction, and
                    accelerate performance. With Vasana, you don’t just improve
                    your bottom line—you build a culture of belonging, deep
                    trust, learning, productivity and resilience.
                  </VSNTypography>
                  <VSNButton
                    sx={{
                      width: "fit-content",
                      padding: "12px 32px",
                    }}
                    endIcon={<RightArrow />}
                    handleClick={() => navigate(`/contact-us?interested=teams`)}
                  >
                    Partner with Us
                  </VSNButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          className={classNames(
            classes.teamAboutSection,
            landingClasses.spacing
          )}
          position="relative"
        >
          <Container maxWidth="xl">
            <Grid
              container
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={4.5}>
                <VSNImage
                  className={classes.teamAboutImage}
                  src={teamAboutImg}
                  alt="image"
                />
              </Grid>
              <Grid item md={5.5}>
                <VSNTypography
                  variant="h6"
                  color="secondary.grey"
                  mb={4}
                  fontSize={{ xl: 24, lg: 20, md: 18, xs: 16 }}
                >
                  Organizations are an amalgamation of teams that drive business
                  outcomes and success. Everyone wants to build and nurture
                  high-performance teams.
                </VSNTypography>
                <VSNTypography
                  variant="h5"
                  color="secondary.grey"
                  fontWeight={700}
                  mb={2}
                  fontSize={{ xl: 32, lg: 28, md: 24, xs: 20 }}
                >
                  That&apos;s where{" "}
                  <VSNTypography color="primary.basic" variant="span">
                    Vasana for Teams
                  </VSNTypography>{" "}
                  comes in.
                </VSNTypography>
                <VSNTypography
                  variant="h6"
                  color="secondary.grey"
                  fontSize={{ xl: 24, lg: 20, md: 18, xs: 16 }}
                >
                  We use insights from neuroscience and behavioral psychology to
                  build evidence-based mental health programs and tools that
                  enhance the way your team thinks and works together.
                </VSNTypography>
              </Grid>
            </Grid>
          </Container>
          <VSNImage
            src={section1Bg1}
            alt="shape"
            className={`${classes.section1Bg1} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg2}
            alt="shape"
            className={`${classes.section1Bg2} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg3}
            alt="shape"
            className={`${classes.section1Bg3} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg4}
            alt="shape"
            className={`${classes.section1Bg4} ${classes.sectionShape}`}
          />
        </Box>
        <Box px={{ sm: 3 }} className={landingClasses.spacing}>
          <Box className={classes.propellingSection}>
            <Container maxWidth="xl">
              <Grid container spacing={3} mb={{ md: 8, sm: 6, xs: 4 }}>
                <Grid item md={4}>
                  <VSNTypography
                    color="secondary.darkGrey"
                    variant="h5"
                    fontWeight={700}
                    className={classes.mdHeading}
                  >
                    Propelling Your Organization’s Potential with Behavioral
                    Health
                  </VSNTypography>
                </Grid>
                <Grid item md={8}>
                  <VSNTypography
                    color="secondary.grey"
                    variant="h6"
                    fontSize={{ lg: 24, md: 20, sm: 18, xs: 16 }}
                  >
                    Focusing solely on performance in teams can lead to
                    prolonged stress, increased friction, fractured connections,
                    eventually impacting overall wellbeing and company bottom
                    lines.
                    <VSNTypography
                      color="primary.basic"
                      variant="h6"
                      fontSize={{ lg: 24, md: 20, sm: 18, xs: 16 }}
                      fontWeight={700}
                    >
                      Our evidence-backed analytics, powered by the Vasana
                      Behavioral Genome, helps make workplaces happier and
                      productive.
                    </VSNTypography>
                  </VSNTypography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
                flexDirection="row-reverse"
              >
                <Grid item md={4}>
                  <VSNImage
                    className={classes.teamAboutImage}
                    src={propellingImg}
                    alt="propellingImg"
                  />
                </Grid>
                <Grid item md={7}>
                  <Box mb={5}>
                    <VSNTypography
                      variant="h5"
                      fontWeight={700}
                      color="primary.basic"
                      className={classes.propellingTitle}
                    >
                      Supercharge Your Team Performance
                    </VSNTypography>
                    <VSNTypography
                      color="secondary.grey"
                      variant="h6"
                      fontSize={{ xl: 24, lg: 20, md: 18, xs: 16 }}
                    >
                      We use behavioral science, advanced AI, neuroscience and
                      behavioral-based communication to enhance clarity, deflate
                      conflicts, reduce friction, build trust and accelerate
                      learning.
                    </VSNTypography>
                  </Box>
                  <Box mb={3}>
                    <VSNTypography
                      variant="h5"
                      fontWeight={700}
                      color="primary.basic"
                      className={classes.propellingTitle}
                    >
                      Nurture an Environment of Belonging
                    </VSNTypography>
                    <VSNTypography
                      color="secondary.grey"
                      variant="h6"
                      fontSize={{ xl: 24, lg: 20, md: 18, xs: 16 }}
                    >
                      We help improve relationships, develop empathy and
                      communicate effectively, driving cultural change in
                      organizations, creating an environment where trust, and
                      strong connections flourish.
                    </VSNTypography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Box
          className={classNames(
            landingClasses.spacing,
            classes.workplaceSection
          )}
          position="relative"
        >
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Box sx={{ position: "sticky", top: 100 }}>
                  <VSNTypography
                    variant="h6"
                    color="primary.basic"
                    fontSize={{ lg: 24, md: 20, xs: 18 }}
                  >
                    WHY VASANA
                  </VSNTypography>
                  <VSNTypography variant="h4" className={classes.mdHeading}>
                    Reimagining Workplace{" "}
                    <VSNTypography
                      variant="span"
                      color="primary.basic"
                      fontWeight={700}
                      className={classes.mdHeading}
                    >
                      Wellbeing and Mental Healthcare with Actionable Tools
                    </VSNTypography>
                  </VSNTypography>
                </Box>
              </Grid>
              <Grid item md={8}>
                {workplaceList?.map((data, i) => {
                  return (
                    <Box
                      className={classes.workplaceListBox}
                      sx={{ backgroundColor: data.bgColor }}
                      key={i}
                    >
                      <Box className={classes.workplaceListContent}>
                        <VSNTypography variant="h6" mb={2}>
                          {data.title}
                        </VSNTypography>
                        <VSNTypography color="secondary.grey">
                          {data.description}
                        </VSNTypography>
                      </Box>
                      <VSNImage
                        src={data.image}
                        alt="shape"
                        className={classNames(
                          classes.workplaceListImage,
                          classes[`workplaceListImage${i + 1}`]
                        )}
                      />
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
          </Container>
          <VSNImage
            src={section1Bg1}
            alt="shape"
            className={classNames(classes.section3Bg1, classes.sectionShape)}
          />
          <VSNImage
            src={shape6}
            alt="shape"
            className={classNames(classes.section3Bg2, classes.sectionShape)}
          />
          <VSNImage
            src={shape7}
            alt="shape"
            className={classNames(classes.section3Bg3, classes.sectionShape)}
          />
          <VSNImage
            src={section1Bg5}
            alt="shape"
            className={classNames(classes.section3Bg4, classes.sectionShape)}
          />
          <VSNImage
            src={section1Bg1}
            alt="shape"
            className={classNames(classes.section3Bg5, classes.sectionShape)}
          />
          <VSNImage
            src={section1Bg2}
            alt="shape"
            className={classNames(classes.section3Bg6, classes.sectionShape)}
          />
          <VSNImage
            src={section1Bg4}
            alt="shape"
            className={classNames(classes.section3Bg7, classes.sectionShape)}
          />
        </Box>
        <AchieveLogo title="Working with the Best to Achieve the Amazing" />
        <Box
          px={3}
          className={classNames(
            landingClasses.spacing,
            classes.workFlowSection
          )}
          position="relative"
          s
        >
          <Container maxWidth="xl" className={classes.workFlowBoxContainer}>
            <VSNTypography
              variant="h4"
              color="secondary.darkGrey"
              fontWeight={700}
              textAlign="center"
              mb={3}
              fontSize={{ lg: 40, md: 34, sm: 30, xs: 26 }}
            >
              How Vasana Works
            </VSNTypography>
            {workFlow?.map((data, i) => {
              return (
                <Box
                  key={i}
                  className={classNames(
                    classes[`workFlowBox${i + 1}`],
                    classes.workFlow
                  )}
                >
                  {data.icon}
                  <Box>
                    <VSNTypography variant="h6">{data.title}</VSNTypography>
                    <VSNTypography color="secondary.grey">
                      {data.description}
                    </VSNTypography>
                  </Box>
                  <Box className={classes[`workFlowBoxShape${i + 1}`]}>
                    {data.shape}
                  </Box>
                  {i !== workFlow.length - 2 && (
                    <Box className={classes.mobileLine}>
                      <GenomeShape5 />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Container>
        </Box>
        <FooterSlider mt={{ sm: 10, xs: 5 }} />
      </Box>
    </>
  );
}
