import { Box, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ReactComponent as Copy } from "../../../assets/admin/assessmentResult/svg/Copy.svg";
import shape2 from "../../../assets/admin/png/round-model-shape-2.png";
import shape1 from "../../../assets/admin/png/round-model-shape.png";
import { ReactComponent as Round } from "../../../assets/admin/svg/org-image.svg";
import { copyLinkContainer } from "../../../container/copyLink.container";
import { formContainer } from "../../../container/form.container";
import { linkInput } from "../../../description/createOrg.description";
import { sendOrg } from "../../../description/sendOrg.description";
import VSNButton from "../../../shared/VSNButton";
import VSNImage from "../../../shared/VSNImage";
import VSNLabel from "../../../shared/VSNLabel";
import VSNTextField from "../../../shared/VSNTextField";
import VSNTypography from "../../../shared/VSNTypography";
import ControlForm from "../../../shared/form/ControlForm";
import { copyToClipboard } from "../../../utils/javascript";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 40,
    borderBottom: "1px solid #FBCEBD",
    paddingBottom: 16,
    marginTop: 20,
    [theme.breakpoints.down("md")]: {
      marginBottom: 30,
      paddingBottom: 10,
    },
    "& svg": {
      borderRadius: "50%",
      [theme.breakpoints.down("md")]: {
        width: 40,
        height: 40,
      },
    },
    "& h5": {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "31px",
      marginLeft: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
        lineHeight: "26px",
      },
    },
  },
  shape: {
    position: "absolute",
  },
  shape1: {
    top: "0%",
    right: "30%",
    [theme.breakpoints.down("md")]: {
      top: "-5%",
    },
  },
  shape2: {
    top: "35%",
    right: "-2%",
    zIndex: "-10",
  },
  linkField: {
    "& p": {
      fontSize: 14,
      lineHeight: "18px",
      fontWeight: 700,
      color: theme.palette.secondary.grey,
      marginBottom: 4,
    },
    "& .MuiStack-root": {
      marginBottom: 54,

      [theme.breakpoints.down("md")]: {
        marginBottom: 30,
      },

      "& .MuiFormControl-root": {
        "& input": {
          color: theme.palette.primary.main,
          WebkitTextFillColor: "inherit",
          fontSize: 14,
          fontWeight: 500,
        },
      },
      "& svg": {
        marginLeft: 24,
        cursor: "pointer",
      },
    },
  },
  emailInfo: {
    marginBottom: 35,
    "& p": {
      fontSize: 14,
      color: theme.palette.secondary.grey,
    },
    "& .MuiButtonBase-root": {
      padding: [[12, 38]],
      flex: "none",
      marginLeft: 15,
      margin: 0,
      lineHeight: "21px",
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        marginTop: 20,
        marginLeft: 0,
        width: "100%",
      },
    },
  },
  formGroup: {
    marginBottom: 30,
  },
}));

const CopyLinkModal = ({ mode, currentRow }) => {
  const classes = useStyles();
  const {
    orgLink,
    sendEmail,
    sendLinkLoader,
    handleChange,
    formPath,
    handleCancel,
  } = copyLinkContainer();
  const { formData } = formContainer({ formPath });
  return (
    <Box>
      <VSNImage
        src={shape1}
        className={`${classes.shape1} ${classes.shape}`}
        alt="shape"
      />
      <VSNImage
        src={shape2}
        className={`${classes.shape2} ${classes.shape}`}
        alt="shape"
      />
      <Stack direction="row" alignItems="center" className={classes.heading}>
        <Box>
          <Round />
        </Box>
        <VSNTypography variant="h5" component="h5">
          {mode === "send"
            ? "Send Organization Link"
            : "Copy Organization Link"}
        </VSNTypography>
      </Stack>
      <Box className={classes.linkField}>
        <VSNTypography variant="body1" component="p">
          {" "}
          Share the Organization Assessment Link
        </VSNTypography>
        <Stack direction="row" alignItems="center">
          <VSNTextField
            {...{
              linkInput,
              value: mode === "send" ? currentRow?.organization_link : orgLink,
            }}
            fullWidth
          />
          <Copy
            onClick={() => copyToClipboard(currentRow?.organization_link)}
          />
        </Stack>
      </Box>
      {mode === "send" && (
        <Grid item xs={12}>
          {sendOrg?.map(
            (
              { isRequired, label, errorMessage, name, ...attribute },
              index
            ) => {
              return (
                <Box className={classes.formGroup} key={index}>
                  <VSNLabel required={isRequired} label={label} />
                  <ControlForm
                    {...{
                      ...attribute,
                      onChange: handleChange,
                      name,
                      value: formData?.[name],
                    }}
                  />
                </Box>
              );
            }
          )}
        </Grid>
      )}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        className={classes.emailInfo}
        justifyContent="flex-end"
      >
        {mode !== "send" && (
          <VSNTypography variant="body1" component="p">
            {" "}
            By clicking on the ‘Send Email’ you can directly send this link to
            the point of contact in the organization
          </VSNTypography>
        )}

        {mode === "send" && (
          <VSNButton handleClick={handleCancel} variant="outlined">
            Cancel
          </VSNButton>
        )}
        <VSNButton
          handleClick={() => sendEmail(mode, currentRow)}
          isLoading={sendLinkLoader}
        >
          Send Email
        </VSNButton>
      </Stack>
    </Box>
  );
};

export default CopyLinkModal;
