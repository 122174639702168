import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_API_DATA, SET_USER_DATA } from "../redux/constants";
import { ApiContainer } from "../utils/api";
import { getAssessmentState, getSlug } from "../shared/CustomFunc";
import { assessmentIds } from "../utils/constant";

export const assessmentsViewContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { api } = ApiContainer();
  const apiState = useSelector((state) => state?.api);
  const assessmentListLoader = useSelector(
    (state) => state?.api?.loader?.assessmentList
  );
  const markerInfo = useSelector((state) => state?.api?.markerInfo);
  const userRoleData = useSelector((state) => state.app.userData);
  const allAssessmentResults = useSelector(
    (state) => state?.api?.assessmentResult
  );
  const [resultInfo, setResultInfo] = useState({});

  const getAssessments = async () => {
    const keyword =
      !apiState?.tabs?.assessmentTab || apiState?.tabs?.assessmentTab === "all"
        ? ""
        : apiState?.tabs?.assessmentTab;

    const filterKeyword = apiState?.tabs?.assessmentFilterTab;
    const assessmentListResponse = await api({
      method: "GET",
      endPoint: `assessment/list${
        filterKeyword ? `?assessment_type=${filterKeyword}` : ""
      }${keyword ? `${filterKeyword ? "&" : "?"}key_word=${keyword}` : ""}`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "assessmentList",
    });
    if (assessmentListResponse) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentList:
            assessmentListResponse?.data?.message === "No Data Found"
              ? []
              : assessmentListResponse?.data || [],
        },
      });
    }
  };

  useEffect(() => {
    if (apiState?.tabs?.assessmentTab) getAssessments();
  }, [apiState?.tabs?.assessmentTab, apiState?.tabs?.assessmentFilterTab]);

  useEffect(() => {
    dispatch({
      type: SET_API_DATA,
      payload: {
        tabs: {
          ...apiState?.tabs,
          assessmentTab: "all",
        },
      },
    });
  }, []);

  useEffect(() => {
    const result = apiState?.assessmentList?.length
      ? apiState?.assessmentList?.find((el) => el?.name === "Vasana Assessment")
          ?.result
      : null;
    if (result) {
      setResultInfo({ ...markerInfo?.[result?.toLowerCase()] });
    }
  }, [apiState?.assessmentList]);

  const handleTabChange = (value) => {
    dispatch({
      type: SET_API_DATA,
      payload: {
        tabs: {
          ...apiState?.tabs,
          assessmentTab: value,
        },
      },
    });
  };
  const onFilterChange = (e) => {
    dispatch({
      type: SET_API_DATA,
      payload: {
        tabs: {
          ...apiState?.tabs,
          assessmentFilterTab: e?.target?.value,
        },
      },
    });
  };

  const handleButtonClick = (name, assessmentId, type, submissionId) => {
    localStorage.setItem("currentAssessmentId", assessmentId);
    if (name === "Vasana Assessment") {
      navigate(`/`);
    } else if (submissionId) {
      navigate(`/assessments/${assessmentId}`);
    } else {
      const slug = getSlug(apiState?.assessmentList, assessmentId);
      navigate(`/get-started/${slug}`);
    }
  };

  const retakeAssessment = (assessmentId, type) => {
    dispatch({
      type: SET_API_DATA,
      payload: {
        ...(assessmentId === assessmentIds.vasanaType
          ? { downloadVasanaTypeReport: null }
          : assessmentId === assessmentIds.introExtro
          ? { downloadIntroExtroReport: null }
          : {}),
        assessmentResult: { ...allAssessmentResults, [assessmentId]: null },
      },
    });
    localStorage.setItem("currentAssessmentId", assessmentId);
    const slug = getSlug(apiState?.assessmentList, assessmentId);
    navigate(`/get-started/${slug}`);
  };

  return {
    assessmentTab: apiState?.tabs?.assessmentTab,
    assessmentFilterTab: apiState?.tabs?.assessmentFilterTab,
    handleTabChange,
    assessmentList: apiState?.assessmentList,
    assessmentListLoader,
    resultInfo,
    handleButtonClick,
    retakeAssessment,
    userRoleData,
    onFilterChange,
  };
};
