import { useMediaQuery } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ score, maxScore }) => {
  const matches = useMediaQuery("(max-width:900px)");
  const positiondata = matches ? "bottom" : "top";
  const roundedScore = Math.ceil(maxScore / 5) * 5;

  const series = [
    {
      name: "Individual",
      data: [null, `${score ?? null}`, null],
    },
    {
      name: "Department",
      data: [null, 8, null],
    },
    {
      name: "Population",
      data: [null, 12, null],
    },
  ];

  const chartOptions = {
    chart: {
      type: "bar",
      height: 260,
      toolbar: {
        show: false,
      },
    },
    colors: ["#FBCEBD", "#97C8E9", "#82C799"],
    markers: {
      size: [7],
      colors: ["#1780C6"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [2023, 2024, 2025],
    },
    yaxis: {
      min: 0,
      max: roundedScore,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {},
    legend: {
      position: positiondata,
    },
  };

  return (
    <div id="chart1">
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="bar"
        height={270}
      />
      <div id="html-dist1" />
    </div>
  );
};

export default BarChart;
